import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Redirect } from 'react-router-dom';

import {
  Button,
  Form,
  Grid,
  Header,
  Input,
  Message,
  Segment
} from 'semantic-ui-react';

import classNames from 'classnames';

import '../css/Login.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import Auth from '../managers/AuthManager';
import navigationManager, { VIEW_SELECTION } from '../managers/NavigationManager';
import { ERROR_MESSAGES } from '../managers/UserManager';

import DynamicSatelliteService from '../services/DynamicSatelliteService';

export default @inject(
  'dynamicSatelliteManager', 'userManager')
@observer
class RegisterInvite extends Component {
  teacherRegisterSuccessMessage = 'Registration complete, check your email to verify your account.';

  studentRegisterSuccessMessage = 'Registration complete, check your email to verify your account.';

  publisherSatelliteCode = Auth.publisherSatelliteCode;

  userId = '';
  authKey = '';

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      passwordInputType: 'password',
      passwordInputType2: 'password',
      firstName: {
        value: '',
        error: null
      },
      lastName: {
        value: '',
        error: null
      },
      email: {
        value: '',
        error: null
      },
      password: {
        value: '',
        error: null
      },
      confirmPassword: {
        value: '',
        error: null
      },

      submitted: false,

      success: false,
      student: false,
      username: ''
    };
    this.Logo = SatCoreComponent('Logo');
    this.SatCoreLoader = SatCoreComponent('SatCoreLoader');
    this.ShowPasswordButton = SatCoreComponent('ShowPasswordButton');
  }

  async componentDidMount() {
    this.setState({ loaded: false });

    const { dynamicSatelliteManager, userManager } = this.props;

    navigationManager.setView(VIEW_SELECTION.LOGIN);
    this.userId = this.props.match.params.id;
    const result = await userManager.getUserInvitation(this.userId);
    this.authKey = result.authKey;

    this.setState((prevState) => {
      const newState = { ...prevState };
      newState.firstName.value = result.firstName;
      newState.lastName.value = result.lastName;
      newState.email.value = result.username;
      return newState;
    });

    if (dynamicSatelliteManager.isDynamicSatellite && !Auth.publisherSatelliteCode) {
      await DynamicSatelliteService.fetchDynamicSatelliteByDomain();
      setTimeout(() => {
        this.setState({ loaded: true });
        this.publisherSatelliteCode = Auth.publisherSatelliteCode;
      }, 500);
    } else {
      this.setState({ loaded: true });
    }
  }

  handleShowPasswordPressed = (name, isPasswordInput) => {
    const inputType = isPasswordInput ? 'password' : 'text';
    this.setState({ [name]: inputType });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => {
      const newState = { ...prevState };
      newState[name].value = value;
      return newState;
    });
  }

  handleChangePassword = (e) => {
    this.handleChange(e);
    this.validateConfirmPassword(this.state.confirmPassword.value, e.target.value);
  }

  handleChangeConfirmPassword = (e) => {
    this.handleChange(e);
    this.validateConfirmPassword(this.state.password.value, e.target.value);
  };

  validateConfirmPassword = (password, confirmPassword) => {
    this.setError(
      'confirmPassword',
      password && confirmPassword && !password.includes(confirmPassword) ? ERROR_MESSAGES.PASSWORDS_DO_NOT_MATCH : null
    );
  };

  setError = (name, error) => {
    this.setState((prevState) => {
      const newState = { ...prevState };
      newState[name].error = error;
      return newState;
    });
  };

  clearError = (e) => {
    this.setError(e.target.name, null);
  };

  validate = async () => {
    const validEmail = await this.validateEmail();
    const validPassword = this.validatePassword();
    return validEmail && validPassword;
  }

  validateEmail = async () => {
    const email = { ...this.state.email };
    let valid = true;

    const emailRegexp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    email.error = null;
    if (!emailRegexp.test(email.value)) {
      email.error = ERROR_MESSAGES.VALID_EMAIL_FORMAT;
      valid = false;
    }

    this.setState({ email });
    return valid;
  }

  validatePassword = () => {
    const { password, confirmPassword } = this.state;
    let valid = true;
    if (password.value !== confirmPassword.value) {
      confirmPassword.error = ERROR_MESSAGES.PASSWORDS_DO_NOT_MATCH;
      valid = false;
    } else {
      confirmPassword.error = null;
    }
    if (password.value !== null && password.value.length > 0 && password.value.length < 8) {
      password.error = ERROR_MESSAGES.PASSWORD_LENGTH;
      valid = false;
    } else {
      password.error = null;
    }
    this.setState({ password, confirmPassword });
    return valid;
  }

  isComplete = () => {
    const { firstName, lastName, email, password, confirmPassword } = this.state;
    return !!(
      firstName.value.length && !firstName.error &&
      lastName.value.length && !lastName.error &&
      email.value.length && !email.error &&
      password.value.length && !password.error &&
      confirmPassword.value.length && !confirmPassword.error &&
      password.value === confirmPassword.value
    );
  }

  handleAutoLogin = async () => {
    const loggedIn = await Auth.login(this.state.email.value, this.state.password.value);
    if (loggedIn) {
      this.props.history.replace('/');
    }
  }

  handleFormSubmit = async (e) => {
    e.preventDefault();
    const { userManager } = this.props;
    const valid = await this.validate();
    if (!valid) {
      return;
    }
    this.setState({ submitted: true });

    const { userId, authKey } = this;
    const password = this.state.password.value;
    const firstName = this.state.firstName.value;
    const lastName = this.state.lastName.value;

    const result = await userManager.activateUserInvitation(userId, firstName, lastName, password, authKey);
    if (result) {
      this.setState({
        success: true,
        username: this.state.email.value
      });
    }
  }

  renderForm() {
    const { t } = this.props;
    const { email, password, confirmPassword, passwordInputType, passwordInputType2, submitted } = this.state;
    const { ShowPasswordButton } = this;
    return (
      <>
        <Form.Field>
          <Form.Input
            label={t('firstName', 'First Name')}
            name='firstName'
            onChange={this.handleChange}
            onFocus={this.clearError}
            placeholder={t('firstName', 'First Name')}
            type='text'
            value={this.state.firstName.value}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            label={t('lastName', 'Last Name')}
            name='lastName'
            onChange={this.handleChange}
            onFocus={this.clearError}
            placeholder={t('lastName', 'Last Name')}
            type='text'
            value={this.state.lastName.value}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            label={t('username', 'Username/email')}
            name='email'
            onChange={this.handleChange}
            onFocus={this.clearError}
            placeholder='name@email.com'
            readOnly={true}
            type='text'
            value={this.state.email.value}
          />
          <Message
            content={email.error}
            error
            visible={email.error !== null} />
        </Form.Field>
        <Form.Field>
          <label>{t('passwordLabel', 'Enter your password')}</label>
          <Input
            autoComplete='current-password'
            label={(
              <ShowPasswordButton
                isPassword={passwordInputType === 'password'}
                name='passwordInputType'
                onFlip={this.handleShowPasswordPressed} />
            )}
            labelPosition='right'
            name='password'
            onChange={this.handleChangePassword}
            onFocus={this.clearError}
            onKeyPress={(e) => e.stopPropagation()}
            placeholder={t('enterPassword', 'Enter password')}
            type={passwordInputType} />
          {/* <div className='note'>
            (
            {t('passwordsNote', 'Passwords are required to be at least 5 characters long.')}
            )
          </div> */}
        </Form.Field>
        <Message
          content={password.error}
          error
          visible={password.error !== null} />
        <Form.Field>
          <label>{t('confirmPasswordLabel', 'Confirm your password')}</label>
          <Input
            autoComplete='current-password'
            disabled={password.value.length <= 7}
            label={(
              <ShowPasswordButton
                isPassword={passwordInputType2 === 'password'}
                name='passwordInputType2'
                onFlip={this.handleShowPasswordPressed} />
            )}
            labelPosition='right'
            name='confirmPassword'
            onChange={this.handleChangeConfirmPassword}
            onFocus={this.clearError}
            onKeyPress={(e) => e.stopPropagation()}
            placeholder={t('confirmPassword', 'Confirm password')}
            type={passwordInputType2} />
          <Message
            content={confirmPassword.error}
            error
            visible={confirmPassword.error !== null} />
        </Form.Field>
        <Form.Field>
          <Button disabled={submitted || !this.isComplete()} onClick={this.handleFormSubmit} primary>
            Submit
          </Button>
        </Form.Field>
      </>
    );
  }

  renderSuccessMessage() {
    const { t } = this.props;
    return (
      <p>
        <Button basic className='homepage' onClick={this.handleAutoLogin} primary>
          {t('homepage', 'Take me to my home page')}
        </Button>
      </p>
    );
  }

  render() {
    const { SatCoreLoader } = this;
    const { t } = this.props;
    const { loaded, success } = this.state;
    if (Auth.loggedIn()) {
      return <Redirect to='/' />;
    }
    const { Logo } = this;

    return loaded ? (
      <Grid className='login-body register-body' textAlign='center' verticalAlign='middle'>
        <Grid.Column className='max-width-558'>
          <Form>
            <Header as='h2' attached='top' block>
              <Logo />
              <span className='header-text'>
                {t('registration', 'Self-Registration')}
                {' '}
                {success ? 'Success' : null}
              </span>
            </Header>
            <Segment attached className={classNames('element-body-login element-body-login-register', {
              'element-body-login-register-success': success
            })}>
              {success ? this.renderSuccessMessage() : this.renderForm()}
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    ) : <SatCoreLoader active />;
  }
}

SatCoreRegister('RegisterInvite', RegisterInvite);
