import { ItemBankService, userManager } from 'sat-core';

ItemBankService.sortLibraryCardKebabOptions = (options) => {
    if (userManager.isTeacher) {
        const customOptions = {};
        customOptions['edit'] = options.edit;
        customOptions['details'] = options.details;
        customOptions['preview'] = options.preview;
        customOptions['teacherPreview'] = options.teacherPreview;
        customOptions['copy'] = options.copy;
        customOptions['delete'] = options.delete;
        return customOptions;
    } else {
        return options;
    }
}
