import React, { useContext, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import InfiniteScroll from 'react-infinite-scroller';

import classNames from 'classnames';

import {
  Button, Checkbox, Icon, Image, Loader, Pagination, Table
} from 'semantic-ui-react';

import iconInstructions from '../img/icon-instructions.svg';
import iconPodium from '../img/icon-podium.svg';
import iconSurvey from '../img/icon-survey.svg';
import iconView from '../img/icon-view.svg';

import audio from '../img/resources/Audio.png';
import articleQuestion from '../img/resources/ArticleQuestion.png';
import imageIcon from '../img/resources/Image.png';
import imageQuestion from '../img/resources/ImageQuestion.png';
import pdf from '../img/resources/Pdf.png';
import userFile from '../img/resources/UserFile.png';
import video from '../img/resources/Video.png';
import videoQuestion from '../img/resources/VideoQuestion.png';
import webPage from '../img/resources/WebPage.png';

import '../css/GradebookDetailsTable.less';

import { SatCoreRegister } from '../SatCoreRegistry';

import { dateFormat, stripImgHtmlTags, toFixed } from '../utils';

import { ASSIGNMENT_STATUS, ASSIGNMENT_TYPE } from '../managers/AssignmentManager';

import GradebookService, { RESPONSE_CONTENT_STATE, RESPONSE_SUBMITTED_STATE, SCORE_STATE } from '../services/GradebookService';
import PopupService from '../services/PopupService';
import UsageService from '../services/UsageService';
import UtilityService from '../services/UtilityService';

const GradebookDetailsTable = observer((props) => {
  const { t } = props;

  const { assignmentManager, gradebookManager } = useContext(MobXProviderContext);

  const {
    activeGradebookType,
    allowLoadMoreAggregateTableRows,
    hasMoreAggregateTableRows
  } = gradebookManager;

  const isLoading = gradebookManager.gradebookManagerLoadingFlagActive;

  const isReadOnly = GradebookService.isReadOnly();

  const isLearnosity = props.assignmentEntityTypeId?.includes?.('learnosity');

  const PAGE_SIZE = gradebookManager.GRADEBOOK_DETAILS_TABLE_HORIZONTAL_SCORES_PAGE_SIZE;

  const column = gradebookManager.gradebookDetailsTableSortColumn;
  const direction = gradebookManager.gradebookDetailsTableSortDirection;

  const urlParams = new URLSearchParams(window.location.search);
  const assignmentId = urlParams.get('assignmentId');

  const [allowSort, setAllowSort] = useState(true);

  const [loadingActivePopupContentFunction, setLoadingActivePopupContentFunction] = useState(false);

  const handleToggleHideUnscorableColumns = async (_event, data) => {
    const shouldHideUnscorableColumns = data.checked;
    gradebookManager.setShouldHideUnscorableColumns(shouldHideUnscorableColumns);
    await props.refreshAllGradebookData();
  };

  const handleLoadMore = async (page) => {
    const assignment = await assignmentManager.fetchActivity(assignmentId);

    const sortField = gradebookManager.gradebookDetailsTableSortColumn;
    const sortDirection = gradebookManager.gradebookDetailsTableSortDirection;

    const pageSize = undefined;
    const clearStudentsFirst = undefined;
    const clearAllFirst = undefined;
    const functions = {
      isSpecialAggregateGradebookDetailsSortCase: GradebookService.isSpecialAggregateGradebookDetailsSortCase
    };
    await gradebookManager.fetchAggregateGradebookDetails(
      assignment, sortField, sortDirection, page,
      pageSize, clearStudentsFirst, clearAllFirst, functions
    );
  };

  const handleSort = async (clickedColumn, headerScoreIndex = -1) => {
    if (!allowSort) {
      return;
    }
    if (allowLoadMoreAggregateTableRows) {
      gradebookManager.setAllowLoadMoreAggregateTableRows(false);
      if (GradebookService.isSpecialAggregateGradebookDetailsSortCase(clickedColumn, headerScoreIndex)) {
        await GradebookService.handleSpecialAggregateGradebookDetailsSortCase(
          clickedColumn, headerScoreIndex
        );
      } else {
        await GradebookService.handleGradebookDetailsSortOnBackend(clickedColumn);
      }
      gradebookManager.setAllowLoadMoreAggregateTableRows(true);
    }
  };

  const handleHorizontalScoresPageChange = async (_event, pageInfo) => {
    const totalPages = gradebookManager.gradebookDetailsTableHorizontalScoresPageTotal;
    if (totalPages > 1) {
      const { activePage } = pageInfo;
      gradebookManager.setActiveGradebookDetailsHorizontalScoresPage(activePage);
    }
  };

  const renderTableHeader = () => {
    const { hideDetailsDates, showDetailsGradeAsRawScore, showDetailsGradeAsPercentage } = gradebookManager;
    const activePage = gradebookManager.activeGradebookDetailsHorizontalScoresPage;

    const paginatedHeaderInfo = GradebookService.getPaginatedGradebookDetailsHeaderInfo();

    return (
      <Table.Row
        className={isLoading ? 'top-header-row loading-row' : 'top-header-row'}
        disabled={isLoading}>

        {/* HEADER: ACTIVITY NUMBER (ASGMT) */}
        <Table.HeaderCell
          key='key-header-cell-activity-number'
          className='cell-activity-number'
          onClick={() => handleSort('activityNumber')}
          sorted={column === 'activityNumber' ? direction : null}>
          <span>{t('activityNumber')}</span>
        </Table.HeaderCell>

        {/* HEADER: START DATE */}
        {!hideDetailsDates && (
        <Table.HeaderCell
          key='key-header-cell-start-date'
          className={`cell-start-date ${activeGradebookType}`}
          onClick={() => handleSort('activityTimezoneStartDate')}
          sorted={column === 'activityTimezoneStartDate' ? direction : null}>
          {t('activityTimezoneStartDate')}
        </Table.HeaderCell>
        )}

        {/* HEADER: END DATE */}
        {!hideDetailsDates && (
        <Table.HeaderCell
          key='key-header-cell-end-date'
          className={`cell-end-date ${activeGradebookType}`}
          onClick={() => handleSort('activityTimezoneEndDate')}
          sorted={column === 'activityTimezoneEndDate' ? direction : null}>
          {t('activityTimezoneEndDate')}
        </Table.HeaderCell>
        )}

        {/* HEADER: FIRST NAME */}
        <Table.HeaderCell
          key='key-header-cell-first-name'
          className={`cell-first-name ${activeGradebookType}`}
          onClick={() => handleSort('firstName')}
          sorted={column === 'firstName' ? direction : null}>
          <span>{t('firstName')}</span>
        </Table.HeaderCell>

        {/* HEADER: LAST NAME */}
        <Table.HeaderCell
          key='key-header-cell-last-name'
          className={`cell-last-name ${activeGradebookType}`}
          onClick={() => handleSort('lastName')}
          sorted={column === 'lastName' ? direction : null}>
          <span>{t('lastName')}</span>
        </Table.HeaderCell>

        {/* HEADER: USAGE */}
        {!isLearnosity && (
          <Table.HeaderCell
            key='key-header-cell-usage'
            className={`cell-usage ${activeGradebookType}`}
            onClick={() => handleSort('viewedTime')}
            sorted={column === 'viewedTime' ? direction : null}>
            <span>{t('viewedTimeHeader', 'Usage')}</span>
          </Table.HeaderCell>
        )}

        {/* HEADER: GRADE */}
        {showDetailsGradeAsPercentage && (
          <Table.HeaderCell
            key='key-header-cell-grade'
            className={`cell-grade ${activeGradebookType}`}
            onClick={() => handleSort('grade')}
            sorted={column === 'grade' ? direction : null}>
            <span>{t('gradeHeaderLabel')}</span>
          </Table.HeaderCell>
        )}

        {/* HEADER: GRADE */}
        {showDetailsGradeAsRawScore && (
          <Table.HeaderCell
            key='key-header-cell-score'
            className={`cell-grade ${activeGradebookType}`}
            onClick={() => handleSort('grade')}
            sorted={column === 'grade' ? direction : null}>
            <span>{t('scoreHeaderLabel')}</span>
          </Table.HeaderCell>
        )}

        {/* HEADER: SCORE INDEXES */}
        {paginatedHeaderInfo.map((cellHeaderInfo, headerScoreIndex) => {
          const { elementId, item, number, type } = cellHeaderInfo;

          const isSurvey = item === 'Survey';
          const isScorableSurvey = item === 'ScorableSurvey';
          const isItem = item === 'Item';

          const trueHeaderScoreIndex = headerScoreIndex + ((activePage - 1) * PAGE_SIZE);

          const className = `cell-score ${trueHeaderScoreIndex} ${activeGradebookType}`;

          const slideNumberLabel = cellHeaderInfo.label;

          let headerItemNumberLabel;

          if (isSurvey || isScorableSurvey) {
            headerItemNumberLabel = (
              <Image className='header-icon-survey' src={iconSurvey} />
            );
          } else if (isItem) {
            headerItemNumberLabel = number;
          } else if (type === 'StudentInstruction' || type === 'TeacherInstruction') {
            const icon = gradebookManager.shouldUsePodiumIconForInstructions ? iconPodium : iconInstructions;
            headerItemNumberLabel = (
              <Image className='header-icon-podium' src={icon} />
            );
          } else {
            switch (type) {
              case 'Audio': {
                headerItemNumberLabel = (
                  <Image className='header-icon' src={audio} />
                );
                break;
              }
              case 'Video': {
                headerItemNumberLabel = (
                  <Image className='header-icon' src={video} />
                );
                break;
              }
              case 'UserFile': {
                headerItemNumberLabel = (
                  <Image className='header-icon' src={userFile} />
                );
                break;
              }
              case 'Image': {
                headerItemNumberLabel = (
                  <Image className='header-icon' src={imageIcon} />
                );
                break;
              }
              case 'ImageQuestion': {
                headerItemNumberLabel = (
                  <Image className='header-icon' src={imageQuestion} />
                );
                break;
              }
              case 'VideoQuestion': {
                headerItemNumberLabel = (
                  <Image className='header-icon' src={videoQuestion} />
                );
                break;
              }
              case 'WebPage': {
                headerItemNumberLabel = (
                  <Image className='header-icon' src={webPage} />
                );
                break;
              }
              case 'Pdf': {
                headerItemNumberLabel = (
                  <Image className='header-icon' src={pdf} />
                );
                break;
              }
              default: {
                headerItemNumberLabel = (
                  <Image className='header-icon' src={articleQuestion} />
                );
              }
            }
          }

          let cellActivityElement, excludeFromScoring, isScorable, maxScore;

          // eslint-disable-next-line prefer-destructuring
          const students = gradebookManager.gradebookDetails.students;

          // For the table header exclusion checkbox, we don't want it to be based off the first students
          // exclusion. Instead we loop over the students and check the specific item. If any of the items
          // are not exluded then the header will show not exluded as well.
          if (students) {
            excludeFromScoring = true;
            for (let i = 0; i < students.length; i++) {
              if (students[i].activityElements[trueHeaderScoreIndex].excludeFromScoring === false) {
                excludeFromScoring = false;
              }
            }
          }
          // Use the first student to get the activity element for the current index.
          const student = students && students[0];
          if (student.activityElements) {
            cellActivityElement = student.activityElements[trueHeaderScoreIndex];
          }
          // eslint-disable-next-line prefer-destructuring
          const activityId = student.activityId;
          // TODO unused // const paginatedStudentMaxScores = GradebookService.getPaginatedGradebookDetailsStudentMaxScores(student);
          const questionStem = student.questionStems[trueHeaderScoreIndex];

          if (cellActivityElement) {
            if (cellActivityElement.scorable !== undefined) {
              isScorable = !!cellActivityElement.scorable;
            }
          }

          const clickedColumn = slideNumberLabel;
          const scoreLabelTrigger = headerItemNumberLabel;

          const scoreCellDetails = {
            activityId,
            cellActivityElement,
            cellHeaderInfo,
            elementId,
            excludeFromScoring,
            instanceInfo: student,
            isScorable,
            isSurvey,
            maxScore,
            questionStem,
            scoreIndex: trueHeaderScoreIndex,
            slideNumberLabel,
            questionNum: (isItem || isSurvey || isScorableSurvey) ? number : null,
            tableSection: 'header'
          };

          let scoreLabelContent;

          if (isScorable || isSurvey || !isItem) {
            scoreLabelContent = {
              children: () => renderScoreLabelContent(scoreCellDetails)
            };
          }
          return (
            <Table.HeaderCell
              key={`${trueHeaderScoreIndex}`}
              // TODO removed because it was causing popup to have hovering issues.
              // elementId is usually undefined and randomKey changes on every render which causes the popup key to change.
              // key={`key-header-cell-score-${trueHeaderScoreIndex}-${elementId || randomKey()}`}
              className={className}
              onClick={() => handleSort(clickedColumn, trueHeaderScoreIndex)}
              sorted={column === clickedColumn ? direction : null}>
              {(isScorable || isSurvey || !isItem) ? renderPopup(
                scoreLabelContent, scoreLabelTrigger,
                'cell-score-popup', 'top center', {
                  // mouseEnterDelay: 250,
                  // mouseLeaveDelay: 750,
                  mouseEnterDelay: 400,
                  mouseLeaveDelay: 400,
                  offset: [0, 16]
                }) : scoreLabelTrigger}
            </Table.HeaderCell>
          );
        })}
      </Table.Row>
    );
  };

  const renderTableBodyRows = () => {
    const { headerInfo, students } = gradebookManager.gradebookDetails;
    const { getAssignmentNumberCell, t } = props;
    const { hideDetailsDates, showDetailsGradeAsRawScore, showDetailsGradeAsPercentage } = gradebookManager;

    const rows = [];

    const isDetailScoreCellReadOnly = isReadOnly || isLearnosity;

    // table body rows
    // eslint-disable-next-line array-callback-return
    students.map((student, studentIndex) => {
      // eslint-disable-next-line prefer-destructuring
      const activityId = student.activityId;
      const paginatedStudentMaxScores = GradebookService.getPaginatedGradebookDetailsStudentMaxScores(student);

      const paginatedStudentScores = GradebookService.getPaginatedGradebookDetailsStudentScores(student);
      const paginatedHeaderInfo = GradebookService.getPaginatedGradebookDetailsHeaderInfo();

      const emptyScoreCellsLength = paginatedHeaderInfo.length - paginatedStudentScores.length;
      for (let i = 0; i < emptyScoreCellsLength; i++) {
        paginatedStudentScores.push('—');
      }

      const activePage = gradebookManager.activeGradebookDetailsHorizontalScoresPage;

      const isSubmitted = !!(student && student.submitted);

      rows.push(
        <Table.Row
          key={`key-data-row-${studentIndex}-${activityId}`}
          className={isLoading ? 'loading-row' : ''}
          disabled={isLoading}>

          {/* BODY: ACTIVITY NUMBER (ASGMT) */}
          <Table.Cell
            key={`key-data-cell-activity-number-${studentIndex}-${activityId}`}
            className={classNames('cell-activity-number', {
              'is-read-only': isReadOnly
            })}>
            <span>
              {getAssignmentNumberCell(student, t)}
            </span>
          </Table.Cell>

          {/* BODY: START DATE */}
          {!hideDetailsDates && (
            <Table.Cell className={classNames(`cell-start-date ${activeGradebookType}`, {
              'is-read-only': isReadOnly
            })}>
              <span>
                {dateFormat(student.activityTimezoneStartDate, 'twoDigitDate')}
              </span>
            </Table.Cell>
          )}

          {/* BODY: END DATE */}
          {!hideDetailsDates && (
            <Table.Cell className={classNames(`cell-end-date ${activeGradebookType}`, {
              'is-read-only': isReadOnly
            })}>
              <span>
                {dateFormat(student.activityTimezoneEndDate, 'twoDigitDate')}
              </span>
            </Table.Cell>
          )}

          {/* BODY: FIRST NAME */}
          <Table.Cell
            key={`key-data-cell-first-name-${studentIndex}-${activityId}`}
            className={classNames(`cell-first-name ${activeGradebookType}`, {
              'is-read-only': isReadOnly
            })}>
            {renderPopup(student.firstName)}
          </Table.Cell>

          {/* BODY: LAST NAME */}
          <Table.Cell
            key={`key-data-cell-last-name-${studentIndex}-${activityId}`}
            className={classNames('cell-last-name', {
              'is-read-only': isReadOnly
            })}>
            {renderPopup(student.lastName)}
          </Table.Cell>

          {/* BODY: USAGE */}
          {!isLearnosity && (
            <Table.Cell
              key={`key-data-cell-usage-${activityId}`}
              className={classNames(`cell-usage ${activeGradebookType}`, {
                'is-read-only': isReadOnly
              })}>
              {renderPopup(
                renderContentsWithLabel([
                  {
                    content: UsageService.getUnroundedUsageLabel(student.viewedTime),
                    translationKey: 'viewedTime'
                  }
                ]),
                UsageService.getUsageLabel({
                  lettersToAppendWhenNotZero: 'm',
                  lettersToAppendWhenZero: 's',
                  showUnroundedOnHover: false,
                  viewedTimeInSeconds: student.viewedTime
                }), 'cell-usage-popup'
              )}
            </Table.Cell>
          )}

          {/* BODY: GRADE */}
          {showDetailsGradeAsPercentage && (
            <Table.Cell
              key={`key-data-cell-grade-${activityId}`}
              className={classNames(`cell-grade ${activeGradebookType}`, {
                'is-read-only': isReadOnly
              })}>
              {props.showGradeColumn(student, true)}
            </Table.Cell>
          )}

          {/* BODY: Raw Score */}
          {showDetailsGradeAsRawScore && (
            <Table.Cell
              key={`key-data-cell-score-${activityId}`}
              className={classNames(`cell-grade ${activeGradebookType}`, {
                'is-read-only': isReadOnly
              })}>
              {props.showGradeColumn(student, false)}
            </Table.Cell>
          )}

          {/* BODY: SCORE INDEXES */}
          {paginatedStudentScores.map((score, scoreIndex) => {
            if (score === '—') {
              return (
                <>
                  <Table.Cell
                    key={`key-data-cell-${activityId}-${(student.studentId || student.id)}-${scoreIndex}`}
                    className={classNames('cell-score empty', {
                      'is-read-only': isDetailScoreCellReadOnly
                    })}>
                    <span>—</span>
                  </Table.Cell>
                </>
              );
            }
            const trueScoreIndex = scoreIndex + ((activePage - 1) * PAGE_SIZE);

            const isScorable = !!student.activityElements[trueScoreIndex].scorable;
            //const isAutoScored = !!student.activityElements[trueScoreIndex].autoScored;
            const excludeFromScoring = !!student.activityElements[trueScoreIndex].excludeFromScoring;

            const { NOT_SCORED } = SCORE_STATE;
            const { HAS_RESPONSE_CONTENT } = RESPONSE_CONTENT_STATE;
            const { RESPONSE_SUBMITTED } = RESPONSE_SUBMITTED_STATE;

            const scoreState = student.scoreStates[trueScoreIndex];
            const responseContentState = student.responseContentStates[trueScoreIndex];
            const resonseSubmittedState = student.responseSubmittedStates[trueScoreIndex];
            const isResponseSubmitted = resonseSubmittedState === RESPONSE_SUBMITTED;


            let maxScore = paginatedStudentMaxScores[scoreIndex];
            if (typeof maxScore !== 'number' || !isScorable) {
              maxScore = t('notAvailableDash');
            }
            let shouldRenderPopup = isScorable;
            let scoreLabel;

            // case 1: scorable and unscored
            if (isScorable && (typeof score !== 'number' || scoreState === NOT_SCORED)) {
              scoreLabel = '';
              // *** leaving this for now in case we want to end up using the special character for auto scored that aren't submitted.
              // if (isAutoScored) {
              //   console.log("--- isAutoScored");//remove
              //   scoreLabel = (!isDetailScoreCellReadOnly && responseContentState === HAS_RESPONSE_CONTENT) ? (<Icon name='check circle outline' size='large'/>) : <span>—</span>;
              // }
              if (isResponseSubmitted || isSubmitted) {
                scoreLabel = (!isDetailScoreCellReadOnly && responseContentState === HAS_RESPONSE_CONTENT) ? (<Icon name='pencil' />) : <span>—</span>;
              } else {
                scoreLabel = <span>—</span>
              }
            // case 2: scorable, submitted and 'Scored'
            } else if (isScorable && typeof score === 'number') {
              const shouldRound = false;
              scoreLabel = toFixed(score, shouldRound);
            // case 4: non-scorable podium item
            } else {
              scoreLabel = !isDetailScoreCellReadOnly ? (
                <div className='view-icon-wrapper'>
                  <img alt='' src={iconView} />
                </div>
              ) : <span>—</span>;
              shouldRenderPopup = true;
            }

            let className = `cell-score ${trueScoreIndex}`;

            className += isScorable && excludeFromScoring ? ' excluded' : '';

            if (isScorable && isSubmitted && scoreState === NOT_SCORED) {
              className += ' scorable scorable-credit';
            } else if (isScorable) {
              const creditClassName = GradebookService.getScoreCellCreditClassName(
                score, maxScore
              );
              className += ` scorable ${creditClassName}`;
            } else {
              className += ' non-scorable';
            }
            const elementId = student.activityElements[trueScoreIndex].entityId;
            const cellActivityElement = student.activityElements[trueScoreIndex];
            const questionStem = student.questionStems[trueScoreIndex];
            const scoreCellDetails = {
              activityId,
              cellActivityElement,
              cellHeaderInfo: headerInfo[trueScoreIndex],
              elementId,
              excludeFromScoring,
              instanceInfo: student,
              isScorable,
              maxScore,
              questionStem,
              scoreIndex: trueScoreIndex,
              slideNumberLabel: headerInfo[trueScoreIndex].label,
              student,
              studentScore: score,
              studentScoreLabel: scoreLabel,
              questionNum: headerInfo[trueScoreIndex].number,
              tableSection: 'body'
            };
            let scoreLabelCellData = <></>;
            scoreLabelCellData = (
              <div onClick={() => {
                if (!isReadOnly && !isLearnosity) {
                  props.onClickScoreCellDetails(scoreCellDetails);
                }
              }}>
                {scoreLabel}
              </div>
            );
            let scoreLabelContent;
            if (shouldRenderPopup) {
              scoreLabelContent = {
                children: () => renderScoreLabelContent(scoreCellDetails)
              };
            }
            const scoreLabelTrigger = (
              <span>{scoreLabelCellData}</span>
            );
            return (
              <Table.Cell
                key={`key-data-cell-${activityId}-${(student.studentId || student.id)}-${scoreIndex}`}
                className={classNames(className, {
                  'is-read-only': isDetailScoreCellReadOnly
                })}>
                {shouldRenderPopup ? renderPopup(
                  scoreLabelContent, scoreLabelTrigger,
                  'cell-score-popup', 'top center', {
                    mouseEnterDelay: 400,
                    mouseLeaveDelay: 400,
                    // offset: [0, 16]
                  }) : scoreLabelTrigger}
              </Table.Cell>
            );
          })}
        </Table.Row>
      );
    });
    return rows;
  };

  const renderPopup = (
    content, trigger = null, className = '', position = 'top center', {
      disabled = null,
      mouseEnterDelay = 0,
      mouseLeaveDelay = 0,
      offset = null,
      wide = 'very'
    } = {}
  ) => {
    const defaultClassName = 'gradebook-details-table-popup';
    className = className ? `${defaultClassName} ${className}` : defaultClassName;
    disabled = typeof disabled !== 'boolean' ? isLoading : disabled;
    if (props.waitingForGradebookSummaryTableComponent) {
      return (<span>{(trigger || typeof trigger === 'number') ? trigger : content}</span>);
    }
    return PopupService.renderPopup({
      className,
      content: typeof content?.children === 'function' && loadingActivePopupContentFunction ?
        <Loader active inline /> : content,
      disabled,
      mouseEnterDelay,
      mouseLeaveDelay,
      offset,
      onClose: () => {
        !allowSort && setAllowSort(true);
        setLoadingActivePopupContentFunction(false);
      },
      onOpen: () => {
        // placeholder
      },
      position,
      trigger,
      wide
    });
  };

  /**
   * @param {{content: string | any, translationKey: string}[]} contents
   */
  const renderContentsWithLabel = (contents = []) => {
    return (
      <div className='cell-score-popup-content-wrapper'>
        {contents.map((current, index) => {
          const { content, translationKey } = current;
          return (
            <div key={`content-${current.translationKey}-${index}`}
              className={`content ${current.translationKey} ${index}`}>
              <span>
                {t(translationKey)}
                {' '}
                {content === '—' ? t('notAvailable') : content}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  const renderScoreLabelContent = (scoreCellDetails) => {
    return (
      <div className='cell-score-popup-content-wrapper'>
        {renderScoreLabelContent_questionStem(scoreCellDetails)}
        {renderScoreLabelContent_maxScore(scoreCellDetails)}
        {!isLearnosity && renderScoreLabelContent_viewedTime(scoreCellDetails)}
        {renderScoreLabelContent_questionNumberLabel(scoreCellDetails)}
        {(scoreCellDetails.slideNumberLabel) && renderScoreLabelContent_slideNumberLabel(scoreCellDetails)}
        {!isReadOnly && !isLearnosity &&
          renderScoreLabelContent_infoForExcludeFromScore(scoreCellDetails)}
      </div>
    );
  };

  // TODO remove; unused
  // const renderScoreLabelContent_surveyQuestionLabel = (scoreCellDetails) => {
  //   const { isSurvey, tableSection } = scoreCellDetails;
  //   const isFromHeader = tableSection === 'header';
  //   return isFromHeader && isSurvey ? (
  //     <>
  //       <div className='cell-survey-question-label'>
  //         {t('surveyQuestionLabel')}
  //       </div>
  //     </>
  //   ) : null;
  // };

  const renderScoreLabelContent_questionStem = (scoreCellDetails) => {
    let { questionStem } = scoreCellDetails;
    questionStem = stripImgHtmlTags(questionStem);
    return questionStem && (
      <>
        <div className='cell-question-stem'>
          {UtilityService.reactHtmlParserWrapper(questionStem).parsed}
        </div>
        <div className='cell-question-stem-bottom-spacer'>
          {/* placeholder */}
        </div>
      </>
    );
  };

  const renderScoreLabelContent_maxScore = (scoreCellDetails) => {
    let { maxScore } = scoreCellDetails;
    maxScore = maxScore === t('notAvailableDash') ? t('notAvailable') : maxScore;
    return maxScore && (
      <div className='cell-max-score-label'>
        {t('maxScoreLabel', { maxScore })}
      </div>
    );
  };

  const renderScoreLabelContent_viewedTime = (scoreCellDetails) => {
    const { scoreIndex, student } = scoreCellDetails;
    if (!student || !student.usages) {
      return <div className='cell-viewed-time-placeholder' />;
    }

    const viewedTimeInSeconds = student.usages[scoreIndex] || 0;

    const viewedTimeTranslationKey = scoreCellDetails.translationKey || 'viewedTime';

    const usageLabel = UsageService.getUnroundedUsageLabel(viewedTimeInSeconds);
    return (
      <div className='cell-viewed-time-label'>
        {t(viewedTimeTranslationKey)}{' '}{usageLabel}
      </div>
    );
  };

  const renderScoreLabelContent_slideNumberLabel = (scoreCellDetails) => {
    const slideNumberLabel = scoreCellDetails.slideNumberLabel.replace('Slide', t('slideLabel'));
    return slideNumberLabel && (
    <>
      <div className='cell-slide-number-label'>
        {slideNumberLabel}
      </div>
    </>
    );
  };

  const renderScoreLabelContent_questionNumberLabel = (scoreCellDetails) => {
    if (scoreCellDetails.questionNum !== null && scoreCellDetails.questionNum !== 'null') {
      const questionNumberLabel = `${t('questionLabel', 'Question')}: ${scoreCellDetails.questionNum}`;
      return questionNumberLabel && (
        <>
          <div className='cell-slide-number-label'>
            {questionNumberLabel}
          </div>
        </>
      );
    }
  };

  const renderScoreLabelContent_infoForExcludeFromScore = (scoreCellDetails) => {
    const { cellActivityElement, isScorable, tableSection } = scoreCellDetails;
    let { activityId } = scoreCellDetails;
    const isFromBody = tableSection === 'body';
    const isFromHeader = tableSection === 'header';

    if (!isScorable) {
      return null;
    }
    activityId = activityId || gradebookManager.currentAssignmentId;
    if (!activityId) {
      throw new TypeError('renderScoreLabelContent_infoForExcludeFromScore:' +
        '`scoreCellDetails.activityId` || `gradebookManager.currentAssignmentId` is required');
    }
    const assignment = assignmentManager.assignments.get(activityId);
    if (!assignment) {
      // `assignment` is likely still being fetched from `assignmentManager.fetchActivity` via handleLoadMore().
      // renderScoreLabelContent_infoForExcludeFromScore() will rerender once handleLoadMore() is finished
      return null;
    }
    if (!cellActivityElement) {
      throw new TypeError('renderScoreLabelContent_infoForExcludeFromScore: `scoreCellDetails.cellActivityElement` is required');
    }
    let excludeFromScoring;
    if (isFromHeader) {
      excludeFromScoring = scoreCellDetails.excludeFromScoring;
    } else {
      excludeFromScoring = cellActivityElement.excludeFromScoring;
    }

    const graded = assignment.status === ASSIGNMENT_STATUS.COMPLETED;
    const hasAnyGraded = gradebookManager.gradebookDetails.hasGraded; // for header

    const isAssignedToClassroom = assignment.assignEntityTypeId === ASSIGNMENT_TYPE.CLASSROOM;
    if (activeGradebookType !== 'aggregate' && isFromBody && isAssignedToClassroom) {
      // if 'nonAggregate' and assigned to entire classroom, only allow 'excludeFromScoring' in the table header cells
      return null;
    }
    return (
      <>
        <div className='cell-info-for-exclude-from-score-container'>
          <div className='exclude-from-score-checkbox-wrapper'>
            <Checkbox
              checked={excludeFromScoring}
              disabled={(isFromBody && graded) || (isFromHeader && hasAnyGraded)}
              label={t('excludeFromScore_checkboxLabel')}
              onClick={(event, data) => props.handleExcludeItemFromScore(event, { ...data, scoreCellDetails })}
              onMouseEnter={() => allowSort && setAllowSort(false)} />
          </div>
          {(isFromHeader && hasAnyGraded) && (
            <div className='exclude-from-score-description-wrapper'>
              {t('excludeFromScore_checkboxGradedWarning')}
            </div>
          )}
          {(isFromBody && graded) && (
          <>
            <div className='exclude-from-score-description-wrapper'>
              {t('excludeFromScore_descriptionForAggregate')}
            </div>
            <div className='exclude-from-score-button-wrapper'>
              <Button
                loading={props.waitingForGradebookSummaryTableComponent}
                onClick={(event, data) => props.handleBringStatusBackToClosed(event, { ...data, assignment, scoreCellDetails })}
                onMouseEnter={() => allowSort && setAllowSort(false)}
                primary>
                {t('excludeFromScore_buttonLabel')}
              </Button>
            </div>
          </>
          )}
        </div>
      </>
    );
  };

  // const randomKey = () => UtilityService.getRandomNumber();

  const totalPages = gradebookManager.gradebookDetailsTableHorizontalScoresPageTotal;

  const hideUnscorable = gradebookManager.shouldHideUnscorableColumns;

  const toggleLabel = hideUnscorable ? t('Show') : t('Hide');

  return (
    <div className='gradebook-details-table-container'>
      {gradebookManager.gradebookDetails ? (
        <>
          <div className='gradebook-details-top'>
            {/* TOP LEFT (hideUnscorable toggler) */}
            <div className='gradebook-details-topleft'>
              {!isLearnosity && (
                <div className='toggle-hide-or-show-wrapper'>
                  <Checkbox
                    className='hide-or-show-toggler hide-or-show-toggler-directly-on-background'
                    data-content={t(hideUnscorable ? 'togglerLabelShow' : 'togglerLabelHide')}
                    defaultChecked={gradebookManager.shouldHideUnscorableColumns}
                    label={t(hideUnscorable ? 'hideUnscorableTrueLabel' : 'hideUnscorableFalseLabel')}
                    onChange={handleToggleHideUnscorableColumns}
                    toggle />
                </div>
              )}
            </div>
            {/* TOP CENTER (placeholder) */}
            <div className='gradebook-details-topcenter'>
              {/* placeholder */}
            </div>
            {/* TOP RIGHT (paginator) */}
            <div className='gradebook-details-topright'>
              <div className='gradebook-details-paginator-outer-wrapper'>
                <Pagination
                  activePage={gradebookManager.activeGradebookDetailsHorizontalScoresPage}
                  onPageChange={handleHorizontalScoresPageChange}
                  totalPages={totalPages} />
              </div>
            </div>
          </div>

          <InfiniteScroll
            hasMore={!isLoading && allowLoadMoreAggregateTableRows && hasMoreAggregateTableRows}
            initialLoad={true}
            loadMore={(page) => allowLoadMoreAggregateTableRows && handleLoadMore(page)}>
            <Table
              className={`gradebook-details-table${isLoading ? ' loading-table' : ''}`}
              sortable
              striped>
              <Table.Header>
                {renderTableHeader()}
              </Table.Header>
              {/* <Table.Body>
                {renderTableAveragesRow()}
              </Table.Body> */}
              <Table.Body>
                {renderTableBodyRows()}
              </Table.Body>
            </Table>
            <Loader
              active={isLoading && allowLoadMoreAggregateTableRows && hasMoreAggregateTableRows}
              inline='centered'
              style={{ marginBottom: '14px' }} />
          </InfiniteScroll>
        </>
      ) : <Loader active />}
    </div>
  );
});
export default GradebookDetailsTable;

SatCoreRegister('GradebookDetailsTable', GradebookDetailsTable);
