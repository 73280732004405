/* eslint-disable sort-keys */
import { action, computed, makeObservable, observable, toJS } from 'mobx';

import { getRegisteredClass } from '../SatCoreRegistry';

import Auth from './AuthManager';
import userManager from './UserManager';

import { register } from '../i18n';

const t = register('AssignmentManager');

export const ASSIGNMENT_TYPE = {
  CLASSROOM: 'classroom',
  CLASSROOM_USER: 'classroom_user', // for UI use only, this doesn't exist on backend ecms.
  ALL_CLASSES: 'allClasses',
  USER: 'user',
  UNASSIGNED: 'unassigned',
  GROUP: 'group',
  TEACHER: 'teacher',
  CUT_SCORE: 'cutScore', // for UI use only, used to assign by student average (cut score)
  INDIVIDUAL: 'individual', // new aggregate assignment view queries use "individual" instead of "user"

  getFlag: (assignmentType) => {
    if (assignmentType === ASSIGNMENT_TYPE.CLASSROOM) return t('assignmentTypeClassroom');
    if (assignmentType === ASSIGNMENT_TYPE.CLASSROOM_USER) return t('assignmentTypeClassroomUser');
    if (assignmentType === ASSIGNMENT_TYPE.USER) return t('assignmentTypeUser');
    if (assignmentType === ASSIGNMENT_TYPE.ALL_CLASSES) return t('assignmentTypeAllClasses');
    if (assignmentType === ASSIGNMENT_TYPE.UNASSIGNED) return t('assignmentTypeUnassigned');
    if (assignmentType === ASSIGNMENT_TYPE.GROUP) return t('assignmentTypeGroup');
    if (assignmentType === ASSIGNMENT_TYPE.TEACHER) return t('assignmentTypeTeacher');
    if (assignmentType === ASSIGNMENT_TYPE.CUT_SCORE) return t('assignmentTypeCutScore');
  },

  getSubdomainTypeFlag: (subdomainTypeId) => {
    if (subdomainTypeId === ASSIGNMENT_TYPE.CLASSROOM) return 'Classroom';
    if (subdomainTypeId === ASSIGNMENT_TYPE.GROUP) return 'Group';
    if (subdomainTypeId === ASSIGNMENT_TYPE.TEACHER) return 'Teacher'; // not currently implemented but potential is there
  }
};

export const ASSIGNMENT_STATUS = {
  ALL: 'all',
  LOCKED: 'locked',
  READY: 'ready',
  STARTED: 'started',
  CLOSED: 'closed',
  PREVIEW: 'preview',
  COMPLETED: 'completed',
  PRESENTATION: 'presentation',
  QUEUED: 'queued',
  getFlag: (assignmentStatus) => {
    if (assignmentStatus === ASSIGNMENT_STATUS.ALL) return t('assignmentStatusAll');
    if (assignmentStatus?.includes?.('pending')) return t('assignmentStatusPending');
    if (assignmentStatus === ASSIGNMENT_STATUS.READY) return t('assignmentStatusReady');
    if (assignmentStatus === ASSIGNMENT_STATUS.STARTED) return t('assignmentStatusStarted');
    if (assignmentStatus === ASSIGNMENT_STATUS.CLOSED) return t('assignmentStatusClosed');
    if (assignmentStatus === ASSIGNMENT_STATUS.PREVIEW) return t('assignmentStatusPreview');
    if (assignmentStatus === ASSIGNMENT_STATUS.LOCKED) return t('assignmentStatusLocked');
    if (assignmentStatus === ASSIGNMENT_STATUS.COMPLETED) return t('assignmentStatusGraded');
    if (assignmentStatus === ASSIGNMENT_STATUS.PRESENTATION) return t('assignmentStatusPresentation');
  },
  getCheckableStatuses: () => [ASSIGNMENT_STATUS.READY, ASSIGNMENT_STATUS.LOCKED, ASSIGNMENT_STATUS.STARTED, ASSIGNMENT_STATUS.CLOSED, ASSIGNMENT_STATUS.COMPLETED],
  getOpenStatuses: () => [ASSIGNMENT_STATUS.READY, ASSIGNMENT_STATUS.STARTED]
};

export const ASSIGNMENT_MENU_STATUS = {
  CURRENT: 'current',
  TODAY: 'today',
  CLOSED: 'closed',
  GRADED: 'graded',
  getTitle: (menuStatus) => {
    if (menuStatus === ASSIGNMENT_MENU_STATUS.CURRENT) return 'CurrentAssignments';
    if (menuStatus === ASSIGNMENT_MENU_STATUS.TODAY) return 'DueTodayAssignments';
    if (menuStatus === ASSIGNMENT_MENU_STATUS.GRADED) return 'GradedAssignments';
    if (menuStatus === ASSIGNMENT_MENU_STATUS.CLOSED) return 'ClosedAssignments';
  },
  getNoAssignmentsMessage: (menuStatus) => {
    if (!menuStatus) return 'NoAssignmentsYet';
    if (menuStatus === ASSIGNMENT_MENU_STATUS.CURRENT) return 'NoCurrentAssignments';
    if (menuStatus === ASSIGNMENT_MENU_STATUS.TODAY) return 'NoDueAssignments';
    if (menuStatus === ASSIGNMENT_MENU_STATUS.GRADED) return 'NoGradedAssignments';
    if (menuStatus === ASSIGNMENT_MENU_STATUS.CLOSED) return 'NoClosedAssignments';
  }
};

export const TEACHER_ASSIGNMENT_MENU_STATUS = {
  ALL: 'all',
  ASSIGNED: 'assigned',
  IN_PROGRESS: 'inProgress',
  CLOSED: 'needsGrading',
  GRADED: 'graded',
  LIST: 'list',
  getTitle: (menuStatus) => {
    if (menuStatus === TEACHER_ASSIGNMENT_MENU_STATUS.ALL) return 'ShowAllAssignments';
    if (menuStatus === TEACHER_ASSIGNMENT_MENU_STATUS.ASSIGNED) return 'AssignedAssignments';
    if (menuStatus === TEACHER_ASSIGNMENT_MENU_STATUS.IN_PROGRESS) return 'InProgressAssignments';
    if (menuStatus === TEACHER_ASSIGNMENT_MENU_STATUS.GRADED) return 'GradedAssignments';
    if (menuStatus === TEACHER_ASSIGNMENT_MENU_STATUS.CLOSED) return 'ClosedAssignments';
    if (menuStatus === TEACHER_ASSIGNMENT_MENU_STATUS.LIST) return 'ShowAssignmentList';
  },
  getNoAssignmentsMessage: (menuStatus) => {
    if (!menuStatus) return 'NoAssignmentsYet';
    if (menuStatus === TEACHER_ASSIGNMENT_MENU_STATUS.ALL) return 'NoAssignmentsYet';
    if (menuStatus === TEACHER_ASSIGNMENT_MENU_STATUS.ASSIGNED) return 'NoAssignedAssignments';
    if (menuStatus === TEACHER_ASSIGNMENT_MENU_STATUS.IN_PROGRESS) return 'NoInProgressAssignments';
    if (menuStatus === TEACHER_ASSIGNMENT_MENU_STATUS.GRADED) return 'NoGradedAssignments';
    if (menuStatus === TEACHER_ASSIGNMENT_MENU_STATUS.CLOSED) return 'NoClosedAssignments';
    if (menuStatus === TEACHER_ASSIGNMENT_MENU_STATUS.LIST) return 'NoAssignmentsYet';
  }
};

// new aggregate assignment view queries use "individual" instead of "user" and "class" instead of "classroom"
export const ASSIGNED_TO_TYPE = {
  CLASSROOM: 'class',
  ALL: 'all',
  INDIVIDUAL: 'individual',
  GROUP: 'group',

  getFlag: (assignmentType) => {
    if (assignmentType === ASSIGNMENT_TYPE.CLASSROOM) return 'Entire Class';
    if (assignmentType === ASSIGNMENT_TYPE.INDIVIDUAL) return 'Individual';
    if (assignmentType === ASSIGNMENT_TYPE.ALL) return 'All';
    if (assignmentType === ASSIGNMENT_TYPE.GROUP) return 'Group';
  },
};

export const ASSIGNMENT_DATA_EXPORT_TYPE = {
  // TODO remove, unused // GRADEBOOK_ASSESSMENT: 'EXPORT_ACTIVITY_INSTANCE',
  // TODO remove, unused // ITEM_ANALYSIS: 'EXPORT_ACTIVITY_ITEM_ANALYSIS',
  EXPORT_AGGREGATE_INSTANCES: 'EXPORT_AGGREGATE_INSTANCES',
  EXPORT_AGGREGATE_INSTANCES_FOR_REMOVED_COURSES: 'EXPORT_ORPHANED_ACTIVITIES',
  EXPORT_AGGREGATE_INSTANCES_MINI: 'EXPORT_AGGREGATE_INSTANCES_MINI',
  EXPORT_AGGREGATE_ITEM_ANALYSIS: 'EXPORT_AGGREGATE_ITEM_ANALYSIS',
  EXPORT_AGGREGATE_ITEM_ANALYSIS_FOR_REMOVED_COURSES: 'EXPORT_ORPHANED_ITEM_ANALYSIS',
  EXPORT_AGGREGATE_MINI_ANALYSIS: 'EXPORT_AGGREGATE_MINI_ANALYSIS',
  EXPORT_AGGREGATE_STANDARDS: 'EXPORT_AGGREGATE_STANDARDS', // TODO name may need to be changed once BE is implemented
  EXPORT_SCH_AGG_INSTANCES: 'EXPORT_SCH_AGG_INSTANCES',
  EXPORT_SCH_AGG_ITEM_ANALYSIS: 'EXPORT_SCH_AGG_ITEM_ANALYSIS',
  getLabel: (assignmentDataExportType) => t(assignmentDataExportType)
};

export const DELIVERY_MODES = {
  TEACHER_ONLY: 'teacher_only',
  ASSIGNABLE: 'assignable',
  STUDENT_ALWAYS: 'student_always',
  STUDENT_ALWAYS_ASSIGNABLE: 'student_always_assignable',
  STUDENT_ALWAYS_READ_ONLY_UNTIL_ASSIGNED: 'student_always_rou_assigned'
};

export const PDF_DELIVERY_FORMATS = {
  PDF: 'pdf',
  FLOWPAPER: 'flowpaper',
  PDF_AND_FLOWPAPER: 'pdf_and_flowpaper',
  DOCREADER: 'docreader',
  PDF_AND_DOCREADER: 'pdf_and_docreader'
};

export const ASSIGNER_TYPE = {
  ALL: 'all',
  LEAD_TEACHER: 'leadteacher',
  CO_TEACHER: 'coteacher',

  getFlag: (assignerType) => {
    if (assignerType === ASSIGNER_TYPE.ALL) return t('assignerTypeAll');
    if (assignerType === ASSIGNER_TYPE.LEAD_TEACHER) return t('assignerTypeLeadTeacher');
    if (assignerType === ASSIGNER_TYPE.CO_TEACHER) return t('assignerTypeCoTeacher');
  }
};

const ASSIGNMENT_ENDPOINTS = {
  CREATE: '/api/requestActivity',
  STUDENT_CREATE: '/api/createStudentPacedActivity',
  CREATE_CLASS_INDIVIDUAL: '/api/requestClassUserActivity',
  CREATE_GROUP_INDIVIDUAL: '/api/requestGroupUserActivity',
  CREATE_ALL_FOR_COURSE: '/api/createClassroomActivitiesForEntireCourse',
  REASSIGN: '/api/reassignActivity',
  REASSIGN_CLASS_INDIVIDUAL: '/api/reassignClassUserActivity',
  REASSIGN_GROUP: '/api/reassignGroupUserActivity',
  UPDATE: '/api/updateActivity',
  UPDATE_ALL: '/api/updateActivities',
  UPDATE_NOTES: '/api/updateActivityNote',
  GET_DISTINCT: '/api/viewActivity',
  GET_SUBMITTED_ACTIVITY_INSTANCE: '/api/viewSubmittedActivityInstance',
  DELETE: '/api/deleteActivity',
  DELETE_ALL: '/api/deleteActivities',
  GET_BY_CLASSROOM: '/api/viewClassroomActivities',
  GET_BY_COURSE: '/api/viewClassroomActivitiesByCourse',
  GET_BY_STUDENT: '/api/viewActivitiesByStudent',
  GET_BY_INSTANCE_STUDENT: '/api/viewActivityByStudent',
  GET_DUE_TODAY_STUDENT: '/api/viewDueTodayActivitiesByStudent',
  GET_BY_TEACHER: '/api/viewActivitiesByTeacher',
  GET_DUE_TODAY_TEACHER: '/api/viewDueTodayActivitiesByTeacher',
  GET_REPORTABLE_BY_STANDARD: '/api/viewReportableActivitiesByStandard',
  GET_REPORTABLE_BY_CMAP_ELEMENT: '/api/viewReportableActivitiesByCmapElement',
  MARK_VIEWED: '/api/markActivityElementAsViewed',
  MARK_ACTIVITY_AS_REVIEWED: '/api/markActivityAsReviewed',
  ADD_RESPONSE: 'api/addActivityResponseComment',
  GET_FLOWPAPER_URL: '/api/getFlowpaperUrl',
  SUBMIT: '/api/submitActivityInstance',
  SEARCH_CLUSTERED_BY_TEACHER: '/api/searchClusteredActivitiesByTeacher',
  SEARCH_SERIES_BY_TEACHER: '/api/searchActivitySeriesByTeacher',
  TEACHER_SUBMIT: '/api/teacherSubmitActivityInstance',
  TEACHER_UNSUBMIT: '/api/teacherUnsubmitActivityInstance',
  TEACHER_SUBMIT_ALL: '/api/teacherSubmitActivities',
  TEACHER_UNSUBMIT_ALL: '/api/teacherUnsubmitActivities',
  CHECK: '/api/checkActivity',
  START: '/api/startActivity',
  START_ACTIVITY_INSTANCE: '/api/startActivityInstance',
  MARK_ACTIVITY_ELEMENT_VIEWED: '/api/markActivityElementAsViewed',
  BY_CONTENT_ITEM: '/api/viewActivitiesByContentItem',
  GET_ACTIVITY_INSTANCES: '/api/viewActivityInstancesGradebook',
  GET_READY_TO_GRADE: '/api/viewReadyToGradeActivities',
  GET_LPL_ASSIGNMENTS: '/api/viewActivitiesByLPLAdmin/',
  MAKE_ACTIVITY_INSTANCES_FOR_USER: '/api/makeActivityInstancesForUser',
  ACTIVITY_INSTANCE: '/api/viewActivityInstance',
  ACTIVITY_USERS: '/api/viewActivitySubmittedUsers',
  GET_ALTERNATE_LESSON_MODES: '/api/viewAlternateLessonModes',
  CREATE_LTI_LINK_TO_COURSE: '/api/createLtiLinkToCourse',
  UPDATE_SUBMISSION_DATA: '/api/updateActivityInstanceSessionData',
  GET_EXTERNAL_APP_STATE: '/api/getExternalAppState',
  ADD_UPDATE_EXTERNAL_APP_STATE: '/api/addUpdateExternalAppState'

};

const globalDefaultPageSize = 15;

// make this client specific when another client uses standards.
// export const STANDARD_TRANSLATE = {
//   convertStandardName: ( str ) => {
//     if (str.startsWith('§110.5(b)')) return str.replace('§110.5(b)', '3.');
//     if (str.startsWith('§111.5(b)')) return str.replace('§111.5(b)','3.');
//     if (str.startsWith('§110.6(b)')) return str.replace('§110.6(b)' ,'4.');
//     if (str.startsWith('§111.6(b)')) return str.replace('§111.6(b)' ,'4.');
//     if (str.startsWith('§110.7(b)')) return str.replace('§110.7(b)','5.');
//     if (str.startsWith('§111.7(b)')) return str.replace('§111.7(b)' ,'5.');
//     if (str.startsWith('§110.22(b)')) return str.replace('§110.22(b)','6.');
//     if (str.startsWith('§111.26(b)')) return str.replace('§111.26(b)','6.');
//     if (str.startsWith('§110.23(b)')) return str.replace('§110.23(b)','7.');
//     if (str.startsWith('§111.27(b)')) return str.replace('§111.27(b)','7.');
//     if (str.startsWith('§110.24(b)')) return str.replace('§110.24(b)','8.');
//     if (str.startsWith('§111.28(b)')) return str.replace('§111.28(b)','8.');
//     if (str.startsWith('§110.36(c)')) return str.replace('§110.36(c)','E1.');
//     if (str.startsWith('§111.39(c)')) return str.replace('§111.39(c)','A1.');
//     if (str.startsWith('§110.37(c)')) return str.replace('§110.37(c)','E2.');
//     if (str.startsWith('§112.16(b)')) return str.replace('§112.16(b)','5.');
//     if (str.startsWith('§113.20(b)')) return str.replace('§113.20(b)','8.');
//     if (str.startsWith('§112.20(b)')) return str.replace('§112.20(b)','8.');
//     if (str.startsWith('§113.41(c)')) return str.replace('§113.41(c)','H.');
//     if (str.startsWith('§112.34(c)')) return str.replace('§112.34(c)','B.');
//     return str;
//   } };

export const STANDARD_TRANSLATE = {
  convertStandardName: (str) => {
    let updatedStr = null;
    // first always remove any section indicator prefix and/or any dangling decimal chars
    if (str.startsWith('§')) str = str.replace('§', '');
    if (str.endsWith('.')) str = str.slice(0, -1);

    // now see if the standard matches any of the ones we need to translate
    if (str.startsWith('110.5.b')) updatedStr = str.replace('110.5.b', '3');
    if (str.startsWith('111.5.b')) updatedStr = str.replace('111.5.b', '3');
    if (str.startsWith('110.6.b')) updatedStr = str.replace('110.6.b', '4');
    if (str.startsWith('111.6.b')) updatedStr = str.replace('111.6.b', '4');
    if (str.startsWith('110.7.b')) updatedStr = str.replace('110.7.b', '5');
    if (str.startsWith('111.7.b')) updatedStr = str.replace('111.7.b', '5');
    if (str.startsWith('110.22.b')) updatedStr = str.replace('110.22.b', '6');
    if (str.startsWith('111.26.b')) updatedStr = str.replace('111.26.b', '6');
    if (str.startsWith('110.23.b')) updatedStr = str.replace('110.23.b', '7');
    if (str.startsWith('111.27.b')) updatedStr = str.replace('111.27.b', '7');
    if (str.startsWith('110.24.b')) updatedStr = str.replace('110.24.b', '8');
    if (str.startsWith('111.28.b')) updatedStr = str.replace('111.28.b', '8');
    if (str.startsWith('110.36.c')) updatedStr = str.replace('110.36.c', 'E1');
    if (str.startsWith('111.39.c')) updatedStr = str.replace('111.39.c', 'A'); // Change per GAL-47
    if (str.startsWith('110.37.c')) updatedStr = str.replace('110.37.c', 'E2');
    if (str.startsWith('112.16.b')) updatedStr = str.replace('112.16.b', '5');
    if (str.startsWith('113.20.b')) updatedStr = str.replace('113.20.b', '8');
    if (str.startsWith('112.20.b')) updatedStr = str.replace('112.20.b', '8');
    if (str.startsWith('113.41.c')) updatedStr = str.replace('113.41.c', 'H');
    if (str.startsWith('112.34.c')) updatedStr = str.replace('112.34.c', 'B');
    // New additions per GAL-30
    if (str.startsWith('112.14.b')) updatedStr = str.replace('112.14.b', '3');
    if (str.startsWith('112.15.b')) updatedStr = str.replace('112.15.b', '4');
    if (str.startsWith('112.18.b')) updatedStr = str.replace('112.18.b', '6');
    if (str.startsWith('112.19.b')) updatedStr = str.replace('112.19.b', '7');

    if (updatedStr) {
      // if we translated it, make sure only the first occurance of a decimal char is kept.
      const pointChar = '.';
      const tempStr = updatedStr.substring(0, updatedStr.indexOf(pointChar) + pointChar.length);
      updatedStr = tempStr + updatedStr.substring(tempStr.length).replace(pointChar, '');
      return updatedStr;
    }
    return str;
  }
};

export class AssignmentManager {
  @observable assignments = new Map();
  @observable aggregateAssignments = new Map();
  @observable assignmentInstances = new Map();

  @observable totalPages = 0; // for assignment list table view

  @observable loaded = false;
  @observable assignmentMemberPopupLoading = false;

  @observable loadingAssignmentFilter = false;

  @observable hasMore = true;

  @observable hasMoreAggregateAssignments = false;

  @observable editAssignment = null;

  @observable assignmentMembers = [];
  @observable currentAssignmentMemberId = null;

  @observable lastAddedAssignment = null;

  /** NOTE: see comment for `setCustomDefaultAssignmentCardImg` */
  @observable customDefaultAssignmentCardImg = null;

  @observable enableCourseSearch = false;
  @observable studentAssignmentResourceTypeFilter = false;

  @observable includeAlignments = false;

  @observable alwaysExcludeStudentAssignmentCardAlignmentPills = false;

  @observable filteredAssignmentSettings = null;

  @observable assignmentCourseOptions = [];

  @observable assignmentStatus = [];

  @observable searchTextTimeout = null;

  @observable useDateSearchType = true;

  @observable useContentTypeSearch = false;

  @observable useAssignmentNickname = true;

  @observable useExpandedAssignmentCard = true;

  @observable useAssignmentTabRollovers = false;

  @observable lateSubmissionOptionAllowed = false;

  @observable alwaysShowKeyboardingSettingsForLessonAssessment = false;

  @observable activityAccommodationIds = '';

  @observable externalAppStateData = '';

  @observable showProgress = false;
  @observable totalProgress = 0;
  @observable completedProgress = 0;

  isLtiLaunched = false;

  hasProcessingActivities = false;

  currentFilter = { // this should not be observable
    classroomId: null,
    filterDateStr: null,
    filterEndDateStr: null,
    status: null,
    assignmentType: [],
    search: null,
    contentTypes: null,
    currentPage: 0,
    dueToday: false,
    clear: false,
    pageSize: 15,
    sortDir: null
  }

  constructor() {
    makeObservable(this);
  }

  @action clearAll = () => {
    this.assignmentCourseOptions = [];
    this.assignmentMembers = [];
    this.clearFetch();
    this.clearGroupResourceSelections();
    this.currentAssignmentMemberId = null;
    this.currentFilter = { // this should not be observable
      classroomId: null,
      filterDateStr: null,
      filterEndDateStr: null,
      status: null,
      assignmentType: [],
      search: null,
      contentTypes: null,
      currentPage: 0,
      dueToday: false,
      clear: false,
      pageSize: 15,
      sortDir: null
    };
    this.editAssignment = null;
    this.lastAddedAssignment = null;
    this.setTotalPages(0);
  }

  clearFetch = () => {
    this.setLoaded(false);
    this.clearAssignments();
    this.setHasMore(true);
    this.setHasMoreAggregateAssignments(false);
    this.currentAssignmentMemberId = null;
    this.lastAddedAssignment = null;
    this.currentFilter.currentPage = 0;
    this.setTotalPages(0);
  }

  @action clearGroupResourceSelections = () => {
    this.groupResourceSelectorCheckedChildContentItemIds = [];
    this.groupResourceSelectorExpandedChildIds = [];
  }

  @action setShowProgress = (show) => {
    this.showProgress = show;
  }

  @action setCompletedProgress = (num) => {
    this.completedProgress = num;
  }

  @action setTotalProgress = (num) => {
    this.totalProgress = num;
  }

  @action setAssignmentStatus = (status) => {
    this.assignmentStatus = status;
  }

  @action getAssignmentStatus = () => this.assignmentStatus;

  @computed get assignmentArray() {
    if (this.assignments) {
      return Array.from(toJS(this.assignments).values());
    }
    return null;
  }

  @computed get aggregateAssignmentArray() {
    if (this.aggregateAssignments) {
      return Array.from(toJS(this.aggregateAssignments).values());
    }
    return null;
  }

  @computed get assignmentInstanceArray() {
    if (this.assignmentInstances) {
      return Array.from(toJS(this.assignmentInstances).values());
    }
    return null;
  }

  @action setAssignmentCourseOptions = (assignments) => {
    if (!this.assignmentCourseOptions || this.assignmentCourseOptions.length === 0) {
      this.assignmentCourseOptions.push({ key: 'all-content', text: 'All Courses', value: null });
    }
    if (assignments) {
      const assignmentsArray = Array.from(toJS(assignments).values());
      assignmentsArray.map((assignment) => {
        if (!this.assignmentCourseOptions.some((element) => element.key === assignment.courseContentItemId)) {
          let contentItemName = assignment.courseContentItemName;
          if (contentItemName && contentItemName.length > 50) {
            contentItemName = `${contentItemName.slice(0, 47).trim()}...`;
          }
          this.assignmentCourseOptions.push({
            key: assignment.courseContentItemId,
            text: contentItemName,
            value: assignment.courseContentItemId
          });
        }
        return true;
      });
    }
  }

  setUseDateSearchType = (useDateSearchType) => {
    this.useDateSearchType = useDateSearchType;
  }

  setUseContentTypeSearch = (useContentTypeSearch) => {
    this.useContentTypeSearch = useContentTypeSearch;
  }

  // Set this to false from satellite index.js to hide nickname from Add & Edit Assign modals.
  setUseAssignmentNickname = (useAssignmentNickname) => {
    this.useAssignmentNickname = useAssignmentNickname;
  }

  // Set this to false from satellite index.js to not use expanded image padding on assignment cards.
  setUseExpandedAssignmentCard = (useExpandedAssignmentCard) => {
    this.useExpandedAssignmentCard = useExpandedAssignmentCard;
  }

  // Set this to true from satellite index.js to use rollover text for assignment view tabs.
  setUseAssignmentTabRollovers = (useAssignmentTabRollovers) => {
    this.useAssignmentTabRollovers = useAssignmentTabRollovers;
  }

  // Set this to true from satellite index.js to allow add/edit modal to show late submission option image.
  setLateSubmissionOptionAllowed = (lateSubmissionOptionAllowed) => {
    this.lateSubmissionOptionAllowed = lateSubmissionOptionAllowed;
  }

  // Set this to true from satellite index.js to always show typing settings for lessons and assessments as well as keyboarding resources
  setAlwaysShowTypingSettingsForLessonAssessment = (alwaysShowTypingSettingsForLessonAssessment) => {
    this.alwaysShowTypingSettingsForLessonAssessment = alwaysShowTypingSettingsForLessonAssessment
  }

  setActivityAccommodationIds = (ids) => {
    this.activityAccommodationIds = ids;
  }

  setExternalAppStateData = (data) => {
    this.externalAppStateData = data;
  }

  @action setLoaded = (loaded) => {
    this.loaded = loaded;
  }

  @action setLoadingAssignmentFilter = (toggle) => {
    this.loadingAssignmentFilter = toggle;
  }

  /**
   * NOTE: per GL-1 decision, we should **avoid** using this method unless we are
   * specifically told to change a satellite's default assignment icon programmatically.
   *
   * Instead, we should prefer asking the customer to do any icon customizations
   * themselves when they are setting up their resources.
   */
  @action setCustomDefaultAssignmentCardImg = (customImg) => {
    this.customDefaultAssignmentCardImg = customImg;
  }

  @action
  setEnableCourseSearch = (enableCourseSearch) => {
    this.enableCourseSearch = enableCourseSearch;
  }

  @action
  setStudentAssignmentResourceTypeFilter = (studentAssignmentResourceTypeFilter) => {
    this.studentAssignmentResourceTypeFilter = studentAssignmentResourceTypeFilter;
  }

  @action
  setIncludeAlignments = (includeAlignments) => {
    this.includeAlignments = includeAlignments;
  }

  @action setAlwaysExcludeStudentAssignmentCardAlignmentPills = (toggle) => {
    this.alwaysExcludeStudentAssignmentCardAlignmentPills = toggle;
  }

  @action setFilteredAssignmentSettings = (filteredAssignmentSettings) => {
    this.filteredAssignmentSettings = filteredAssignmentSettings;
  }

  @action loadAssignments(assignments) {
    this.loaded = false;
    if (assignments) {
      assignments.forEach((assignment) => {
        if (assignment.id !== null) {
          this.assignments.set(assignment.id, assignment);
        }
      });
      this.setAssignmentCourseOptions(assignments);
    }
    this.loaded = true;
  }

  @action loadAggregateAssignments(aggregateAssignments) {
    if (aggregateAssignments) {
      aggregateAssignments.forEach((aggregateAssignment) => {
        // There isn't a unique id so use classroom and courseResourceElementId

        let entityId = aggregateAssignment.activityDomainId ? aggregateAssignment.classroomId + aggregateAssignment.activityDomainId + aggregateAssignment.activitySubdomainLinkId + aggregateAssignment.activitySubdomainId : null;
        if (!entityId) {
          entityId = aggregateAssignment.classroomId + aggregateAssignment.courseResourceElementId;
        }

        // if assignment creation is processing, give it a unique id so the 'processing' card will be shown
        if (aggregateAssignment.processing) {
          entityId += 'processing';
        }

        if (entityId && entityId.length > 0) {
          this.aggregateAssignments.set(entityId, aggregateAssignment);
        }
      });
      // TODO probably not needed but TBD
      // this.setAssignmentCourseOptions(aggregateAssignments);
    }
  }

  @action setAssignment = (assignment = null) => {
    if (assignment !== null) {
      this.assignments.set(assignment.id, assignment);
    }
  }

  @action setAggregateAssignment = (aggregateAssignment = null) => {
    if (aggregateAssignment !== null) {
      // There isn't a unique id so use classroom and courseResourceElementId
      const entityId = aggregateAssignment.classroomId + aggregateAssignment.courseResourceElementId;
      if (entityId && entityId.length > 0) {
        this.aggregateAssignments.set(entityId, aggregateAssignment);
      }
    }
  }

  @action setAssignmentInstance = (assignment = null) => {
    assignment !== null && this.assignmentInstances.set(assignment.id, assignment);
  }

  @action loadAssignmentMembers(activityInstances, activityId) {
    this.currentAssignmentMemberId = activityId;
    if (activityInstances) {
      this.assignmentMembers.clear();
      activityInstances.forEach((instance) => {
        if (instance.id !== null) {
          this.assignmentMembers.push(`${instance.firstName} ${instance.lastName}`);
        }
      });
    }
  }

  @action clearAssignmentMembers = () => new Promise((resolve, _reject) => {
    this.assignmentMembers.clear();
    resolve();
  })

  @action getAssignmentMembers = () => this.assignmentMembers

  @action setAssignmentField(assignmentId, field, value) {
    const assignment = this.assignments.get(assignmentId);
    if (assignment !== undefined && assignment !== null) {
      assignment[field] = value;
    }
  }

  @action setSearchTextTimeout = (searchTextTimeout) => {
    this.searchTextTimeout = searchTextTimeout;
  }

  @action setTotalPages = (totalPages) => {
    this.totalPages = totalPages;
  }

  // Gets the assignment from cache
  getAssignment = (key) => {
    if (key && this.assignments.has(key)) {
      return this.assignments.get(key);
    }
    return null;
  }

  // Tries to get assignment from cache, otherwise calls BE.
  getAssignmentAsync = async (key) => {
    if (key && this.assignments.has(key)) {
      return this.assignments.get(key);
    } else {
      if (key) {
        return await this.fetchActivity(key);
      } else {
        return null;
      }
    }
  }

  setLastAddedAssignment(assignment) {
    this.lastAddedAssignment = null;
    if (Array.isArray(assignment)) {
      const index = assignment.length - 1 > 0 ? assignment.length - 1 : 0;
      const newAssignment = assignment[index];
      this.lastAddedAssignment = newAssignment;
    } else {
      this.lastAddedAssignment = assignment;
    }
  }

  getLastAddedAssignment() {
    return this.lastAddedAssignment;
  }

  submitActivityInstance = async (id, remoteSubmissionData, remoteSubmissionStatus) => {
    try {
      const response = await Auth.fetch(Auth.ecms + ASSIGNMENT_ENDPOINTS.SUBMIT, {
        method: 'POST',
        body: { id, remoteSubmissionData, remoteSubmissionStatus }
      });

      if (response.status === 'SUCCESS' && response.activityInstanceStatus !== null && response.activityInstanceStatus !== '') {
        this.setAssignmentField(id, 'status', response.activityInstanceStatus);
        // Better to get that data from response. But request return only status.
        this.setAssignmentField(id, 'submitted', true);
        this.setAssignmentField(id, 'submittedDate', (new Date()).toLocaleDateString('en-US'));
        return true;
      }
      return false;
    } catch (error) {
      console.warn(error);
      return false;
    }
  }

  fetchActivity = async (activityId) => {
    try {
      if (this.assignments.has(activityId)) {
        return this.assignments.get(activityId);
      }
      const apiUrl = `${Auth.ecms}${ASSIGNMENT_ENDPOINTS.GET_DISTINCT}?id=${activityId}`;
      const response = await Auth.fetch(apiUrl, {
        method: 'GET'
      });
      const assignment = response && response.data && response.data[0];
      this.setAssignment(assignment);
      return assignment;
    } catch (error) {
      console.error(error);
    }
  }

  fetchSubmittedActivityInstance = async (activityId) => {
    try {
      if (this.assignmentInstances.has(activityId)) {
        return this.assignmentInstances.get(activityId);
      }
      const apiUrl = `${Auth.ecms}${ASSIGNMENT_ENDPOINTS.GET_SUBMITTED_ACTIVITY_INSTANCE}?activityId=${activityId}`;
      const response = await Auth.fetch(apiUrl, { method: 'GET' });
      const assignment = response && response.data && response.data[0];
      this.setAssignmentInstance(assignment);
      return assignment;
    } catch (error) {
      console.error(error);
    }
  }

  markActivityAsReviewed = async (activityId) => {
    try {
      const apiUrl = `${Auth.ecms}${ASSIGNMENT_ENDPOINTS.MARK_ACTIVITY_AS_REVIEWED}?activityId=${activityId}`;
      const result = !!await Auth.post(apiUrl);
      const assignment = this.getAssignment(activityId);
      assignment && this.setAssignment({ ...assignment, scoresReleased: true });
      return result;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  fetchUsersForActivity = async (activityIds) => {
    try {
      const response = await Auth.fetch(`${Auth.ecms + ASSIGNMENT_ENDPOINTS.ACTIVITY_USERS}?activityIds=${activityIds}`, {
        method: 'GET'
      });

      if (response.status === 'SUCCESS' && response.users && response.users.length > 0) {
        return response.users;
      }
      return null;
    } catch (error) {
      console.warn(error);
      return null;
    }
  }

  fetchActivityInstance = async (activityInstanceId) => {
    try {
      const response = await Auth.fetch(`${Auth.ecms + ASSIGNMENT_ENDPOINTS.ACTIVITY_INSTANCE}?activityInstanceId=${activityInstanceId}`, {
        method: 'GET'
      });
      if (response.status === 'SUCCESS' && response.data && response.data.length > 0) {
        return response.data[0];
      }
      return null;
    } catch (error) {
      console.warn(error);
      return null;
    }
  }

  teacherSubmitActivityInstance = async (id) => {
    try {
      const response = await Auth.fetch(Auth.ecms + ASSIGNMENT_ENDPOINTS.TEACHER_SUBMIT, {
        method: 'POST',
        body: {
          id
        }
      });
      if (response.status === 'SUCCESS' && response.activityInstanceStatus !== null && response.activityInstanceStatus !== '') {
        return true;
      }
      return false;
    } catch (error) {
      console.warn(error);
      return false;
    }
  }

  teacherUnsubmitActivityInstance = async (id) => {
    try {
      const response = await Auth.fetch(Auth.ecms + ASSIGNMENT_ENDPOINTS.TEACHER_UNSUBMIT, {
        method: 'POST',
        body: {
          id
        }
      });
      if (response.status === 'SUCCESS' && response.activityInstanceStatus !== null && response.activityInstanceStatus !== '') {
        return true;
      }
      return false;
    } catch (error) {
      console.warn(error);
      return false;
    }
  }

  teacherSubmitActivities = async (activityIds) => {
    const activityIdsStr = activityIds.join();
    try {
      const response = await Auth.fetch(Auth.ecms + ASSIGNMENT_ENDPOINTS.TEACHER_SUBMIT_ALL, {
        method: 'POST',
        body: {
          ids: activityIdsStr
        }
      });
      if (response.status === 'SUCCESS' && response.activityInstanceStatus !== null && response.activityInstanceStatus !== '') {
        // this.setAssignmentField( id, 'status', response.activityInstanceStatus );
        return true;
      }
      return false;
    } catch (error) {
      console.warn(error);
      return false;
    }
  }

  teacherUnsubmitActivities = async (activityIds) => {
    const activityIdsStr = activityIds.join();
    try {
      const response = await Auth.fetch(Auth.ecms + ASSIGNMENT_ENDPOINTS.TEACHER_UNSUBMIT_ALL, {
        method: 'POST',
        body: {
          ids: activityIdsStr
        }
      });
      if (response.status === 'SUCCESS' && response.activityInstanceStatus !== null && response.activityInstanceStatus !== '') {
        return true;
      }
      return false;
    } catch (error) {
      console.warn(error);
      return false;
    }
  }

  creatAllAssignmentsForCourse = async (classroomId, courseContentItemId) => {
    try {
      // eslint-disable-next-line max-len
      const url = `${Auth.ecms}${ASSIGNMENT_ENDPOINTS.CREATE_ALL_FOR_COURSE}?classroomId=${classroomId}&courseContentItemId=${courseContentItemId}`;
      const response = await Auth.fetch(url, {
        method: 'POST'
      });
      if (response.status === 'SUCCESS') {
        return true;
      }
      return false;
    } catch (error) {
      console.warn(error.message);
    }
  }

  fetchContentAssignments = async (
    courseContentItemId,
    courseResourceElementId,
    classroomId,
    resourceContentItemId,
    page = 0,
    dialogOpen = false,
    returnImmediatelyAfterResponse = false
  ) => {
    try {
      if (!returnImmediatelyAfterResponse && page === 0 && dialogOpen === false) {
        this.clearAssignments();
        this.setLoaded(false);
      }
      let apiUrl = `${Auth.ecms}${ASSIGNMENT_ENDPOINTS.BY_CONTENT_ITEM}`;
      apiUrl += `?classroomId=${classroomId}`;
      apiUrl += `&resourceContentItemId=${resourceContentItemId}`;
      apiUrl += `&courseResourceElementId=${courseResourceElementId}`;
      apiUrl += `&courseContentItemId=${courseContentItemId}`;
      apiUrl += `&skip=${page * globalDefaultPageSize}`;
      apiUrl += `&includeAlignments=${this.includeAlignments}`;
      if (userManager.isStudent) {
        apiUrl += '&trackContentViewerCompatible=true';
      }
      const response = await Auth.fetch(apiUrl, {
        method: 'GET'
      });
      if (response.status === 'SUCCESS') {
        const assignments = response.data;
        if (returnImmediatelyAfterResponse) {
          return assignments;
        }
        this.loadAssignments(assignments);
        if (this.assignments.size < parseInt(response.pageTotal)) {
          this.setLoaded(true);
          this.setHasMore(true);
        } else {
          this.setLoaded(true);
          this.setHasMore(false);
        }
      } else {
        this.setLoaded(true);
        this.setHasMore(false);
      }
    } catch (error) {
      console.error(error.message);
      this.setLoaded(true);
      this.setHasMore(false);
    }
  }

  fetchAssignmentMembers = async (activityId, forceLoad = false) => {
    try {
      if (!forceLoad && activityId === this.currentAssignmentMemberId) {
        return false;
      }
      this.currentAssignmentMemberId = null;
      const response = await Auth.fetch(`${Auth.ecms}${ASSIGNMENT_ENDPOINTS.GET_ACTIVITY_INSTANCES}?id=${activityId}`, {
        method: 'GET'
      });
      if (response.status === 'SUCCESS') {
        const activityInstances = response.data;
        this.loadAssignmentMembers(activityInstances, activityId);
      }
    } catch (error) {
      console.warn(error.message);
      return false;
    }
  }

  makeActivityInstancesForUser = async (classroomId, courseId) => {
    try {
      const response = await Auth.fetch(Auth.ecms + ASSIGNMENT_ENDPOINTS.MAKE_ACTIVITY_INSTANCES_FOR_USER, {
        method: 'POST',
        body: {
          classroomId,
          courseId
        }
      });
      if (response.status === 'SUCCESS') {
        return true;
      }
      return false;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  createAssignment = async ({
    activityContentStr,
    additionalPropertiesJson = {},
    assignEntityIds,
    assignEntityTypeId,
    classroomId,
    courseContentItemId,
    endRealDate,
    includeInReports,
    instruction,
    maxScore,
    nickname = '',
    scoresReleased,
    startRealDate,
    status,
    studentReview
  } = {}) => {
    try {
      const response = await Auth.fetch(Auth.ecms + ASSIGNMENT_ENDPOINTS.CREATE, {
        method: 'POST',
        body: {
          activityContent: activityContentStr,
          additionalPropertiesJson: JSON.stringify(additionalPropertiesJson),
          assignEntityIds,
          assignEntityTypeId,
          classroomId,
          courseContentItemId,
          endDateTime: this.convertAssignmentDateToJavaString(endRealDate),
          includeInReports: includeInReports ? 'true' : 'false',
          instruction,
          maxScore: (maxScore !== null && maxScore !== undefined) ? maxScore : '1.0',
          nickname,
          scoresReleased: scoresReleased ? 'true' : 'false',
          startDateTime: this.convertAssignmentDateToJavaString(startRealDate),
          status: (status) || ASSIGNMENT_STATUS.LOCKED,
          studentReview: studentReview ? 'true' : 'false'
        }
      });
      if (response.status === 'SUCCESS') {
        const assignments = response.data;
        this.loadAssignments(assignments);
        this.setLastAddedAssignment(assignments);
        return true;
      }
      return false;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  reassignClassIndividualAssignment = async ({
    activityId,
    classroomId,
    startDateTime,
    endDateTime,
    instruction,
    assignEntityTypeId,
    assignEntityIds,
    includeInReports,
    scoresReleased,
    studentReview,
    status,
    maxScore,
    additionalPropertiesJson = {},
    nickname = '',
    offAccommodationIdsStr
  } = {}) => {
    // call reassignAssignment as it's the same params just different endpoint.
    this.reassignAssignment({
      activityId,
      classroomId,
      startDateTime,
      endDateTime,
      instruction,
      assignEntityTypeId,
      assignEntityIds,
      includeInReports,
      scoresReleased,
      studentReview,
      status,
      maxScore,
      additionalPropertiesJson,
      nickname,
      endpoint: ASSIGNMENT_ENDPOINTS.REASSIGN_CLASS_INDIVIDUAL,
      offAccommodationIdsStr
    });
  }

  reassignAssignment = async ({
    activityId,
    classroomId,
    startDateTime,
    endDateTime,
    instruction,
    assignEntityTypeId,
    assignEntityIds,
    includeInReports,
    scoresReleased,
    studentReview,
    status,
    maxScore,
    additionalPropertiesJson = {},
    nickname = '',
    endpoint = ASSIGNMENT_ENDPOINTS.REASSIGN
  } = {}) => {
    try {
      const response = await Auth.fetch(Auth.ecms + endpoint, {
        method: 'POST',
        body: {
          activityId,
          classroomId,
          startDateTime: this.convertAssignmentDateToJavaString(startDateTime),
          endDateTime: this.convertAssignmentDateToJavaString(endDateTime),
          status: (status) || ASSIGNMENT_STATUS.LOCKED,
          maxScore: (maxScore !== null && maxScore !== undefined) ? maxScore : '1.0',
          instruction,
          assignEntityTypeId,
          assignEntityIds,
          includeInReports: includeInReports ? 'true' : 'false',
          scoresReleased: scoresReleased ? 'true' : 'false',
          studentReview: studentReview ? 'true' : 'false',
          additionalPropertiesJson: JSON.stringify(additionalPropertiesJson),
          nickname
        }
      });
      if (response.status === 'SUCCESS') {
        const assignment = response.data;
        this.loadAssignments(assignment);
        this.setLastAddedAssignment(assignment);
        return true;
      }
      return false;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  reassignGroupAssignment = async ({
    activityId,
    classroomId,
    groupIds,
    startDateTime,
    endDateTime,
    instruction,
    includeInReports,
    scoresReleased,
    studentReview,
    status,
    maxScore,
    additionalPropertiesJson = {},
    nickname = ''
  } = {}) => {
    try {
      let groupIdsStr = '';
      if (groupIds && groupIds.length > 0) {
        if (!Array.isArray(groupIds) && typeof groupIds !== 'string') {
          throw new TypeError('groupIds must be a string[] || string');
        } else if (Array.isArray(groupIds)) {
          groupIdsStr = groupIds.join(',');
        } else {
          groupIdsStr = groupIds;
        }
      }
      const response = await Auth.fetch(Auth.ecms + ASSIGNMENT_ENDPOINTS.REASSIGN_GROUP, {
        method: 'POST',
        body: {
          activityId,
          classroomId,
          groupIds: groupIdsStr,
          startDateTime: this.convertAssignmentDateToJavaString(startDateTime),
          endDateTime: this.convertAssignmentDateToJavaString(endDateTime),
          status: (status) || ASSIGNMENT_STATUS.LOCKED,
          maxScore: (maxScore !== null && maxScore !== undefined) ? maxScore : '1.0',
          instruction,
          includeInReports: includeInReports ? 'true' : 'false',
          scoresReleased: scoresReleased ? 'true' : 'false',
          studentReview: studentReview ? 'true' : 'false',
          additionalPropertiesJson: JSON.stringify(additionalPropertiesJson),
          nickname
        }
      });
      if (response.status === 'SUCCESS') {
        const assignment = response.data;
        this.loadAssignments(assignment);
        this.setLastAddedAssignment(assignment);
        return true;
      }
      return false;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  studentCreateAssignment = async (contentItemId, courseResourceElementId, classroomId, courseContentItemId) => {
    try {
      const additionalPropertiesJson = {
        showCorrectAnswers: true
      };
      const response = await Auth.fetch(Auth.ecms + ASSIGNMENT_ENDPOINTS.STUDENT_CREATE, {
        method: 'POST',
        body: {
          contentItemId,
          courseResourceElementId,
          classroomId,
          courseContentItemId,
          includeInReports: 'true',
          scoresReleased: 'true',
          studentReview: 'true',
          additionalPropertiesJson: JSON.stringify(additionalPropertiesJson)
        }
      });
      if (response.status === 'SUCCESS') {
        const assignment = response.data;
        this.loadAssignments(assignment);
        this.setLastAddedAssignment(assignment);
        return true;
      }
      return false;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  createClassIndividualAssignments = async ({
    activityContentStr,
    additionalPropertiesJson = {},
    classroomId,
    courseContentItemId,
    endRealDate,
    includeInReports,
    instruction,
    maxScore,
    nickname = '',
    scoresReleased,
    startRealDate,
    status,
    studentReview
  } = {}) => {
    try {
      const response = await Auth.fetch(Auth.ecms + ASSIGNMENT_ENDPOINTS.CREATE_CLASS_INDIVIDUAL, {
        method: 'POST',
        body: {
          activityContent: activityContentStr,
          additionalPropertiesJson: JSON.stringify(additionalPropertiesJson),
          classroomId,
          courseContentItemId,
          endDateTime: this.convertAssignmentDateToJavaString(endRealDate),
          includeInReports: includeInReports ? 'true' : 'false',
          instruction,
          maxScore: (maxScore !== null && maxScore !== undefined) ? maxScore : '1.0',
          nickname,
          scoresReleased: scoresReleased ? 'true' : 'false',
          startDateTime: this.convertAssignmentDateToJavaString(startRealDate),
          status: (status) || ASSIGNMENT_STATUS.LOCKED,
          studentReview: studentReview ? 'true' : 'false',
        }
      });
      if (response.status === 'SUCCESS') {
        const assignments = response.data;
        this.loadAssignments(assignments);
        this.setLastAddedAssignment(assignments);
        return true;
      } else if (response.status === 'FAILURE') {
        throw new TypeError(response.statusMessage || response.error || '');
      }
      return false;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  createGroupIndividualAssignments = async ({
    activityContentStr,
    additionalPropertiesJson,
    classroomId,
    courseContentItemId,
    endRealDate,
    groupIds,
    includeInReports,
    instruction,
    maxScore,
    nickname = '',
    scoresReleased,
    startRealDate,
    status,
    studentReview
  } = {}) => {
    try {
      let groupIdsStr = '';
      if (groupIds && groupIds.length > 0) {
        if (!Array.isArray(groupIds) && typeof groupIds !== 'string') {
          throw new TypeError('groupIds must be a string[] || string');
        } else if (Array.isArray(groupIds)) {
          groupIdsStr = groupIds.join(',');
        } else {
          groupIdsStr = groupIds;
        }
      }
      const response = await Auth.fetch(Auth.ecms + ASSIGNMENT_ENDPOINTS.CREATE_GROUP_INDIVIDUAL, {
        method: 'POST',
        body: {
          activityContent: activityContentStr,
          additionalPropertiesJson: JSON.stringify(additionalPropertiesJson),
          classroomId,
          courseContentItemId,
          endDateTime: this.convertAssignmentDateToJavaString(endRealDate),
          groupIds: groupIdsStr,
          includeInReports: includeInReports ? 'true' : 'false',
          instruction,
          maxScore: (maxScore !== null && maxScore !== undefined) ? maxScore : '1.0',
          nickname,
          scoresReleased: scoresReleased ? 'true' : 'false',
          startDateTime: this.convertAssignmentDateToJavaString(startRealDate),
          status: (status) || ASSIGNMENT_STATUS.LOCKED,
          studentReview: studentReview ? 'true' : 'false',
        }
      });
      if (response.status === 'SUCCESS') {
        const assignments = response.data;
        this.loadAssignments(assignments);
        this.setLastAddedAssignment(assignments);
        return true;
      }
      return false;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  deleteAssignment = async (assignmentId) => {
    try {
      const response = await Auth.fetch(`${Auth.ecms + ASSIGNMENT_ENDPOINTS.DELETE}?id=${assignmentId}`, {
        method: 'DELETE'
      });
      if (response.status === 'SUCCESS') {
        this.assignments.delete(assignmentId);
        return true;
      }
      return false;
    } catch (error) {
      console.warn(error);
      return false;
    }
  }

  deleteAssignments = async (assignmentIds) => {
    const assignmentIdsStr = assignmentIds.join();
    try {
      const response = await Auth.fetch(`${Auth.ecms + ASSIGNMENT_ENDPOINTS.DELETE_ALL}?ids=${assignmentIdsStr}`, {
        method: 'DELETE'
      });
      if (response.status === 'SUCCESS') {
        return true;
      }
      return false;
    } catch (error) {
      console.warn(error);
      return false;
    }
  }

  updateAssignments = async ({
    assignmentId,
    startDateTime,
    endDateTime,
    instruction,
    status,
    maxScore,
    includeInReports,
    studentReview,
    scoresReleased,
    additionalPropertiesJson,
    nickname
  } = {}) => {
    try {
      const body = {
        id: assignmentId,
        startDateTime: this.convertAssignmentDateToJavaString(startDateTime),
        endDateTime: this.convertAssignmentDateToJavaString(endDateTime),
        instruction,
        maxScore: (maxScore !== null && maxScore !== undefined) ? maxScore : '1.0',
        status,
        showCorrectAnswers: true,
        includeInReports,
        studentReview,
        scoresReleased,
        includeAlignments: true,
        additionalPropertiesJson: JSON.stringify(additionalPropertiesJson),
        nickname
      };
      const apiUrl = `${Auth.ecms}${ASSIGNMENT_ENDPOINTS.UPDATE}`;
      const response = await Auth.fetch(apiUrl, {
        method: 'POST',
        body
      });
      if (response.status === 'SUCCESS') {
        const assignments = response.data;
        this.loadAssignments(assignments);
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  updateAllAssignments = async ({
    activityIds,
    changeAccommodationIds,
    changeStatus,
    changeStartDate,
    changeEndDate,
    changeReports,
    changeInstruction,
    changeMode,
    changeRandomizeQuestions,
    changeLateSubmitDateChecked,
    startDateTime,
    endDateTime,
    instruction,
    status,
    includeInReports,
    studentReview,
    scoresReleased,
    additionalPropertiesJson = {},
    nickname
  }) => {
    try {
      startDateTime = (startDateTime) ? this.convertAssignmentDateToJavaString(startDateTime) : '';
      endDateTime = (endDateTime) ? this.convertAssignmentDateToJavaString(endDateTime) : '';
      instruction = (instruction) || '';
      status = (status) || '';
      const activityIdsStr = activityIds.join();
      const body = {
        ids: activityIdsStr,
        changeAccommodationIds,
        changeStatus,
        changeStartDate,
        changeEndDate,
        changeReports,
        changeInstruction,
        changeMode,
        changeRandomizeQuestions,
        changeLateSubmitDateChecked,
        startDateTime,
        endDateTime,
        instruction,
        status,
        includeInReports,
        studentReview,
        scoresReleased,
        includeAlignments: true,
        additionalPropertiesJson: JSON.stringify(additionalPropertiesJson),
        nickname
      };
      const apiUrl = `${Auth.ecms}${ASSIGNMENT_ENDPOINTS.UPDATE_ALL}`;
      const response = await Auth.fetch(apiUrl, {
        method: 'POST',
        body
      });
      if (response.status === 'SUCCESS') {
        const assignments = response.data;
        this.loadAssignments(assignments);
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  updateAssignmentNotes = async (assignmentId, notes) => {
    try {
      const body = {
        id: assignmentId,
        note: notes
      };
      const response = await Auth.fetch(Auth.ecms + ASSIGNMENT_ENDPOINTS.UPDATE_NOTES, {
        method: 'POST',
        body
      });
      if (response.status === 'SUCCESS') {
        this.setAssignmentField(assignmentId, 'note', notes);
        return true;
      }
      return false;
    } catch (error) {
      console.warn(error);
      return false;
    }
  }

  viewAssignmentFeedback = async (assignmentInstaneId) => {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/viewUserComments?entityId=${assignmentInstaneId}`, {
        method: 'get'
      });
      if (response.status === 'SUCCESS') {
        if (response.comments && response.comments[0]) {
          return response.comments[0].comment;
        }
        return '';
      }
      return '';
    } catch (error) {
      console.warn(error);
      return '';
    }
  }

  updateAssignmentFeedback = async (assignmentInstanceId, comment) => {
    try {
      const body = {
        entityTypeId: 'activity_instance',
        entityId: assignmentInstanceId,
        comment
      };

      const response = await Auth.fetch(`${Auth.ecms}/api/addUpdateComment`, {
        method: 'POST',
        body
      });

      if (response.status === 'SUCCESS') {
        return true;
      }
      return false;
    } catch (error) {
      console.warn(error);
      return false;
    }
  }

  @action
  clearAssignments = () => {
    this.assignments.clear();
    this.aggregateAssignments.clear();
  }

  @action
  setHasMore = (more) => {
    this.hasMore = more;
  }

  @action
  setHasMoreAggregateAssignments = (more) => {
    this.hasMoreAggregateAssignments = more;
  }

  checkActivity = async (activityId) => {
    try {
      const response = await Auth.fetch(Auth.ecms + ASSIGNMENT_ENDPOINTS.CHECK, {
        method: 'POST',
        body: {
          activityId
        }
      });

      if (response.status === 'SUCCESS' && response.isActivityAvailable) {
        return true;
      }
      return false;
    } catch (error) {
      console.warn(error);
      return false;
    }
  }

  startActivity = async (instanceId, activityId, turnInLate = false) => {
    try {
      const response = await Auth.fetch(Auth.ecms + ASSIGNMENT_ENDPOINTS.START, {
        method: 'POST',
        body: {
          activityId,
          turnInLate
        }
      });
      if (response.status === 'SUCCESS') {
        const singleActivity = response.data[0];
        if (singleActivity && singleActivity.contentItemId !== '' && singleActivity.contentItemId !== null) {
          this.setActivityAccommodationIds(singleActivity.additionalPropertiesJson.accommodationIds);
          this.setAssignmentField(instanceId, 'contentItemId', singleActivity.contentItemId);
          this.startActivityInstance(instanceId, turnInLate);
        }
        return true;
      }
      return false;
    } catch (error) {
      console.warn(error);
      return false;
    }
  }

  startActivityInstance = async (activityInstanceId, turnInLate = false) => {
    try {
      const response = await Auth.fetch(Auth.ecms + ASSIGNMENT_ENDPOINTS.START_ACTIVITY_INSTANCE, {
        method: 'POST',
        body: {
          activityInstanceId,
          turnInLate
        }
      });
      if (response.status === 'SUCCESS' && response.activityInstanceStatus !== null && response.activityInstanceStatus !== '') {
        this.setAssignmentField(activityInstanceId, 'status', response.activityInstanceStatus);
        return true;
      }
      return false;
    } catch (error) {
      console.warn(error);
      return false;
    }
  }

  markActivityViewed = async (activityId, entityId, viewedTime) => {
    try {
      const response = await Auth.fetch(Auth.ecms + ASSIGNMENT_ENDPOINTS.MARK_ACTIVITY_ELEMENT_VIEWED, {
        method: 'POST',
        body: {
          activityId,
          entityId,
          viewedTime,
          playedTime: 0.0
        }
      });

      if (response.status === 'SUCCESS') {
        return true;
      }
      return false;
    } catch (error) {
      console.warn(error);
      return false;
    }
  }

  setCurrentFilter = (filter) => {
    this.currentFilter = { ...filter };
  }

  fetchMoreAssignmentsToGrade = async (classroomId, page) => {
    const { filterClassroomId } = this.currentFilter;
    if (!classroomId) {
      classroomId = filterClassroomId;
    }
    await this.fetchAssignmentsToGrade(classroomId, page, false);
  }

  fetchMoreTeacherAssignments = async (page) => {
    const {
      classroomId, filterDateStr, filterEndDateStr, status, assignmentType, search, contentTypes, assignerType,
      dateSearchType, dueToday, pageSize
    } = this.currentFilter;
    await this.fetchTeacherAssignments(classroomId, filterDateStr, filterEndDateStr, status, assignmentType, search, contentTypes,
      assignerType, dateSearchType, page, dueToday, false, pageSize);
  }

  fetchMoreTeacherAggregateAssignments = async (page, clear = false) => {
    const {
      assignerEntityIds, classroomId, filterDateStr, filterEndDateStr, status, assignmentType, search, contentTypes, assignerType,
      dateSearchType, pageSize, subdomainIds, assignedToIds
    } = this.currentFilter;

    await this.fetchTeacherAggregateAssignments(classroomId, dateSearchType, filterDateStr, filterEndDateStr, status, assignmentType,
      search, contentTypes, assignerType, page, clear, pageSize, subdomainIds, assignerEntityIds, assignedToIds);
  }

  fetchMoreStudentAssignments = async (page) => {
    const {
      classroomId, filterDateStr, status, assignmentType, search, contentTypes,
      assignerType, dueToday, pageSize, sortDir
    } = this.currentFilter;
    await this.fetchStudentAssignments(classroomId, filterDateStr, status, assignmentType, search, contentTypes,
      assignerType, page, dueToday, false, pageSize, sortDir);
  }

  fetchMoreLplAssignments = async (stateId, institutionId, courseContentItemIds, search, page) => {
    await this.fetchLplAssignments(stateId, institutionId, courseContentItemIds, search, false, page);
  }

  fetchAssignmentsToGrade = async (classroomId, currentPage, clear, dialogOpen = false) => {
    try {
      if (clear && dialogOpen) {
        return; // don't get the assignments again if dialog is open. refreshing them all
        // will make the screen blink
      }
      const filterDateStr = null, status = null,
        assignmentType = null, search = null,
        contentTypes = null, assignerType = null,
        dateSearchType = null, pageSize = null,
        searchAll = null;
      let url = this.getFetchUrlParams(
        classroomId, filterDateStr, status,
        assignmentType, search, contentTypes,
        assignerType, dateSearchType,
        currentPage, clear, pageSize, searchAll
      );
      url = Auth.ecms + ASSIGNMENT_ENDPOINTS.GET_READY_TO_GRADE + url;

      const response = await Auth.fetch(url, {
        method: 'GET'
      });
      if (response.status === 'SUCCESS') {
        this.setCurrentFilter({
          classroomId,
          filterDateStr: this.currentFilter.filterDateStr,
          status: this.currentFilter.status,
          assignmentType: this.currentFilter.assignmentType,
          search: this.currentFilter.search,
          contentTypes: this.currentFilter.contentTypes,
          assignerType: this.currentFilter.assignerType,
          currentPage,
          dueToday: this.currentFilter.dueToday,
          clear,
          pageSize: this.currentFilter.pageSize,
          sortDir: null
        });
        const assignments = response.data;
        this.loadAssignments(assignments);
        if (this.assignments.size < parseInt(response.pageTotal)) {
          this.setHasMore(true);
        } else {
          this.setHasMore(false);
        }
      }
      this.setLoaded(true);
    } catch (error) {
      console.warn(error.message);
      this.setHasMore(false);
      this.setLoaded(true);
    }
  }

  fetchTeacherAssignments = async (classroomId, filterDateStr, filterEndDateStr, status, assignmentType, search, contentTypes,
    assignerType, dateSearchType, currentPage, dueToday, clear, pageSize, searchAll = true, subdomainIds = null) => {
    try {
      let url = this.getFetchUrlParams(
        classroomId, filterDateStr, status,
        assignmentType, search, contentTypes,
        assignerType, dateSearchType, currentPage,
        clear, pageSize, searchAll, subdomainIds, null, filterEndDateStr
      );
      url = (dueToday) ?
        Auth.ecms + ASSIGNMENT_ENDPOINTS.GET_DUE_TODAY_TEACHER + url : Auth.ecms + ASSIGNMENT_ENDPOINTS.GET_BY_TEACHER + url;
      url += `&includeAlignments=${this.includeAlignments}`;
      const response = await Auth.fetch(url, {
        method: 'GET'
      });
      this.storeAssignmentsAndSetFilter(
        response, classroomId, filterDateStr, status,
        assignmentType, search, contentTypes, assignerType,
        dateSearchType, currentPage, clear, pageSize, null,
        dueToday, filterEndDateStr
      );
    } catch (error) {
      console.warn(error.message);
      this.setHasMore(false);
      this.setLoaded(true);
    }
  }

  fetchTeacherAggregateAssignments = async (classroomId, dateSearchType, filterDateStr, filterEndDateStr,
    status, assignmentType, search, contentTypes, assignerType, currentPage, clear, pageSize, subdomainIds = null,
    assignerEntityIds = null, assignedToIds = null) => {
    try {
      let url = this.getAggregateFetchUrlParams(
        classroomId, filterDateStr, status, assignmentType, search, contentTypes, assignerType,
        currentPage, clear, pageSize, subdomainIds, filterEndDateStr, dateSearchType, assignerEntityIds, assignedToIds
      );
      url = Auth.ecms + ASSIGNMENT_ENDPOINTS.SEARCH_CLUSTERED_BY_TEACHER + url;
      const response = await Auth.fetch(url, {
        method: 'GET'
      });

      this.storeAggregateAssignmentsAndSetFilter(
        response, classroomId, filterDateStr, status, assignmentType, search, contentTypes,
        assignerType, currentPage, clear, pageSize, null, filterEndDateStr, dateSearchType, assignerEntityIds
      );

      this.checkForProcessing(response, currentPage);
    } catch (error) {
      console.warn(error.message);
      this.setHasMoreAggregateAssignments(false);
      this.setLoaded(true);
    }
  }

  fetchTeacherAggregateAssignmentSeries = async (classroomId, dateSearchType, filterDateStr, filterEndDateStr,
    status, assignmentType, search, contentTypes, assignerType, currentPage, clear, pageSize, subdomainIds = null,
    assignerEntityIds = null, assignedToIds = null) => {
    try {
      const usePage = (currentPage - 1);
      let url = this.getAggregateFetchUrlParams(
        classroomId, filterDateStr, status, assignmentType, search, contentTypes, assignerType,
        usePage, clear, pageSize, subdomainIds, filterEndDateStr, dateSearchType, assignerEntityIds, assignedToIds
      );
      url = Auth.ecms + ASSIGNMENT_ENDPOINTS.SEARCH_SERIES_BY_TEACHER + url;
      const response = await Auth.fetch(url, {
        method: 'GET'
      });
      this.clearAssignments();
      this.storeAggregateAssignmentsAndSetFilter(
        response, classroomId, filterDateStr, status, assignmentType, search, contentTypes,
        assignerType, currentPage, clear, pageSize, null, filterEndDateStr, dateSearchType, assignerEntityIds
      );
    } catch (error) {
      console.warn(error.message);
      this.setHasMoreAggregateAssignments(false);
      this.setLoaded(true);
    }
  }

  fetchReportableAssignmentsByStandard = async (classroomId, standardId, studentId, courseContentItemId, activityIds) => {
    this.clearAssignments();
    this.setLoaded(false);

    try {
      let url = Auth.ecms + ASSIGNMENT_ENDPOINTS.GET_REPORTABLE_BY_STANDARD;
      url += `?classroomId=${classroomId}&standardId=${standardId}&includeAlignments=true`;
      if (studentId) {
        url = `${url}&studentId=${studentId}`;
      }
      if (courseContentItemId) {
        url = `${url}&courseContentItemId=${courseContentItemId}`;
      }
      if (activityIds) {
        url = `${url}&activityIds=${activityIds}`;
      }

      const response = await Auth.fetch(url, {
        method: 'GET'
      });
      const assignments = response.data;
      this.loadAssignments(assignments);
      this.setHasMore(false);
      this.setLoaded(true);
    } catch (error) {
      console.warn(error.message);
      this.setHasMore(false);
      this.setLoaded(true);
    }
  }

  fetchReportableAssignmentsByCmapElement = async (classroomId, cmapElementId, studentId, courseContentItemId, activityIds) => {
    this.clearAssignments();
    this.setLoaded(false);

    try {
      let url = Auth.ecms + ASSIGNMENT_ENDPOINTS.GET_REPORTABLE_BY_CMAP_ELEMENT;
      url += `?classroomId=${classroomId}&cmapElementId=${cmapElementId}&includeAlignments=true`;
      if (studentId) {
        url = `${url}&studentId=${studentId}`;
      }
      if (courseContentItemId) {
        url = `${url}&courseContentItemId=${courseContentItemId}`;
      }
      if (activityIds) {
        url = `${url}&activityIds=${activityIds}`;
      }
      const response = await Auth.fetch(url, {
        method: 'GET'
      });
      const assignments = response.data;
      this.loadAssignments(assignments);
      this.setHasMore(false);
      this.setLoaded(true);
    } catch (error) {
      console.warn(error.message);
      this.setHasMore(false);
      this.setLoaded(true);
    }
  }

  fetchStudentAssignments = async (
    classroomId, filterDateStr, status, assignmentType,
    search, contentTypes, assignerType = ASSIGNER_TYPE.ALL, currentPage,
    dueToday, clear, pageSize, sortDir = null, searchAll = true, resourceTypes
  ) => {
    try {
      currentPage = 0; // TODO InfiniteScroll appears broken for student assignments, so fetching all at once for now
      pageSize = 999; // TODO InfiniteScroll appears broken for student assignments, so fetching all at once for now

      const dateSearchType = null;
      let url = this.getFetchUrlParams(
        classroomId, filterDateStr, status,
        assignmentType, search, contentTypes,
        assignerType, dateSearchType,
        currentPage, clear, pageSize, searchAll,
        null, resourceTypes
      );
      if (sortDir === 'asc' || sortDir === 'desc') {
        url += `&sort[0][field]=endDate&sort[0][dir]=${sortDir}`;
      }
      url += `&includeAlignments=${this.includeAlignments}`;
      url += '&trackContentViewerCompatible=true';
      url = (dueToday) ?
        Auth.ecms + ASSIGNMENT_ENDPOINTS.GET_DUE_TODAY_STUDENT + url : Auth.ecms + ASSIGNMENT_ENDPOINTS.GET_BY_STUDENT + url;

      const response = await Auth.fetch(url, {
        method: 'GET'
      });

      this.storeAssignmentsAndSetFilter(
        response, classroomId, filterDateStr, status,
        assignmentType, search, contentTypes, assignerType,
        currentPage, clear, pageSize, sortDir, dueToday
      );
    } catch (error) {
      console.error(error);
      this.setHasMore(false);
      this.setLoaded(true);
    }
  }

  fetchStudentAssignment = async (activityInstanceId) => {
    this.clearAssignments();
    this.setLoaded(false);
    try {
      const qs = `?activityInstanceId=${activityInstanceId}`;
      const url = Auth.ecms + ASSIGNMENT_ENDPOINTS.GET_BY_INSTANCE_STUDENT + qs;
      const response = await Auth.fetch(url, {
        method: 'GET'
      });
      const assignments = response.data;
      this.loadAssignments(assignments);
      this.setHasMore(false);
      this.setLoaded(true);
    } catch (error) {
      console.warn(error.message);
      this.setHasMore(false);
      this.setLoaded(true);
    }
  }

  fetchLplAssignments = async (stateId, institutionId, courseContentItemIds, search, clear = true, page = 0) => {
    try {
      if (clear) {
        this.setLoaded(false);
        this.assignments.clear();
      }
      let started = false;
      let params = '';
      if (stateId !== null && stateId !== '') {
        params = `${params}stateId=${stateId}`;
        started = true;
      }

      if (institutionId !== null && institutionId !== '') {
        if (started) {
          params += '&';
        }
        params = `${params}institutionId=${institutionId}`;
        started = true;
      }

      if (courseContentItemIds !== null && courseContentItemIds !== '') {
        if (started) {
          params += '&';
        }
        params = `${params}courseContentItemIds=${courseContentItemIds.join()}`;
        started = true;
      }
      if (courseContentItemIds !== null && courseContentItemIds !== '') {
        if (started) {
          params += '&';
        }
        params = `${params}search=${search}`;
      }

      const currentPage = page * 15;

      params += `&skip=${currentPage}&pageSize=15`;

      const url = `${Auth.ecms + ASSIGNMENT_ENDPOINTS.GET_LPL_ASSIGNMENTS}?${params}`;

      const response = await Auth.fetch(url, {
        method: 'GET'
      });

      const assignments = response.data;
      this.loadAssignments(assignments);
      if (this.assignments.size < parseInt(response.pageTotal)) {
        this.setHasMore(true);
      } else {
        this.setHasMore(false);
      }
      this.setLoaded(true);
    } catch (error) {
      console.warn(error.message);
      this.setHasMore(false);
      this.setLoaded(true);
    }
  }

  @action storeAssignmentsAndSetFilter = (
    response, classroomId, filterDateStr, status, assignmentType,
    search, contentTypes, assignerType, dateSearchType, currentPage,
    clear, pageSize, sortDir = null, dueToday, filterEndDateStr = null
  ) => {
    if (response.status === 'SUCCESS') {
      this.setCurrentFilter({
        classroomId,
        filterDateStr,
        filterEndDateStr,
        status,
        assignmentType,
        search,
        contentTypes,
        assignerType,
        dateSearchType,
        currentPage,
        dueToday,
        clear,
        pageSize,
        sortDir
      });
      const assignments = response.data;
      this.loadAssignments(assignments);
      if (this.assignments.size < parseInt(response.pageTotal)) {
        this.setHasMore(true);
      } else {
        this.setHasMore(false);
      }
    }
    this.setLoaded(true);
  }

  @action storeAggregateAssignmentsAndSetFilter = (
    response, classroomId, filterDateStr, status, assignmentType, search, contentTypes, assignerType,
    currentPage, clear, pageSize, sortDir = null, filterEndDateStr = null, dateSearchType = 'all',
    assignerEntityIds = []
  ) => {
    let shouldShowExpiredLicenseWarning;
    if (response.status === 'SUCCESS') {
      this.setCurrentFilter({
        classroomId,
        dateSearchType,
        filterDateStr,
        filterEndDateStr,
        status,
        assignmentType,
        search,
        contentTypes,
        assignerType,
        currentPage,
        clear,
        pageSize,
        sortDir,
        assignerEntityIds
      });
      const aggregateAssignments = response.data;

      this.setLoaded(false);

      this.loadAggregateAssignments(aggregateAssignments);

      // show expired license warning(s) if any aggregateAssignments are associated with an expired license
      const hasSomeResponseDataWithLicenseExpired = aggregateAssignments?.some((aggregateAssignment) => {
        return aggregateAssignment?.licenseExpired;
      });
      const hasSomeMobxAggregateAssignmentsWithLicenseExpired = !!hasSomeResponseDataWithLicenseExpired ||
        Array.from(this.aggregateAssignments.values()).some((aggregateAssignment) => {
          return aggregateAssignment?.licenseExpired;
        });
      shouldShowExpiredLicenseWarning = !!hasSomeResponseDataWithLicenseExpired || !!hasSomeMobxAggregateAssignmentsWithLicenseExpired;

      if (this.aggregateAssignments.size < parseInt(response.pageTotal)) {
        this.setHasMoreAggregateAssignments(true);
      } else {
        this.setHasMoreAggregateAssignments(false);
      }
      this.setTotalPages(Math.ceil(+response.pageTotal / pageSize));
    }
    this.setLoaded(true);

    const ProductService = getRegisteredClass('ProductService');
    ProductService.setShouldShowExpiredLicenseWarning(!!shouldShowExpiredLicenseWarning);
  }

  // check if any activities are still in the process of being created - re-fetch polling is done if there are any
  checkForProcessing = (response, currentPage) => {
    const activities = response.data;
    let hasProcessingActivities = false;
    // if scrolled to more pages with infinite scroll, don't do re-fetch polling
    if (currentPage === 0) {
      for (const activity of activities) {
        if (activity.processing) {
          hasProcessingActivities = true;
          break;
        }
      }
    }
    this.hasProcessingActivities = hasProcessingActivities;
  }

  fetchCourseActivityInformation = async (activityId) => {
    if (!activityId) {
      return;
    }
    const url = `${Auth.ecms}/api/viewCourseActivityInfomation?activityId=${activityId}`;
    try {
      const response = await Auth.fetch(url, {
        method: 'GET'
      });

      if (response.status === 'SUCCESS') {
        const activityInfo = response.data;
        return activityInfo;
      }
    } catch (e) {
      console.warn(e);
    }
  }

  fetchStudentActivity = async (activityInstanceId) => {
    if (!activityInstanceId) {
      return null;
    }
    const url = `${Auth.ecms}/api/findUserActivity?activityInstanceId=${activityInstanceId}`;
    try {
      const response = await Auth.fetch(url, {
        method: 'GET'
      });
      return response;
    } catch (e) {
      console.warn(e);
    }
  }

  getFetchUrlParams = (
    classroomId = null, filterDateStr = null, status = null,
    assignmentType = null, search = null, contentTypes,
    assignerType = 'all', dateSearchType = 'forward',
    currentPage = 0, clear = true, pageSize, searchAll, subdomainIds = null, resourceTypes, filterEndDateStr = null
  ) => {
    if (clear) {
      this.clearFetch();
    }

    let defaultPageSize = globalDefaultPageSize;

    if (pageSize) {
      defaultPageSize = pageSize;
    }
    currentPage *= defaultPageSize;

    let url = `?skip=${currentPage}&pageSize=${defaultPageSize}`;

    if (classroomId !== null && classroomId !== '') {
      url += `&classroomId=${classroomId}`;
    }

    if (status !== null && status !== '') {
      url += `&status=${status}`;
    }
    if (filterDateStr !== null) {
      const isADate = (Object.prototype.toString.call(filterDateStr)
        === '[object Date]' && !isNaN(filterDateStr));
      if (isADate) {
        filterDateStr = this.convertAssignmentDateToJavaString(filterDateStr);
      }

      let dateWithTime = filterDateStr;
      if (dateWithTime !== '' && dateWithTime.indexOf(':') < 0) {
        dateWithTime = `${filterDateStr} 00:00:00`;
      }
      url += `&filterDate=${dateWithTime}`;
    }

    if (filterEndDateStr !== null && filterEndDateStr !== '') {
      const isADate = (Object.prototype.toString.call(filterEndDateStr)
        === '[object Date]' && !isNaN(filterEndDateStr));
      if (isADate) {
        filterEndDateStr = this.convertAssignmentDateToJavaString(filterEndDateStr);
      }

      let dateWithTime = filterEndDateStr;
      if (dateWithTime !== '' && dateWithTime.indexOf(':') < 0) {
        dateWithTime = `${filterEndDateStr} 00:00:00`;
      }
      url += `&filterEndDate=${dateWithTime}`;
    }

    if (assignmentType !== null && assignmentType !== '' && assignmentType !== ASSIGNMENT_TYPE.ALL_CLASSES) {
      url += `&assignEntityTypeId=${assignmentType}`;
    }

    if (resourceTypes) {
      url += `&resourceTypes=${resourceTypes}`;
    }

    if (search !== null && search !== '') {
      url += `&search=${search}`;
    }

    if (contentTypes !== null && contentTypes !== '') {
      url += `&contentCategoryTags=${contentTypes}`;
    }

    // assignerType may be an array
    if (assignerType !== null && assignerType !== '') {
      if (Array.isArray(assignerType) && assignerType.length > 0) {
        const assignerIdsStr = assignerType.join();
        url += `&assignerType=${assignerIdsStr}`;
      } else {
        url += `&assignerType=${assignerType}`;
      }
    }

    if (dateSearchType !== null && dateSearchType !== '') {
      url += `&dateSearchType=${dateSearchType}`;
    }

    if (searchAll !== null && searchAll !== '') {
      url += `&searchAll=${searchAll}`;
    }

    if (subdomainIds !== null && (Array.isArray(subdomainIds) && subdomainIds.length > 0)) {
      const subdomainIdsStr = subdomainIds.join(',');
      url += `&subdomainIds=${subdomainIdsStr}`;
    }
    return url;
  }

  getAggregateFetchUrlParams = (
    classroomId = null, filterDateStr = null, status = null,
    assignmentType = null, search = null, contentTypes,
    assignerType = 'all', currentPage = 0, clear = true, pageSize,
    subdomainIds = null, filterEndDateStr = null, dateSearchType = 'all',
    assignerEntityIds = null, assignedToIds = null
  ) => {
    if (clear) {
      this.clearFetch();
    }
    let defaultPageSize = globalDefaultPageSize;

    if (pageSize) {
      defaultPageSize = pageSize;
    }
    currentPage *= defaultPageSize;

    let url = `?skip=${currentPage}&pageSize=${defaultPageSize}`;

    if (classroomId !== null && classroomId !== '') {
      url += `&domainIds=${classroomId}`;
    }

    if (status !== null && status !== '') {
      url += `&statusKeys=${status}`;
    }
    if (dateSearchType !== 'all' && filterDateStr !== null) {
      const isADate = (Object.prototype.toString.call(filterDateStr)
        === '[object Date]' && !isNaN(filterDateStr));
      if (isADate) {
        filterDateStr = this.convertAssignmentDateToJavaString(filterDateStr);
      }

      let dateWithTime = filterDateStr;
      if (dateWithTime !== '' && dateWithTime.indexOf(':') < 0) {
        dateWithTime = `${filterDateStr} 00:00:00`;
      }
      url += `&fromDate=${dateWithTime}`;
    }

    if (dateSearchType !== 'all' && filterEndDateStr !== null && filterEndDateStr !== '') {
      const isADate = (Object.prototype.toString.call(filterEndDateStr)
        === '[object Date]' && !isNaN(filterEndDateStr));
      if (isADate) {
        filterEndDateStr = this.convertAssignmentDateToJavaString(filterEndDateStr);
      }

      let dateWithTime = filterEndDateStr;
      if (dateWithTime !== '' && dateWithTime.indexOf(':') < 0) {
        dateWithTime = `${filterEndDateStr} 00:00:00`;
      }
      url += `&toDate=${dateWithTime}`;
    }

    if (assignmentType !== null && assignmentType !== '') {
      url += `&assignedToType=${assignmentType}`;
    }

    if (search !== null && search !== '') {
      url += `&textSearch=${search}`;
    }

    if (contentTypes !== null && contentTypes !== '') {
      url += `&contentCategoryTags=${contentTypes}`;
    }

    if (subdomainIds !== null && (Array.isArray(subdomainIds) && subdomainIds.length > 0)) {
      const subdomainIdsStr = subdomainIds.join(',');
      url += `&subdomainIds=${subdomainIdsStr}`;
    }

    if (assignerType && assignerEntityIds && assignerEntityIds.length > 0) {
      const assignerEntityIdsStr = assignerEntityIds.join(',');
      url += `&assignedBy=${assignerEntityIdsStr}`;
    }

    if (assignedToIds && assignedToIds.length > 0) {
      const assignedToIdsStr = assignedToIds.join(',');
      url += `&assignedTo=${assignedToIdsStr}`;
    }

    return url;
  }

  isValidTimeInput = (timeStr) => {
    if (typeof timeStr === 'string') {
      const timeRegex = /((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/;
      const okLength = timeStr.trim().length >= 7 && timeStr.trim().length <= 8;
      return !!(timeStr.includes(' ') && okLength &&
        timeStr.endsWith('M') && timeStr.match(timeRegex));
    }
    return false;
  }

  convertAssignmentDateToJavaString = (date) => {
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false
    };
    let dateStr = Intl.DateTimeFormat('en-US', options).format(new Date(date));
    dateStr = dateStr.replace(',', '').replace(' 24:', ' 00:');
    return dateStr;
  }

  convertJSStringToJSDate = (dateStr, timeStr) => {
    const aDate = new Date(`${dateStr || ''} ${timeStr || ''}`);
    const isADate = (Object.prototype.toString.call(aDate) === '[object Date]' && !isNaN(aDate));
    return isADate ? aDate : null;
  }

  getJSDateTimeStringParts = (dateTimeStr) => {
    const date = new Date(dateTimeStr);
    let timeStr = date.toLocaleTimeString('en-US');
    const sindex = timeStr.indexOf('M') - 5;
    const eindex = timeStr.indexOf('M') - 1;
    const removeStr = timeStr.substring(sindex, eindex);
    timeStr = timeStr.replace(removeStr, ' ');
    return { date: date.toLocaleDateString('en-US'), time: timeStr };
  }

  getAssignmentStartAndEnd = (startDate = new Date(), durationTotalHours = 0, dueDate = null) => {
    const dateAfterStartDate = new Date(startDate);
    dateAfterStartDate.setDate(dateAfterStartDate.getDate() + 1);

    // See if we have durationTotalHours, if so use that;
    // else, see if we have an endDateOverride;
    // else use dateAfterStartDate
    let durationEndDate = new Date(startDate);
    if (durationTotalHours > 0 && !dueDate) {
      durationEndDate.setHours(durationEndDate.getHours() + durationTotalHours);
    } else if (dueDate) {
      durationEndDate = new Date(dueDate);
    } else {
      durationEndDate = new Date(dateAfterStartDate);
    }

    let startTimeStr = startDate.toLocaleTimeString('en-US');
    let sindex = startTimeStr.indexOf('M') - 5;
    let eindex = startTimeStr.indexOf('M') - 1;
    const removeStr = startTimeStr.substring(sindex, eindex);
    startTimeStr = startTimeStr.replace(removeStr, ' ');

    let endTimeStr = durationEndDate.toLocaleTimeString('en-US');
    sindex = endTimeStr.indexOf('M') - 5;
    eindex = endTimeStr.indexOf('M') - 1;
    const removeEStr = endTimeStr.substring(sindex, eindex);
    endTimeStr = endTimeStr.replace(removeEStr, ' ');

    return {
      startLocaleDateString: startDate.toLocaleDateString('en-US'),
      endLocaleDateString: durationEndDate.toLocaleDateString('en-US'),
      startTimeStr,
      endTimeStr
    };
  }

  // Get translation, if any, use const flag as default.
  getTranslatedAssignmentTypeFlag = (assignment) => {
    let flag = ASSIGNMENT_TYPE.getFlag(assignment.assignEntityTypeId);
    const hasSubdomain = assignment.subdomainId !== null;
    if (hasSubdomain && assignment.assignEntityTypeId === ASSIGNMENT_TYPE.USER) {
      flag = ASSIGNMENT_TYPE.getSubdomainTypeFlag(assignment.subdomainTypeId);
      return t(assignment.subdomainTypeId, flag);
    }
    return t(assignment.assignEntityTypeId, flag);
  }

  getAssignmentExpiredStatus = (assignment) => {
    const today = new Date();
    const { fullTimezoneEndTime } = assignment;
    const isAssignmentExpired = fullTimezoneEndTime && today.valueOf() >= new Date(fullTimezoneEndTime).valueOf();
    let isAssignmentExpiring = false;
    if (!isAssignmentExpired) {
      const diffInMs = Math.abs(today - new Date(fullTimezoneEndTime));
      const diffInHours = diffInMs / (1000 * 60 * 60);
      isAssignmentExpiring = diffInHours <= 24;
    }
    return { isAssignmentExpired, isAssignmentExpiring };
  }

  getAssignmentModes = async (courseResourceElementId) => {
    try {
      const response = await Auth.fetch(`${Auth.ecms}${ASSIGNMENT_ENDPOINTS.GET_ALTERNATE_LESSON_MODES}?courseResourceElementId=${courseResourceElementId}`, {
        method: 'GET'
      });
      if (response.status === 'SUCCESS') {
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.warn(error.message);
      return false;
    }
  }

  createLtiLinkToCourse = async (id) => {
    try {
      const response = await Auth.fetch(`${Auth.ecms + ASSIGNMENT_ENDPOINTS.CREATE_LTI_LINK_TO_COURSE}?courseElementId=${id}`, {
        method: 'GET'
      });
      if (response.status === 'SUCCESS' && response.data && response.data.length > 0) {
        return response.data[0];
      }
      return null;
    } catch (error) {
      console.warn(error);
      return null;
    }
  }

  updateActivityInstanceSubmissionData = async (id, remoteSubmissionData, remoteSubmissionStatus) => {
    try {
      const response = await Auth.fetch(Auth.ecms + ASSIGNMENT_ENDPOINTS.UPDATE_SUBMISSION_DATA, {
        method: 'POST',
        body: {
          id,
          remoteSubmissionData,
          remoteSubmissionStatus
        }
      });
      if (response.status === 'SUCCESS' && response.activityInstanceStatus !== null && response.activityInstanceStatus !== '') {
        return response.data;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  getAssignmentMenuStatus = (menuName) => {
    switch (menuName) {
    case TEACHER_ASSIGNMENT_MENU_STATUS.ASSIGNED:
      return [ASSIGNMENT_STATUS.LOCKED, ASSIGNMENT_STATUS.READY, ASSIGNMENT_STATUS.QUEUED];
    case TEACHER_ASSIGNMENT_MENU_STATUS.IN_PROGRESS:
      return ASSIGNMENT_STATUS.STARTED;
    case TEACHER_ASSIGNMENT_MENU_STATUS.CLOSED:
      return ASSIGNMENT_STATUS.CLOSED;
    case TEACHER_ASSIGNMENT_MENU_STATUS.GRADED:
      return ASSIGNMENT_STATUS.COMPLETED;
    default:
      return [];
    }
  }

  multipleAssignmentCreatePromise = (parms, assignmentType) => {
    const {
      activityContentStr,
      additionalPropertiesJson,
      classroomId,
      courseContentItemId,
      endRealDate,
      includeInReports,
      instruction,
      maxScore,
      nickname: trimmedNickname,
      scoresReleased,
      startRealDate,
      status,
      studentReview,
      groupIds,
      assignEntityIds,
      assignEntityTypeId,
    } = parms;

    return new Promise((resolve) => {
      if (assignmentType === ASSIGNMENT_TYPE.CLASSROOM_USER) {
        setTimeout(() => resolve(
          this.createClassIndividualAssignments({
            activityContentStr,
            additionalPropertiesJson,
            classroomId,
            courseContentItemId,
            endRealDate,
            includeInReports,
            instruction,
            maxScore,
            nickname: trimmedNickname,
            scoresReleased,
            startRealDate,
            status,
            studentReview
          })
        ), 100);
      } else if (assignmentType === ASSIGNMENT_TYPE.GROUP) {
        setTimeout(() => resolve(
          this.createGroupIndividualAssignments({
            activityContentStr,
            additionalPropertiesJson,
            classroomId,
            courseContentItemId,
            endRealDate,
            groupIds,
            includeInReports,
            instruction,
            maxScore,
            nickname: trimmedNickname,
            scoresReleased,
            startRealDate,
            status,
            studentReview
          })
        ), 100);
      } else {
        setTimeout(() => resolve(
          this.createAssignment({
            activityContentStr,
            additionalPropertiesJson,
            assignEntityIds,
            assignEntityTypeId,
            classroomId,
            courseContentItemId,
            endRealDate,
            includeInReports,
            instruction,
            nickname: trimmedNickname,
            scoresReleased,
            startRealDate,
            studentReview,
          })
        ), 100);
      }
    });
  }

  multipleAssignmentCreateIterate = async (parms, assignmentType) => {
    const {
      activityContentArr,
    } = parms;

    this.setShowProgress(true);
    this.setTotalProgress(activityContentArr.length);

    for (const [i, item] of activityContentArr.entries()) {
      this.setCompletedProgress(i + 1);
      // increment seconds to retain activity order
      parms.endRealDate.setSeconds(i);
      parms.activityContentStr = JSON.stringify([item]);
      // eslint-disable-next-line no-await-in-loop
      await this.multipleAssignmentCreatePromise(parms, assignmentType);
    }

    this.setShowProgress(false);
    this.setTotalProgress(0);
    this.setCompletedProgress(0);
  }

  multipleAssignmentEntityIdsCreateIterate = async (parms, assignmentType) => {
    const {
      assignEntityIds,
    } = parms;

    const idArray = assignEntityIds.split(',').map((value) => value.trim());

    this.setShowProgress(true);
    this.setTotalProgress(idArray.length);

    let i = 0;
    for (const id of idArray) {
      // parms was being directly modified in this loop and passed to multipleAssignmentCreatePromise, but the
      // code ended up sending the same data for every call to the endpoint. I'm not sure what was happening,
      // but cloning parms for each call fixed the problem.
      const clonedParms = { ...parms };

      this.setCompletedProgress(i);
      // increment seconds to retain activity order
      clonedParms.endRealDate.setSeconds(i);
      clonedParms.assignEntityIds = id;

      if (clonedParms.assignEntityTypeId === 'classroom') {
        clonedParms.classroomId = id;
      }

      // eslint-disable-next-line no-await-in-loop
      await this.multipleAssignmentCreatePromise(clonedParms, assignmentType);
      ++i;
    }

    this.setShowProgress(false);
    this.setTotalProgress(0);
    this.setCompletedProgress(0);
  }

  getExternalAppState = async (sessionId) => {
    try {
      const response = await Auth.fetch(`${Auth.ecms}${ASSIGNMENT_ENDPOINTS.GET_EXTERNAL_APP_STATE}?sessionId=${sessionId}`, {
        method: 'GET'
      });
      if (response.status === 'SUCCESS') {
        this.setExternalAppStateData(response.data);
      } else {
        return false;
      }
    } catch (error) {
      console.warn(error.message);
      return false;
    }
  }

  addUpdateExternalAppState = async (sessionId, userId, sessionData, status) => {
    try {
      const response = await Auth.fetch(Auth.ecms + ASSIGNMENT_ENDPOINTS.ADD_UPDATE_EXTERNAL_APP_STATE, {
        method: 'POST',
        body: {
          sessionId,
          userId,
          sessionData,
          status
        }
      });
      if (response.status === 'SUCCESS') {
        this.setExternalAppStateData(response.data);
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}

export default new AssignmentManager();
