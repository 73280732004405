import React, { useContext } from 'react';
import { useHistory } from 'react-router';

import { MobXProviderContext, observer } from 'mobx-react';

import '../../css/reports/ReportCaption.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import { REPORT_FACULTY_TYPE, REPORT_URL_KEY } from '../../services/reports/ReportConstants';

import PopupService from '../../services/PopupService';
import ReportIdentityService from '../../services/reports/ReportIdentityService';

const isModifiedReportId = (reportId, type) => {
  const modReportId = reportId === 'GALL_STANDARDS_CLASS_PERFORM' || reportId === 'GALL_STANDARDS_SCHOOL_PERFORM'
      || reportId === 'GALL_STANDARDS_DIST_PERFORM';
  const modType = type === 'expertrack' || type === 'clickbook';

  return modReportId && modType;
};

const getModifiedReportId = (reportId, type) => {
  let newReportId = reportId;
  let searchVal = 'PERFORM';
  if (reportId === 'GALL_STANDARDS_CLASS_PERFORM') {
    searchVal = 'CLASS_PERFORM';
  }

  if (type === 'expertrack') {
    newReportId = reportId.replace(searchVal, 'EXPERTRACK');
  } else if (type === 'clickbook') {
    newReportId = reportId.replace(searchVal, 'CLICKBOOK');
  }
  return newReportId;
};

const ReportCaption = observer((props) => {
  const { t } = props;
  const {
    reportCourseManager, reportIdentityManager, reportOverrideManager, reportStandardsManager, reportTypeManager, userManager
  } = useContext(MobXProviderContext);

  const history = useHistory();

  const { pathname } = window.location;
  const isLti = pathname.includes('/lti-courses');

  const shouldShowLastModifiedAsRollover = reportIdentityManager.isFacultyClassroomReport
    && !userManager.isTeacher/* && userManager.hasSatelliteBetaAccessUserPermission */;

  const InfoIcon = SatCoreComponent('InfoIcon');

  const renderReportCaptionComponent = () => {
    const {
      activeReportFacultyType,
      isFacultyClassroomReport,
      reportFacultyName,
      reportInfoClassNames,
      reportLastModified
    } = reportIdentityManager;

    const reportLeadTeacherName = ReportIdentityService.getReportLeadTeacherName();

    const urlParams = new URLSearchParams(window.location.search);
    const studentFullName = (isLti) ? reportIdentityManager.studentFullName : urlParams.get('studentFullName');
    const translationOptions = {
      studentFullName // used for individual student report only
    };

    let { pathname } = window.location;
    if (isLti) {
      pathname = reportIdentityManager.ltiTableTypePathname;
    }
    const translatedReportTitle = t(REPORT_URL_KEY[pathname], translationOptions);

    // TODO DEMO-2356 - this is temporary for testing BE report rewrite
    const allowExperimentalReport = userManager.hasExperimentalAccessPermission;

    return (
      <div className={`report-caption ${reportInfoClassNames}`} onClick={async (e) => {
        // TODO DEMO-2356 - this is temporary for testing BE report rewrite
        if (allowExperimentalReport) {
          if (e.shiftKey) {
            const urlParams = new URLSearchParams(window.location.search);
            let reportId = urlParams.get('reportId');
            const type = reportTypeManager.selectedReportLessonFilterType;

            if (isModifiedReportId(reportId, type)) {
              reportId = getModifiedReportId(reportId, type);
            }

            const courseId = reportCourseManager.selectedReportCourseContentItemId;
            const classroomId = urlParams.get('classroomId');
            const institutionId = reportIdentityManager.activeReportInstitutionId;
            // required for **cmap standards** reports (only sirius at the moment)
            const curriculumMapId = reportStandardsManager.selectedReportCmapContentItemId;
            const isStandardsReport = reportId.includes('STANDARDS');

            let entityId;
            if (reportId.includes('DIST') || reportId.includes('SCH')) {
              if (isStandardsReport && curriculumMapId) {
                entityId = `${institutionId}${curriculumMapId}`;
              } else if (isStandardsReport) {
                entityId = `${institutionId}${courseId}`;
              } else {
                entityId = `${institutionId}`;
              }
            } else if (reportId.includes('CLASS') || ReportIdentityService.isCustomStandardsFilterReport(reportId)) {
              entityId = `${classroomId}`;
            }

            history.push({
              pathname: '/reportJsonDiff',
              search: `?reportId=${reportId}&entityId=${entityId}`
            });
          } else {
            reportIdentityManager.setIsExperimentalReportToggled(!reportIdentityManager.isExperimentalReportToggled);

            let reportId = urlParams.get('reportId');
            const type = reportTypeManager.selectedReportLessonFilterType;

            if (isModifiedReportId(reportId, type)) {
              reportId = getModifiedReportId(reportId, type);
              await reportIdentityManager.fetchReportResponseJson({ reportId });
            } else if (reportId.includes('SIRIUS_STANDARDS_DIST') || reportId.includes('SIRIUS_STANDARDS_SCH')) {
              const curriculumMapId = reportStandardsManager.selectedReportCmapContentItemId;
              await reportIdentityManager.fetchReportResponseJson({ curriculumMapId });
            } else {
              await reportIdentityManager.fetchReportResponseJson();
            }
          }
        }
      }}>
        {/* REPORT TITLE */}
        <div className='report-caption-title-wrapper'>
          <div className='report-caption-title ellipsify' title={translatedReportTitle}>
            {translatedReportTitle}

            {/* TODO DEMO-2356 - this is temporary for testing BE report rewrite */}
            {reportIdentityManager.isExperimentalReportToggled && allowExperimentalReport ? ' - Experimental' : ''}
          </div>
          {renderReportCaptionTitleInfoIconIfApplicable({
            reportLastModified
          })}
        </div>

        {/* SECTION: FACULTY */}
        {renderCaptionSection({
          additionalCaptionClassNames: 'report-faculty',
          captionLabelKey: activeReportFacultyType,
          captionLabelValue: reportFacultyName
        })}

        {/* SECTION: STANDARDS DOCUMENT
        //  TODO remove - this is no longer used
        // {isStandardsReport && (!isFacultyAdminReport || selectedReportCourseName)
        //   && standardsDocumentStringValue && (
        //   renderCaptionSection({
        //     additionalCaptionClassNames: 'report-standards-document',
        //     captionLabelKey: 'standardsDocument',
        //     captionLabelValue: standardsDocumentStringValue
        //   })
        )} */}

        {/* SECTION: LAST MODIFIED */}
        {!shouldShowLastModifiedAsRollover && renderCaptionSection({
          additionalCaptionClassNames: 'report-last-modified',
          captionLabelKey: 'lastModified',
          captionLabelValue: reportLastModified || t('unknownLastModified'),
          popupDisabled: true
        })}

        {/* SECTION: LEAD TEACHER NAME */}
        {shouldShowLastModifiedAsRollover && isFacultyClassroomReport && renderCaptionSection({
          additionalCaptionClassNames: 'report-lead-teacher-name',
          captionLabelKey: 'leadTeacherName',
          captionLabelValue: reportLeadTeacherName || t('unknownLeadTeacherName'),
          popupDisabled: true
        })}
      </div>
    );
  };

  const renderReportCaptionTitleInfoIconIfApplicable = ({
    reportLastModified
  } = {}) => {
    if (shouldShowLastModifiedAsRollover) {
      return (
        <InfoIcon popupOptions={{
          content: (
            <div className='report-caption-title-info-rollover'>
              <div className='report-caption-title-info-rollover-title'>
                {t('lastModified')}:
              </div>
              <div className='report-caption-title-info-rollover-description'>
                {reportLastModified || t('unknownLastModified')}
              </div>
            </div>),
          offset: [0, -4],
          on: 'hover',
          position: 'bottom center'
        }} />
      );
    }
  };

  const renderCaptionSection = ({
    captionLabelKey = '',
    captionLabelValue = '',
    additionalCaptionClassNames = '',
    popupDisabled = false
  } = {}) => {
    const { allowLastModifiedAdditionalReportInfo } = reportOverrideManager;
    return (
      <div className={`report-caption-section ${additionalCaptionClassNames}`}>
        <div className={`report-caption-label-key ${additionalCaptionClassNames}`}>
          {t(captionLabelKey)}:
        </div>
        {PopupService.renderPopup({
          content: captionLabelValue,
          disabled: popupDisabled,
          position: 'bottom center',
          trigger: (
            <div className={`report-caption-label-value ${additionalCaptionClassNames} ellipsify`}>
              {captionLabelValue}
            </div>
          )
        })}
        {allowLastModifiedAdditionalReportInfo && captionLabelKey === 'lastModified' && renderLastModifiedAdditonalReportInfo()}
      </div>
    );
  };

  const renderLastModifiedAdditonalReportInfo = () => {
    const { activeReportFacultyType } = reportIdentityManager;
    let infoMessageKey = '';
    if (activeReportFacultyType === REPORT_FACULTY_TYPE.DISTRICT) {
      infoMessageKey = 'districtInfoMessage';
    } else if (activeReportFacultyType === REPORT_FACULTY_TYPE.SCHOOL) {
      infoMessageKey = 'schoolInfoMessage';
    } else if (activeReportFacultyType === REPORT_FACULTY_TYPE.CLASSROOM) {
      infoMessageKey = 'classroomInfoMessage';
    } else {
      infoMessageKey = 'modifiedContentInfo';
    }

    const popupContent = t(infoMessageKey);
    const popupTrigger = t('lastModifiedPopupTrigger');

    return (
      <>
        {PopupService.renderPopup({
          content: popupContent,
          trigger: popupTrigger
        })}
      </>
    );
  };

  return renderReportCaptionComponent();
});
export default ReportCaption;

SatCoreRegister('ReportCaption', ReportCaption);
