import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { withRouter } from 'react-router-dom';

import { Image, Label, Menu, Popup } from 'semantic-ui-react';

import iconHelp from '../img/icon-help.svg';
import '../css/TopNav.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { VIEW_SELECTION } from '../managers/NavigationManager';

import { DEFAULT_TEACHER_TOP_NAV_ORDER_ARRAY, TEACHER_TOP_NAV } from '../constants';

import InitService from '../services/InitService';
import PopupService from '../services/PopupService';
import TeacherProductViewService from '../services/TeacherProductViewService';
import UserService from '../services/UserService';

const isVisible = (element) => window?.getComputedStyle(element)?.display !== 'none';
export const topNavHandleKeyDown = (e, menuBarRef) => {
  const menuItems = Array.prototype.slice.call(menuBarRef.current.querySelectorAll('[role="tab"]')).filter(isVisible);
  const index = menuItems.indexOf(e.target.closest('[role=tab]') || e.target);
  const subMenu = menuItems.indexOf(e.target) === -1;
  if (index === -1) {
    return;
  }
  const moves = {
    ArrowRight: 1,
    ArrowDown: 1,
    ArrowLeft: -1,
    ArrowUp: -1
  };
  switch (e.key) {
    case 'ArrowRight':
    case 'ArrowLeft': {
      const nextIndex = (menuItems.length + index + moves[e.key]) % menuItems.length;
      (menuItems[nextIndex].querySelector('[role=menu]') || menuItems[nextIndex]).focus();
      e.preventDefault();
      break;
    }
    case 'ArrowDown':
    case 'ArrowUp': {
      if (menuItems[index].querySelector('[role=menu]')) {
        !subMenu && menuItems[index].querySelector('[role=menu]').focus();
      } else {
        const nextIndex = (menuItems.length + index + moves[e.key]) % menuItems.length;
        (menuItems[nextIndex].querySelector('[role=menu]') || menuItems[nextIndex]).focus();
      }
      e.preventDefault();
      break;
    }
    case 'Home':
    case 'End': {
      const nextIndex = e.key === 'Home' ? 0 : menuItems.length - 1;
      (menuItems[nextIndex].querySelector('[role=menu]') || menuItems[nextIndex]).focus();
      e.preventDefault();
      break;
    }
    case ' ':
    case 'Enter':
      if (menuItems[index].querySelector('[role=menu]')) {
        if (subMenu) {
          menuItems[index].querySelector('[role=menuitem][aria-selected=true]')?.click();
        } else {
          menuItems[index].querySelector('[role=menu]').focus();
        }
      } else {
        menuItems[index].click();
      }
      break;
  }
};

export default
@inject(
  'appManager',
  'contentManager',
  'dynamicSatelliteManager',
  'navigationManager',
  'notificationManager',
  'productManager',
  'schoolManager',
  'teacherProductViewManager',
  'topNavManager',
  'userManager')
@observer
class TopNav extends Component {
  constructor(props) {
    super(props);
    this.LogoButtons = SatCoreComponent('LogoButtons');
    this.ProfileMenu = SatCoreComponent('ProfileMenu');

    this.menuBarRef = React.createRef();
  }

  componentDidMount() {
    const { appManager, dynamicSatelliteManager, history } = this.props;

    TeacherProductViewService.updateTeacherProductNavStatus(window.location.pathname);

    InitService.initActiveUserRoleAsHtmlBodyAttr();

    if (dynamicSatelliteManager.isDynamicSatellite) {
      // send user to a specified custom 'main' nav tab if the following conditions are true:
      // (1) user has the proper satellite and publisher permissions to see the given tab (i.e. the tab is in the displayed list), and
      // (2) user is on currently on the dashboard tab (i.e. likely via redirection after login), and
      // (3) user has likely not manually clicked on the 'dashboard' nav tab since login (i.e. ?view='dashboard' param is absent), and
      // (4) satelliteLayoutConfig has a 'mainTopNavId' that is **not** the dashboard ('default')
      const mainTopNavId = dynamicSatelliteManager.dynamicSatelliteLayoutConfig?.teacher?.mainTopNavId || 'default';

      const topNavObj = TEACHER_TOP_NAV[mainTopNavId];

      if (topNavObj?.functionKey) {
        const pathname = window?.location?.pathname;
        const urlParams = new URLSearchParams(window.location.search);
        if (mainTopNavId !== 'default' && pathname === '/' && urlParams?.get?.('view') !== VIEW_SELECTION.DASHBOARD) {
          this[topNavObj?.functionKey]?.();
        }
      }
    }

    this.unlisten = history.listen((location, _action) => {
      appManager.setLocation(location);
      TeacherProductViewService.updateTeacherProductNavStatus(location.pathname);
    });
  }

  componentWillUnmount() {
    InitService.removeHtmlBodyRoleAttr();
    this.unlisten?.();
  }

  showProfile = () => {
    const { history } = this.props;
    history.push(`/profile?view=${VIEW_SELECTION.PROFILE}`, { url: history.createHref(history.location) });
  };

  showArchivedClasses = () => {
    const { history, navigationManager } = this.props;
    navigationManager.setView(VIEW_SELECTION.ARCHIVED_CLASSES);
    history.push(`/archived-classes?view=${VIEW_SELECTION.ARCHIVED_CLASSES}`);
  };

  showClasses = async () => {
    const { history, navigationManager } = this.props;
    navigationManager.setView(VIEW_SELECTION.DASHBOARD);
    await history.push(`/?view=${VIEW_SELECTION.DASHBOARD}`);
  };

  showContent = async () => {
    const { history, navigationManager, userManager } = this.props;
    const { allowTeacherLibraryContentView } = userManager;
    if (allowTeacherLibraryContentView) {
      navigationManager.setView(VIEW_SELECTION.CONTENT);
      await history.push(`/content?view=${VIEW_SELECTION.CONTENT}`);
    }
  }

  showProducts = async () => {
    const { history, navigationManager, teacherProductViewManager } = this.props;
    if (teacherProductViewManager.allowTeacherProductView) {
      navigationManager.setView(VIEW_SELECTION.PRODUCTS);
      await history.push(`/products?view=${VIEW_SELECTION.PRODUCTS}`);
    }
  }

  showAssignments = async () => {
    const { history, navigationManager } = this.props;
    navigationManager.setView(VIEW_SELECTION.ASSIGNMENTS);
    await history.push(`/assignments?view=${VIEW_SELECTION.ASSIGNMENTS}`);
  };

  handleClickTestBuilder = async () => {
    const {
      history,
      navigationManager, schoolManager, userManager
    } = this.props;
    if (userManager.canViewSatelliteTestBuilder) {
      navigationManager.setView(VIEW_SELECTION.TEST_BUILDER);
      navigationManager.clearAllPaths();
      schoolManager.setSearchText('');
      await history.push(`/testbuilder?view=${VIEW_SELECTION.TEST_BUILDER}`);
    }
  }

  showReports = async () => {
    const { history, navigationManager } = this.props;
    const noAddDefaultBreadcrumb = true;
    navigationManager.clearAllPaths(noAddDefaultBreadcrumb);
    navigationManager.setView(VIEW_SELECTION.REPORTS);
    await history.push(`/reports?view=${VIEW_SELECTION.REPORTS}`);
  }

  showHelpWindow = async () => {
    const { contentManager } = this.props;
    if (contentManager.helpWindowUrl !== null) {
      window.open(contentManager.helpWindowUrl, '_blank');
    }
  }

  showNotifications = () => {
    const { history, navigationManager } = this.props;
    navigationManager.setView(VIEW_SELECTION.NOTIFICATIONS);
    history.push(`/notifications?view=${VIEW_SELECTION.NOTIFICATIONS}`);
  }

  showResources = async () => {
    const { history, navigationManager } = this.props;
    navigationManager.setView(VIEW_SELECTION.RESOURCES);
    await history.push(`/resources?view=${VIEW_SELECTION.RESOURCES}`);
  }

  /**
   * Per **DEMO-2504**, to better ensure the Notifications count in TopNav is current,
   *
   * we are now fetching notifications whenever the active TopNav section changes.
   */
  handleFetchNotifications = async () => {
    const { notificationManager, userManager } = this.props;
    await notificationManager.fetchManualNotifications({ page: 0, userId: userManager.userId });
    await notificationManager.fetchSystemNotifications({ page: 0, userId: userManager.userId });
  }

  handleLogout = async () => {
    const { history, navigationManager } = this.props;
    navigationManager.setView(VIEW_SELECTION.LOGIN);
    InitService.doLogoutCleanUp();
    history.replace('/signin');
  }

  isViewSelected = (view) => {
    const { navigationManager, productManager } = this.props;
    const { viewSelection } = navigationManager;

    if (productManager.FROM_TEACHER_PRODUCTS_NAV &&
      (viewSelection === VIEW_SELECTION.BOOK || viewSelection === VIEW_SELECTION.PRODUCTS)
    ) {
      return view === VIEW_SELECTION.PRODUCTS;
    } else {
      return viewSelection && viewSelection === view;
    }
  }

  isViewSelectedClass = (view) => (this.isViewSelected(view) ? 'selected' : null);

  renderMenuItem = (children, view, props, {
    _id,
    /* navTranslationKey = '', */
    navTranslationRolloverKey = ''
  } = {}) => {
    const { productManager, t } = this.props;

    const rolloverMessage = t(navTranslationRolloverKey);

    return (!productManager.isFromProduct || productManager.FROM_TEACHER_PRODUCTS_NAV)
      && PopupService.renderPopup({
        content: rolloverMessage,
        disabled: !rolloverMessage,
        key: _id,
        trigger: (
          <Menu.Item
            aria-selected={!!this.isViewSelectedClass(view)}
            as='li'
            role='tab'
            tabIndex='0'
            {...props}
            className={['tnav-tab', this.isViewSelectedClass(view), (props || {}).className].filter((className) => className).join(' ')}
            onClick={async () => {
              await this.handleFetchNotifications();
              if (productManager.shouldShowExpiredLicenseWarning) {
                productManager.setShouldShowExpiredLicenseWarning(false);
              }
              await props.onClick();
            }}>
            {children}
          </Menu.Item>
        ),
        wide: false
      });
  }

  getNavTabs = () => {
    const { getNavTabs, productManager } = this.props;
    if (productManager.isFromProduct && !productManager.FROM_TEACHER_PRODUCTS_NAV) {
      return null;
    } else {
      return getNavTabs !== undefined ? getNavTabs(this.props, this) : this.getBaseNavTabs();
    }
  };

  getBaseNavTabs = () => {
    const { dynamicSatelliteManager, t, teacherProductViewManager, userManager } = this.props;
    const { allowTeacherProductView } = teacherProductViewManager;
    const { allowTeacherLibraryContentView, canViewSatelliteTestBuilder } = userManager;
    const showTestBuilderTab = canViewSatelliteTestBuilder;

    const tabNavOrderArray = (dynamicSatelliteManager.isDynamicSatellite ? (
      dynamicSatelliteManager.teacherTopNavOrderArray
    ) : DEFAULT_TEACHER_TOP_NAV_ORDER_ARRAY) || [];

    return tabNavOrderArray.map((tabNavId) => {
      const tabNavObj = TEACHER_TOP_NAV[tabNavId];

      const { _id, className, functionKey, navTranslationKey, viewSelectionKey } = tabNavObj;

      switch (_id) {
      case TEACHER_TOP_NAV.assignments._id:
        break;
      case TEACHER_TOP_NAV.classrooms._id:
        break;
      case TEACHER_TOP_NAV.libraryContent._id:
        if (!allowTeacherLibraryContentView) {
          return;
        }
        break;
      case TEACHER_TOP_NAV.reports._id:
        break;
      case TEACHER_TOP_NAV.teacherProducts._id:
        if (!allowTeacherProductView) {
          return;
        }
        break;
      case TEACHER_TOP_NAV.testBuilder._id:
        if (!showTestBuilderTab) {
          return;
        }
        break;
      }
      return this.renderMenuItem(t(navTranslationKey), VIEW_SELECTION[viewSelectionKey], {
        className, onClick: this[functionKey]
      }, {
        ...(tabNavObj || {})
        // navTranslationKey, navTranslationRolloverKey
      });
    }).filter((tabNavObj) => !!tabNavObj);

    // TODO remove
    // return (
    //   <>
    //     {this.renderMenuItem(t('DashboardLabel'), VIEW_SELECTION.DASHBOARD, {
    //       className: 'dashboard', onClick: this.showClasses,
    //     }, { navTranslationKey: 'DashboardLabel', navTranslationRolloverKey: 'DashboardRollover' })}

    //     {allowTeacherLibraryContentView && this.renderMenuItem(t('libraryContentLabel'), VIEW_SELECTION.CONTENT, {
    //       className: 'content', onClick: this.showContent
    //     }, { navTranslationKey: 'libraryContentLabel', navTranslationRolloverKey: 'libraryContentRollover' })}

    //     {teacherProductViewManager.allowTeacherProductView && (
    //       this.renderMenuItem(t('ProductLabel'), VIEW_SELECTION.PRODUCTS, { className: 'products', onClick: this.showProducts },
    //         { navTranslationKey: 'ProductLabel', navTranslationRolloverKey: 'ProductRollover' })
    //     )}

    //     {this.renderMenuItem(t('AssignmentLabel'), VIEW_SELECTION.ASSIGNMENTS, {
    //       className: 'assignments', onClick: this.showAssignments
    //     }, { navTranslationKey: 'AssignmentLabel', navTranslationRolloverKey: 'AssignmentRollover' })}

    //     {showTestBuilderTab && this.renderMenuItem(t('testBuilderLabel', 'Test Builder'), VIEW_SELECTION.TEST_BUILDER, {
    //       className: 'library', onClick: this.handleClickTestBuilder
    //     }, { navTranslationKey: 'testBuilderLabel', navTranslationRolloverKey: 'testBuilderRollover' })}

    //     {this.renderMenuItem(t('ReportLabel'), VIEW_SELECTION.REPORTS, {
    //       className: 'reports', onClick: this.showReports
    //     }, { navTranslationKey: 'ReportLabel', navTranslationRolloverKey: 'ReportRollover' })}
    //   </>
    // );
  };

  getHelpLink = () => {
    const { contentManager } = this.props;
    if (contentManager.helpWindowUrl !== undefined && contentManager.helpWindowUrl !== null && contentManager.helpWindowUrl !== '') {
      return this.renderMenuItem(
        <Image alt='' aria-label='Help link' role='tab' src={iconHelp} />, null, { className: 'help-nav', onClick: this.showHelpWindow }
      );
    }
  }

  getNotificationLink = () => {
    const { notificationManager, t, topNavManager } = this.props;
    if (notificationManager.totalNotificationCount > 0) {
      return this.renderMenuItem(
        topNavManager.showFullNotificationBanner ? (
          <div className='notification-count-wrapper notification-count'>
            <div>
              {`Notifications (${notificationManager.topNavNotificationCountLabel})`}
            </div>
          </div>
        ) : (
          <Popup
            hideOnScroll={false}
            hoverable={true}
            on='hover'
            position='bottom center'
            trigger={(
              <div className='notification-count-wrapper notification-count'>
                <Label circular>
                  {notificationManager.topNavNotificationCountLabel}
                </Label>
              </div>
            )}
            wide>
            <div key={1} className='card-value'>{t('notificationsLabel')}</div>
          </Popup>
        ),
        null,
        { className: 'help-nav', onClick: this.showNotifications }
      );
    }
  }

  renderProfileMenu = () => {
    const { ProfileMenu, props: { history, profileMenu, t, userManager } } = this;
    const { productManager } = this.props;

    const { profile = true, archivedClasses = true, items, logout = true } = profileMenu || {};

    const { allowSwitchRole, isSsoUser, isTeacher } = userManager;
    const hasMultipleRoles = UserService.getAvailableRoleCount() > 1;

    let options = [];
    if (logout) {
      options = { onClick: this.handleLogout, text: t('logout') };
    }
    if (UserService.hasActivePermissionId()) {
      options = [
        profile && { onClick: this.showProfile, text: t('profile') },
        archivedClasses && isTeacher && !isSsoUser && { onClick: this.showArchivedClasses, text: t('archivedClasses') },
        allowSwitchRole && hasMultipleRoles && { onClick: () => UserService.showRoleSwitcher(history), text: t('switchRole') },
        ...(items || []),
        logout && { onClick: this.handleLogout, text: t('logout') }
      ];
    }
    return (!productManager.isFromProduct || productManager.FROM_TEACHER_PRODUCTS_NAV) && (
      <ProfileMenu
        {...profileMenu}
        options={options} />
    );
  }

  render() {
    const { additionalClassName = '', history, location, productManager } = this.props;
    const { LogoButtons } = this;

    let topNavClassName = 'topNav';
    topNavClassName += additionalClassName ? ` ${additionalClassName}` : '';

    const allowClickNavLogo = !productManager.isFromProduct;

    return (
      <Menu
        as={(props) => <header id='top-nav-bar' role='tablist' {...props} ref={this.menuBarRef} />}
        className={topNavClassName}
        fixed='top'
        inverted
        onKeyDown={(e) => topNavHandleKeyDown(e, this.menuBarRef)}>
        <LogoButtons history={history} location={location} navButton={allowClickNavLogo} role='tab' />
        <Menu.Menu
          aria-label='Navigation panel'
          as={(props) => <nav {...props} />}
          position='right'
          role='navigation'>
          <ul className='nav-list' role='tablist'>
            {this.getNavTabs()}
            {this.getHelpLink()}
            {this.getNotificationLink()}
          </ul>
          {this.renderProfileMenu()}
        </Menu.Menu>
      </Menu>
    );
  }
}

SatCoreRegister('TopNav', withRouter(TopNav));
