import classroomManager from '../managers/ClassroomManager';
import classroomRosterManager from '../managers/ClassroomRosterManager';
import userManager from '../managers/UserManager';
import PurchaseCodeApi from '../api/PurchaseCodeApi';

export default class PurchaseCodeService {
  static async burnCodes(codes) {
    const result = await PurchaseCodeApi.burnCodes(classroomManager.currentClassroomId, codes);
    if (result) {
      await classroomRosterManager.fetchCurrentRoster(userManager.userId, classroomManager.currentClassroomId);
    }
    return result;
  }
}
