import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { SatCoreComponent } from '../SatCoreRegistry';

export const ImpersonationCautionBanner = observer(() => {
  const { userManager } = useContext(MobXProviderContext);
  const { firstName, lastName } = userManager;

  const CautionBanner = SatCoreComponent('CautionBanner');

  const fullName = `${firstName || ''} ${lastName || ''}`;

  const bannerKey = 'ImpersonationCautionBanner';
  const cautionBannerMsg = (`
    Caution! You are logged in as ${fullName},
    any and all actions taken beyond this point will be logged as
    activity by ${fullName} and attributed to their account.
    You must log out of this account in order to stop impersonating ${fullName}.
  `);

  return <CautionBanner bannerKey={bannerKey} cautionBannerMsg={cautionBannerMsg} />;
});
