/* eslint-disable max-len */
import { action, computed, makeObservable, observable, toJS } from 'mobx';

import CryptoMD5 from 'crypto-js/md5';

// import iconCommentActive from '../img/icon-comment-active.svg';

import { getRegisteredClass } from '../SatCoreRegistry';

import AssignmentManager, { PDF_DELIVERY_FORMATS } from './AssignmentManager';
import Auth from './AuthManager';
import UserManager from './UserManager';
import courseManager from './CourseManager';
import { ONLINE_CONTENT_TYPES } from './TagContentManager';

import UserService from '../services/UserService';
import UserOptionsService from '../services/UserOptionsService';

import { generateUrl } from '../utils/url';
import ReportIdentityManager from './reports/ReportIdentityManager';

const CONTENT_MANAGER_ENDPOINTS = {
  FETCH_LESSON_CONTENT: '/api/getLessonContent',
  GET_LESSON_GRADER_URL: '/api/getLessonGraderUrl',
  GET_LESSON_PLAYER_URLS: '/api/getLessonPlayerUrls',
  GET_CONTENT_USAGE: '/api/viewContentUsage',
  LAUNCH_TYPING_MASTER_URL: '/api/launchTypingMasterResource'
};

export const CONTENT_ITEM_TYPES = {
  ARTICLE_RESOURCE: 'article_resource',
  ASSESSMENT: 'assessment',
  AUDIO_RESOURCE: 'audio_resource',
  BOOKMARK_RESOURCE: 'bookmark_resource',
  CMAP_RESOURCE: 'curriculum_map',
  CODEMONKEY_RESOURCE: 'codemonkey_resource',
  COMMOM_CARTRIDGE_RESOURCE: 'common_cartridge',
  COURSE_RESOURCE: 'course_resource',
  DOCREADER: 'docreader',
  FILE_RESOURCE: 'file_resource',
  GOOGLE_DOC_RESOURCE: 'google_doc_resource',
  GOOGLE_SHEET_RESOURCE: 'google_sheet_resource',
  GOOGLE_SLIDE_RESOURCE: 'google_slide_resource',
  IMAGE_RESOURCE: 'image_resource',
  KEYBOARDING_RESOURCE: 'keyboarding_resource',
  LEARNOSITY_ACTIVITY_RESOURCE: 'learnosity_activity_resource',
  LEARNOSITY_ITEM_RESOURCE: 'learnosity_item_resource',
  LESSON: 'lesson',
  LESSON_PLAN: 'lesson_plan',
  MICROSITE_RESOURCE: 'microsite_resource',
  ONLINE_CONTENT_TYPE: 'online_assessment_type',
  PDF_RESOURCE: 'pdf_resource',
  PURPOSE_CONTAINER: 'PurposeContainer',
  RUBRIC_RESOURCE: 'rubric',
  SCORM_RESOURCE: 'scorm_resource',
  TEST_ITEM: 'test_item',
  VIDEO_RESOURCE: 'video_resource',
  WEBCONTENT_RESOURCE: 'webcontent_resource',
  getFlag: (contentItemType) => {
    if (contentItemType === CONTENT_ITEM_TYPES.ARTICLE_RESOURCE) return 'ARTICLE';
    if (contentItemType === CONTENT_ITEM_TYPES.ASSESSMENT) return 'ASSESSMENT';
    if (contentItemType === CONTENT_ITEM_TYPES.AUDIO_RESOURCE) return 'AUDIO';
    if (contentItemType === CONTENT_ITEM_TYPES.BOOKMARK_RESOURCE) return 'BOOKMARK';
    if (contentItemType === CONTENT_ITEM_TYPES.CMAP_RESOURCE) return 'CMAP';
    if (contentItemType === CONTENT_ITEM_TYPES.COMMOM_CARTRIDGE_RESOURCE) return 'COMMON CARTRIDGE';
    if (contentItemType === CONTENT_ITEM_TYPES.COURSE_RESOURCE) return 'COURSE';
    if (contentItemType === CONTENT_ITEM_TYPES.FILE_RESOURCE) return 'FILE';
    if (contentItemType === CONTENT_ITEM_TYPES.GOOGLE_DOC_RESOURCE) return 'GOOGLE DOC';
    if (contentItemType === CONTENT_ITEM_TYPES.GOOGLE_SHEET_RESOURCE) return 'GOOGLE SHEET';
    if (contentItemType === CONTENT_ITEM_TYPES.GOOGLE_SLIDE_RESOURCE) return 'GOOGLE SLIDE';
    if (contentItemType === CONTENT_ITEM_TYPES.IMAGE_RESOURCE) return 'IMAGE';
    if (contentItemType === CONTENT_ITEM_TYPES.LEARNOSITY_ACTIVITY_RESOURCE) return 'LEARNOSITY';
    if (contentItemType === CONTENT_ITEM_TYPES.LEARNOSITY_ITEM_RESOURCE) return 'LEARNOSITY ITEM';
    if (contentItemType === CONTENT_ITEM_TYPES.LESSON) return 'LESSON';
    if (contentItemType === CONTENT_ITEM_TYPES.LESSON_PLAN) return 'LESSON PLAN';
    if (contentItemType === CONTENT_ITEM_TYPES.MICROSITE_RESOURCE) return 'MICROSITE';
    if (contentItemType === CONTENT_ITEM_TYPES.ONLINE_CONTENT_TYPE) return 'Content Type';
    if (contentItemType === CONTENT_ITEM_TYPES.PDF_RESOURCE) return 'PDF';
    if (contentItemType === CONTENT_ITEM_TYPES.PURPOSE_CONTAINER) return 'CONTAINER';
    if (contentItemType === CONTENT_ITEM_TYPES.RUBRIC_RESOURCE) return 'RUBRIC';
    if (contentItemType === CONTENT_ITEM_TYPES.TEST_ITEM) return 'TEST ITEM';
    if (contentItemType === CONTENT_ITEM_TYPES.VIDEO_RESOURCE) return 'VIDEO';
    if (contentItemType === CONTENT_ITEM_TYPES.WEBCONTENT_RESOURCE) return 'WEBCONTENT';
  },
  getContentImageUrl: (imageUrl, imgFolder = 'userFiles') => {
    if (imageUrl) {
      return `${Auth.ecms}/api/redirectToResource?resourceFolder=${imgFolder}&resourceFileName=${imageUrl}&authKey=${Auth.authKey}`;
    } else {
      return null;
    }
  },
  getContentIdImageUrl: (id, imgFolder = 'userFiles') => `${Auth.ecms}/api/redirectToResource?resourceFolder=${imgFolder}&contentItemId=${id}&authKey=${Auth.authKey}`,
  getTempClassroomImageUrl: (imageUrl) => `${Auth.ecms}/api/redirectToResource?resourceFolder=classroomImages&resourceFileName=${imageUrl}&authKey=${Auth.authKey}`,
  isViewableFileType: (type, mime) => {
    if (type === CONTENT_ITEM_TYPES.LESSON_PLAN) return true;
    if (type === CONTENT_ITEM_TYPES.ARTICLE_RESOURCE) return true;
    if (type === CONTENT_ITEM_TYPES.AUDIO_RESOURCE) return true;
    if (type === CONTENT_ITEM_TYPES.IMAGE_RESOURCE) return true;
    if (type === CONTENT_ITEM_TYPES.VIDEO_RESOURCE) return true;
    if (type === CONTENT_ITEM_TYPES.FILE_RESOURCE && mime === 'application/pdf') return true;
    return false;
  },
  isGradeableType: (type) => {
    // TODO: THESE ARE COMING.
    //  if (type === CONTENT_ITEM_TYPES.LESSON_PLAN) return true;
    //  if (type === CONTENT_ITEM_TYPES.ARTICLE_RESOURCE) return true;
    //  if (type === CONTENT_ITEM_TYPES.AUDIO_RESOURCE) return true;
    //  if (type === CONTENT_ITEM_TYPES.IMAGE_RESOURCE) return true;
    if (type === CONTENT_ITEM_TYPES.VIDEO_RESOURCE) return true;
    if (type === CONTENT_ITEM_TYPES.BOOKMARK_RESOURCE) return true;
    return false;
  },
  isExternalAppType: (type) => {
    if (type === CONTENT_ITEM_TYPES.SCORM_RESOURCE) return true;
    if (type === CONTENT_ITEM_TYPES.CODEMONKEY_RESOURCE) return true;
    if (type === CONTENT_ITEM_TYPES.KEYBOARDING_RESOURCE) return true;
    return false;
  }
};

export const RESOURCE_BANK_CONTENT_ITEM_TYPES = {
  ARTICLE_RESOURCE: 'article_resource',
  ASSESSMENT: 'assessment',
  AUDIO_RESOURCE: 'audio_resource',
  BOOKMARK_RESOURCE: 'bookmark_resource',
  FILE_RESOURCE: 'file_resource',
  IMAGE_RESOURCE: 'image_resource',
  LEARNOSITY_ACTIVITY_RESOURCE: 'learnosity_activity_resource',
  LESSON: 'lesson',
  MICROSITE_RESOURCE: 'microsite_resource',
  PDF_RESOURCE: 'pdf_resource',
  VIDEO_RESOURCE: 'video_resource',
  getLabel: (contentItemType) => {
    if (contentItemType === CONTENT_ITEM_TYPES.ARTICLE_RESOURCE) return 'Article';
    if (contentItemType === CONTENT_ITEM_TYPES.ASSESSMENT) return 'Assessment';
    if (contentItemType === CONTENT_ITEM_TYPES.AUDIO_RESOURCE) return 'Audio';
    if (contentItemType === CONTENT_ITEM_TYPES.BOOKMARK_RESOURCE) return 'Bookmark';
    if (contentItemType === CONTENT_ITEM_TYPES.FILE_RESOURCE) return 'File';
    if (contentItemType === CONTENT_ITEM_TYPES.IMAGE_RESOURCE) return 'Image';
    if (contentItemType === CONTENT_ITEM_TYPES.LEARNOSITY_ACTIVITY_RESOURCE) return 'Learnosity Activity';
    if (contentItemType === CONTENT_ITEM_TYPES.LESSON) return 'Lesson';
    if (contentItemType === CONTENT_ITEM_TYPES.MICROSITE_RESOURCE) return 'Microsite';
    if (contentItemType === CONTENT_ITEM_TYPES.PDF_RESOURCE) return 'PDF';
    if (contentItemType === CONTENT_ITEM_TYPES.VIDEO_RESOURCE) return 'Video';
  },
  getResourceContentTypes: (type = 'resourceBank') => {
    const {
      ARTICLE_RESOURCE, ASSESSMENT, AUDIO_RESOURCE, BOOKMARK_RESOURCE, FILE_RESOURCE, IMAGE_RESOURCE,
      LEARNOSITY_ACTIVITY_RESOURCE, LESSON, MICROSITE_RESOURCE, PDF_RESOURCE, VIDEO_RESOURCE
    } = RESOURCE_BANK_CONTENT_ITEM_TYPES;
    let resourceContentTypes = '';
    if (type === 'itemBank') {
      resourceContentTypes = `${ASSESSMENT},${LESSON}`;
    } else {
      resourceContentTypes = `${ARTICLE_RESOURCE},${ASSESSMENT},${AUDIO_RESOURCE},${BOOKMARK_RESOURCE},${FILE_RESOURCE},${IMAGE_RESOURCE},${LEARNOSITY_ACTIVITY_RESOURCE},${LESSON},${MICROSITE_RESOURCE},${PDF_RESOURCE},${VIDEO_RESOURCE}`;
    }
    return resourceContentTypes;
  },
};

export const CONTENT_ITEM_SUB_TYPES = {
  KEY_DRILL: 'key_drill',
  LESSON_EXAM: 'lesson_exam',
  MOTORIC: 'motoric',
  PARAGRAPH_DRILL: 'paragraph_drill',
  PRE_TEST: 'pre_test',
  TYPING_REVIEW: 'typing_review',
  SENTENCE_DRILL: 'sentence_drill',
  SKILLS_TEST: 'skills_test',
  TYPING_TEST: 'typing_test',
  WORD_DRILL: 'word_drill',
  TYPING_CUSTOM: 'typing_custom',
  getLabel: (contentItemSubType) => {
    if (contentItemSubType === CONTENT_ITEM_SUB_TYPES.KEY_DRILL) return 'Key Drill';
    if (contentItemSubType === CONTENT_ITEM_SUB_TYPES.LESSON_EXAM) return 'Lesson Exam';
    if (contentItemSubType === CONTENT_ITEM_SUB_TYPES.MOTORIC) return 'Motoric';
    if (contentItemSubType === CONTENT_ITEM_SUB_TYPES.PARAGRAPH_DRILL) return 'Paragraph Drill';
    if (contentItemSubType === CONTENT_ITEM_SUB_TYPES.PRE_TEST) return 'Pre Test';
    if (contentItemSubType === CONTENT_ITEM_SUB_TYPES.TYPING_REVIEW) return 'Typing Review';
    if (contentItemSubType === CONTENT_ITEM_SUB_TYPES.SENTENCE_DRILL) return 'Sentence Drill';
    if (contentItemSubType === CONTENT_ITEM_SUB_TYPES.SKILLS_TEST) return 'Skill Test';
    if (contentItemSubType === CONTENT_ITEM_SUB_TYPES.TYPING_TEST) return 'Typing Test';
    if (contentItemSubType === CONTENT_ITEM_SUB_TYPES.WORD_DRILL) return 'Word Drill';
    if (contentItemSubType === CONTENT_ITEM_SUB_TYPES.TYPING_CUSTOM) return 'Typing Custom';
  },
  getVisibilityPermissions: (contentItemSubType) => {
    const subType = (contentItemSubType && contentItemSubType !== 'undefined') ? contentItemSubType : '';
    if (subType === CONTENT_ITEM_SUB_TYPES.KEY_DRILL ||
        subType === CONTENT_ITEM_SUB_TYPES.MOTORIC ||
        subType === CONTENT_ITEM_SUB_TYPES.PARAGRAPH_DRILL ||
        subType === CONTENT_ITEM_SUB_TYPES.TYPING_REVIEW ||
        subType === CONTENT_ITEM_SUB_TYPES.SENTENCE_DRILL ||
        subType === CONTENT_ITEM_SUB_TYPES.TYPING_TEST ||
        subType === CONTENT_ITEM_SUB_TYPES.WORD_DRILL
    ) {
      return {
        allowBackspaceEdit: true,
        allowBackpaceShow: true,
        allowDurationEdit: true,
        allowDurationShow: true,
        allowMinSpeedEdit: false,
        allowMinSpeedShow: true,
        allowMinAccuracyEdit: false,
        allowMinAccuracyShow: true,
        allowTestTextEdit: false,
        allowTestTextShow: false
      }
    } else if (subType === CONTENT_ITEM_SUB_TYPES.SKILLS_TEST || subType === CONTENT_ITEM_SUB_TYPES.LESSON_EXAM) {
      return {
        allowBackspaceEdit: true,
        allowBackpaceShow: true,
        allowDurationEdit: true,
        allowDurationShow: true,
        allowMinSpeedEdit: true,
        allowMinSpeedShow: true,
        allowMinAccuracyEdit: true,
        allowMinAccuracyShow: true,
        allowTestTextEdit: false,
        allowTestTextShow: false
      }
    } else if (subType === '' || subType === CONTENT_ITEM_SUB_TYPES.PRE_TEST || subType === CONTENT_ITEM_SUB_TYPES.TYPING_CUSTOM) {
      return {
        allowBackspaceEdit: true,
        allowBackpaceShow: true,
        allowDurationEdit: true,
        allowDurationShow: true,
        allowMinSpeedEdit: true,
        allowMinSpeedShow: true,
        allowMinAccuracyEdit: true,
        allowMinAccuracyShow: true,
        allowTestTextEdit: subType !== '',
        allowTestTextShow: subType !== ''
      }
    } else {
      return {
        allowBackspaceEdit: false,
        allowBackpaceShow: false,
        allowDurationEdit: false,
        allowDurationShow: false,
        allowMinSpeedEdit: false,
        allowMinSpeedShow: false,
        allowMinAccuracyEdit: false,
        allowMinAccuracyShow: false,
        allowTestTextEdit: false,
        allowTestTextShow: false
      }
    }
  },
};

export const PLAYER_TYPES = {
  LESSON_PLAYER: 'lesson-player',
  LEARNOSITY_PLAYER: 'learnosity-player',
  LEARNOSITY_SCORING_PLAYER: 'learnosity-scoring-player',
  FILE_VIEWER: 'file-viewer',
  CONTENT_PREVIEW_PLAYER: 'content-viewer',
  DOCREADER_VIEWER: 'docreader-viewer'
};

export const CONTENT_MODE = {
  PREVIEW: 'preview',
  PRESENT: 'present',
  ASSESS: 'assess',
  REVIEW: 'review',
  TEACHER_REVIEW_AS_STUDENT: 'teacher_review'
};

/**
 * NOTE: used for Flowpaper AND DocReader
 */
export const FLOWPAPER_SESSION_KEYS = {
  CRE_USER: 'courseResourceElement-user',
  CLASSROOM_CRE_USER: 'classroom-courseResourceElement-user'
};

export class ContentManager {
  @observable contentItems = new Map();

  // resource items are stored by contentItemId
  @observable resourceItems = new Map();

  @observable lessonUrl = null;

  @observable flowpaperUrl = null;

  @observable docReaderUrl = null;

  @observable docReaderId = null;

  @observable webReaderId = null;

  @observable textHelpId = null;

  // @observable alwaysShowReviewableAssignmentToggle = true; // TODO commenting out for now.  We need to re-evaluate the need for this
  @observable isReaderConfigLoaded = false;

  @observable flowpaperSessionKeyTeacherPreview = null;

  /**
   * `flowpaperSessionKeyStudent` can be defined at startup via `setFlowpaperSessionKeyStudent` in index.js (for any given satellite)
   *
   * possible values:
   *
   * `FLOWPAPER_SESSION_KEYS.CRE_USER`
   * `FLOWPAPER_SESSION_KEYS.CLASSROOM_CRE_USER`
   */
  @observable flowpaperSessionKeyStudent = FLOWPAPER_SESSION_KEYS.CRE_USER;

  @observable resourceIFrameUrl = null;
  @observable resourceIFrameUrlStudent = null;

  @observable helpWindowUrl = null;

  @observable useFlowpaperAnswerKey = false;

  @observable currentContentItemId = null;

  @observable currentflowPaperSessionId = null;

  @observable feedbackIconActiveImg = null; // iconCommentActive;

  constructor() {
    makeObservable(this);
  }

  @action setFlowpaperSessionKeyTeacherPreview = (flowpaperIdPattern) => {
    this.flowpaperSessionKeyTeacherPreview = flowpaperIdPattern;
  }

  @action setFlowpaperSessionKeyStudent = (flowpaperIdPattern) => {
    this.flowpaperSessionKeyStudent = flowpaperIdPattern;
  }

  @action setResourceIFrameUrl = (resourceUrl) => {
    this.resourceIFrameUrl = resourceUrl;
  }

  @action setResourceIFrameUrlStudent = (resourceUrl) => {
    this.resourceIFrameUrlStudent = resourceUrl;
  }

  @action setHelpWindowUrl = (helpWindowUrl) => {
    this.helpWindowUrl = helpWindowUrl;
  }

  @action setUserFlowpaperAnswerKey = (useFlowpaperAnswerKey) => {
    this.useFlowpaperAnswerKey = useFlowpaperAnswerKey;
  }

  @action setCurrentContentItemId = (contentItemId) => {
    this.currentContentItemId = contentItemId;
  }

  @action setCurrentFlowPaperSessionId = (flowPaperSessionId) => {
    this.currentflowPaperSessionId = flowPaperSessionId;
  }

  @action setFeedbackIconActiveImg = (feedbackIconActiveImg) => {
    this.feedbackIconActiveImg = feedbackIconActiveImg;
  }

  /**
   * Generate & return a **reader session id** based on the args passed to this method
   *
   * NOTE: used for Flowpaper AND DocReader
   */
  getFlowpaperSessionId = (
    activity, activityInstance, flowpaperIdPattern,
    userId = '', courseResourceElementId = null, classroomId = null
  ) => {
    activityInstance = activityInstance || {};
    activity = activity || {};

    let activityInstanceId;

    classroomId = classroomId || activityInstance.classroomId || activity.classroomId || '';
    courseResourceElementId = courseResourceElementId || activityInstance.courseResourceElementId || activity.courseResourceElementId || '';
    activityInstanceId = (activityInstance.activityInstanceId || activityInstance.id) || '';

    let sessionId;
    // old TODO: remove;
    // if (flowpaperIdPattern === FLOWPAPER_SESSION_KEYS.CRE_USER) {
    //   sessionId = `${courseResourceElementId}-${(activityInstanceId || userId)}`;
    // } else if (flowpaperIdPattern === FLOWPAPER_SESSION_KEYS.CLASSROOM_CRE_USER) {
    //   sessionId = `${classroomId}-${courseResourceElementId}-${(activityInstanceId || userId)}`;
    // }

    if (flowpaperIdPattern === FLOWPAPER_SESSION_KEYS.CRE_USER) {
      if (activityInstanceId !== '' && activityInstanceId !== null && activityInstanceId !== undefined) {
        sessionId = activityInstanceId;
      } else {
        sessionId = `${courseResourceElementId}-${userId}`;
      }
    } else if (flowpaperIdPattern === FLOWPAPER_SESSION_KEYS.CLASSROOM_CRE_USER) {
      if (activityInstanceId !== '' && activityInstanceId !== null && activityInstanceId !== undefined) {
        sessionId = activityInstanceId;
      } else {
        sessionId = `${classroomId}-${courseResourceElementId}-${userId}`;
      }
    }
    return sessionId;
  }

  isControlledReviewableType = (entityTypeId, pdfDeliveryFormat) => {
    const isAssessment = entityTypeId === CONTENT_ITEM_TYPES.ASSESSMENT;
    const isLearnosityActivityResource = entityTypeId === CONTENT_ITEM_TYPES.LEARNOSITY_ACTIVITY_RESOURCE;

    const isPdfResource = entityTypeId === CONTENT_ITEM_TYPES.PDF_RESOURCE;
    const isFlowpaper = pdfDeliveryFormat === PDF_DELIVERY_FORMATS.FLOWPAPER;
    const isPdfAndFlowpaper = pdfDeliveryFormat === PDF_DELIVERY_FORMATS.PDF_AND_FLOWPAPER;

    if (isAssessment || isLearnosityActivityResource) {
      return true;
    } else if (isPdfResource && (isFlowpaper || isPdfAndFlowpaper)) {
      return true;
    }
    return false;
  }

  getTeacherPreviewHatId = (classroomId, courseResourceElementId, userId) => {
    if (classroomId !== '' && classroomId !== null && classroomId !== undefined &&
      courseResourceElementId !== '' && courseResourceElementId !== null && courseResourceElementId !== undefined &&
      userId !== '' && userId !== null && userId !== undefined) {
      const startId = `${classroomId}-${courseResourceElementId}-${userId}`;
      const hatId = CryptoMD5(startId).toString().toUpperCase();
      return hatId;
    }
    return null;
  }

  clearAll = () => {
    this.contentItems.clear();
    this.resourceItems.clear();
    this.lessonUrl = null;
    this.flowpaperUrl = null;
  }

  // Check which type of url we should return.  Some elements don't have the content image (Gallopade),
  // so fall back to content type based URL from TagContentManager.
  getContentElementImageUrl(element) {
    if (element.elementContentItemImageUrl) {
      return CONTENT_ITEM_TYPES.getContentImageUrl(element.elementContentItemImageUrl);
    } else if (element.contentType) {
      return ONLINE_CONTENT_TYPES.getContentImageUrl(element.contentType);
    }
    return null;
  }

  getDownloadResource = async (contentItemId) => {
    const resource = await this.fetchResourceItem(contentItemId);

    return resource.presignedAwsUrl;
  }

  getOptionsForTeacherPreview = async (contentItemId, entityTypeId, pdfDeliveryFormat, origin, isTeacher, userId,
    courseResourceElementId = null, classroomId = null, publisherModeId = null, courseContentItemId = null
  ) => {
    const contentMode = CONTENT_MODE.PREVIEW;

    if (entityTypeId === CONTENT_ITEM_TYPES.MICROSITE_RESOURCE) {
      const ContentService = getRegisteredClass('ContentService');
      return ContentService.getOptionsForFileViewer({
        classroomId,
        contentItemId,
        contentMode,
        courseResourceElementId,
        entityTypeId,
        fromMethodName: 'getOptionsForTeacherPreview',
        isTeacher,
        origin,
        pdfDeliveryFormat,
        publisherModeId,
        userId
      });
    }


    let viewUrl = null;
    let playerType = null;
    let isFlowpaper = false;
    this.setCurrentContentItemId(contentItemId);

    await this.fetchReaderConfig();

    const { isDistrictAdmin, isSchoolAdmin, hasPublisherPermission, isProductAdmin } = UserManager;
    const isAdmin = isDistrictAdmin || isSchoolAdmin || hasPublisherPermission;

    if (!isTeacher && !isAdmin && !isProductAdmin) {
      console.warn('Not a teacher/admin. This is a teacher/admin function only');
      return false;
    }
    if (entityTypeId === CONTENT_ITEM_TYPES.LESSON || entityTypeId === CONTENT_ITEM_TYPES.ASSESSMENT) {
      const hatId = this.getTeacherPreviewHatId(classroomId, courseResourceElementId, userId);
      const result = await this.fetchLessonPlayerUrl(contentItemId, null, Auth.authKey, origin,
        contentMode, isTeacher, null, hatId, null, publisherModeId, classroomId, courseResourceElementId);
      // For preview, use react player if satellite supports it.
      if (Auth.lesson && Auth.authKey && !Auth.overrideLessonPlayer) {
        const finalCourseContentItemId = (courseContentItemId) || courseManager.currentCourseId;
        viewUrl = `${Auth.lesson}/preview?contentItemId=${contentItemId}&courseId=${finalCourseContentItemId}&entityTypeId=${entityTypeId}&forceTeacher=${UserManager.courseTeacher}&forceStudent=${UserManager.courseStudent}`;
        if (publisherModeId) {
          viewUrl += `&modeId=${publisherModeId}`;
        }
        viewUrl = await this.addUserOptions(viewUrl, hatId, origin, null);
        viewUrl += `#${Auth.authKey}`;

        playerType = PLAYER_TYPES.LESSON_PLAYER;
      } else {
        if (result) {
          viewUrl = this.lessonUrl;
          playerType = PLAYER_TYPES.LESSON_PLAYER;
        }
      }
    } else if (entityTypeId === CONTENT_ITEM_TYPES.LEARNOSITY_ACTIVITY_RESOURCE) {
      viewUrl = null;
      playerType = PLAYER_TYPES.LEARNOSITY_PLAYER;
    } else if (entityTypeId === CONTENT_ITEM_TYPES.PDF_RESOURCE) {
      const resource = await this.fetchResourceItem(contentItemId);
      if (pdfDeliveryFormat === PDF_DELIVERY_FORMATS.FLOWPAPER || pdfDeliveryFormat === PDF_DELIVERY_FORMATS.PDF_AND_FLOWPAPER) {
        let flowPaperSessionId = null;
        if (this.flowpaperSessionKeyTeacherPreview !== '' && this.flowpaperSessionKeyTeacherPreview !== null) {
          flowPaperSessionId = this.getFlowpaperSessionId(null, null, this.flowpaperSessionKeyTeacherPreview, userId, courseResourceElementId, classroomId);
        } else {
          flowPaperSessionId = `${contentItemId}-${userId}`;
        }
        await this.fetchFlowpaperUrl(contentItemId, flowPaperSessionId, null);
        viewUrl = this.flowpaperUrl;
        isFlowpaper = true;
        playerType = PLAYER_TYPES.FILE_VIEWER;
      } else if (pdfDeliveryFormat === PDF_DELIVERY_FORMATS.DOCREADER || pdfDeliveryFormat === PDF_DELIVERY_FORMATS.PDF_AND_DOCREADER) {
        let flowPaperSessionId = null;
        if (this.flowpaperSessionKeyTeacherPreview !== '' && this.flowpaperSessionKeyTeacherPreview !== null) {
          flowPaperSessionId = this.getFlowpaperSessionId(null, null, this.flowpaperSessionKeyTeacherPreview, userId, courseResourceElementId, classroomId);
        } else {
          flowPaperSessionId = `${contentItemId}-${userId}`;
        }
        this.setCurrentFlowPaperSessionId(flowPaperSessionId);
        await this.fetchDocReaderUrl(contentItemId, courseResourceElementId);
        viewUrl = this.docReaderUrl;
        isFlowpaper = false;
        playerType = PLAYER_TYPES.DOCREADER_VIEWER;
      } else {
        viewUrl = resource.presignedAwsUrl;
        playerType = PLAYER_TYPES.FILE_VIEWER;
      }
    } else {
      const resource = await this.fetchResourceItem(contentItemId);
      if (CONTENT_ITEM_TYPES.isViewableFileType(entityTypeId, resource.mime)) {
        playerType = PLAYER_TYPES.CONTENT_PREVIEW_PLAYER;
        viewUrl = null;
      } else if (entityTypeId === CONTENT_ITEM_TYPES.BOOKMARK_RESOURCE) {
        const isIframable = resource.iframeCompatible;
        if (isIframable) {
          playerType = PLAYER_TYPES.FILE_VIEWER;
        }
        viewUrl = resource.pageUrl;
      } else if (CONTENT_ITEM_TYPES.isExternalAppType(entityTypeId)) {
        playerType = PLAYER_TYPES.FILE_VIEWER;
        if (entityTypeId === CONTENT_ITEM_TYPES.SCORM_RESOURCE) {
          // For teacher preview, just pass a resource id, no activity id.
          viewUrl = `${Auth.ecms}/api/redirectToScorm?resourceId=${resource.id}&authKey=${Auth.authKey}`;
        } else if (entityTypeId === CONTENT_ITEM_TYPES.KEYBOARDING_RESOURCE || entityTypeId === CONTENT_ITEM_TYPES.CODEMONKEY_RESOURCE) {
          viewUrl = `${Auth.ecms}${CONTENT_MANAGER_ENDPOINTS.LAUNCH_TYPING_MASTER_URL}?contentItemId=${contentItemId}&authKey=${Auth.authKey}`;
          console.log(viewUrl);
        }
      }
    }
    return { playerType, viewUrl, isFlowpaper, isTeacher, contentMode };
  }

  getOptionsForAnswerKey = async (attachmentContentItemId, entityTypeId, classroomId, origin, isTeacher, userId) => {
    const contentMode = CONTENT_MODE.PREVIEW;

    if (entityTypeId === CONTENT_ITEM_TYPES.MICROSITE_RESOURCE) {
      const ContentService = getRegisteredClass('ContentService');
      return ContentService.getOptionsForFileViewer({
        classroomId,
        contentItemId: attachmentContentItemId,
        contentMode,
        entityTypeId,
        fromMethodName: 'getOptionsForAnswerKey',
        isTeacher,
        origin,
        userId
      });
    }

    // This function is currently a clone of getOptionsForTeacherPreview.
    // It exists to facilitate the creation of options specific to answer keys
    let viewUrl = null;
    let playerType = null;
    let isFlowpaper = false;

    await this.fetchReaderConfig();

    const { isDistrictAdmin, isSchoolAdmin, hasPublisherPermission, isProductAdmin } = UserManager;
    const isAdmin = isDistrictAdmin || isSchoolAdmin || hasPublisherPermission;

    if (!isTeacher && !isAdmin && !isProductAdmin) {
      console.warn('Not a teacher/admin. This is a teacher/admin function only');
      return false;
    }
    if (entityTypeId === CONTENT_ITEM_TYPES.PDF_RESOURCE) {
      if (this.useFlowpaperAnswerKey) {
        let flowPaperSessionId = null;
        if (this.flowpaperSessionKeyTeacherPreview !== '' && this.flowpaperSessionKeyTeacherPreview !== null) {
          flowPaperSessionId = this.getFlowpaperSessionId(null, null, this.flowpaperSessionKeyTeacherPreview, userId, attachmentContentItemId, classroomId);
        } else {
          flowPaperSessionId = `${attachmentContentItemId}-${userId}`;
        }
        await this.fetchFlowpaperUrl(attachmentContentItemId, flowPaperSessionId, null);
        viewUrl = this.flowpaperUrl;
        isFlowpaper = true;
      } else {
        const resource = await this.fetchResourceItem(attachmentContentItemId);
        viewUrl = resource.presignedAwsUrl;
        playerType = PLAYER_TYPES.FILE_VIEWER;
      }
    } else {
      return false;
    }
    return { playerType, viewUrl, isFlowpaper, isTeacher, contentMode };
  }

  getOptionsForStudentPreview = async (
    contentItemId, entityTypeId, pdfDeliveryFormat,
    origin, isTeacher, userId, courseResourceElementId = null,
    deliveryMode = '', classroomId = null, publisherModeId = null
  ) => {
    const contentMode = CONTENT_MODE.PREVIEW;

    if (entityTypeId === CONTENT_ITEM_TYPES.MICROSITE_RESOURCE) {
      const ContentService = getRegisteredClass('ContentService');
      return ContentService.getOptionsForFileViewer({
        classroomId,
        contentItemId,
        contentMode,
        courseResourceElementId,
        deliveryMode,
        entityTypeId,
        fromMethodName: 'getOptionsForStudentPreview',
        isTeacher,
        origin,
        pdfDeliveryFormat,
        publisherModeId,
        userId
      });
    }

    let viewUrl = null;
    let playerType = null;
    let isFlowpaper = false;
    this.setCurrentContentItemId(contentItemId);

    await this.fetchReaderConfig();

    if (entityTypeId === CONTENT_ITEM_TYPES.LESSON || entityTypeId === CONTENT_ITEM_TYPES.ASSESSMENT) {
      await UserOptionsService.fetchMyUserOptions();

      // For preview, use react player if satellite supports it.
      if (Auth.lesson && Auth.authKey && !Auth.overrideLessonPlayer) {
        const courseContentItemId = courseManager.currentCourseId;
        viewUrl = `${Auth.lesson}/preview?contentItemId=${contentItemId}&courseId=${courseContentItemId}&isLtiLaunched=${AssignmentManager.isLtiLaunched}`;
        viewUrl = await this.addUserOptions(viewUrl, null, origin, null);
        if (deliveryMode.includes('student_always')) {
          viewUrl += '&studentFacingAlways=true';
        }
        if (publisherModeId) {
          viewUrl += `&modeId=${publisherModeId}`;
        }
        viewUrl += `#${Auth.authKey}`;
        playerType = PLAYER_TYPES.LESSON_PLAYER;
      } else {
        const result = await this.fetchLessonPlayerUrl(
          contentItemId, null, Auth.authKey, origin, contentMode, isTeacher, null, null, null, null, classroomId, courseResourceElementId);

        if (result) {
          viewUrl = this.lessonUrl;
          if (viewUrl && deliveryMode.includes('student_always')) {
            viewUrl += '&studentFacingAlways=true';
          }
          playerType = PLAYER_TYPES.LESSON_PLAYER;
        }
      }
    } else if (entityTypeId === CONTENT_ITEM_TYPES.LEARNOSITY_ACTIVITY_RESOURCE) {
      viewUrl = null;
      playerType = PLAYER_TYPES.LEARNOSITY_PLAYER;
    } else if (entityTypeId === CONTENT_ITEM_TYPES.PDF_RESOURCE) {
      const resource = await this.fetchResourceItem(contentItemId);
      if (pdfDeliveryFormat === PDF_DELIVERY_FORMATS.FLOWPAPER || pdfDeliveryFormat === PDF_DELIVERY_FORMATS.PDF_AND_FLOWPAPER) {
        // should this just be pdf ?
        let flowPaperSessionId = null;
        if (this.flowpaperSessionKeyStudent) {
          flowPaperSessionId = this.getFlowpaperSessionId(null, null, this.flowpaperSessionKeyStudent, userId, courseResourceElementId, null);
        } else {
          flowPaperSessionId = `${contentItemId}-${userId}`;
        }
        await this.fetchFlowpaperUrl(contentItemId, flowPaperSessionId, null);
        viewUrl = this.flowpaperUrl;
        isFlowpaper = true;
        playerType = PLAYER_TYPES.FILE_VIEWER;
      } else if (pdfDeliveryFormat === PDF_DELIVERY_FORMATS.DOCREADER || pdfDeliveryFormat === PDF_DELIVERY_FORMATS.PDF_AND_DOCREADER) {
        // should this just be pdf ?
        let flowPaperSessionId = null;
        if (this.flowpaperSessionKeyStudent) {
          flowPaperSessionId = this.getFlowpaperSessionId(null, null, this.flowpaperSessionKeyStudent, userId, courseResourceElementId, null);
        } else {
          flowPaperSessionId = `${contentItemId}-${userId}`;
        }
        this.setCurrentFlowPaperSessionId(flowPaperSessionId);
        await this.fetchDocReaderUrl(contentItemId, courseResourceElementId);
        viewUrl = this.docReaderUrl;
        isFlowpaper = false;
        playerType = PLAYER_TYPES.DOCREADER_VIEWER;
      } else {
        viewUrl = resource.presignedAwsUrl;
        playerType = PLAYER_TYPES.FILE_VIEWER;
      }
    } else {
      const resource = await this.fetchResourceItem(contentItemId);
      if (CONTENT_ITEM_TYPES.isViewableFileType(entityTypeId, resource.mime)) {
        playerType = PLAYER_TYPES.CONTENT_PREVIEW_PLAYER;
        viewUrl = null;
      } else if (entityTypeId === CONTENT_ITEM_TYPES.BOOKMARK_RESOURCE) {
        const isIframable = resource.iframeCompatible;
        if (isIframable) {
          playerType = PLAYER_TYPES.FILE_VIEWER;
        }
        viewUrl = resource.pageUrl;
      } else if (CONTENT_ITEM_TYPES.isExternalAppType(entityTypeId)) {
        playerType = PLAYER_TYPES.FILE_VIEWER;
        if (entityTypeId === CONTENT_ITEM_TYPES.SCORM_RESOURCE) {
          // For student preview, just pass a resource id, no activity id.
          viewUrl = `${Auth.ecms}/api/redirectToScorm?resourceId=${resource.id}&authKey=${Auth.authKey}`;
        } else if (entityTypeId === CONTENT_ITEM_TYPES.KEYBOARDING_RESOURCE || entityTypeId === CONTENT_ITEM_TYPES.CODEMONKEY_RESOURCE) {
          viewUrl = `${Auth.ecms}${CONTENT_MANAGER_ENDPOINTS.LAUNCH_TYPING_MASTER_URL}?contentItemId=${contentItemId}&authKey=${Auth.authKey}`;
        }
      }
    }

    return { playerType, viewUrl, isFlowpaper, isTeacher, optionContentMode: contentMode };
  }

  getOptionsForTeacherPreviewAssigned = async (activity, origin, isTeacher, userId, publisherModeId = null) => {
    const contentMode = CONTENT_MODE.PREVIEW;

    if (activity.contentItemEntityTypeId === CONTENT_ITEM_TYPES.MICROSITE_RESOURCE) {
      const ContentService = getRegisteredClass('ContentService');
      return ContentService.getOptionsForFileViewer({
        activity,
        contentItemId: activity.contentItemId,
        contentMode,
        entityTypeId: activity.contentItemEntityTypeId,
        fromMethodName: 'getOptionsForTeacherPreviewAssigned',
        isTeacher,
        origin,
        publisherModeId,
        userId
      });
    }

    let viewUrl = null;
    let playerType = null;
    let isFlowpaper = false;
    this.setCurrentContentItemId(activity.contentItemId);

    await this.fetchReaderConfig();

    const { isDistrictAdmin, isSchoolAdmin, hasPublisherPermission, isProductAdmin } = UserManager;
    const isAdmin = isDistrictAdmin || isSchoolAdmin || hasPublisherPermission;

    if (!isTeacher && !isAdmin && !isProductAdmin) {
      console.warn('Not a teacher/admin. This is a teacher/admin function only');
      return false;
    }
    if (activity.contentItemEntityTypeId === CONTENT_ITEM_TYPES.LESSON ||
          activity.contentItemEntityTypeId === CONTENT_ITEM_TYPES.ASSESSMENT) {
      const hatId = this.getTeacherPreviewHatId(activity.classroomId, activity.courseResourceElementId, userId);
      // For preview, use react player if satellite supports it.
      if (Auth.lesson && Auth.authKey && !Auth.overrideLessonPlayer) {
        viewUrl = `${Auth.lesson}/preview?contentItemId=${activity.contentItemId}&courseId=${activity.courseContentItemId}&isLtiLaunched=${AssignmentManager.isLtiLaunched}`;
        if (publisherModeId) {
          viewUrl += `&modeId=${publisherModeId}`;
        }
        viewUrl = await this.addUserOptions(viewUrl, hatId, origin, null);
        viewUrl += `#${Auth.authKey}`;

        playerType = PLAYER_TYPES.LESSON_PLAYER;
      } else {
        const result = await this.fetchLessonPlayerUrl(activity.contentItemId, null, Auth.authKey, origin,
          contentMode, isTeacher, activity.instruction, hatId, activity.courseContentItemId, publisherModeId,
          activity.classroomId, activity.courseResourceElementId);
        if (result) {
          viewUrl = this.lessonUrl;
          playerType = PLAYER_TYPES.LESSON_PLAYER;
        }
      }
    } else if (activity.contentItemEntityTypeId === CONTENT_ITEM_TYPES.LEARNOSITY_ACTIVITY_RESOURCE) {
      viewUrl = null;
      playerType = PLAYER_TYPES.LEARNOSITY_PLAYER;
    } else if (activity.contentItemEntityTypeId === CONTENT_ITEM_TYPES.PDF_RESOURCE) {
      const resource = await this.fetchResourceItem(activity.contentItemId);
      if (activity.pdfDeliveryFormat === PDF_DELIVERY_FORMATS.FLOWPAPER || activity.pdfDeliveryFormat === PDF_DELIVERY_FORMATS.PDF_AND_FLOWPAPER) {
        let flowPaperSessionId = null;
        if (this.flowpaperSessionKeyTeacherPreview !== '' && this.flowpaperSessionKeyTeacherPreview !== null) {
          flowPaperSessionId = this.getFlowpaperSessionId(activity, null, this.flowpaperSessionKeyTeacherPreview, userId, null, null);
        } else {
          flowPaperSessionId = `${activity.contentItemId}-${userId}`;
        }
        await this.fetchFlowpaperUrl(activity.contentItemId, flowPaperSessionId, null);
        viewUrl = this.flowpaperUrl;
        isFlowpaper = true;
        playerType = PLAYER_TYPES.FILE_VIEWER;
      } else if (activity.pdfDeliveryFormat === PDF_DELIVERY_FORMATS.DOCREADER || activity.pdfDeliveryFormat === PDF_DELIVERY_FORMATS.PDF_AND_DOCREADER) {
        let flowPaperSessionId = null;
        if (this.flowpaperSessionKeyTeacherPreview !== '' && this.flowpaperSessionKeyTeacherPreview !== null) {
          flowPaperSessionId = this.getFlowpaperSessionId(activity, null, this.flowpaperSessionKeyTeacherPreview, userId, null, null);
        } else {
          flowPaperSessionId = `${activity.contentItemId}-${userId}`;
        }
        this.setCurrentFlowPaperSessionId(flowPaperSessionId);
        await this.fetchDocReaderUrl(activity.contentItemId, activity.courseResourceElementId);
        viewUrl = this.docReaderUrl;
        isFlowpaper = false;
        playerType = PLAYER_TYPES.DOCREADER_VIEWER;
      } else {
        viewUrl = resource.presignedAwsUrl;
        playerType = PLAYER_TYPES.FILE_VIEWER;
      }
    } else {
      const resource = await this.fetchResourceItem(activity.contentItemId);
      if (CONTENT_ITEM_TYPES.isViewableFileType(activity.contentItemEntityTypeId, resource.mime)) {
        playerType = PLAYER_TYPES.CONTENT_PREVIEW_PLAYER;
        viewUrl = null;
      } else if (activity.contentItemEntityTypeId === CONTENT_ITEM_TYPES.BOOKMARK_RESOURCE) {
        const isIframable = resource.iframeCompatible;
        if (isIframable) {
          playerType = PLAYER_TYPES.FILE_VIEWER;
        }
        viewUrl = resource.pageUrl;
      } else if (CONTENT_ITEM_TYPES.isExternalAppType(activity.contentItemEntityTypeId)) {
        playerType = PLAYER_TYPES.FILE_VIEWER;
        if (activity.contentItemEntityTypeId === CONTENT_ITEM_TYPES.SCORM_RESOURCE) {
          // For teacher preview assigned, still the same resource, just pass a resource id, no activity id.
          viewUrl = `${Auth.ecms}/api/redirectToScorm?resourceId=${resource.id}&authKey=${Auth.authKey}`;
        } else if (activity.contentItemEntityTypeId === CONTENT_ITEM_TYPES.KEYBOARDING_RESOURCE || activity.contentItemEntityTypeId === CONTENT_ITEM_TYPES.CODEMONKEY_RESOURCE) {
          viewUrl = `${Auth.ecms}${CONTENT_MANAGER_ENDPOINTS.LAUNCH_TYPING_MASTER_URL}?contentItemId=${contentItemId}&authKey=${Auth.authKey}`;
        }
      }
    }
    return { playerType, viewUrl, isFlowpaper, isTeacher, contentMode };
  }

  getOptionsForTeacherPresent = async (contentItemId, activity, entityTypeId, origin, isTeacher) => {
    const contentMode = CONTENT_MODE.PRESENT;

    if (entityTypeId === CONTENT_ITEM_TYPES.MICROSITE_RESOURCE) {
      const ContentService = getRegisteredClass('ContentService');
      return ContentService.getOptionsForFileViewer({
        activity,
        contentItemId,
        contentMode,
        entityTypeId,
        fromMethodName: 'getOptionsForTeacherPresent',
        isTeacher,
        origin
      });
    }

    let viewUrl = null;
    let playerType = null;
    const isFlowpaper = false;
    this.setCurrentContentItemId(contentItemId);

    await this.fetchReaderConfig();

    if (!isTeacher) {
      console.log('Not a teacher. This is a teacher function only.');
      return false;
    }

    if (entityTypeId === CONTENT_ITEM_TYPES.LESSON || entityTypeId === CONTENT_ITEM_TYPES.ASSESSMENT) {
      const activityId = activity ? activity.id : null;
      const classroomId = activity ? activity.classroomId : null;
      const courseResourceElementId = activity ? activity.courseResourceElementId : null;
      const result = await this.fetchLessonPlayerUrl(
        contentItemId, activityId, Auth.authKey, origin, contentMode, isTeacher, null, null, null, null,
        classroomId, courseResourceElementId);
      if (result) {
        viewUrl = this.lessonUrl;
        playerType = PLAYER_TYPES.LESSON_PLAYER;
      } else {
        console.log('Invalid presentation type.');
        return false;
      }
    } else {
      console.log('Invalid presentation type.');
      return false;
    }

    return { playerType, viewUrl, isFlowpaper, isTeacher, contentMode };
  }

  getOptionsForStudentAssessment = async (activityInstance, origin, isTeacher, userId) => {
    const contentMode = CONTENT_MODE.ASSESS;

    if (activityInstance.contentItemEntityTypeId === CONTENT_ITEM_TYPES.MICROSITE_RESOURCE) {
      const ContentService = getRegisteredClass('ContentService');
      return ContentService.getOptionsForFileViewer({
        activityInstance,
        contentItemId: activityInstance.contentItemId,
        contentMode,
        entityTypeId: activityInstance.contentItemEntityTypeId,
        fromMethodName: 'getOptionsForStudentAssessment',
        isTeacher,
        origin,
        userId
      });
    }

    let viewUrl = null;
    let playerType = null;
    let isFlowpaper = false;
    // object could be activityInstance or activity so we need to test for the right activity id.
    const activityId = activityInstance.activityId || activityInstance.id;
    this.setCurrentContentItemId(activityInstance.contentItemId);
    await this.fetchReaderConfig();

    if (activityInstance.contentItemEntityTypeId === CONTENT_ITEM_TYPES.LESSON || activityInstance.contentItemEntityTypeId === CONTENT_ITEM_TYPES.ASSESSMENT) {
      await UserOptionsService.fetchMyUserOptions();
      const { activityAccommodationIds } = AssignmentManager;
      // For assess or review check BE settings for whether to use react player.
      const useReactPlayer = await UserService.getUseAssignmentReactPlayer(activityId);
      if (useReactPlayer && Auth.authKey && !Auth.overrideLessonPlayer) {
        viewUrl = `${Auth.lesson}/activityMode?contentItemId=${activityInstance.contentItemId}&courseId=${activityInstance.courseContentItemId
        }&entityTypeId=${activityInstance.contentItemEntityTypeId}&activityId=${activityId}&activityInstanceId=${activityInstance.id}&actAcmIds=${activityAccommodationIds
        }&isLtiLaunched=${AssignmentManager.isLtiLaunched}`;
        viewUrl = await this.addUserOptions(viewUrl, null, origin, activityInstance.activityInstruction);
        viewUrl += `#${Auth.authKey}`;
        playerType = PLAYER_TYPES.LESSON_PLAYER;
      } else {
        const result = await this.fetchLessonPlayerUrl(activityInstance.contentItemId, activityId,
          Auth.authKey, origin, contentMode, isTeacher, activityInstance.activityInstruction, null, null, null,
          activityInstance.classroomId, activityInstance.courseResourceElementId);
        if (result) {
          viewUrl = this.lessonUrl;
          playerType = PLAYER_TYPES.LESSON_PLAYER;
        }
      }
    } else if (activityInstance.contentItemEntityTypeId === CONTENT_ITEM_TYPES.LEARNOSITY_ACTIVITY_RESOURCE) {
      viewUrl = null;
      playerType = PLAYER_TYPES.LEARNOSITY_PLAYER;
    } else if (activityInstance.contentItemEntityTypeId === CONTENT_ITEM_TYPES.PDF_RESOURCE) {
      const resource = await this.fetchResourceItem(activityInstance.contentItemId);
      if (activityInstance.pdfDeliveryFormat === PDF_DELIVERY_FORMATS.FLOWPAPER || activityInstance.pdfDeliveryFormat === PDF_DELIVERY_FORMATS.PDF_AND_FLOWPAPER) {
        let flowPaperSessionId = null;
        if (this.flowpaperSessionKeyStudent) {
          flowPaperSessionId = this.getFlowpaperSessionId(null, activityInstance, this.flowpaperSessionKeyStudent, userId, null, null);
        } else {
          flowPaperSessionId = `${activityInstance.contentItemId}-${userId}`;
        }
        await this.fetchFlowpaperUrl(activityInstance.contentItemId, flowPaperSessionId, activityId);
        viewUrl = this.flowpaperUrl;
        isFlowpaper = true;
        playerType = PLAYER_TYPES.FILE_VIEWER;
      } else if (activityInstance.pdfDeliveryFormat === PDF_DELIVERY_FORMATS.DOCREADER || activityInstance.pdfDeliveryFormat === PDF_DELIVERY_FORMATS.PDF_AND_DOCREADER) {
        let flowPaperSessionId = null;
        if (this.flowpaperSessionKeyStudent) {
          flowPaperSessionId = this.getFlowpaperSessionId(null, activityInstance, this.flowpaperSessionKeyStudent, userId, null, null);
        } else {
          flowPaperSessionId = `${activityInstance.contentItemId}-${userId}`;
        }
        this.setCurrentFlowPaperSessionId(flowPaperSessionId);
        await this.fetchDocReaderUrl(activityInstance.contentItemId, activityInstance.courseResourceElementId);
        viewUrl = this.docReaderUrl;
        isFlowpaper = false;
        playerType = PLAYER_TYPES.DOCREADER_VIEWER;
      } else {
        viewUrl = resource.presignedAwsUrl;
        playerType = PLAYER_TYPES.FILE_VIEWER;
      }
    } else {
      const resource = await this.fetchResourceItem(activityInstance.contentItemId);
      if (CONTENT_ITEM_TYPES.isViewableFileType(activityInstance.contentItemEntityTypeId, resource.mime)) {
        playerType = PLAYER_TYPES.CONTENT_PREVIEW_PLAYER;
        viewUrl = null;
      } else if (activityInstance.contentItemEntityTypeId === CONTENT_ITEM_TYPES.BOOKMARK_RESOURCE) {
        const isIframable = resource.iframeCompatible;
        if (isIframable) {
          playerType = PLAYER_TYPES.FILE_VIEWER;
        }
        viewUrl = resource.pageUrl;
      } else if (CONTENT_ITEM_TYPES.isExternalAppType(activityInstance.contentItemEntityTypeId)) {
        playerType = PLAYER_TYPES.FILE_VIEWER;
        if (activityInstance.contentItemEntityTypeId === CONTENT_ITEM_TYPES.SCORM_RESOURCE) {
          // Pass the activity id as well as resource id.
          viewUrl = `${Auth.ecms}/api/redirectToScorm?activityId=${activityId}&resourceId=${resource.id}&authKey=${Auth.authKey}`;
        } else if (activityInstance.contentItemEntityTypeId === CONTENT_ITEM_TYPES.KEYBOARDING_RESOURCE || activityInstance.contentItemEntityTypeId === CONTENT_ITEM_TYPES.CODEMONKEY_RESOURCE) {
          viewUrl = `${Auth.ecms}${CONTENT_MANAGER_ENDPOINTS.LAUNCH_TYPING_MASTER_URL}?activityId=${activityId}&authKey=${Auth.authKey}`;
        }
      }
    }
    return { playerType, viewUrl, isFlowpaper, isTeacher, contentMode };
  }

  // This is for the teacher grade, be careful
  getOptionsForTeacherReviewAndScoring = async (
    activity, activityInstance, origin, isTeacher, elementId = null, activityId = null
  ) => {
    const contentMode = CONTENT_MODE.REVIEW;

    const entityTypeId = activity.contentItemEntityTypeId || activity.entityTypeId;
    if (entityTypeId === CONTENT_ITEM_TYPES.MICROSITE_RESOURCE) {
      const ContentService = getRegisteredClass('ContentService');
      return ContentService.getOptionsForFileViewer({
        activity,
        activityId,
        activityInstance,
        contentItemId: activity.contentItemId,
        contentMode,
        elementId,
        entityTypeId,
        fromMethodName: 'getOptionsForTeacherReviewAndScoring',
        isTeacher,
        origin
      });
    }

    activityId = activityId || activityInstance.id;

    const { isDistrictAdmin, isSchoolAdmin, hasPublisherPermission, isProductAdmin } = UserManager;
    const isAdmin = isDistrictAdmin || isSchoolAdmin || hasPublisherPermission;

    if (!isTeacher && !isAdmin && !isProductAdmin) {
      console.warn('Not a teacher/admin. This is a teacher/admin function only');
      return false;
    }

    let viewUrl = null;
    let playerType = null;
    let isFlowpaper = false;
    this.setCurrentContentItemId(activity.contentItemId);

    await this.fetchReaderConfig();

    if (entityTypeId === CONTENT_ITEM_TYPES.LESSON || entityTypeId === CONTENT_ITEM_TYPES.ASSESSMENT) {
      // For assess or review check BE settings for whether to use react player.
      const useReactPlayer = await UserService.getUseAssignmentReactPlayer(activityInstance.activityId);
      if (useReactPlayer && Auth.authKey && !Auth.overrideLessonPlayer) {
        viewUrl = `${Auth.lesson}/scoring?contentItemId=${activity.contentItemId}&courseId=${activity.courseContentItemId
        }&entityTypeId=${activityInstance.contentItemEntityTypeId}&activityId=${activityInstance.activityId}&activityInstanceId=${activityInstance.id}&sid=${activityInstance.userId
        }&lessonElementId=${elementId}&isLtiLaunched=${AssignmentManager.isLtiLaunched}`;
        viewUrl = await this.addUserOptions(viewUrl, null, origin, activity.instruction);
        viewUrl += `#${Auth.authKey}`;
        playerType = PLAYER_TYPES.LESSON_PLAYER;
      } else {
        const result = await this.fetchLessonGraderUrl(
          activityId,
          activityInstance.userId,
          Auth.authKey,
          origin,
          contentMode,
          isTeacher,
          activity.instruction,
          elementId
        );
        if (result) {
          viewUrl = this.lessonUrl;
          playerType = PLAYER_TYPES.LESSON_PLAYER;
        }
      }
    } else if (entityTypeId === CONTENT_ITEM_TYPES.LEARNOSITY_ACTIVITY_RESOURCE) {
      viewUrl = null;
      playerType = PLAYER_TYPES.LEARNOSITY_SCORING_PLAYER;
    } else if (entityTypeId === CONTENT_ITEM_TYPES.PDF_RESOURCE) {
      const resource = await this.fetchResourceItem(activity.contentItemId);
      if (activity.pdfDeliveryFormat === PDF_DELIVERY_FORMATS.FLOWPAPER || activity.pdfDeliveryFormat === PDF_DELIVERY_FORMATS.PDF_AND_FLOWPAPER) {
        let flowPaperSessionId = null;
        if (this.flowpaperSessionKeyStudent) {
          flowPaperSessionId = this.getFlowpaperSessionId(activity, activityInstance, this.flowpaperSessionKeyStudent, activityInstance.userId, null, null);
        } else {
          flowPaperSessionId = `${activity.contentItemId}-${activityInstance.userId}`;
        }
        // null activityId for scoring. that sets tracking.
        await this.fetchFlowpaperUrl(activity.contentItemId, flowPaperSessionId, null);
        viewUrl = this.flowpaperUrl;
        isFlowpaper = true;
        playerType = PLAYER_TYPES.FILE_VIEWER;
      } else if (activity.pdfDeliveryFormat === PDF_DELIVERY_FORMATS.DOCREADER || activity.pdfDeliveryFormat === PDF_DELIVERY_FORMATS.PDF_AND_DOCREADER) {
        let flowPaperSessionId = null;
        if (this.flowpaperSessionKeyStudent) {
          flowPaperSessionId = this.getFlowpaperSessionId(activity, activityInstance, this.flowpaperSessionKeyStudent, activityInstance.userId, null, null);
        } else {
          flowPaperSessionId = `${activity.contentItemId}-${activityInstance.userId}`;
        }
        this.setCurrentFlowPaperSessionId(flowPaperSessionId);
        await this.fetchDocReaderUrl(activity.contentItemId, activity.courseResourceElementId);
        viewUrl = this.docReaderUrl;
        isFlowpaper = false;
        playerType = PLAYER_TYPES.DOCREADER_VIEWER;
      } else {
        viewUrl = resource.presignedAwsUrl;
        playerType = PLAYER_TYPES.FILE_VIEWER;
      }
    } else {
      const resource = await this.fetchResourceItem(activity.contentItemId);
      if (CONTENT_ITEM_TYPES.isViewableFileType(entityTypeId, resource.mime)) {
        playerType = PLAYER_TYPES.CONTENT_PREVIEW_PLAYER;
        viewUrl = null;
      } else if (entityTypeId === CONTENT_ITEM_TYPES.BOOKMARK_RESOURCE) {
        const isIframable = resource.iframeCompatible;
        if (isIframable) {
          playerType = PLAYER_TYPES.FILE_VIEWER;
        }
        viewUrl = resource.pageUrl;
      } else if (CONTENT_ITEM_TYPES.isExternalAppType(entityTypeId)) {
        playerType = PLAYER_TYPES.FILE_VIEWER;
        if (entityTypeId === CONTENT_ITEM_TYPES.SCORM_RESOURCE) {
          // Pass the activity id as well as resource id.
          viewUrl = `${Auth.ecms}/api/redirectToScorm?activityId=${activityId}&resourceId=${resource.id}&authKey=${Auth.authKey}`;
        } else if (entityTypeId === CONTENT_ITEM_TYPES.KEYBOARDING_RESOURCE || entityTypeId === CONTENT_ITEM_TYPES.CODEMONKEY_RESOURCE) {
          viewUrl = `${Auth.ecms}${CONTENT_MANAGER_ENDPOINTS.LAUNCH_TYPING_MASTER_URL}?activityId=${activityId}&authKey=${Auth.authKey}`;
        }
      }
    }

    return { playerType, viewUrl, isFlowpaper, isTeacher, contentMode };
  }

  // Yes, this is a lot like preview, but there are/will be mods.
  getOptionsForStudentReview = async (activityInstance, origin, isTeacher, userId, contentMode = CONTENT_MODE.ASSESS) => {
    if (activityInstance.contentItemEntityTypeId === CONTENT_ITEM_TYPES.MICROSITE_RESOURCE) {
      const ContentService = getRegisteredClass('ContentService');
      return ContentService.getOptionsForFileViewer({
        activityInstance,
        contentItemId: activityInstance.contentItemId,
        contentMode,
        entityTypeId: activityInstance.contentItemEntityTypeId,
        fromMethodName: 'getOptionsForStudentReview',
        isTeacher,
        origin,
        userId
      });
    }

    let viewUrl = null;
    let playerType = null;
    let isFlowpaper = false;
    // object could be activityInstance or activity so we need to test for the right activity id.
    const activityId = activityInstance.activityId || activityInstance.id;
    await this.fetchReaderConfig();

    this.setCurrentContentItemId(activityInstance.contentItemId);
    if (activityInstance.contentItemEntityTypeId === CONTENT_ITEM_TYPES.LESSON || activityInstance.contentItemEntityTypeId === CONTENT_ITEM_TYPES.ASSESSMENT) {
      await UserOptionsService.fetchMyUserOptions();
      // For assess or review check BE settings for whether to use react player.
      const useReactPlayer = await UserService.getUseAssignmentReactPlayer(activityId);
      if (useReactPlayer && Auth.authKey && !Auth.overrideLessonPlayer) {
        viewUrl = `${Auth.lesson}/review?contentItemId=${activityInstance.contentItemId}&courseId=${activityInstance.courseContentItemId
        }&entityTypeId=${activityInstance.contentItemEntityTypeId}&activityId=${activityId}&activityInstanceId=${activityInstance.id}&sid=${userId
        }&classroomId=${activityInstance.classroomId}`;
        viewUrl = await this.addUserOptions(viewUrl, null, origin, activityInstance.activityInstruction);
        viewUrl += `#${Auth.authKey}`;
        playerType = PLAYER_TYPES.LESSON_PLAYER;
      } else {
        const result = await this.fetchLessonPlayerUrl(activityInstance.contentItemId, activityId,
          Auth.authKey, origin, contentMode, isTeacher, activityInstance.activityInstruction, null, null, null,
          activityInstance.classroomId, activityInstance.courseResourceElementId);
        if (result) {
          viewUrl = this.lessonUrl;
          if (contentMode === CONTENT_MODE.TEACHER_REVIEW_AS_STUDENT) {
            viewUrl += `&activityInstanceId=${activityInstance.id}&sid=${userId}&mode=present`;
          }
          playerType = PLAYER_TYPES.LESSON_PLAYER;
        }
      }
    } else if (activityInstance.contentItemEntityTypeId === CONTENT_ITEM_TYPES.LEARNOSITY_ACTIVITY_RESOURCE) {
      viewUrl = null;
      playerType = PLAYER_TYPES.LEARNOSITY_SCORING_PLAYER;
    } else if (activityInstance.contentItemEntityTypeId === CONTENT_ITEM_TYPES.PDF_RESOURCE) {
      const resource = await this.fetchResourceItem(activityInstance.contentItemId);
      if (activityInstance.pdfDeliveryFormat === PDF_DELIVERY_FORMATS.FLOWPAPER || activityInstance.pdfDeliveryFormat === PDF_DELIVERY_FORMATS.PDF_AND_FLOWPAPER) {
        let flowPaperSessionId = null;
        if (this.flowpaperSessionKeyStudent) {
          flowPaperSessionId = this.getFlowpaperSessionId(null, activityInstance, this.flowpaperSessionKeyStudent, userId, null, null);
        } else {
          flowPaperSessionId = `${activityInstance.contentItemId}-${userId}`;
        }
        await this.fetchFlowpaperUrl(activityInstance.contentItemId, flowPaperSessionId, null);
        viewUrl = this.flowpaperUrl;
        isFlowpaper = true;
        playerType = PLAYER_TYPES.FILE_VIEWER;
      } else if (activityInstance.pdfDeliveryFormat === PDF_DELIVERY_FORMATS.DOCREADER || activityInstance.pdfDeliveryFormat === PDF_DELIVERY_FORMATS.PDF_AND_DOCREADER) {
        let flowPaperSessionId = null;
        if (this.flowpaperSessionKeyStudent) {
          flowPaperSessionId = this.getFlowpaperSessionId(null, activityInstance, this.flowpaperSessionKeyStudent, userId, null, null);
        } else {
          flowPaperSessionId = `${activityInstance.contentItemId}-${userId}`;
        }
        this.setCurrentFlowPaperSessionId(flowPaperSessionId);
        await this.fetchDocReaderUrl(activityInstance.contentItemId, activityInstance.courseResourceElementId);
        viewUrl = this.docReaderUrl;
        isFlowpaper = false;
        playerType = PLAYER_TYPES.DOCREADER_VIEWER;
      } else {
        viewUrl = resource.presignedAwsUrl;
        playerType = PLAYER_TYPES.FILE_VIEWER;
      }
    } else {
      const resource = await this.fetchResourceItem(activityInstance.contentItemId);
      if (CONTENT_ITEM_TYPES.isViewableFileType(activityInstance.contentItemEntityTypeId, resource.mime)) {
        playerType = PLAYER_TYPES.CONTENT_PREVIEW_PLAYER;
        viewUrl = null;
      } else if (activityInstance.contentItemEntityTypeId === CONTENT_ITEM_TYPES.BOOKMARK_RESOURCE) {
        const isIframable = resource.iframeCompatible;
        if (isIframable) {
          playerType = PLAYER_TYPES.FILE_VIEWER;
        }
        viewUrl = resource.pageUrl;
      } else if (CONTENT_ITEM_TYPES.isExternalAppType(activityInstance.contentItemEntityTypeId)) {
        playerType = PLAYER_TYPES.FILE_VIEWER;
        if (activityInstance.contentItemEntityTypeId === CONTENT_ITEM_TYPES.SCORM_RESOURCE) {
          // Pass the activity id as well as resource id.
          viewUrl = `${Auth.ecms}/api/redirectToScorm?activityId=${activityId}&resourceId=${resource.id}&authKey=${Auth.authKey}`;
        } else if (activityInstance.contentItemEntityTypeId === CONTENT_ITEM_TYPES.KEYBOARDING_RESOURCE || activityInstance.contentItemEntityTypeId === CONTENT_ITEM_TYPES.CODEMONKEY_RESOURCE) {
          viewUrl = `${Auth.ecms}${CONTENT_MANAGER_ENDPOINTS.LAUNCH_TYPING_MASTER_URL}?activityId=${activityId}&authKey=${Auth.authKey}`;
        }
      }
    }
    return { playerType, viewUrl, isFlowpaper, isTeacher, contentMode };
  }

  configPlayerWindow = async (playerType, theWindow, hideIframeFromOuterClick) => {
    if (playerType === PLAYER_TYPES.LESSON_PLAYER ||
      playerType === PLAYER_TYPES.LEARNOSITY_PLAYER ||
      playerType === PLAYER_TYPES.FILE_VIEWER) {
      theWindow.addEventListener('message', hideIframeFromOuterClick);
    }
  }

  getContentItem = (id) => {
    if (id) {
      return this.contentItems.get(id);
    }
    return null;
  }

  @computed get contentItemList() {
    if (this.contentItems) {
      return Array.from(toJS(this.contentItems).values());
    }
    return [];
  }

  getResourceItem = (contentItemId) => {
    if (contentItemId) {
      return this.resourceItems.get(contentItemId);
    }
    return null;
  }

  @action setContentItem(id, item) {
    if (id && item) {
      this.contentItems.set(id, item);
    }
  }

  // resource items are stored via the content item id.
  @action setResourceItem(contentItemId, item) {
    if (contentItemId && item) {
      this.resourceItems.set(contentItemId, item);
    }
  }

  @action setLessonUrl(url) {
    if (url) {
      this.lessonUrl = url;
    }
  }

  @action setFlowpaperUrl(url) {
    if (url) {
      this.flowpaperUrl = url;
    }
  }

  @action setDocReaderUrl(url) {
    if (url) {
      this.docReaderUrl = url;
    }
  }

  @action setDocReaderId(id) {
    if (id) {
      this.docReaderId = id;
    }
  }

  @action setWebReaderId(id) {
    if (id) {
      this.webReaderId = id;
    }
  }

  @action setTextHelpId(id) {
    if (id) {
      this.textHelpId = id;
    }
  }

  // TODO commenting out for now.  We need to re-evaluate the need for this
  // @action setAlwaysShowReviewableAssignmentToggle = (toggle) => {
  //   this.alwaysShowReviewableAssignmentToggle = toggle;
  // }

  @action setIsReaderConfigLoaded(isLoaded) {
    this.isReaderConfigLoaded = isLoaded;
  }

  async fetchContentItem(id, callBack) {
    if (!id || id === 'ALL_COURSES') {
      return;
    }
    const url = `${Auth.ecms}/api/viewContentItem?contentItemId=${id}`;

    try {
      const response = await Auth.fetch(url, {
        method: 'GET'
      });
      if (response.status === 'SUCCESS') {
        const item = response.contentItems?.[0];

        this.setContentItem(item?.id, item);
        if (callBack) {
          callBack(item);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async fetchResourceItem(contentItemId, {
    forceFetchResourceItem = false
  } = {}) {

    if (!contentItemId) {
      return;
    }

    const resourceLoaded = this.getResourceItem(contentItemId);

    if (!forceFetchResourceItem && resourceLoaded) {
      return resourceLoaded;
    }
    const url = `${Auth.ecms}/api/viewResource?contentItemId=${contentItemId}`;

    try {
      const response = await Auth.fetch(url, {
        method: 'GET'
      });
      if (response.status === 'SUCCESS') {
        const resource = response.resources[0];
        this.setResourceItem(contentItemId, resource);
        return resource;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  fetchFlowpaperUrl = async (contentItemId, flowpaperSessionId, activityId) => {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/getFlowpaperUrl?contentItemId=${contentItemId}&flowpaperSessionId=${flowpaperSessionId}&activityId=${activityId}`, {
        method: 'GET'
      });
      if (response && response.flowpaperDocumentUrl) {
        const url = response.flowpaperDocumentUrl;
        // const { docReaderId } = response;
        // url += '&satelliteApp=true';
        // url += '&authKey=' + authKey;
        // url += `&appUrl=${encodeURIComponent(origin)}`;
        this.setFlowpaperUrl(url);
        // this.setDocReaderId(docReaderId);
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  fetchReaderConfig = async () => {
    if (!this.isReaderConfigLoaded) {
      try {
        const response = await Auth.fetch(`${Auth.ecms}/api/getReaderConfig`, {
          method: 'GET'
        });
        if (response) {
          if (response.docReaderId !== null && response.docReaderId !== '' && response.docReaderId !== undefined) {
            this.setDocReaderId(response.docReaderId);
          }
          if (response.webReaderId !== null && response.webReaderId !== '' && response.webReaderId !== undefined) {
            this.setWebReaderId(response.webReaderId);
          }
          if (response.textHelpId !== null && response.textHelpId !== '' && response.textHelpId !== undefined) {
            this.setTextHelpId(response.textHelpId);
          }

          this.setIsReaderConfigLoaded(true);
        }
      } catch (error) {
        console.error(error);
        return false;
      }
    }
  }

  fetchDocReaderUrl = async (contentItemId, courseResourceElementId = null) => {
    try {
      let url = `${Auth.ecms}/api/getDocReaderUrl?contentItemId=${contentItemId}`;
      if (courseResourceElementId) {
        url = `${url}&courseResourceElementId=${courseResourceElementId}`;
      }
      const response = await Auth.fetch(url, {
        method: 'GET'
      });
      if (response && response.docReaderUrl) {
        const docReaderBaseUrl = response.docReaderUrl;
        const { presignedAwsUrl } = response;
        const url = docReaderBaseUrl + presignedAwsUrl;

        this.setDocReaderUrl(url);
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  checkContentItemNameAddress = async (contentItemId) => {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/checkContentItemNameAddress?contentItemId=${contentItemId}`, {
        method: 'GET'
      });
      if (response && response.docReaderUrl) {
        const docReaderBaseUrl = response.docReaderUrl;
        const { presignedAwsUrl } = response;
        const url = docReaderBaseUrl + presignedAwsUrl;

        this.setDocReaderUrl(url);
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  // TODO unused
  // fetchLessonContent = async (contentItemId) => {
  //   try {
  //     const response = await Auth.fetch(`${Auth.ecms}${CONTENT_MANAGER_ENDPOINTS.FETCH_LESSON_CONTENT}?contentItemId=${contentItemId}`, {
  //       method: 'GET'
  //     });
  //     return response;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  fetchLessonPlayerUrl = async (
    contentItemId, activityId, authKey, origin, mode,
    isTeacher, instruction, hatId = null, courseContentId = null,
    publisherModeId = null, classroomId = null, courseResourceElementId = null
  ) => {

    try {
      activityId = (activityId === null || activityId === '' ? '' : activityId);

      let courseId = '';
      if (!ReportIdentityManager.isReport) {
        courseId = courseContentId || courseManager.currentCourseId || '';
      }

      let apiUrl = `${Auth.ecms}${CONTENT_MANAGER_ENDPOINTS.GET_LESSON_PLAYER_URLS}`;
      apiUrl += `?contentItemId=${contentItemId}`;
      apiUrl += `&activityId=${activityId}`;
      apiUrl += courseId ? `&courseId=${courseId}` : '';
      apiUrl += `&publisherSatelliteCode=${Auth.publisherSatelliteCode}`;
      apiUrl += publisherModeId ? `&previewModeId=${publisherModeId}` : '';
      apiUrl += classroomId ? `&classroomId=${classroomId}` : '';
      apiUrl += courseResourceElementId ? `&courseResourceElementId=${courseResourceElementId}` : '';

      const response = await Auth.fetch(apiUrl, { method: 'GET' });

      let url = '';
      if (response) {
        if (isTeacher && mode === CONTENT_MODE.PREVIEW) {
          url = response.teacherPreviewUrl;
        } else if (isTeacher && mode === CONTENT_MODE.PRESENT) {
          url = (response.teacherPresentationUrl && response.teacherPresentationUrl !== null
            ? response.teacherPresentationUrl : response.teacherPreviewUrl);
        } else if (!isTeacher && mode === CONTENT_MODE.PREVIEW) {
          url = response.studentPreviewUrl;
        } else if (!isTeacher && mode == CONTENT_MODE.ASSESS) {
          url = response.studentAssignmentUrl;
        } else if (isTeacher && mode == CONTENT_MODE.TEACHER_REVIEW_AS_STUDENT) {
          url = response.studentAssignmentUrl;
        } else {
          return false;
        }

        if (response.docReaderId !== '' && response.docReaderId !== null && response.docReaderId !== undefined) {
          this.setDocReaderId(response.docReaderId);
        }
        if (response.webReaderId !== '' && response.webReaderId !== null && response.webReaderId !== undefined) {
          this.setWebReaderId(response.webReaderId);
        }
        if (response.textHelpId !== '' && response.textHelpId !== null && response.textHelpId !== undefined) {
          this.setTextHelpId(response.textHelpId);
        }
      }

      url = await this.addUserOptions(url, hatId, origin, instruction);

      url += `&authKey=${authKey}`;

      this.setLessonUrl(url);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  fetchContentUsage = async (
    contentItemId,
    page,
    pageSize,
    sortColumn,
    sortDirection,
  ) => {
    try {
      if (!contentItemId) {
        return;
      }

      let apiUrl = `${Auth.ecms}${CONTENT_MANAGER_ENDPOINTS.GET_CONTENT_USAGE}`;
      apiUrl += `?contentItemId=${contentItemId}`;

      if (page && pageSize) {
        apiUrl += `?pageSize=${pageSize}`;
        apiUrl += `&skip=${(page - 1) * pageSize}`;
      }
      if (sortColumn) {
        apiUrl += `&sort[0][field]=${sortColumn}`;
        apiUrl += `&sort[0][dir]=${sortDirection}`;
      }

      const response = await Auth.fetch(apiUrl, { method: 'GET' });

      if (response && response.contentUsage) {
        return response.contentUsage;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

   addUserOptions = async (oldUrl, hatId, origin, instruction) => {
     let url = oldUrl;
     const userOptions = await UserOptionsService.getUserOptions();
     const hasInstruction = !((instruction === null || instruction === ''));

     // webreader;
     if (this.webReaderId && this.webReaderId !== '' && this.webReaderId !== null && this.webReaderId !== undefined) {
       url += `&rsid=${this.webReaderId}`; // TODO: MAKE THIS THE REAL RSID.
     }
     // texthelp
     if (this.textHelpId && this.textHelpId !== '' && this.textHelpId !== null && this.textHelpId !== undefined) {
       url += `&thid=${this.textHelpId}`; // TODO: MAKE THIS THE REAL RSID.
     }
     if (hatId !== null && hatId !== '' && hatId !== undefined) {
       url += `&hatkey=${hatId}`;
     }

     url += `&acEnabled=${userOptions.includeClassroomAccommodations}`;
     url += `&thEnabled=${userOptions.textHelpEnabled}`;
     url += `&hasInstruction=${hasInstruction}`;
     url += '&satelliteApp=true';
     url += `&appUrl=${encodeURIComponent(origin)}`;
     return url;
   }

  fetchLessonGraderUrl = async (activityId, studentId, authKey, origin, mode, isTeacher, instruction, elementId) => {
    if (!isTeacher && mode === CONTENT_MODE.REVIEW) {
      console.warn('ContentManager.fetchLessonGraderUrl: Teacher function only.');
      return false;
    }

    try {
      const hasInstruction = !((instruction === null || instruction === ''));
      activityId = (activityId === null || activityId === '' ? '' : activityId);

      const { publisherSatelliteCode } = Auth;

      const apiUrlPrefix = `${Auth.ecms}${CONTENT_MANAGER_ENDPOINTS.GET_LESSON_GRADER_URL}`;
      const apiUrl = generateUrl(apiUrlPrefix, {
        publisherSatelliteCode, activityId, studentId, elementId
      });

      const response = await Auth.fetch(apiUrl, {
        method: 'GET'
      });
      let lessonGraderUrl = '';
      if (response && response.status === 'SUCCESS') {
        lessonGraderUrl = response.lessonGraderUrl;
      } else {
        return false;
      }
      lessonGraderUrl += `&hasInstruction=${hasInstruction}`;
      lessonGraderUrl += '&satelliteApp=true';
      lessonGraderUrl += `&authKey=${authKey}`;
      lessonGraderUrl += `&appUrl=${encodeURIComponent(origin)}`;

      this.setLessonUrl(lessonGraderUrl);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  fetchLearnosityActivityContentItemSettings = async (learnosityActivityResourceId) => {
    if (!learnosityActivityResourceId) {
      return;
    }
    const url = `${Auth.ecms}/api/getLearnosityActivityResourceSettings?lrnActivityResourceId=${learnosityActivityResourceId}`;

    try {
      const response = await Auth.fetch(url, {
        method: 'GET'
      });
      const lessonGraderUrl = '';
      if (response && response.status === 'SUCCESS') {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  openTabContent = async (viewUrl) => {
    if (viewUrl !== '' && viewUrl !== null && viewUrl !== undefined) {
      window.open(viewUrl, '_blank');
    }
  }
}

export default new ContentManager();
