import { action, makeObservable, observable } from 'mobx';

export class TopNavManager {
  @observable showFullNotificationBanner = false;

  constructor() {
    makeObservable(this);
  }

  @action setShowFullNotificationBanner = (showBanner) => {
    this.showFullNotificationBanner = showBanner;
  };
}

export default new TopNavManager();
