import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Form, Input, Message, Segment } from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';
import { ERROR_MESSAGES } from '../../managers/UserManager';
import Modal from '../Modal';

import '../../css/RegisterStudentModal.less';
// import '../../css/Login.less';

export default @inject('userManager')
@observer
class RegisterStudentModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      passwordInputType: 'password',
      passwordInputType2: 'password',
      accessCode: {
        value: props.accessCode,
        error: null,
        result: null
      },
      firstName: {
        value: '',
        error: null
      },
      lastName: {
        value: '',
        error: null
      },
      email: {
        value: '',
        error: null
      },
      password: {
        value: '',
        error: null
      },
      confirmPassword: {
        value: '',
        error: null
      },
      submitted: false,
      success: false,
      student: false,
      error: null,
      username: ''
    };

    this.ModalBanner = SatCoreComponent('ModalBanner');
    this.ShowPasswordButton = SatCoreComponent('ShowPasswordButton');
  }

  closeRegisterStudentModal = () => {
    this.props.closeRegisterStudentModal();
  }

  handleShowPasswordPressed = (name, isPasswordInput) => {
    const inputType = isPasswordInput ? 'password' : 'text';
    this.setState({ [name]: inputType });
  }

  render() {
    const { registerStudentOpen, labelInfo, contentName, t } = this.props;
    const { ModalBanner } = this;
    const { ShowPasswordButton } = this;
    return (
      <>
        <Modal
          className='RegisterStudentModal'
          closeOnDimmerClick={false}
          closeOnEscape={true}
          onClose={this.closeRegisterStudentModal}
          open={registerStudentOpen}
          size='tiny'>
          <ModalBanner
            label={(labelInfo !== undefined && labelInfo !== null && labelInfo !== '') ? labelInfo : 'Add New Student'}
            onClose={this.closeRegisterStudentModal}
            title={contentName} />
          <Modal.Content>
            <Form>
              <Segment attached className='element-body-register'>
                <Form.Field>
                  <Form.Input
                    label={t('accessCode', 'Access Code')}
                    name='accessCode'
                    onChange={this.handleChange}
                    onFocus={this.clearError}
                    placeholder='x-digits'
                    readOnly={true}
                    type='text'
                    value={this.state.accessCode.value} />
                  <Message
                    content={this.state.accessCode.error}
                    error
                    visible={this.state.accessCode.error !== null} />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    label='Student First Name'
                    name='firstName'
                    onChange={this.handleChange}
                    onFocus={this.clearError}
                    placeholder='Student First Name'
                    type='text' />
                </Form.Field>

                <Form.Field>
                  <Form.Input
                    label='Student Last Name'
                    name='lastName'
                    onChange={this.handleChange}
                    onFocus={this.clearError}
                    placeholder='Student Last Name'
                    type='text' />
                </Form.Field>

                <Form.Field>
                  <Form.Input
                    label={t('emailLabel', 'Student Username/ email')}
                    name='email'
                    onChange={this.handleChange}
                    onFocus={this.clearError}
                    placeholder={t('emailLabel', 'Student Username/ email')}
                    type='email' />

                  <Message
                    content={this.state.email.error}
                    error
                    visible={this.state.email.error !== null} />
                </Form.Field>
                <Form.Field>
                  <label>Enter student password</label>
                  <Input
                    autoComplete='current-password'
                    label={(
                      <ShowPasswordButton
                        isPassword={(this.state.passwordInputType === 'password')}
                        name='passwordInputType'
                        onFlip={this.handleShowPasswordPressed} />
                    )}
                    labelPosition='right'
                    name='password'
                    onChange={this.handleChange}
                    onFocus={this.clearError}
                    onKeyPress={(e) => e.stopPropagation()}
                    placeholder='Enter student password'
                    type={this.state.passwordInputType} />
                </Form.Field>

                <Form.Field>
                  <label>Confirm Student password</label>
                  <Input
                    autoComplete='current-password'
                    label={(
                      <ShowPasswordButton
                        isPassword={(this.state.passwordInputType2 === 'password')}
                        name='passwordInputType2'
                        onFlip={this.handleShowPasswordPressed} />
                    )}
                    labelPosition='right'
                    name='confirmPassword'
                    onChange={this.handleChange}
                    onFocus={this.clearError}
                    onKeyPress={(e) => e.stopPropagation()}
                    placeholder='Confirm student password'
                    type={this.state.passwordInputType2} />
                  <Message
                    content={this.state.confirmPassword.error}
                    error
                    visible={this.state.confirmPassword.error !== null} />
                  <Message
                    content={this.state.password.error}
                    error
                    visible={this.state.password.error !== null} />
                </Form.Field>

                <Form.Field />
              </Segment>
              <Message
                content={this.state.error}
                error
                visible={this.state.error !== null} />
            </Form>
          </Modal.Content>

          <Modal.Actions>
            <Button basic className='cancelButton' onClick={this.closeRegisterStudentModal} primary>Cancel</Button>
            <Button disabled={this.state.submitted || !this.isComplete()} onClick={this.handleFormSubmit} primary>Save</Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }

   handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => {
      const newState = { ...prevState };
      newState[name].value = value;
      return newState;
    });
  }

  clearError = (e) => {
    const { name } = e.target;
    this.setState((prevState) => {
      const newState = { ...prevState };
      newState[name].error = null;
      return newState;
    });
  }

  validate = async () => {
    const { userManager, t } = this.props;
    let valid = true;
    const accessCode = { ...this.state.accessCode };
    await userManager.validateAccessCode(this.state.accessCode);
    const { accessCodeResult } = userManager;

    accessCode.result = accessCodeResult;
    if (accessCodeResult.codeValid && (accessCodeResult.codeType === 'classroom' || accessCodeResult.codeType === 'school')) {
      // classroom access code type can be "classroom" for co-teacher or student so have to check the category now too.
      this.setState({
        student: accessCodeResult.codeType === 'classroom' && accessCodeResult.codeCategory === 'classroom'
      });
      accessCode.error = null;
    } else {
      accessCode.error = ERROR_MESSAGES.ACCESS_CODE_INVALID;
    }
    this.setState({ accessCode });
    if (accessCode.error !== null) {
      valid = false;
    }

    // check username/email for format and existence in the DB
    if (this.state.email.value) {
      const email = { ...this.state.email };
      // check if we have a valid username/email format
      const usernameResult = userManager.validateStudentUsernameFormat(email.value);
      email.error = null;
      if (!usernameResult.valid) {
          email.error = usernameResult.errorMessage;
      } else {
        // if format is valid, check if the username exists
        await userManager.validateUser(this.state.accessCode, this.state.email);
        const { emailResult } = userManager;
        if (emailResult.emailAddressExists) {
          if (userManager.allowStudentUsernamesAsEmail) {
            email.error = t('userExistsError', ERROR_MESSAGES.USER_EXISTS);
          } else {
            email.error = t('userExistsErrorNoEmail', ERROR_MESSAGES.USER_EXISTS_NO_EMAIL);
          }
        } else {
          email.error = null;
        }
      }
      this.setState({ email });
      if (email.error !== null) {
        valid = false;
      }
    }

    const { password, confirmPassword } = this.state;
    if (password.value !== confirmPassword.value) {
      confirmPassword.error = ERROR_MESSAGES.PASSWORDS_DO_NOT_MATCH;
      valid = false;
    } else {
      confirmPassword.error = null;
    }
    if (password.value !== null && password.value.length > 0 && password.value.length < 8) {
      password.error = ERROR_MESSAGES.PASSWORD_LENGTH;
      valid = false;
    } else {
      password.error = null;
    }
    this.setState({ password, confirmPassword });

    return valid;
  }

  isComplete = () => {
    const { accessCode, firstName, lastName, email, password, confirmPassword } = this.state;
    return !!(
      accessCode.value.length && !accessCode.error &&
      firstName.value.length && !firstName.error &&
      lastName.value.length && !lastName.error &&
      email.value.length && !email.error &&
      password.value.length && !password.error &&
      confirmPassword.value.length && !confirmPassword.error &&
      password.value === confirmPassword.value
    );
  }

  handleFormSubmit = async (e) => {
    const { userManager } = this.props;
    e.preventDefault();
    const valid = await this.validate();
    if (!valid) {
      return;
    }
    this.setState({ submitted: true, error: null });
    const accessCode = this.state.accessCode.result;

    const body = {
      signUpCode: accessCode.signupCode,
      institutionId: accessCode.institutionId,
      password: this.state.password.value,
      firstName: this.state.firstName.value,
      lastName: this.state.lastName.value,
      publisherSatelliteCode: this.publisherSatelliteCode,
      username: this.state.email.value
    };

    const result = await userManager.registerUser(body, accessCode);
    if (result) {
      this.closeRegisterStudentModal();
    } else {
      this.setState({ submitted: false, error: ERROR_MESSAGES.GENERAL });
    }
  }
}

SatCoreRegister('RegisterStudentModal', RegisterStudentModal);
