/* eslint-disable max-len */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import {
  Icon, Loader, Menu,
} from 'semantic-ui-react';

import classNames from 'classnames';

import '../css/LibraryResourceCard.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import PopupService from '../services/PopupService';

@inject('dialogManager', 'navigationManager', 'schoolManager', 'userManager')
@observer
class LibraryResourceCard extends Component {
  constructor(props) {
    super(props);
    this.SCCheckbox = SatCoreComponent('SCCheckbox');
    this.state = {
      resourceId: '',
      resourceTitle: '',
      resourceType: ''
    };
  }

  handleOpenPopup = () => {
    this.setState({ popupOpen: true });
  }

  handleClosePopup = () => {
    this.setState({ popupOpen: false });
  }

  renderMenuContent = () => {
    const { libraryCardParams, libraryCardKebabOptions } = this.props;
    const { popupOpen } = this.state;
    const kebabOptions = [];
    if (libraryCardKebabOptions) {
      for (const kebabOptionKey of Object.keys(libraryCardKebabOptions)) {
        const kebabOption = libraryCardKebabOptions[kebabOptionKey];
        kebabOptions.push(
          <Menu.Item key={kebabOption.key}
            name={kebabOption.label}
            onClick={
              () => {
                // if the options tooltip is open, close it
                if (popupOpen) {
                  this.handleClosePopup();
                }
                kebabOption.callback(libraryCardParams);
              }
            }>
            {kebabOption.label}
          </Menu.Item>
        );
      }
      return (
        <Menu vertical>
          {kebabOptions}
        </Menu>
      );
    } else {
      return (
        <Menu vertical>
          <Loader active />
        </Menu>
      );
    }
  }

  renderAlternateCardStyle() {
    const {
      isShopping, isSelected, isSelectDisabled, onBodyClick,
      libraryCardParams, selectHandler, showClassroomsBanner,
      showLiveBanner, t
    } = this.props;
    const { SCCheckbox, state } = this;
    const { popupOpen } = state;
    // libraryResource is the object for this entity so either a course, workspace (item bank)
    const { backgroundImg, cardClassName, libraryResource, title, typeDisplayName } = libraryCardParams;
    const { entityTypeId, inApproval, published, friendlyName } = libraryResource;
    // we can fit about 168 chars of the title on the card face for a normal card, less for resourceBank.
    const maxTitleLength = 35;
    const finalTitle = friendlyName || title;
    const truncateTitle = finalTitle.length > maxTitleLength;
    const truncatedTitle = truncateTitle ? `${finalTitle.substring(0, maxTitleLength - 3)}...` : finalTitle;
    const isCustomImage = cardClassName && cardClassName.includes('customImg');
    const backgroundImageStyle = {
      backgroundImage: `url(${backgroundImg})`,
      backgroundSize: 'cover',
    };

    return (
      <div className='library-card-wrapper'>
        {isShopping && (
          <div className='resource-selection-card-checkbox'>
            <SCCheckbox
              checked={isSelected}
              disabled={isSelectDisabled}
              onChange={(event) => selectHandler(event, libraryCardParams)} />
          </div>
        )}
        <div className={classNames('library-card', cardClassName, {
          disabled: (showLiveBanner && inApproval)
        })}>
          <div className={`library-card-title resourceBank ${onBodyClick ? 'clickable' : ''}`} onClick={onBodyClick ? () => onBodyClick(libraryCardParams) : null}>
            {(truncateTitle) ?
              PopupService.renderPopup({
                className: 'resource-option-popup',
                content: <div className={`library-card-title-text resourceBank ${showLiveBanner ? 'withBanner' : ''}`}>{finalTitle}</div>,
                on: 'hover',
                position: 'bottom left',
                trigger: <div className={`library-card-title-text resourceBank ${showLiveBanner ? 'withBanner' : ''}`}>{truncatedTitle}</div>
              })
              :
              <div className={`library-card-title-text resourceBank ${showLiveBanner ? 'withBanner' : ''} ${onBodyClick ? 'clickable' : ''}`} onClick={onBodyClick ? () => onBodyClick(libraryCardParams) : null}>{finalTitle}</div>}
          </div>
          <div className={`library-card-image alternate ${onBodyClick ? 'clickable' : ''}`} onClick={onBodyClick ? () => onBodyClick(libraryCardParams) : null}>
            {isCustomImage ?
              <div className="library-card-image-div" alt={`For ${truncatedTitle}`} style={backgroundImageStyle}></div>
              :
              <img alt={`For ${truncatedTitle}`} src={backgroundImg} />}
            {(showLiveBanner && inApproval && !published) &&
            <div className='live-banner'>{t('updatingToLiveLabel')}</div>}
            {(showLiveBanner && published && !inApproval) &&
            <div className='live-banner'>{entityTypeId === 'assessment' ? t('isLiveLabelAssessment') : t('isLiveLabel')}</div>}
            {(showClassroomsBanner && (
              <div className='live-banner live-banner-classrooms'>{t('classroomsBannerLabel')}</div>
            ))}
          </div>
          <div className='library-card-footer'>
            <div className='resource-type-text left'>{typeDisplayName}</div>
            {(!showLiveBanner || (showLiveBanner && entityTypeId === 'assessment')) &&
              PopupService.renderPopup({
                className: 'resource-option-popup',
                content: this.renderMenuContent(),
                on: 'hover',
                open: popupOpen,
                onClose: this.handleClosePopup,
                onOpen: this.handleOpenPopup,
                position: 'bottom left',
                trigger: <Icon name='ellipsis horizontal' />
              })}
            {showLiveBanner && !(entityTypeId === 'assessment') &&
              PopupService.renderPopup({
                className: 'resource-option-popup',
                content: `${typeDisplayName} ${t('updatingToLiveMessageSfx')}`,
                on: 'hover',
                open: popupOpen,
                onClose: this.handleClosePopup,
                onOpen: this.handleOpenPopup,
                position: 'bottom left',
                trigger: <Icon className='disabled' name='ellipsis horizontal' />
              })}
          </div>
        </div>
      </div>
    );
  }

  renderCartModalCard() {
    const {
      isShopping, isSelected, isSelectDisabled, onBodyClick,
      libraryCardParams, selectHandler, showClassroomsBanner,
      showLiveBanner, t
    } = this.props;
    const { SCCheckbox } = this;
    // libraryResource is the object for this entity so either a course, workspace (item bank)
    const { backgroundImg, cardClassName, libraryResource, title, typeDisplayName } = libraryCardParams;
    const { entityTypeId, inApproval, published, friendlyName } = libraryResource;
    // we can fit about 125 chars of the title on the horizontal card.
    const maxTitleLength = 130;
    const finalTitle = friendlyName || title;
    const truncateTitle = finalTitle.length > maxTitleLength;
    const truncatedTitle = truncateTitle ? `${finalTitle.substring(0, maxTitleLength - 3)}...` : finalTitle;
    return (
      <div className='library-card-wrapper'>
        {isShopping && (
          <div className='resource-selection-card-checkbox modal-layout'>
            <SCCheckbox
              customClassName='modal-layout'
              checked={isSelected}
              disabled={isSelectDisabled}
              onChange={(event) => selectHandler(event, libraryCardParams)} />
          </div>
        )}
        <div className={classNames('library-card', cardClassName, 'horizontal', {
          disabled: (showLiveBanner && inApproval)
        })}>
          <div className={`library-card-image ${onBodyClick ? 'clickable' : ''}`} onClick={onBodyClick ? () => onBodyClick(libraryCardParams) : null}>
            <img alt={`For ${truncatedTitle}`} src={backgroundImg} />
            {(showLiveBanner && inApproval && !published) &&
              <div className='live-banner'>{t('updatingToLiveLabel')}</div>}
            {(showLiveBanner && published && !inApproval) &&
              <div className='live-banner'>{entityTypeId === 'assessment' ? t('isLiveLabelAssessment') : t('isLiveLabel')}</div>}
            {(showClassroomsBanner && (
              <div className='live-banner live-banner-classrooms'>{t('classroomsBannerLabel')}</div>
            ))}
          </div>
          <div className={`library-card-title ${onBodyClick ? 'clickable' : ''}`} onClick={onBodyClick ? () => onBodyClick(libraryCardParams) : null}>
            {(truncateTitle) ?
              PopupService.renderPopup({
                className: 'resource-option-popup',
                content: <div className={`library-card-title-text resourceBank ${showLiveBanner ? 'withBanner' : ''}`}>{finalTitle}</div>,
                on: 'hover',
                position: 'bottom left',
                trigger: <div className={`library-card-title-text resourceBank ${showLiveBanner ? 'withBanner' : ''}`}>{truncatedTitle}</div>
              })
              :
              <div className={`library-card-title-text resourceBank ${showLiveBanner ? 'withBanner' : ''} ${onBodyClick ? 'clickable' : ''}`} onClick={onBodyClick ? () => onBodyClick(libraryCardParams) : null}>{finalTitle}</div>}
              <div className='resource-type-text horizontal'>{typeDisplayName}</div>
          </div>
        </div>
      </div>
    );
  }

  renderDefaultCard() {
    const {
      isShopping, isSelected, isSelectDisabled, onBodyClick,
      libraryCardParams, selectHandler, showClassroomsBanner,
      showLiveBanner, t
    } = this.props;
    const { SCCheckbox, state } = this;
    const { popupOpen } = state;
    // libraryResource is the object for this entity so either a course, workspace (item bank)
    const { backgroundImg, cardClassName, libraryResource, title, typeDisplayName } = libraryCardParams;
    const { /* contextType, */entityTypeId, inApproval, published, friendlyName } = libraryResource;
    // we can fit about 168 chars of the title on the card face.
    const finalTitle = friendlyName || title;
    const truncateTitle = finalTitle.length > 168;
    const truncatedTitle = truncateTitle ? `${finalTitle.substring(0, 145)}...` : finalTitle;
    return (
      <div className='library-card-wrapper'>
        {isShopping && (
          <div className='resource-selection-card-checkbox'>
            <SCCheckbox
              checked={isSelected}
              disabled={isSelectDisabled}
              onChange={(event) => selectHandler(event, libraryCardParams)} />
          </div>
        )}
        <div className={classNames('library-card', cardClassName, {
          disabled: (showLiveBanner && inApproval)
        })} style={{ backgroundImage: `url(${backgroundImg})` }}>
          <div className={`library-card-title ${onBodyClick ? 'clickable' : ''}`} onClick={onBodyClick ? () => onBodyClick(libraryCardParams) : null}>
            {(truncateTitle) ?
              PopupService.renderPopup({
                className: 'resource-option-popup',
                content: <div className={`library-card-title-text ${showLiveBanner ? 'withBanner' : ''}`}>{finalTitle}</div>,
                on: 'hover',
                position: 'bottom left',
                trigger: <div className={`library-card-title-text ${showLiveBanner ? 'withBanner' : ''}`}>{truncatedTitle}</div>
              })
              :
              <div className={`library-card-title-text ${showLiveBanner ? 'withBanner' : ''} ${onBodyClick ? 'clickable' : ''}`}>{finalTitle}</div>}
          </div>
          {(showLiveBanner && inApproval && !published) &&
            <div className='live-banner'>{t('updatingToLiveLabel')}</div>}
          {(showLiveBanner && published && !inApproval) &&
            <div className='live-banner'>{entityTypeId === 'assessment' ? t('isLiveLabelAssessment') : t('isLiveLabel')}</div>}
          {(showClassroomsBanner && (
            <div className='live-banner live-banner-classrooms'>{t('classroomsBannerLabel')}</div>
          ))}
          <div className='library-card-footer'>
            <div className='resource-type-text left'>{typeDisplayName}</div>
            {(!showLiveBanner || (showLiveBanner && entityTypeId === 'assessment')) &&
              PopupService.renderPopup({
                className: 'resource-option-popup',
                content: this.renderMenuContent(),
                on: 'hover',
                open: popupOpen,
                onClose: this.handleClosePopup,
                onOpen: this.handleOpenPopup,
                position: 'bottom left',
                trigger: <Icon name='ellipsis horizontal' />
              })}
            {showLiveBanner && !(entityTypeId === 'assessment') &&
              PopupService.renderPopup({
                className: 'resource-option-popup',
                content: `${typeDisplayName} ${t('updatingToLiveMessageSfx')}`,
                on: 'hover',
                open: popupOpen,
                onClose: this.handleClosePopup,
                onOpen: this.handleOpenPopup,
                position: 'bottom left',
                trigger: <Icon className='disabled' name='ellipsis horizontal' />
              })}

          </div>
        </div>
      </div>
    );
  }

  render() {
    const { horizontalLayout } = this.props;
    const useAlternateCardStyle = !horizontalLayout;
    if (useAlternateCardStyle) {
      return this.renderAlternateCardStyle();
    } else if (horizontalLayout) {
      return this.renderCartModalCard();
    } else {
      // this is deprecated as we decided to use alternate as default
      return this.renderDefaultCard();
    }
  }
}
export default LibraryResourceCard;

SatCoreRegister('LibraryResourceCard', LibraryResourceCard);
