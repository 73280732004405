import { action, makeObservable, observable } from 'mobx';

import Auth from './AuthManager';

export const APP_MANAGER_ENDPOINTS = {
  FETCH_ONLINE_STATUS: '/api/zombie'
};

export class AppManager {
  constructor() {
    makeObservable(this);
  }

  classWorkspaceFeature = false; // TODO make observable
  trialFeature = true; // TODO make observable

  @observable initializingApp = false;
  @observable shouldReloadLoginPageAfterLogout = false;

  @observable location = null;

  @observable locale = 'en-us';

  @action setInitializingApp = (toggle) => {
    this.initializingApp = toggle;
  }

  fetchOnlineStatus = async () => {
    try {
      const { FETCH_ONLINE_STATUS } = APP_MANAGER_ENDPOINTS;
      const response = await Auth.fetch(`${Auth.ecms}${FETCH_ONLINE_STATUS}`, {
        method: 'GET'
      });
      return response;
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  @action setShouldReloadLoginPageAfterLogout = () => {
    this.shouldReloadLoginPageAfterLogout = true;
  }

  /** @param {{ pathname: string; search: string; hash: string; state: undefined; key: string }} location} */
  @action setLocation = (location) => {
    this.location = location;
  }

  @action setLocale = (locale) => {
    if (locale) {
      this.locale = locale;
    }
  }

  /** @deprecated */
  init(options) {
    Object.entries(options || {}).forEach(([key, value]) => this[key] = value);
  }
}

export default new AppManager();
