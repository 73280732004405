import React from 'react';

import { register } from '../i18n';

import { registerClass } from '../SatCoreRegistry';

import appManager from '../managers/AppManager';
import assignmentManager, { ASSIGNER_TYPE } from '../managers/AssignmentManager';
import classroomManager from '../managers/ClassroomManager';
import courseManager from '../managers/CourseManager';
import navigationManager, { VIEW_SELECTION } from '../managers/NavigationManager';
import studentProgressManager from '../managers/StudentProgressManager';
import userManager from '../managers/UserManager';

import { setSessionStorageItem } from '../utils';

import ClassroomService from './ClassroomService';
import NavigationService from './NavigationService';

const t = register('StudentHelperService');

export default class StudentHelperService {
  /**
   * If the student classroom has changed, or we are initializing the student classroom for the first time,
   * we need to initialize/refresh the student data.
   *
   * This method handles that logic for us.
   */
  static handleChangeStudentClassroomAndInitData = async ({
    classroom,
    classroomId,
    history,
    shouldSetInitializingApp = true,
    status
  } = {}) => {
    const { isTeacher, isStudent } = userManager;

    if (shouldSetInitializingApp) {
      appManager.setInitializingApp(true);
    }

    // init classroom data
    classroomId = classroomId || classroom?.id || ClassroomService.getCurrentClassroomId();
    if (classroom) {
      classroomManager.setClassroom(classroomId, classroom);
      classroomManager.setClassroomData(classroomId, classroom);
    }
    setSessionStorageItem('classroomId', classroomId);
    classroomManager.setCurrentClassroomId(classroomId);

    await courseManager.fetchClassroomCourses(classroomId, isTeacher, isStudent);

    // init assignment data
    status = status || assignmentManager.getAssignmentStatus();
    await StudentHelperService.fetchStudentAssignments({ status });

    // init course data
    const urlParams = new URLSearchParams(window.location.search);
    const courseContentItemId = urlParams.get('courseId') || courseManager.currentCourseId;
    if (navigationManager.viewSelection === VIEW_SELECTION.BOOK || navigationManager.viewSelection === VIEW_SELECTION.COURSES) {
      await StudentHelperService.fetchStudentCourseData({ classroomId, courseContentItemId });
      const classroom = classroomManager.getClassroom(classroomId);
      const course = courseManager.getCurrentCourse(classroomId);
      const courseCount = courseManager.getClassroomCourseCount(classroomId);
      // set course breadcrumbs if we are on the student course view
      if (courseCount === 1 && navigationManager.viewSelection === VIEW_SELECTION.COURSES) {
        // eslint-disable-next-line max-len
        history?.replace(`/class?classroomId=${classroomId}&courseId=${courseContentItemId}&elementId=ROOT&view=${VIEW_SELECTION.BOOK}`);
        navigationManager.setView(VIEW_SELECTION.BOOK);
      }
      NavigationService.buildReFreshPaths(
        classroom, courseCount, classroomManager.getClassName(classroom.id),
        course, courseManager.currentCourseElementList, courseManager, userManager.isStudent
      );
    }

    // init progress data
    await this.fetchStudentProgressData();

    if (shouldSetInitializingApp) {
      appManager.setInitializingApp(false);
    }
  }

  static fetchStudentAssignments = async ({
    assignerType = ASSIGNER_TYPE.ALL,
    assignmentType = null,
    classroomId,
    clear = true,
    contentTypes = null,
    currentPage = 0,
    dueToday = false,
    filterDateStr = null,
    pageSize = 999,
    search = null,
    searchAll = true,
    sortDir = null,
    status = null
  } = {}) => {
    classroomId = classroomId || classroomManager.currentClassroomId;
    return assignmentManager.fetchStudentAssignments(
      classroomId, filterDateStr, status, assignmentType,
      search, contentTypes, assignerType, currentPage,
      dueToday, clear, pageSize, sortDir, searchAll
    );
  };

  static fetchStudentCourseData = async ({
    courseContentItemId,
    classroomId,
    courseResourceElementId,
    forceFetchIfAlreadyCached = false,
    isStudent = true,
    skipSetElement = false
  } = {}) => {
    courseContentItemId = courseContentItemId || courseManager.currentCourseId;
    classroomId = classroomId || classroomManager.currentClassroomId;
    courseResourceElementId = courseResourceElementId || courseManager.currentElementId;
    return courseManager.fetchCourseData(
      courseContentItemId, classroomId, courseResourceElementId,
      forceFetchIfAlreadyCached, isStudent, skipSetElement
    );
  }

  static fetchStudentProgressData = async ({
    classroomId,
    entityId
  } = {}) => {
    classroomId = classroomId || classroomManager.currentClassroomId;
    entityId = entityId || userManager.userId;
    await studentProgressManager.fetchStudentProgress(entityId, classroomId);
  };

  static getProgressSummaryLegend = ({ data } = {}) => {
    return (
      <div className='student-progress-summary-legend-container'>
        {this.getPerformanceBadgeLegendItem({
          data,
          label: 'progressSummaryLegendHighLabel',
          rangeKey: 'high',
          value: 'progressSummaryLegendHighValue'
        })}
        {this.getPerformanceBadgeLegendItem({
          data,
          label: 'progressSummaryLegendMediumLabel',
          rangeKey: 'medium',
          value: 'progressSummaryLegendMediumValue'
        })}
        {this.getPerformanceBadgeLegendItem({
          data,
          label: 'progressSummaryLegendLowLabel',
          rangeKey: 'low',
          value: 'progressSummaryLegendLowValue'
        })}
      </div>
    );
  }

  static getPerformanceBadgeLegendItem = ({ data, label, rangeKey, value } = {}) => {
    return (
      <div className='legend-item'>
        <div className={`performance-badge ${rangeKey}`}>
          {t(label)}
        </div>
        <div className={`value ${rangeKey}`}>
          {data?.[rangeKey]}
        </div>
        <div className='performance-range-label'>{t(value)}</div>
      </div>
    );
  }

  static getProgressViewOverrideOptions = ({
    className = 'student-progress',
    defaultImages = false,
    leftCol = true,
    progressWidget = false,
    summaryWidget = true,
    trophiesWidget = false,
    weeklyPerformanceWidget = true
  } = {}) => {
    return {
      className,
      defaultImages,
      leftCol,
      progressWidget,
      summaryWidget,
      trophiesWidget,
      weeklyPerformanceWidget
    };
  }
}

registerClass('StudentHelperService', StudentHelperService);
