/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';

import { Button, Image } from 'semantic-ui-react';

import courseDefault from '../img/default-course.svg';

import '../css/BookImageCard.less';

import { CONTENT_ITEM_TYPES } from '../managers/ContentManager';

import UtilityService from '../services/UtilityService';

import { stripHtmlTags } from '../utils';

export default class BookImageCard extends Component {
  render() {
    const { course, customDefaultCardImg, showBook, disabled } = this.props;
    const nameObj = UtilityService.reactHtmlParserWrapper(course.name);
    let image = null;

    if (course?.imageUrl) {
      image = <Image fluid src={CONTENT_ITEM_TYPES.getContentImageUrl(course.imageUrl)} />;
    } else if (customDefaultCardImg) {
      image = <Image fluid src={customDefaultCardImg} />;
    }
    return (
      <div className={`book-card-container${disabled ? ' disabled' : ''}`}>
        <div className={`course-card-image ${!image ? 'default-image' : ''}`}>
          {!image ? <Image src={courseDefault} /> : image}
        </div>
        <div className='course-name'>
          <span title={nameObj.stripped}>
            {nameObj.parsed}
          </span>
        </div>
        <div className='actions'>
          <Button
            aria-label={`Open ${stripHtmlTags(course.name)}`}
            disabled={disabled}
            onClick={() => showBook(course.id)}
            primary
            type='button'>
            Open
          </Button>
        </div>
      </div>
    );
  }
}
