import React, { Component } from 'react';
import { MobXProviderContext, inject, observer } from 'mobx-react';
import { Dropdown, Menu, Ref } from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

export default
@inject('userManager')
@observer
class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.mouse = false;
    this.focus = false;
    this.Avatar = SatCoreComponent('Avatar');
    this.ref = React.createRef();
  }

  open = () => this.mouse || this.focus

  setOpen = (mouse, focus) => {
    typeof mouse === 'boolean' && (this.mouse = mouse);
    typeof focus === 'boolean' && (this.focus = focus);
    // Use js to change tabIndex since react re-render component will cause focus problem since we focus that will not exist after re-render
    this.ref.current?.querySelectorAll('[role=menuitem]').forEach((item) => item.tabIndex = this.open() ? 0 : -1);
  }

  getDropdownOptions = (options) => options.filter((option) => option).map((option) => ({
    key: option.text.toLowerCase().replace(/\s+/g, '_'),
    as: (props) => <div {...props} role='menuitem' tabIndex={this.open() ? '0' : '-1'} />,
    ...option
  }))

  render() {
    const { Avatar, props: { userManager, options } } = this;
    return (
      <Menu.Item
        as='a'
        className='tnav-tab profile-menu'
        onBlur={() => this.setOpen(null, false)}
        onFocus={() => this.setOpen(null, true)}
        onMouseEnter={() => this.setOpen(true)}
        onMouseLeave={() => this.setOpen(false)}
        role='tab'
        tabIndex='-1'>
        <Ref innerRef={this.ref}>
          <Dropdown
            aria-haspopup={true}
            aria-label='Account menu'
            as={(props) => <div {...props} role='menu' />}
            icon={null}
            options={this.getDropdownOptions(options)}
            simple
            tabIndex='0'
            trigger={<Avatar fullProfileImageUrl={userManager.fullProfileImageUrl} />} />
        </Ref>
      </Menu.Item>
    );
  }
}

SatCoreRegister('ProfileMenu', ProfileMenu);
