import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Button, Container, Dropdown, Form, Radio, Loader, Message } from 'semantic-ui-react';

import Modal from '../Modal';

import { DateInput, TimeInput } from 'semantic-ui-calendar-react';

import '../../css/CreateNewLibraryResourceModal.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import { DIALOG_NAMES } from '../../managers/DialogManager';

import { stripHtmlTags } from '../../utils';

import { dateFormat } from '../../utils';

export default
@inject('assignmentManager', 'assessmentManager', 'courseManager', 'libraryManager', 'userManager')
class CreateNewLibraryResourceModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.ModalBanner = SatCoreComponent('ModalBanner');
    this.SCRadio = SatCoreComponent('SCRadio');
    this.SCCheckbox = SatCoreComponent('SCCheckbox');
  }

  getInitialState = () => {
    return {
      errorMsg: null,
      isValid: false,
      resourceDescription: '',
      resourceName: '',
      resourceUnitName: '',
      resourceTemplate: '',
      originalResourceName: '',
      originalResourceDescription: '',
      selectedModeType: '',
      teacherCanChangeMode: false,
      templateOptions: []
    };
  }

  componentDidMount = async () => {
    const { assessmentManager, contentItemId, courseResourceElement, isEdit, libraryManager, isReadOnly = false, selectedResourceType } = this.props;
    // Get optionsProfiles to use in the template selection dropdown
    const templateOptions = await libraryManager.getOptionProfiles();
    if (templateOptions && templateOptions.length > 0) {
      this.setState({ templateOptions });
    }
    if ((isEdit || isReadOnly) && contentItemId) {
      // get the resource details
      if (selectedResourceType === 'assessment') {
        const assessmentDetails = await libraryManager.fetchDistrictAssessmentDetails(contentItemId);
        const assessmentName = assessmentDetails?.name ? stripHtmlTags(assessmentDetails.name) : '';
        const assessmentDescription = assessmentDetails?.description ? stripHtmlTags(assessmentDetails.description) : '';

        const reviewOutsideTimeframe = courseResourceElement?.reviewOutsideTimeframe || false;
        const originalReviewOutsideTimeframe = courseResourceElement?.reviewOutsideTimeframe || false;
        const contentTimeframeStartDate = courseResourceElement?.timeframeStartDate || null;
        const contentTimeframeEndDate = courseResourceElement?.timeframeEndDate || null;

        const formattedTimeframeStartDate = courseResourceElement?.timeframeStartDateStr || null;
        const formattedTimeframeEndDate = courseResourceElement?.timeframeEndDateStr || null;

        const formattedTimeframeStartTime = courseResourceElement?.timeframeStartTimeStr || null;
        const formattedTimeframeEndTime = courseResourceElement?.timeframeEndTimeStr || null;

        if (assessmentDetails) {
          this.setState({
            resourceName: assessmentName,
            originalResourceName: assessmentName,
            resourceDescription: assessmentDescription,
            originalResourceDescription: assessmentDescription,
            resourceTemplate: assessmentDetails.optionsProfileId,
            originalResourceTemplate: assessmentDetails.optionsProfileId,
            timeframeStartDate: contentTimeframeStartDate,
            timeframeEndDate: contentTimeframeEndDate,
            formattedTimeframeStartDate,
            originalFormattedTimeframeStartDate: formattedTimeframeStartDate,
            formattedTimeframeEndDate,
            originalFormattedTimeframeEndDate: formattedTimeframeEndDate,
            formattedTimeframeStartTime,
            originalFormattedTimeframeStartTime: formattedTimeframeStartTime,
            formattedTimeframeEndTime,
            originalFormattedTimeframeEndTime: formattedTimeframeEndTime,
            reviewOutsideTimeframe,
            originalReviewOutsideTimeframe
          });
        }
      } else if (selectedResourceType === 'course_resource') {
        await assessmentManager.fetchContentItem(contentItemId);
        const assessmentBankDetails = assessmentManager.currentContentItem;
        if (assessmentBankDetails) {
          this.setState({
            resourceName: assessmentBankDetails.name,
            originalResourceName: assessmentBankDetails.name,
            resourceDescription: assessmentBankDetails.description,
            originalResourceDescription: assessmentBankDetails.description
          });
        }
      }
    }
  }

  clearError = () => {
    this.setState({ error: null });
  }

  handleChangeResourceName = async (event) => {
    const resourceName = event.target.value;
    await this.setState({ errorMsg: null, resourceName });
    this.validate();
  }

  handleChangeResourceDescription = async (event) => {
    const resourceDescription = event.target.value;
    await this.setState({ resourceDescription });
    this.validate();
  }

  handleChangeResourceUnitName = async (event) => {
    const resourceUnitName = event.target.value;
    await this.setState({ resourceUnitName });
    this.validate();
  }

  handleChangeResourceTemplate = async (_event, { value }) => {
    const resourceTemplate = value;
    await this.setState({ errorMsg: null, resourceTemplate });
    this.validate();
  }

  handleChangeModeType = async (event) => {
    const selectedModeType = event.target.value;
    this.setState({ selectedModeType });
  }

  handleTeacherCanChangeMode = async (event) => {
    const teacherCanChangeMode = event?.target?.checked;
    this.setState({ teacherCanChangeMode });
  }

  handleReviewOutsideTimeframe = async (name, value) => {
    const reviewOutsideTimeframe = value?.checked;
    await this.setState({ reviewOutsideTimeframe });
    this.validate();
  }

  createOrEditLibraryResource = async () => {
    const { assignmentManager, assessmentManager, contentItemId, courseManager, currentMenu, isEdit, isEditDetails, libraryManager, selectedResourceType, t } = this.props;
    const { originalResourceDescription, originalResourceName, resourceName, resourceDescription, resourceUnitName, resourceTemplate,
      formattedTimeframeStartDate, formattedTimeframeEndDate, formattedTimeframeStartTime, formattedTimeframeEndTime, originalFormattedTimeframeStartDate,
      originalFormattedTimeframeEndDate, originalFormattedTimeframeStartTime, originalFormattedTimeframeEndTime, originalReviewOutsideTimeframe,
      reviewOutsideTimeframe
    } = this.state;
    let errorMessageSuffix = '';
    let responseData = null;
    let success = false;
    if (selectedResourceType === 'course_resource' || selectedResourceType === 'assessment-bank') {
      if (isEdit) {
        if (originalResourceName !== resourceName || originalResourceDescription !== resourceDescription) {
          success = await assessmentManager.updateContentItemName(contentItemId, resourceName, resourceDescription);
        }
      } else {
        const resourceUnitNameSave = resourceUnitName || t('defaultUnitPlaceholderText');
        responseData = await libraryManager.createLibraryAssessmentBank(resourceName, resourceDescription, resourceUnitNameSave);
        if (responseData) {
          success = true;
        }
      }
      errorMessageSuffix = t('assessmentBankLabel');
    } else if (selectedResourceType === 'assessment') {
      if (isEdit || isEditDetails) {
        const { currentElementId } = courseManager;
        let startRealDate = null;
        let endRealDate = null;
        let newReviewOutsideTimeframe = originalReviewOutsideTimeframe;
        if ((formattedTimeframeStartDate !== originalFormattedTimeframeStartDate) || (originalFormattedTimeframeStartTime !== formattedTimeframeStartTime)) {
          startRealDate = assignmentManager.convertJSStringToJSDate(formattedTimeframeStartDate, formattedTimeframeStartTime);
        } 
        if ((originalFormattedTimeframeEndDate !== formattedTimeframeEndDate) || (originalFormattedTimeframeEndTime !== formattedTimeframeEndTime)) {
          endRealDate = assignmentManager.convertJSStringToJSDate(formattedTimeframeEndDate, formattedTimeframeEndTime);
        }
        if (originalReviewOutsideTimeframe !== reviewOutsideTimeframe) {
          newReviewOutsideTimeframe = reviewOutsideTimeframe;
        }
        success = await libraryManager.updateLibraryAssessment(contentItemId, resourceName, resourceDescription, resourceTemplate, currentElementId, startRealDate, endRealDate, newReviewOutsideTimeframe);
      } else {
        const addToWorkspace = currentMenu === 'sharedDrafts';
        responseData = await libraryManager.createLibraryAssessment(resourceName, resourceDescription, resourceTemplate, addToWorkspace);
        if (responseData) {
          success = true;
        }
      }
      errorMessageSuffix = 'Assessment';
    }
    if (success) {
      this.closeCreateNewLibraryResourceModal(responseData);
    } else {
      this.showTextMessage(`Error creating ${errorMessageSuffix}.`);
    }
  }

  showTextMessage = (title, message) => {
    const { dialogManager } = this.props;
    if (message) {
      dialogManager.setOpenDialog(DIALOG_NAMES.TEXT, {
        title,
        message
      }, () => dialogManager.closeDialog(DIALOG_NAMES.TEXT));
    }
  }

  validate = async () => {
    const { assignmentManager, assessmentManager, isEdit, isEditDetails, selectedResourceType } = this.props;
    const { originalResourceDescription, originalResourceName, originalResourceTemplate, resourceTemplate, originalReviewOutsideTimeframe,
      reviewOutsideTimeframe, formattedTimeframeStartDate, formattedTimeframeEndDate, formattedTimeframeStartTime, formattedTimeframeEndTime,
      originalFormattedTimeframeStartDate, originalFormattedTimeframeEndDate, originalFormattedTimeframeStartTime, originalFormattedTimeframeEndTime,
     } = this.state;
    let { resourceName, resourceDescription, resourceUnitName } = this.state;
    const entityTypeId = (selectedResourceType === 'assessment') ? 'assessment' : 'course_resource';
    // remove whitespace around values
    resourceName = resourceName.trim();
    resourceDescription = resourceDescription.trim();
    resourceUnitName = resourceUnitName.trim();
    let allRequiredValid = false;
    let nameValid = false;
    let templateValid = false;
    let unitValid = false;
    let anyChanged = false;
    let resourceNameExists = false;

    if ((!isEdit && !isEditDetails) || (isEdit && (originalResourceName !== resourceName))) {
      // check for resource name exists but do not exclude hidden from the search as live banks are marked hidden
      resourceNameExists = await assessmentManager.checkContentItemName(resourceName, entityTypeId, false);
      if (resourceNameExists) {
        this.setState({ errorMsg: 'Resource name is already in use.' });
      }
    }
    if (isEdit || isEditDetails) {
      let changed = false;
      changed = (originalResourceDescription !== resourceDescription)
        || (originalResourceName !== resourceName)
        || (originalResourceTemplate !== resourceTemplate)
      if (changed) {
        // check resource name
        const nameIsChanged = (originalResourceName !== resourceName);
        if (resourceName || resourceName.length > 0) {
          if (!anyChanged && nameIsChanged) {
            if (resourceName.length <= 200) {
              anyChanged = true;
            }
          }
        }
        // check resource description
        const descIsChanged = (originalResourceDescription !== resourceDescription);
        if (resourceDescription && descIsChanged) {
          if (!anyChanged) {
            anyChanged = true;
          }
        }
        // check resource template
        const templateIsChanged = (originalResourceTemplate !== resourceTemplate);
        if (resourceTemplate && templateIsChanged) {
          if (!anyChanged) {
            anyChanged = true;
          }
        }

        // check if resource name exists and override if it is.
        if (nameIsChanged && resourceNameExists) {
          anyChanged = false;
        }

        allRequiredValid = anyChanged;
      }
      // for edit details we check the timeframe
      if (isEditDetails) {
        let changed = false;
        changed = (originalFormattedTimeframeStartDate !== formattedTimeframeStartDate)
          || (originalFormattedTimeframeStartTime !== formattedTimeframeStartTime)
          || (originalFormattedTimeframeEndDate !== formattedTimeframeEndDate)
          || (originalFormattedTimeframeEndTime !== formattedTimeframeEndTime)
          || (originalReviewOutsideTimeframe !== reviewOutsideTimeframe);
        if (changed) {
          let startDateChanged = false;
          let endDateChanged = false;
          // check timeframe start date.
          const timeframeStartIsChanged = (originalFormattedTimeframeStartDate !== formattedTimeframeStartDate);
          if (formattedTimeframeStartDate && timeframeStartIsChanged) {
            startDateChanged = true;
            anyChanged = true;
          }

          // check timeframe start time.
          const timeframeStartTimeIsChanged = (originalFormattedTimeframeStartTime !== formattedTimeframeStartTime);
          if (formattedTimeframeStartTime && timeframeStartTimeIsChanged) {
            startDateChanged = true;
            anyChanged = true;
          }

          // check timeframe end date.
          const timeframeEndIsChanged = (originalFormattedTimeframeEndDate !== formattedTimeframeEndDate);
          if (formattedTimeframeEndDate && timeframeEndIsChanged) {
            endDateChanged = true;
            anyChanged = true;
          }

          // check timeframe end date.
          const timeframeEndTimeIsChanged = (originalFormattedTimeframeEndTime !== formattedTimeframeEndTime);
          if (formattedTimeframeEndTime && timeframeEndTimeIsChanged) {
            endDateChanged = true;
            anyChanged = true;
          }

          // check allow outside timeframe.
          const reviewOutsideTimeframeIsChanged = (originalReviewOutsideTimeframe !== reviewOutsideTimeframe);
          if (reviewOutsideTimeframeIsChanged) {
            anyChanged = true;
          }

          allRequiredValid = anyChanged;

          // check if end date/time is after start date/time
          if (startDateChanged || endDateChanged) {
            const startRealDate = assignmentManager.convertJSStringToJSDate(formattedTimeframeStartDate, formattedTimeframeStartTime);
            const endRealDate = assignmentManager.convertJSStringToJSDate(formattedTimeframeEndDate, formattedTimeframeEndTime);
            if (endRealDate !== null && (endRealDate < startRealDate)) {
              allRequiredValid = false;
              this.setState({ errorMsg: 'Timeframe start date must be before end date.' });
            } else {
              allRequiredValid = anyChanged;
              this.setState({ errorMsg: null });
            }
          }
        }
      }
    } else {
      // if this isn't an edit or edit details must be an add
      if (!isEdit && !isEditDetails) {
        // for add all only the name need to be valid.
        if (!resourceNameExists) {
          nameValid = !!((resourceName && resourceName.length > 0 && resourceName.length <= 200));
        } else {
          nameValid = false;
        }
        // resource template is now required
        if (selectedResourceType === 'assessment' && (!resourceTemplate || !resourceTemplate.length > 0)) {
          templateValid = false;
        } else {
          templateValid = true;
        }
        // course resource requires unit name
        if (selectedResourceType === 'course_resource' || selectedResourceType === 'assessment-bank') {
          unitValid = !!resourceUnitName;
        }
        if (selectedResourceType === 'assessment') {
          allRequiredValid = (nameValid && templateValid);
        } else {
          allRequiredValid = (nameValid && unitValid);
        }
      }
    }
    this.setState({ isValid: allRequiredValid });
  }

  closeCreateNewLibraryResourceModal = (data = null) => {
    const { closeCreateNewLibraryResourceModal, onCloseCreateNewLibraryResourceModal } = this.props;
    if (onCloseCreateNewLibraryResourceModal) {
      onCloseCreateNewLibraryResourceModal(data);
    }
    closeCreateNewLibraryResourceModal();
  }

  handleChangeStartDate = async (event, { value }) => {
    await this.setState({ formattedTimeframeStartDate: value, errorMsg: null });
    this.validate();
  };

  handleChangeEndDate = async (event, { value }) => {
    await this.setState({ formattedTimeframeEndDate: value, errorMsg: null });
    this.validate();
  };

  handleChangeStartTime = async (event, { value }) => {
    await this.setState({ formattedTimeframeStartTime: value ? value.toUpperCase() : '', errorMsg: null });
    this.validate();
  };

  handleChangeEndTime = async (event, { value }) => {
    await this.setState({ formattedTimeframeEndTime: value ? value.toUpperCase() : '', errorMsg: null });
    this.validate();
  };

  hideError = () => {
    const { originalResourceName, resourceName } = this.state;
    const { isEdit } = this.props;
    const resourceNameVal = isEdit ? originalResourceName : resourceName;
    this.setState({ errorMsg: null, resourceName: resourceNameVal });
  }

  render() {
    const { close, courseResourceElement, createNewLibraryResourceOpen, isEdit, isReadOnly = false, selectedResourceType, isInCourse = false, t } = this.props;
    const {
      errorMsg, isSaving, isValid, resourceDescription, resourceName, resourceUnitName,
      resourceTemplate, selectedModeType, teacherCanChangeMode, templateOptions, formattedTimeframeStartDate,
      formattedTimeframeEndDate, formattedTimeframeStartTime, formattedTimeframeEndTime, reviewOutsideTimeframe,
      timeframeStartDate, timeframeEndDate
    } = this.state;
    const { ModalBanner, SCCheckbox, SCRadio } = this;
    const typeLabel = selectedResourceType === 'assessment' ? 'assessmentLabel' : 'assessmentBankLabel';
    const headerLabel = (isEdit || isReadOnly) ? `${t(typeLabel)} ${t('modalEditLabel')}` : `${t('modalCreateLabel')} ${t(typeLabel)}`;
    // This allows us to have a custom name description. In coreTranslations "customNameLabelSubText" is set to an empty string so we don't
    // use it. We have a custom message in Simple Solutions translation that uses it.
    const customNameLabelSubText = t('customNameLabelSubText');
    const inputLabelSubText = selectedResourceType === 'assessment' ? `${t('nameLabelSubText')} ${t(typeLabel)}.` : `${t('nameLabelSubText')} ${t(typeLabel)}. ${t('nameLabelSubTextPostfix')}`;
    return (
      <Modal className='create-new-library-resource-modal' open={createNewLibraryResourceOpen} size='tiny'>
        <ModalBanner
          label={headerLabel}
          onClose={close} />
        <Modal.Content>
          {/* <div className='description-text'>{t('descriptionText')}</div> */}
          <div className='inputLabelText'>{t('nameLabelText')}</div>
          <div className='inputLabelSubText'>{customNameLabelSubText ? customNameLabelSubText : inputLabelSubText}</div>
          <Form.Field className='create-new-resource-field'>
            <Form.Input
              className='inputText'
              disabled={isReadOnly}
              name='resourceName'
              onChange={this.handleChangeResourceName}
              placeholder={t('inputPlaceholderText')}
              type='text'
              value={resourceName} />
          </Form.Field>
          <div className='inputLabelText'>{t('descriptionLabelText')}</div>
          <div className='inputLabelSubText'>{t('descriptionLabelSubText')}</div>
          <Form.Field className='create-new-resource-field'>
            <Form.Input
              className='inputText'
              disabled={isReadOnly}
              name='resourceDescription'
              onChange={this.handleChangeResourceDescription}
              placeholder={t('inputPlaceholderText')}
              type='text'
              value={resourceDescription} />
          </Form.Field>
          {(selectedResourceType === 'assessment-bank' && !isEdit) && (
            <>
              <div className='inputLabelText'>{t('unitNameLabelText')}</div>
              <div className='inputLabelSubText'>{t('unitNameLabelSubText')}</div>
              <Form.Field className='create-new-resource-field'>
                <Form.Input
                  className='inputText'
                  disabled={isReadOnly}
                  name='resourceUnitName'
                  onChange={this.handleChangeResourceUnitName}
                  placeholder={t('defaultUnitPlaceholderText')}
                  type='text'
                  value={resourceUnitName} />
              </Form.Field>
            </>
          )}
          {selectedResourceType === 'assessment' && (
            <>
              <div className='inputLabelText'>{t('templateLabelText')}</div>
              <div className='inputLabelSubText'>{t('templateLabelSubText')}</div>
              <Form.Field className='create-new-resource-field'>
                <Dropdown
                  disabled={isReadOnly}
                  fluid
                  name='template-select'
                  onChange={this.handleChangeResourceTemplate}
                  options={templateOptions}
                  placeholder={t('templateLabelText')}
                  search
                  selection
                  value={resourceTemplate} />
              </Form.Field>
              {courseResourceElement &&
                <>
                  <div className='inputLabelText timeframe'>{t('dateRange')}</div>
                  <Container className='field-wrapper'>
                    <Container className='time-controls'>
                      <Form.Field>
                        <DateInput
                          animation='false'
                          className='date-input'
                          dateFormat='MM/DD/YYYY'
                          iconPosition='left'
                          label='Start Date'
                          name='startDate'
                          onChange={this.handleChangeStartDate}
                          placeholder='Start Date'
                          value={(formattedTimeframeStartDate !== null && formattedTimeframeStartDate !== 'undefined') ? formattedTimeframeStartDate : ''} />
                      </Form.Field>
                      <Form.Field>
                        <TimeInput
                          animation='false'
                          className='time-input'
                          iconPosition='left'
                          label='Start Time'
                          name='startTime'
                          onChange={this.handleChangeStartTime}
                          placeholder='Start Time'
                          timeFormat='AMPM'
                          value={(formattedTimeframeStartTime !== null && formattedTimeframeStartTime !== 'undefined') ? formattedTimeframeStartTime : ''} />
                      </Form.Field>
                    </Container>
                  </Container>
                  <Container className='field-wrapper'>
                    <Container className='time-controls'>
                      <Form.Field>
                        <DateInput
                          animation='false'
                          className='date-input'
                          dateFormat='MM/DD/YYYY'
                          iconPosition='left'
                          label='Due Date'
                          name='endDate'
                          onChange={this.handleChangeEndDate}
                          placeholder='Due Date'
                          value={(formattedTimeframeEndDate !== null && formattedTimeframeEndDate !== 'undefined') ? formattedTimeframeEndDate : ''} />
                      </Form.Field>
                      <Form.Field>
                        <TimeInput
                          animation='false'
                          className='time-input'
                          iconPosition='left'
                          label='Due Time'
                          name='endTime'
                          onChange={this.handleChangeEndTime}
                          placeholder='Due Time'
                          timeFormat='AMPM'
                          value={(formattedTimeframeEndTime !== null && formattedTimeframeEndTime !== 'undefined') ? formattedTimeframeEndTime : ''} />
                      </Form.Field>
                    </Container>
                    <Container className='field-wrapper'>
                      <Form.Field className='check-list'>
                        <Radio
                          checked={reviewOutsideTimeframe}
                          label={t('reviewOutsideTimeframeLabel')}
                          onChange={this.handleReviewOutsideTimeframe}
                          toggle />
                      </Form.Field>
                    </Container>
                  </Container>
                </>
              }
              {isInCourse && (
                <>
                  <div className='inputLabelText'>{t('modeLabelText')}</div>
                  <div className='inputLabelSubText'>{t('modeLabelSubText')}</div>
                  <Form.Field className='create-new-resource-field'>
                    <SCRadio
                      checked={selectedModeType === 'primary'}
                      disabled={isReadOnly}
                      label={t('usePrimaryModeLabel')}
                      name='exportGroup'
                      onChange={this.handleChangeModeType}
                      value='primary' />
                    <SCRadio
                      checked={selectedModeType === 'alternate'}
                      disabled={isReadOnly}
                      label={t('useAlternateModeLabel')}
                      name='exportGroup'
                      onChange={this.handleChangeModeType}
                      value='alternate' />
                    <SCCheckbox
                      checked={teacherCanChangeMode === true}
                      disabled={isReadOnly}
                      label={t('teacherCanChangeModeLabel')}
                      onChange={this.handleTeacherCanChangeMode}
                      readOnly />
                  </Form.Field>
                </>
              )}
              {errorMsg ? (
                <div className='error-message-wrapper'>
                  <Message
                    className='create-library-resource-modal-error-msg'
                    content={`${errorMsg}`}
                    error
                    visible={errorMsg !== null} />
                </div>
              ) : null}
            </>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={() => { this.closeCreateNewLibraryResourceModal(); }} primary>{t('cancelButtonLabel', 'Cancel')}</Button>
          <Button
            disabled={!isValid}
            onClick={() => {
              this.setState({ isSaving: true });
              this.createOrEditLibraryResource();
            }}
            primary>
            {(isEdit || isReadOnly) ? (isSaving ? <Loader active inline size='tiny' /> : t('saveButtonLabel', 'Save')) : (isSaving ? <Loader active inline size='tiny' /> : t('createButtonLabel', 'Create'))}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

SatCoreRegister('CreateNewLibraryResourceModal', CreateNewLibraryResourceModal);
