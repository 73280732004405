import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import InfiniteScroll from 'react-infinite-scroller';

import { Loader } from 'semantic-ui-react';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { DIALOG_NAMES } from '../managers/DialogManager';

import '../css/Class.less';
import '../css/RosterTable.less';

import ClassroomService from '../services/ClassroomService';

export default
@inject(
  'appManager', 'classroomManager', 'dialogManager',
  'navigationManager', 'userManager'
)
@observer
class ClassRosterTable extends Component {
  constructor(props) {
    super(props);
    this.ClassRosterRow = SatCoreComponent('ClassRosterRow');
  }

  async componentDidMount() {
    const { userManager } = this.props;
    const { isDistrictAdmin, isSchoolAdmin, isTeacher } = userManager;
    const isAdmin = isDistrictAdmin || isSchoolAdmin;
    if (isAdmin || isTeacher) {
      const { classroomManager } = this.props;

      const urlParams = new URLSearchParams(this.props.location.search);
      if (!classroomManager.currentClassroomId && urlParams.has('classroomId')) {
        classroomManager.setCurrentClassroomId(urlParams.get('classroomId'));
      }
    }
  }

  onClickOpenModal = (student) => () => {
    const { dialogManager, classroomManager } = this.props;

    // TODO remove // const isSsoUserOrHasSsoInstitution = UserService.isSsoUserOrHasSsoInstitution();
    const isSsoStudent = !!student?.ssoId;

    if (/* isSsoUserOrHasSsoInstitution */isSsoStudent) {
      return;
    }
    dialogManager.setOpenDialog(DIALOG_NAMES.UPDATE_USER_PASSWORD_MODAL, {
      userName: `${student.firstName} ${student.lastName}`,
      save: async (password) => await classroomManager.resetStudentPassword(student.userId, password)
    });
  }

  handleLoadMore = async (page) => {
    const { classroomManager, isActive, userManager } = this.props;
    const { isDistrictAdmin, isSchoolAdmin } = userManager;

    const isAdmin = isDistrictAdmin || isSchoolAdmin;
    if (isAdmin || isActive) {
      await classroomManager.fetchClassroomRoster(classroomManager.currentClassroomId, page);
    } else {
      await classroomManager.fetchArchiveClassroomRoster(classroomManager.currentClassroomId, page);
    }
  }

  editUser = async (rosterId, userId) => {
    const {
      classroomManager, history, navigationManager, userManager
    } = this.props;
    const isAdmin = userManager.isDistrictAdmin || userManager.isSchoolAdmin;

    const classroomId = classroomManager.currentClassroomId;
    let routerUrl = `/editStudentProfile?userId=${userId}&classroomId=${classroomId}&rosterId=${rosterId}`;
    if (isAdmin) {
      const paramsToExcludeFromStr = 'userId, classroomId';
      const { urlParamStr } = await navigationManager.getAdminUrlParams(
        paramsToExcludeFromStr
      );
      routerUrl = urlParamStr ? `${routerUrl}&${urlParamStr}` : routerUrl;
    }
    history.push(routerUrl);
  }

  startActivateUser = async (rosterItem) => {
    const { dialogManager, t } = this.props;
    const title = `${t('activate', 'Activate')}: ${rosterItem.firstName} ${rosterItem.lastName}`;
    const message = t('messageAct', 'Activating this student will display them in your\n active class roster.');
    dialogManager.setOpenDialog(DIALOG_NAMES.CONFIRM, {
      title,
      message,
      cancelButtonClass: 'cancelButton',
      cancelButtonName: 'Cancel',
      confirmButtonClass: 'saveButton',
      confirmButtonName: t('activate', 'Activate'),
      confirmHandler: () => this.activateUser(rosterItem)
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.CONFIRM));
  }

  startArchiveUser = async (rosterItem) => {
    const { dialogManager, t } = this.props;
    const title = `${t('archive', 'Archive')}: ${rosterItem.firstName} ${rosterItem.lastName}`;
    const message = t('messageArch', 'Archiving this student will remove the student from your active roster and all future assignments.');
    dialogManager.setOpenDialog(DIALOG_NAMES.CONFIRM, {
      title,
      message,
      cancelButtonClass: 'cancelButton',
      cancelButtonName: 'Cancel',
      confirmButtonClass: 'saveButton',
      confirmButtonName: t('archive', 'Archive'),
      confirmHandler: () => this.archiveUser(rosterItem)
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.CONFIRM));
  }

  activateUser = async (rosterItem) => {
    const { classroomManager, dialogManager } = this.props;

    rosterItem.active = true;

    const success = await classroomManager.editRosterStudent(rosterItem);
    if (success) {
      await classroomManager.fetchArchiveClassroomRoster(
        classroomManager.currentClassroomId, 0
      );
    }
    dialogManager.closeDialog(DIALOG_NAMES.CONFIRM);
  }

  archiveUser = async (rosterItem) => {
    const { classroomManager, dialogManager } = this.props;
    rosterItem.active = false;
    const success = await classroomManager.editRosterStudent(rosterItem);
    if (success) {
      await classroomManager.fetchClassroomRoster(classroomManager.currentClassroomId, 0);
    }
    dialogManager.closeDialog(DIALOG_NAMES.CONFIRM);
  }

  enableUser = async (rosterItem) => {
    const { classroomManager, dialogManager, userManager } = this.props;
    rosterItem.enableUser = true;
    const success = await userManager.enableUser(rosterItem.userId);
    if (success) {
      await classroomManager.fetchClassroomRoster(classroomManager.currentClassroomId, 0);
    }
    dialogManager.closeDialog(DIALOG_NAMES.CONFIRM);
  }

  handleTextHelp = async (rosterItem) => {
    const toggle = !rosterItem.textHelpEnabled;
    const { userManager, classroomManager } = this.props;
    const success = await userManager.updateStudentTextHelpOption(rosterItem.userId, toggle);
    if (success) {
      rosterItem.textHelpEnabled = toggle;
      await classroomManager.fetchClassroomRoster(classroomManager.currentClassroomId, 0);
    }
  }

  migrateUserCheck = async (rosterItem) => {
    const { classroomManager, dialogManager, t } = this.props;
    const { currentClassroomId } = classroomManager;
    const response = await classroomManager.migrateStudentDataCheck(rosterItem.userId, currentClassroomId);
    const title = `${t('migrateStudentHeader', 'Migrate Student Data')}: ${rosterItem.firstName} ${rosterItem.lastName}`;
    if (response && response.status === 'SUCCESS') {
      if (response.data[0]) {
        this.showMigrateDataModal(response, rosterItem);
      } else {
        let message = t('messageNoMigrateData', 'This student does not have any class data to migrate');
        dialogManager.setOpenDialog(DIALOG_NAMES.TEXT, {
          title,
          message
        }, () => dialogManager.closeDialog(DIALOG_NAMES.TEXT));
      }
    } else if (response && response.status === 'FAILURE') {
      const message = response.statusMessage;
      dialogManager.setOpenDialog(DIALOG_NAMES.TEXT, {
        title,
        message
      }, () => dialogManager.closeDialog(DIALOG_NAMES.TEXT));
    }
  }

  showMigrateDataModal = async (response, rosterItem) => {
    const { dialogManager, t } = this.props;
    const title = `${t('migrateStudentHeader', 'Migrate Student Data')}: ${rosterItem.firstName} ${rosterItem.lastName}`;
    const message = t('messageMigrate', 'The following assignments are available to migrate for this student.');

    dialogManager.setOpenDialog(DIALOG_NAMES.MIGRATE_STUDENT_DATA_MODAL, {
      activityList: response.data,
      message,
      migrateUserData: () => {
        this.migrateUserData(rosterItem);
      },
      modalTitle: title,
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.MIGRATE_STUDENT_DATA_MODAL));
  }

  migrateUserData = async (rosterItem) => {
    const { classroomManager, dialogManager, t } = this.props;
    const { currentClassroomId } = classroomManager;
    const title = `${t('migrateStudentHeader', 'Migrate Student Data')}: ${rosterItem.firstName} ${rosterItem.lastName}`;
    let message = 'There was an error processing your migration request.';

    // call to submit the migration request
    const success = await classroomManager.migrateStudentData(rosterItem.userId, currentClassroomId);

    if (success) {
      console.log(`Submitted migration request: migrate classroomId: ${currentClassroomId} user: ${rosterItem.userId} ${rosterItem.firstName} ${rosterItem.lastName}`);
      message = t('messageMigrateRequestSubmitted', 'Migration of student data submitted.');
    }

    // Close previous modal
    dialogManager.closeDialog(DIALOG_NAMES.MIGRATE_STUDENT_DATA_MODAL)

    // open the post submission message
    dialogManager.setOpenDialog(DIALOG_NAMES.TEXT, {
      title,
      message
    }, () => dialogManager.closeDialog(DIALOG_NAMES.TEXT));
  }

  render() {
    const {
      appManager,
      classroomManager,
      hideEdit,
      hidePassword,
      isActive,
      readOnly,
      // TODO remove // showTrialTime: showTrialTimeFromProps,
      t,
      userManager,
    } = this.props;

    // TODO remove // const isSsoUserAndSelfRegClassroom = UserService.isSsoUserAndSelfRegClassroom();
    // TODO remove // const isSsoUserOrHasSsoInstitution = UserService.isSsoUserOrHasSsoInstitution();
    const isSelfRegClassroom = ClassroomService.isSelfRegClassroom();

    // TODO remove // const showTrialTime = showTrialTimeFromProps === undefined ? appManager.trialFeature : showTrialTimeFromProps;
    const showTrialTime = appManager.trialFeature && userManager.isLicensedByPurchaseCode;

    const { ClassRosterRow } = this;
    const roster = [];

    let rosterItems;

    if (isActive) {
      rosterItems = classroomManager.rosterMap.get(classroomManager.currentClassroomId);
    } else {
      rosterItems = classroomManager.archRosterMap.get(classroomManager.currentClassroomId);
    }

    if (rosterItems !== null && rosterItems !== undefined) {
      rosterItems.map((student, index) => {
        const isSsoStudent = !!student?.ssoId;
        if ((isSelfRegClassroom && (!isSsoStudent || student.userEnabled)) || (isSsoStudent && student.userEnabled)) {
          roster.push(<ClassRosterRow
            key={student.id}
            activateLabel={t('activate', 'Activate')}
            activateUser={this.startActivateUser}
            archiveLabel={t('archive', 'Archive')}
            archiveUser={this.startArchiveUser}
            editLabel={t('edit', 'Edit')}
            editUser={this.editUser}
            enableUser={this.enableUser}
            even={index % 2 === 1}
            handleTextHelp={this.handleTextHelp}
            hideEdit={hideEdit}
            hidePassword={hidePassword}
            index={index}
            isActive={isActive}
            migrateLabel={t('migrateButtonLabel', 'Migrate')}
            migrateUser={this.migrateUserCheck}
            onClickOpenModal={this.onClickOpenModal}
            publisherTextHelpActive={userManager.publisherTextHelpActive}
            readOnly={readOnly}
            rosterItem={student}
            showTextHelpForAll={userManager.showTextHelpForAll}
            showTrialTime={showTrialTime}
            userEnabled={student.userEnabled} />
          );
        }
      });
    }

    return (
      <>
        <div className='classRosterTable'>
          <div className='table-header-row'>
            <div className='table-header-cell'>{t('firstName', 'First Name')}</div>
            <div className='table-header-cell'>{t('lastName', 'Last Name')}</div>
            <div className='table-header-cell'>{t('username', 'User Name')}</div>
            {showTrialTime && isActive &&
              <div className='table-header-cell center'>{t('trialTimeRemaining', 'Trial Time Remaining')}</div>}
            {!hidePassword &&
              <div className='table-header-cell table-header-cell-small'>{t('password', 'Password')}</div>}
            {(userManager.publisherTextHelpActive && !userManager.showTextHelpForAll)
              ? <div className='table-header-cell table-header-cell-small'>Text Help</div> : ''}

            {!hideEdit && isActive && !readOnly && /* !isSsoUserOrHasSsoInstitution */isSelfRegClassroom && (
              <div className='table-header-cell table-header-cell-small'>{t('edit', 'Edit')}</div>
            )}
            {/* (!isSsoUserOrHasSsoInstitution || isSsoUserAndSelfRegClassroom) */isSelfRegClassroom && (
              <>
                {!readOnly && (isActive ? (
                  <div className='table-header-cell table-header-cell-small'>{t('archive', 'Archive')}</div>
                ) : (
                  <div className='table-header-cell table-header-cell-small'>{t('activate', 'Activate')}</div>
                ))}
              </>
            )}
            {!hideEdit && isActive && !readOnly && classroomManager.allowStudentDataMigrate && userManager.isTeacher && (
              <div className='table-header-cell table-header-cell-medium student-data'>{t('studentData', 'Student Data')}</div>
            )}
          </div>
          <div className='table-body'>
            <InfiniteScroll
              hasMore={classroomManager.rosterHasMore}
              initialLoad={false}
              loadMore={this.handleLoadMore}
              loader={<Loader key={0} active inline />}
              pageStart={0}
              useWindow={true}>
              {roster}
            </InfiniteScroll>
          </div>
        </div>
      </>
    );
  }
}

SatCoreRegister('ClassRosterTable', ClassRosterTable);
