import React from 'react';
import { action, computed, makeObservable, observable } from 'mobx';
import Auth from './AuthManager';

import ClassroomService from '../services/ClassroomService';
import UtilityService from '../services/UtilityService';

export class GroupsManager {
  @observable loaded = false;

  @observable groupOptions = [];
  @observable groups = []; // full groups with members collections
  tmpGroups = []; // keeps track of the previous state of groups for the more filters intricacies
  groupsChanged = false;
  @observable memberIds = [];
  memberMap = {};

  @observable sparseClassGroupMap = new Map(); // group data but no members, keyed by classroomId

  @observable groupMembersMap = new Map();

  @observable groupMemberPopupLoading = false;

  @observable currentGroupId;

  @observable totalPages = 0;

  @observable loadingGroupResourceSelector = false;
  @observable assignableGroupResourcesMap = new Map();
  @observable groupResourceSelectorCheckedChildContentItemIds = [];
  // TODO unused // @observable groupResourceSelectorExpandedChildIds = [];

  GROUP_FETCH_PAGE_SIZE = 5; // TODO add pagination for 6 columns each page;

  // Class roster
  @observable rosterMap = new Map();
  @observable groupClassroomId = null; // TODO unused

  // Assignment filter users multi select checkboxes vs. single select radio buttons
  @observable useAssignmentFilterMultiselect = false;

  constructor() {
    makeObservable(this);
  }

  @action clearAll() {
    this.assignableGroupResourcesMap.clear();
    this.clearGroups();
    this.currentGroupId = null;
    this.groupClassroomId = null; // TODO unused
    this.groupMembersMap.clear();
    this.groupOptions = [];
    this.groupsChanged = false;
    this.loaded = false;
    this.memberIds = [];
    this.memberMap = {};
    this.rosterMap.clear();
    this.sparseClassGroupMap.clear();
    this.totalPages = 0;
  }

  @action setLoaded(toggle) {
    this.loaded = toggle;
  }

  @action initGroups(groups, clearOnInit = true) {
    if (clearOnInit) {
      this.clearGroups();
      this.setGroups(groups);
    } else {
      this.setGroups(groups);
    }
  }

  @action clearGroups = () => {
    this.groups.clear();
  }

  @action addSparseGroups(classroomId, groups) {
    if (classroomId === null || classroomId === undefined || classroomId === '') {
      return;
    }
    const oldRoster = this.sparseClassGroupMap.get(classroomId);
    if (oldRoster !== null && oldRoster !== undefined) {
      oldRoster.push.apply(oldRoster, groups);
      this.sparseClassGroupMap.set(classroomId, oldRoster);
    } else {
      this.sparseClassGroupMap.set(classroomId, groups);
    }
  }

  getTotalPages = () => this.totalPages

  @action addGroup(newGroup) {
    if (this.groups) {
      const existingGroup = this.groups.find((group) => { group.id === newGroup.id; });
      if (!existingGroup) {
        this.groups.push(newGroup);
      }
    } else {
      this.groups = [newGroup];
    }
  }

  @action setGroups = (groups) => {
    const existingGroups = this.groups || [];
    this.groups = [...existingGroups, ...groups];
    this.groupsChanged = true;
  }

  // Changes to setGroups above broke report group filter so setGroupsForReport containing the original code is now used
  // by report group filter.
  @action setGroupsForReport = (groups) => {
    this.groups = groups;
    this.groupsChanged = true;
  }

  @action setGroupOptions = (options) => {
    this.groupOptions = options;
  }

  @action setGroupMemberPopupLoading = (toggle) => {
    this.groupMemberPopupLoading = toggle;
  }

  @action setCurrentGroupId = (groupId) => {
    this.currentGroupId = groupId;
  }

  @action removeGroup = (groupId) => {
    const filteredGroups = this.groups.filter((group) => group.id !== groupId);
    this.setGroups(filteredGroups);
  }

  @action setTotalPages(totalPages) {
    this.totalPages = totalPages;
  }

  @action addRoster(classroomId, roster) {
    if (classroomId === null || classroomId === undefined || classroomId === '') {
      return;
    }
    const oldRoster = this.rosterMap.get(classroomId);
    if (oldRoster !== null && oldRoster !== undefined) {
      oldRoster.push.apply(oldRoster, roster);
      this.rosterMap.set(classroomId, oldRoster);
    } else {
      this.rosterMap.set(classroomId, roster);
    }
  }

  @action
  setGroupMembers = (id, groupMembers) => {
    // console.info('typeof group id: ', typeof id);
    // console.info(`value groupMembers: ${groupMembers} typeof: ${typeof groupMembers}`);
    this.groupMembersMap.set(id, groupMembers);
  }

  @action
  setMemberIds = (memberIds) => {
    this.memberIds = memberIds;
  }

  setMemberMap = (memberMap) => {
    this.memberMap = memberMap;
  }

  // TODO observable is currently unused
  @action setGroupClassroomId = (id) => {
    if (!id || id === 'null' || id === 'undefined') {
      this.groupClassroomId = null;
    } else {
      this.groupClassroomId = id;
    }
  }

  @action getGroup = (id) => {
    if (id) {
      const group = this.groups.find((group) => group.id === id);
      if (group !== null && group !== undefined) {
        return group;
      }
    }
    return null;
  }

  @action getCurrentGroup = () => {
    if (this.currentGroupId) {
      const group = this.getGroup(this.currentGroupId);
      if (group !== null && group !== undefined) {
        return group;
      }
    }
    return null;
  }

  @action getGroupMembers = (groupId) => {
    if (groupId) {
      if (this.groupMembersMap.has(groupId)) {
        const groupMembers = (this.groupMembersMap.get(groupId)) ? this.groupMembersMap.get(groupId) : [];
        return groupMembers;
      }
    }
    return [];
  }

  @action getGroupMemberNames = (groupId) => {
    const groupMemberNames = [];
    let groupMembersString = '';
    if (groupId) {
      const groupMembers = this.getGroupMembers(groupId);
      groupMembers.forEach((groupMember) => {
        if (groupMember.id !== null) {
          groupMemberNames.push(`${groupMember.firstName} ${groupMember.lastName}`);
        }
      });
      if (groupMemberNames && groupMemberNames.length > 0) {
        groupMembersString = groupMemberNames.join(',');
      }
    }
    return groupMembersString;
  }

  @action createClassroomGroup = async (
    classroomId, groupName, groupDescription
  ) => {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/createClassroomGroup`, {
        method: 'POST',
        body: {
          name: groupName,
          description: groupDescription,
          classroomId,
          reactErrorType: true
        }
      });
      if (response) {
        if (response.status === 'SUCCESS') {
          if (response.data && response.data.id) {
            this.setCurrentGroupId(response.data.id);
          }
        }
        return response;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  @action updateClassroomGroup = async (
    groupId, groupName, groupDescription, archived = false
  ) => {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/updateClassroomGroup`, {
        method: 'POST',
        body: {
          groupId,
          name: groupName,
          description: groupDescription,
          archived,
          reactErrorType: true
        }
      });
      if (response) {
        if (response.status === 'SUCCESS') {
          if (response.data && response.data.id) {
            this.setCurrentGroupId(response.data.id);
          }
        }
        return response;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  @action deleteClassroomGroup = async (
    groupId
  ) => {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/deleteClassroomGroup`, {
        method: 'DELETE',
        body: {
          groupId,
          reactErrorType: true
        }
      });
      if (response) {
        return response;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  @action archiveClassroomGroup = async (
    groupId
  ) => {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/removeUserGroup?userGroupId=${groupId}`, {
        method: 'POST'
      });
      if (response) {
        if (response.status === 'SUCCESS') {
          // DELETE THE GROUP FROM THE CURRENT ROSTER
          // this.removeGroup(groupId);
        }
        return response;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  @action setClassroomGroupMembers = async (
    groupId, groupMembers = []
  ) => {
    let memberIds = [];
    try {
      if (Array.isArray(groupMembers)) {
        memberIds = groupMembers.join(',');
      }
      const response = await Auth.fetch(`${Auth.ecms}/api/setClassroomGroupMembers`, {
        method: 'POST',
        body: {
          groupId,
          memberIds
        }
      });
      if (response.status === 'SUCCESS') {
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  // returns full group data including members collection
  @action getFilteredGroupsByClassroom = async (
    classroomId, groupIds, archived = false, page,
    sortColumn = null, sortDirection = 'ascending',
    filters = [], pageSize = this.GROUP_FETCH_PAGE_SIZE,
    clearOnInit = true
  ) => {
    try {
      this.setLoaded(false);
      const usePage = (page - 1) * pageSize;
      let url = `${Auth.ecms}/api/viewClassroomGroupsAndMembers?&skip=${usePage}&pageSize=${pageSize}&archived=${archived}`;
      if (classroomId) {
        url += `&classroomId=${classroomId}`;
      }
      if (groupIds && groupIds.length > 0) {
        let groupIdsStr = '';
        if (!Array.isArray(groupIds) && typeof groupIds !== 'string') {
          throw new TypeError('groupIds must be a string[] || string');
        } else if (Array.isArray(groupIds)) {
          groupIdsStr = groupIds.join(',');
        } else {
          groupIdsStr = groupIds;
        }
        url += `&groupIds=${groupIdsStr}`;
      }
      // we won't be sorting groups by via this mechanism
      // if (sortColumn) {
      //   const serverDirection = sortDirection === 'ascending' ? 'asc' : 'desc';
      //   url += `&sort[0][field]=${sortColumn}&sort[0][dir]=${serverDirection}`;
      // }

      if (filters && filters.length > 0) {
        filters.forEach((filter, index) => {
          if (filter.field && filter.value) {
            const filterValue = filter.value;
            url += `&filter[filters][${index}][field]=${filter.field}&filter[filters][${index}][operator]=${filter.operator}&filter[filters][${index}][value]=${filterValue}`;
          }
        });
      }

      const response = await Auth.fetch(url,
        { method: 'GET' }
      );
      if (response.status === 'SUCCESS') {
        this.initGroups(response.data, clearOnInit);
        this.totalPages = Math.ceil(response.pageTotal / pageSize);
        if (this.groups.length < response.pageTotal) {
          this.setLoaded(true);
          return true;
        }
        this.setLoaded(true);
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  // Returns groups for the classroom without member collections
  @action getGroupsByClassroom = async (
    classroomId, archived = false
  ) => {
    try {
      let url = `${Auth.ecms}/api/viewClassroomGroups?archived=${archived}`;
      if (classroomId) {
        url += `&classroomId=${classroomId}`;
      }

      const response = await Auth.fetch(url,
        { method: 'GET' }
      );
      if (response.status === 'SUCCESS') {
        if (response.data) {
          this.sparseClassGroupMap.clear();
          this.addSparseGroups(classroomId, response.data);
          return response.data;
        } else {
          return false;
        }
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  @action fetchClassroomRoster = async (id, sortColumn = null, sortDirection = 'ascending') => {
    try {
      let url = `${Auth.ecms}/api/viewClassroomRoster?classroomId=${id}`;
      if (sortColumn) {
        const serverDirection = sortDirection === 'ascending' ? 'asc' : 'desc';
        url += `&sort[0][field]=${sortColumn}&sort[0][dir]=${serverDirection}`;
      }
      const response = await Auth.fetch(url, { method: 'GET' });
      if (response.status === 'SUCCESS') {
        this.rosterMap.clear();
        this.setGroupClassroomId(id);
        this.addRoster(id, response.rosters);
      }
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  @action fetchGroupMembers = async (groupId, classroomId) => {
    try {
      const url = `${Auth.ecms}/api/viewClassroomGroupMembers?groupId=${groupId}&classroomId=${classroomId}`;
      const response = await Auth.fetch(url, { method: 'GET' });
      if (response.status === 'SUCCESS') {
        if (response.data && response.data.length > 0) {
          this.setGroupMembers(groupId, response.data);
        }
      }
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  @action fetchGroupListMembers = async () => {
    try {
      // only get a new list of members if the list of groups has changed
      if (this.groupsChanged) {
        this.groupsChanged = false;

        const classroomId = ClassroomService.getCurrentClassroomId();
        const groupIds = this.groups.map((option) => option.value);
        const groupIdList = groupIds.join(',');
        const url = `${Auth.ecms}/api/viewClassroomGroupListMembers?groupIds=${groupIdList}&classroomId=${classroomId}`;
        const response = await Auth.fetch(url, { method: 'GET' });
        let memberIds = [];
        if (response.status === 'SUCCESS') {
          if (response.data) {
            const memberMap = response.data;
            memberIds = Object.keys(memberMap);
            this.setMemberIds(memberIds);
            this.setMemberMap(memberMap);
          }
        }
      }
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  @action clearCurrentGroup() {
    this.currentGroupId = '';
  }

  @action setLoadingGroupResourceSelector = (loading) => {
    this.loadingGroupResourceSelector = loading;
  }

  @action setAssignableGroupResourcesMap = (assignableGroupResourcesMap) => {
    this.assignableGroupResourcesMap = new Map(assignableGroupResourcesMap);
  }

  renderGroupAssignmentInfoPopupContent = (
    groupId, groupName, groupMembers, showDescription = false, showMemberList = false
  ) => {
    let group = null;
    // If we have a groupId use that to find the group, otherwise see if we have groupName passed in.
    if (groupId && !groupName) {
      group = this.getGroup(groupId);
    } else {
      let members = [];
      if (groupMembers) {
        // check members to see if it's an array
        if (Array.isArray(groupMembers)) {
          members = groupMembers;
        } else {
          const tempMembers = groupMembers.split(',');
          tempMembers.forEach((name) => {
            const nameParts = name.split(' ');
            const member = {
              firstName: nameParts[0],
              lastName: nameParts[1]
            };
            members.push(member);
          });
        }
      }
      group = {
        members,
        name: groupName
      };
    }
    return this.renderGroupInfoPopupContent(group, showDescription, showMemberList);
  }

  @action setGroupResourceSelectorCheckedChildContentItemIds = (checkedChildContentItemIds) => {
    this.groupResourceSelectorCheckedChildContentItemIds = checkedChildContentItemIds;
  }

  // TODO unused
  // @action setGroupResourceSelectorExpandedChildIds = (expandedChildIds) => {
  //   this.groupResourceSelectorExpandedChildIds = expandedChildIds;
  // }

  @computed get assignableGroupResourceContentItemIds() {
    return Array.from(this.assignableGroupResourcesMap.keys());
  }

  @computed get assignableGroupResources() {
    return Array.from(this.assignableGroupResourcesMap.values());
  }

  renderGroupInfoPopupContent = (group, showDescription = false, showMemberList = false) => {
    if (group) {
      const descriptionObj = UtilityService.reactHtmlParserWrapper(
        group.description
      );
      const nameObj = UtilityService.reactHtmlParserWrapper(
        group.name
      );
      const memberNames = [];
      if (group.members) {
        group.members.forEach((member) => {
          const memberNameObj = UtilityService.reactHtmlParserWrapper(
            `${member.firstName} ${member.lastName}`
          );
          memberNames.push(memberNameObj.parsed);
        });
      }
      return (
        <div className='groupInfoPopupContent'>
          <div className='groupName'>{nameObj.parsed}</div>
          {showDescription &&
            <div className='groupDescription'>{descriptionObj.parsed}</div>}
          {showMemberList &&
            <div className='groupMembers'>{memberNames.join(', ')}</div>}
        </div>
      );
    } else {
      return ('');
    }
  }
}

export default new GroupsManager();
