import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Image, Popup, Segment } from 'semantic-ui-react';

import '../css/CourseTreeCard.less';

import chapterDefaultCardImage from '../img/default-chapter.svg';
import adminChapterDefaultCardImage from '../img/course-view-unit-image.svg';

import { customUnitDefaultCardImageUri } from '../services/UriService';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { CONTENT_ITEM_TYPES } from '../managers/ContentManager';

import GroupService from '../services/GroupService';
import PopupService from '../services/PopupService';
import ResourcePacingService from '../services/ResourcePacingService';
import UtilityService from '../services/UtilityService';

import InputModal from './dialogs/InputModal';

export default
@inject('courseManager', 'productManager', 'userManager')
@observer
class CourseTreeBranch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courseElement: props.courseElement,
      isInputModalOpen: false,
      renamedCourseUnitName: null
    };
    this.CautionIcon = SatCoreComponent('CautionIcon');
    this.PopupButton = SatCoreComponent('PopupButton');
    this.ResourcePacingToolbar = SatCoreComponent('ResourcePacingToolbar');
  }

  handleClickRename = async (_courseElement) => {
    this.setState({ isInputModalOpen: true });
  }

  doesNotHaveDuplicateCourseName = async () => true

  handleSaveRenameCourseElement = async (save, name, subtitle) => {
    if (save) {
      const { courseManager, courseElement, refreshCourseData } = this.props;
      const newCourseElement = { ...courseElement };
      const urlParams = new URLSearchParams(window.location.search);
      const courseId = urlParams.get('courseId');
      newCourseElement.contentItemId = courseId || courseManager.currentCourseId;
      newCourseElement.name = name;
      newCourseElement.description = subtitle;
      await courseManager.updateCourseElementDetails(newCourseElement);

      // refresh the tree to reflect the new name.
      refreshCourseData();

      this.setState({
        courseElement: newCourseElement,
        isInputModalOpen: false,
        renamedCourseUnitName: name
      });
    } else {
      this.setState({
        isInputModalOpen: false
      });
    }
  }

  renderImage = (branchCardImageClassNames, defaultBranchCardImg) => {
    const { courseElement } = this.state;
    if (courseElement.attachmentContentItemId) {
      return (
        <div className='branch-card-image with-image'>
          <Image
            src={CONTENT_ITEM_TYPES.getContentIdImageUrl(
              courseElement.attachmentContentItemId)}
            wrapped />
        </div>
      );
    }
    return (
      <Image
        className={`branch-card-image ${branchCardImageClassNames}`}
        src={defaultBranchCardImg}
        wrapped />
    );
  }

  renderButton = ({
    basic = false,
    buttonLabel = '',
    disabled = false,
    onClick = () => {},
    popupMsg = undefined,
    primary = true
  } = {}) => {
    const { PopupButton } = this;
    const { t } = this.props;
    return (
      <PopupButton
        basic={basic}
        buttonLabel={t(buttonLabel)}
        disabled={disabled}
        onClick={onClick}
        popupMsg={popupMsg && t(popupMsg)}
        primary={primary} />
    );
  }

  render() {
    const { CautionIcon, ResourcePacingToolbar } = this;
    const {
      courseManager, handleAddAssignment, handleClick,
      productManager, showAdminControls, showAdminIcons, unitOrderCount, userManager, t
    } = this.props;
    const { isFromProduct } = productManager;
    const { courseElement, isInputModalOpen, renamedCourseUnitName } = this.state;

    if (!courseElement) {
      return null;
    }

    const isCustomCourse = courseManager.isCustomCourse();

    let courseName;
    if (renamedCourseUnitName) {
      courseName = renamedCourseUnitName;
    } else {
      courseName = courseManager.getUnitSectionName(courseElement, unitOrderCount);
    }
    const unitSectionNameObj = UtilityService.reactHtmlParserWrapper(courseName);
    const hasDescription = courseElement && (courseElement.description || courseElement.contentItemDescription);
    const description = courseElement.description || courseElement.contentItemDescription;
    const courseElementDescriptionObj = UtilityService.reactHtmlParserWrapper(
      description
    );

    let courseTreeBranchDescriptionJsx = (
      <div
        className='branch-description-text subtitle'>
        {hasDescription ? courseElementDescriptionObj.parsed : null}
      </div>
    );

    const trigger = courseTreeBranchDescriptionJsx;
    const popup = (
      <Popup
        content={courseElementDescriptionObj.parsed}
        hoverable
        on='hover'
        position='bottom left'
        positionFixed
        trigger={trigger}
        wide='very' />
    );
    courseTreeBranchDescriptionJsx = popup;

    let branchCardImageClassNames = '';
    let defaultBranchCardImg;
    if (showAdminIcons || showAdminControls) {
      branchCardImageClassNames = 'custom-default-special';
      defaultBranchCardImg = courseElement.attachmentContentItemId ? CONTENT_ITEM_TYPES.getContentIdImageUrl(courseElement.attachmentContentItemId, 'workspaceImages') : adminChapterDefaultCardImage;
    } else if (isCustomCourse) {
      if (!courseManager.useSpecialCustomCourseCardImages) {
        branchCardImageClassNames += 'custom-default';
        defaultBranchCardImg = chapterDefaultCardImage;
      } else if (courseManager.useSpecialCustomCourseCardImages) {
        branchCardImageClassNames += 'custom-default-special';
        defaultBranchCardImg = customUnitDefaultCardImageUri;
      }
    } else {
      branchCardImageClassNames = 'default';
      defaultBranchCardImg = chapterDefaultCardImage;
    }
    const isVisible = ResourcePacingService.visibilityMode(courseElement) === 'visible_to_students';
    const { isStudent, isTeacher, isDistrictOrSchoolAdmin } = userManager;
    return (
      <Segment
        key={`branch_card_${courseElement.id}`}
        className='branch-card-satcore list-card'>
        <div className={`branch-card-image-wrapper ${branchCardImageClassNames}`}>
          {this.renderImage(branchCardImageClassNames, defaultBranchCardImg)}
        </div>
        <div className='card-body'>
          <div className='course-branch-card-title-wrapper'>
            <div className='course-branch-card-title-name'>
              {PopupService.renderPopup({
                content: unitSectionNameObj.parsed,
                position: 'top left',
                trigger: (
                  <div
                    className='branch-name clickable'
                    onClick={() => { isVisible && handleClick(courseElement); }}>
                    {unitSectionNameObj.parsed}
                  </div>
                )
              })}
            </div>
            {!!courseElement.licenseExpired && (
              !userManager.isStudent || productManager.allowShowExpiredLicenseWarningForStudent
            ) && (
              <div className='course-branch-card-title-caution-icon'>
                <CautionIcon expiredLicenseCautionIcon />
              </div>
            )}
          </div>
          {courseTreeBranchDescriptionJsx}

          {isTeacher && (
            <div className='resource-pacing-toolbar-outer-wrapper'>
              <ResourcePacingToolbar
                // eslint-disable-next-line react/destructuring-assignment
                callback={this.props.handleResourcePacingToolbarCallback}
                {...this.props}
                resource={courseElement} />
            </div>
          )}
        </div>

        <div className='card-nav-buttons'>
          {this.renderButton({
            buttonLabel: 'open',
            disabled: !isVisible,
            onClick: () => { handleClick(courseElement); },
            popupMsg: !isVisible && t('hiddenMsg')
          })}

          {/* TODO hidden for now per DEMO-1913 */}
          {GroupService.isGroupResource(courseElement.groupingMode) && !isStudent && isVisible && !isFromProduct && (
            this.renderButton({
              buttonClassNames: 'btn-assign-all',
              buttonLabel: 'assignAll',
              onClick: () => {
                handleAddAssignment(courseElement, courseElement.imageUrl, {
                  allowMultipleResources: true
                });
              }
            })
          )}

          {isCustomCourse && !isStudent && this.renderButton({
            basic: true,
            buttonLabel: 'rename',
            disabled: !isVisible,
            onClick: () => { this.handleClickRename(courseElement); },
            popupMsg: !isVisible && t('hiddenMsg')
          })}

          {isDistrictOrSchoolAdmin && showAdminControls && this.renderButton({
            basic: true,
            buttonLabel: t('editOptionLabel'),
            disabled: !isVisible,
            onClick: () => { this.handleClickRename(courseElement); },
            popupMsg: !isVisible && t('hiddenMsg')
          })}
        </div>

        {isInputModalOpen && !isStudent && (
          <InputModal
            bannerLabel={showAdminControls ? t('editUnitLabel', 'Edit Unit') : 'Rename'}
            cancelLabel='Cancel'
            isOpen={isInputModalOpen}
            isValidName={this.doesNotHaveDuplicateCourseName}
            name={courseName}
            nameLabel='Name'
            onSave={(save, name, subtitle) => this.handleSaveRenameCourseElement(save, name, subtitle)}
            saveLabel='Save'
            subtitle={courseElement.description}
            subtitleLabel={showAdminControls ? 'Description' : 'Subtitle'}
            {...this.props} />
        )}
      </Segment>
    );
  }
}

SatCoreRegister('CourseTreeBranch', CourseTreeBranch);
