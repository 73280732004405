import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import classNames from 'classnames';

import { Checkbox, Container, Loader } from 'semantic-ui-react';

import '../css/Class.less';
import '../css/CourseListing.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { VIEW_SELECTION } from '../managers/NavigationManager';

import CourseService from '../services/CourseService';
import ClassroomService from '../services/ClassroomService';

export default
@inject(
  'assignmentManager', 'courseDeliveryManager', 'courseManager',
  'navigationManager', 'productManager', 'userManager'
)
@observer
class ClassDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingClassDetails: true
    };

    this.BreadCrumbsCourseDropdown = SatCoreComponent('BreadCrumbsCourseDropdown');
    this.CourseLayout = SatCoreComponent('CourseLayout');
    this.CourseListBanner = SatCoreComponent('CourseListBanner');
  }

  async componentDidMount() {
    await this.initClassDetails();
  }

  initClassDetails = async ({
    courseElementId = undefined,
    courseId = undefined
  } = {}) => {
    this.setState({ isLoadingClassDetails: true });

    const {
      courseManager, navigationManager, productManager
    } = this.props;

    const urlParams = new URLSearchParams(window.location.search);
    const view = urlParams.get('view');
    if (view && !productManager.FROM_TEACHER_PRODUCTS_NAV) {
      navigationManager.setView(urlParams.get('view'));
    } else {
      navigationManager.setView(VIEW_SELECTION.BOOK);
    }

    const previewAsStudent = urlParams.get('previewAsStudent');
    if (previewAsStudent) {
      courseManager.setPreviewAsStudent(true);
    }

    // ensure currentClassroomId is initialized
    ClassroomService.getCurrentClassroomId({ alsoUpdateObservableWithUrlParamIfNull: true });

    if (urlParams.has('courseId')) {
      if (courseManager.currentCourseId === null) {
        courseManager.setCurrentCourseId(urlParams.get('courseId'));
      }
    }

    if (productManager.isFromProduct) {
      await CourseService.initCourseDataProduct();
    } else {
      await CourseService.initCourseData(true, true, true, {
        courseElementId, courseId
      });
    }

    setTimeout(() => {
      this.setState({ isLoadingClassDetails: false });
    }, 500);
  }

  handleToggleHiddenResources = (_event, data) => {
    const shouldShowHiddenResources = data.checked;
    const { courseDeliveryManager } = this.props;
    courseDeliveryManager.setShouldShowHiddenResources(shouldShowHiddenResources);
  }

  render() {
    const {
      assignmentManager, courseDeliveryManager, courseManager,
      history, productManager, t, userManager
    } = this.props;

    const { isLoadingClassDetails } = this.state;

    const { activePermissionId } = userManager;
    const { BreadCrumbsCourseDropdown, CourseLayout, CourseListBanner } = this;
    const isCustomCourse = courseManager.isCustomCourse(courseManager.currentCourseId);

    const { FROM_TEACHER_PRODUCTS_NAV } = productManager;

    // eslint-disable-next-line react/destructuring-assignment
    const zeroState = this.props.zerostate || (
      <div className='no-courses'>
        {!isCustomCourse ? 'There are no items in this Unit/Chapter'
          : t('customAssessmentZeroStateMsg', 'There are no assessments added yet.')}
      </div>
    );

    return courseManager.isTreeLoading || isLoadingClassDetails ? <Loader active /> : (
      <Container className={classNames('class-detail', {
        'teacher-product-class-detail': FROM_TEACHER_PRODUCTS_NAV
      })} fluid>
        <CourseListBanner
          hideAddCourseButton={!FROM_TEACHER_PRODUCTS_NAV}
          hideAddStudentButton={true}
          hideHeader={true}
          history={history}
          resetClassDetails={this.initClassDetails}
          showBreadCrumbs='bottom'
          showBreadCrumbsDropdown='bottom'
          showCourseSearchButton={assignmentManager.enableCourseSearch}
          title={t('BannerTitle', 'No Class Courses Banner Title')} />
        {(courseManager.currentCourseId !== null
          && courseManager.currentCourseElementList.length > 0) ? (
            <Container
              className={classNames(`class-content resources-container class-details-inner-container ${activePermissionId}`, {
                'has-course-search-btn': assignmentManager.enableCourseSearch
              })} fluid>
              {!courseManager.isTreeLoading && (
                <div className='class-details-header'>
                  <div className='class-details-header-left'>
                    {/* marked as 'display: none' by default */}
                    <BreadCrumbsCourseDropdown className='top' history={history} />
                  </div>
                  <div className='class-details-header-mid'>
                    {/* placeholder */}
                  </div>
                  <div className='class-details-header-right'>
                    {!userManager.isStudent && courseDeliveryManager.showResourcePacingToolbar && (
                      <div className='toggle-hidden-resources-wrapper'>
                        <div className='hidden-resources-toggler-label'>
                          {t('showHiddenResources', 'Show hidden resources')}
                        </div>
                        <Checkbox
                          className='hidden-resources-toggler'
                          defaultChecked={courseDeliveryManager.shouldShowHiddenResources}
                          onChange={this.handleToggleHiddenResources}
                          toggle />
                      </div>
                    )}
                  </div>
                </div>
              )}
              <CourseLayout history={history} />
            </Container>
          ) : !courseManager.isTreeLoading && !courseManager.currentCourseElementList.length && !isLoadingClassDetails && (
            <Container className='class-content'>
              {zeroState}
            </Container>
          )}
      </Container>
    );
  }
}

SatCoreRegister('ClassDetails', ClassDetails);
