/* eslint-disable sort-keys */

export const ADMIN_TOP_NAV = {
  schools: {
    _id: 'schools',
    className: 'schools',
    functionKey: 'handleClickSchools',
    navTranslationKey: 'DashboardLabel',
    navTranslationRolloverKey: 'DashboardRollover',
    viewSelectionKey: 'DASHBOARD'
  },
  users: {
    _id: 'users',
    className: 'users',
    functionKey: 'handleClickUsers',
    navTranslationKey: 'UsersLabel',
    navTranslationRolloverKey: 'UsersRollover',
    viewSelectionKey: 'USERS'
  },
  products: {
    _id: 'products',
    className: 'products',
    functionKey: 'handleClickProducts',
    navTranslationKey: 'productsLabel',
    navTranslationRolloverKey: 'productsRollover',
    viewSelectionKey: 'PRODUCTS'
  },
  libraryContent: {
    _id: 'libraryContent',
    className: 'content',
    functionKey: 'handleClickContent',
    navTranslationKey: 'contentLabel',
    navTranslationRolloverKey: 'contentRollover',
    viewSelectionKey: 'CONTENT'
  },
  classrooms: {
    _id: 'classrooms',
    className: 'classes',
    functionKey: 'handleClickClassrooms',
    navTranslationKey: 'classesLabel',
    navTranslationRolloverKey: 'classesRollover',
    viewSelectionKey: 'CLASSROOMS'
  },
  testBuilder: {
    _id: 'testBuilder',
    className: 'library',
    functionKey: 'handleClickTestBuilder',
    navTranslationKey: 'testBuilderLabel',
    navTranslationRolloverKey: 'testBuilderRollover',
    viewSelectionKey: 'TEST_BUILDER'
  },
  reports: {
    _id: 'reports',
    className: 'reports',
    functionKey: 'handleClickReports',
    navTranslationKey: 'ReportsLabel',
    navTranslationRolloverKey: 'ReportsRollover',
    viewSelectionKey: 'REPORTS'
  }
};

export const DISTRICT_ADMIN_TOP_NAV = ADMIN_TOP_NAV;

export const SCHOOL_ADMIN_TOP_NAV = ADMIN_TOP_NAV;

export const TEACHER_TOP_NAV = {
  classrooms: {
    _id: 'classrooms',
    className: 'dashboard',
    functionKey: 'showClasses',
    navTranslationKey: 'DashboardLabel',
    navTranslationRolloverKey: 'DashboardRollover',
    viewSelectionKey: 'DASHBOARD'
  },
  libraryContent: {
    _id: 'libraryContent',
    className: 'content',
    functionKey: 'showContent',
    navTranslationKey: 'libraryContentLabel',
    navTranslationRolloverKey: 'libraryContentRollover',
    viewSelectionKey: 'CONTENT'
  },
  teacherProducts: {
    _id: 'teacherProducts',
    className: 'products',
    functionKey: 'showProducts',
    navTranslationKey: 'ProductLabel',
    navTranslationRolloverKey: 'ProductRollover',
    viewSelectionKey: 'PRODUCTS'
  },
  assignments: {
    _id: 'assignments',
    className: 'assignments',
    functionKey: 'showAssignments',
    navTranslationKey: 'AssignmentLabel',
    navTranslationRolloverKey: 'AssignmentRollover',
    viewSelectionKey: 'ASSIGNMENTS'
  },
  testBuilder: {
    _id: 'testBuilder',
    className: 'library',
    functionKey: 'handleClickTestBuilder',
    navTranslationKey: 'testBuilderLabel',
    navTranslationRolloverKey: 'testBuilderRollover',
    viewSelectionKey: 'TEST_BUILDER'
  },
  reports: {
    _id: 'reports',
    className: 'reports',
    functionKey: 'showReports',
    navTranslationKey: 'ReportLabel',
    navTranslationRolloverKey: 'ReportRollover',
    viewSelectionKey: 'REPORTS'
  }
};

export const STUDENT_TOP_NAV = {
  dashboard: {
    _id: 'dashboard',
    className: 'dashboard',
    functionKey: 'showDashboard',
    navTranslationKey: 'DashboardLabel',
    navTranslationRolloverKey: 'DashboardRollover',
    viewSelectionKey: 'DASHBOARD'
  },
  assignments: {
    _id: 'assignments',
    className: 'assignments',
    functionKey: 'showAssignments',
    navTranslationKey: 'AssignmentLabel',
    navTranslationRolloverKey: 'AssignmentRollover',
    viewSelectionKey: 'ASSIGNMENTS'
  },
  progress: {
    _id: 'progress',
    className: 'progress',
    functionKey: 'showProgress',
    navTranslationKey: 'ProgressLabel',
    navTranslationRolloverKey: 'ProgressRollover',
    viewSelectionKey: 'PROGRESS'
  },
  courses: {
    _id: 'courses',
    className: 'books',
    functionKey: 'showBook',
    navTranslationKey: 'BookLabel',
    navTranslationRolloverKey: 'BookRollover',
    viewSelectionKey: 'BOOK'
  },
  workspace: {
    _id: 'workspace',
    className: 'workspace',
    functionKey: 'showWorkspace',
    navTranslationKey: 'WorkspaceLabel',
    navTranslationRolloverKey: 'WorkspaceRollover',
    viewSelectionKey: 'WORKSPACE'
  }
};

export const DEFAULT_ADMIN_TOP_NAV_ORDER_ARRAY = [
  'schools', 'users', 'products', 'libraryContent', 'classrooms', 'testBuilder', 'reports'
];
export const DEFAULT_DISTRICT_ADMIN_TOP_NAV_ORDER_ARRAY = (
  DEFAULT_ADMIN_TOP_NAV_ORDER_ARRAY
);
export const DEFAULT_SCHOOL_ADMIN_TOP_NAV_ORDER_ARRAY = (
  DEFAULT_ADMIN_TOP_NAV_ORDER_ARRAY
);
export const DEFAULT_TEACHER_TOP_NAV_ORDER_ARRAY = [
  'classrooms', 'libraryContent', 'teacherProducts', 'assignments', 'testBuilder', 'reports'
];
export const DEFAULT_STUDENT_TOP_NAV_ORDER_ARRAY = [
  'dashboard', 'assignments', 'progress', 'courses', 'workspace'
];
