import React, { useContext, useEffect, useState } from 'react';

import { MobXProviderContext, observer } from 'mobx-react';

import InfiniteScroll from 'react-infinite-scroller';

import { Grid, Input, Loader } from 'semantic-ui-react';

import '../css/ClassCourses.less';
import '../css/TeacherProductView.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import Auth from '../managers/AuthManager';

import TeacherProductViewService from '../services/TeacherProductViewService';

import ZeroStateCard from '../components/ZeroStateCard';

const TeacherProductView = observer((props) => {
  const { history, t } = props;

  const {
    classroomManager, productManager, teacherProductViewManager
  } = useContext(MobXProviderContext);

  const [loadingTeacherProductView, setLoadingTeacherProductView] = useState(true);
  const [loadingTeacherProductCourseCards, setLoadingTeacherProductCourseCards] = useState(false);
  const [loadingTeacherProductCourseCardsInfiniteScroll, setLoadingTeacherProductCourseCardsInfiniteScroll] = useState(true);

  const [teacherProductCourseSearchText, setTeacherProductCourseSearchText] = useState('');
  const [teacherProductCourseSearchTextTimeout, setTeacherProductCourseSearchTextTimeout] = useState(null);

  const CourseCard = SatCoreComponent('CourseCard');
  const CourseListBanner = SatCoreComponent('CourseListBanner');
  const ProductDropdown = SatCoreComponent('ProductDropdown');
  const SatCoreLoader = SatCoreComponent('SatCoreLoader');
  const TeacherProductListener = SatCoreComponent('TeacherProductListener');

  useEffect(() => {
    (async () => {
      setLoadingTeacherProductView(true);

      classroomManager.setCurrentClassroomId(null);

      teacherProductViewManager.setCurrentTeacherProductCourse(null);

      productManager.setIsFromProduct('FROM_TEACHER_PRODUCTS_NAV');
      classroomManager.setCurrentClassroomId('FROM_TEACHER_PRODUCTS_NAV');

      productManager.setSelectedUserProductId('allProducts');

      productManager.setShouldFetchLicensedProducts(true);
      await initTeacherProductBreadcrumbs();

      productManager.clearProductResources();
      productManager.setProductResourcesPageTotal(null);

      setTimeout(() => {
        setLoadingTeacherProductView(false);
        setLoadingTeacherProductCourseCardsInfiniteScroll(false);
      }, 500);
    })();
  }, []);

  const initTeacherProductBreadcrumbs = async () => {
    await TeacherProductViewService.initBaseTeacherProductBreadcrumbs();
  };

  const fetchProductCourses = async ({ page }) => {
    if (!loadingTeacherProductCourseCardsInfiniteScroll) {
      setLoadingTeacherProductCourseCardsInfiniteScroll(true);
      await TeacherProductViewService.fetchProductResourcesByInstitution({ page, searchText: teacherProductCourseSearchText });
      setLoadingTeacherProductCourseCardsInfiniteScroll(false);
    }
  };

  const handleViewCourse = (course) => {
    teacherProductViewManager.setCurrentTeacherProductCourse(course);
    history.push(`/publisherCourse?courseContentItemId=${course.id}&authKey=${Auth.authKey}&fromTeacherProductsNav=true`);
  }

  const handleAddCourseToClass = (_event, course) => {
    teacherProductViewManager.setCurrentTeacherProductCourse(course);
    teacherProductViewManager.setIsClassroomSelectionModalOpen(true);
  }

  const handleChangeTeacherProduct = (_event, _data) => {
    setLoadingTeacherProductCourseCardsInfiniteScroll(true);
    setLoadingTeacherProductView(true);
    productManager.clearProductResources();
    productManager.setProductResourcesPageTotal(null);
    setTimeout(() => {
      setLoadingTeacherProductView(false);
      setLoadingTeacherProductCourseCardsInfiniteScroll(false);
    }, 500);
  }

  const handleChangeSearch = (_event, obj) => {
    const searchText = obj?.value || '';

    setLoadingTeacherProductCourseCardsInfiniteScroll(true);
    setLoadingTeacherProductCourseCards(true);

    productManager.clearProductResources();
    productManager.setProductResourcesPageTotal(null);

    setTeacherProductCourseSearchText(searchText);
    setTeacherProductCourseSearchTextTimeout(clearTimeout(teacherProductCourseSearchTextTimeout));
    setTeacherProductCourseSearchTextTimeout(setTimeout(() => {
      if (!searchText || searchText.length >= 1) {
        setLoadingTeacherProductCourseCards(false);
        setLoadingTeacherProductCourseCardsInfiniteScroll(false);
      }
    }, 1000));
  };

  const renderTeacherProductView = () => {
    return (
      <div className='TeacherProductView'>
        {renderCourseListBanner()}
        <div className='teacher-product-view-body'>
          {renderTeacherProductNavRow()}
          {renderTeacherProductCourseCards()}
        </div>
        <TeacherProductListener />
      </div>
    );
  };

  const renderCourseListBanner = () => {
    return (
      <CourseListBanner
        hideAddCourseButton
        hideAddStudentButton
        hideBackgroundImage
        hideHeader
        history={history}
        showBreadCrumbs='bottom' />
    );
  };

  const renderTeacherProductNavRow = () => {
    return (
      <div className='teacher-product-nav-row'>
        <div className='teacher-product-nav-row-left'>
          {renderProductDropdown()}
        </div>
        <div className='teacher-product-nav-row-center'>
          {/* placeholder */}
        </div>
        <div className='teacher-product-nav-row-right'>
          {renderProductCourseSearchBox()}
        </div>
      </div>
    );
  };

  const renderProductDropdown = () => {
    return (
      <ProductDropdown {...props} onChange={handleChangeTeacherProduct} />
    );
  };

  const renderProductCourseSearchBox = () => {
    return (
      <div className='product-course-search-box'>
        <Grid>
          <Grid.Column>
            <Input
              autoFocus={!!teacherProductCourseSearchText}
              icon='search'
              name='search'
              onChange={handleChangeSearch}
              placeholder={t('searchCourseName')}
              type='text'
              value={teacherProductCourseSearchText} />
          </Grid.Column>
        </Grid>
      </div>
    );
  };

  const renderTeacherProductCourseCards = () => {
    let showZeroStateCardIfApplicable;
    if (!loadingTeacherProductView && !loadingTeacherProductCourseCards && !loadingTeacherProductCourseCardsInfiniteScroll &&
      (!productManager.productResources || !productManager.productResources?.length)
    ) {
      showZeroStateCardIfApplicable = (
        <ZeroStateCard {...props}
          style={{ margin: '16px 0 0 0' }}
          translationKey='noDataToDisplay' />
      );
    };

    return !loadingTeacherProductCourseCards ? (
      <div className='teacher-product-course-cards'>
        <InfiniteScroll
          hasMore={!loadingTeacherProductCourseCardsInfiniteScroll && productManager.productResourcesHasMore}
          initialLoad={true}
          loadMore={(page) => fetchProductCourses({ page })}
          pageStart={0}
          useWindow={true}>
          {productManager.productResources?.map((course) => {
            return (
              <CourseCard
                key={`course_${course.contentItemId}`}
                course={{
                  ...course,
                  id: course.contentItemId,
                  name: course.contentItemName
                }}
                handleCardClick={handleViewCourse}
                handleAddCourseToClass={handleAddCourseToClass}
                id={course.contentItemId}
                imageUrl={course.imageUrl}
                openKey={'readOnlyPreview'} />
            );
          })}
        </InfiniteScroll>
        <Loader active={loadingTeacherProductCourseCardsInfiniteScroll} inline />
        {showZeroStateCardIfApplicable}
      </div>
    ) : null;
  };
  return !loadingTeacherProductView ? renderTeacherProductView() : <SatCoreLoader />;
});

export default TeacherProductView;

SatCoreRegister('TeacherProductView', TeacherProductView);
