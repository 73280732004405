import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import {
  Button,
  Form,
  Grid,
  Header,
  Input,
  Message,
  Segment
} from 'semantic-ui-react';

import '../css/Login.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import Auth from '../managers/AuthManager';

import navigationManager, { VIEW_SELECTION } from '../managers/NavigationManager';

import DynamicSatelliteService from '../services/DynamicSatelliteService';

export default @inject(
  'dynamicSatelliteManager', 'userManager')
@observer
class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      fail: false,
      loaded: false,
      success: false
    };
    this.Logo = SatCoreComponent('Logo');
    this.SatCoreLoader = SatCoreComponent('SatCoreLoader');
  }

  async componentDidMount() {
    this.setState({ loaded: false });

    const { dynamicSatelliteManager } = this.props;

    navigationManager.setView(VIEW_SELECTION.LOGIN);

    if (dynamicSatelliteManager.isDynamicSatellite && !Auth.publisherSatelliteCode) {
      await DynamicSatelliteService.fetchDynamicSatelliteByDomain();
      setTimeout(() => {
        this.setState({ loaded: true });
      }, 500);
    } else {
      this.setState({ loaded: true });
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    this.setState(
      {
        [e.target.name]: e.target.value
      }
    );
  }

  handleSendEmail = async (event) => {
    event.preventDefault();
    this.setState({ success: false, fail: false });

    const result = await this.props.userManager.sendResetEmail(this.state.email);
    if (result) {
      this.setState({ success: true, fail: false });
    } else {
      this.setState({ success: false, fail: true });
    }
  }

  render() {
    if (Auth.loggedIn()) {
      return <Redirect to='/' />;
    }
    const { Logo, SatCoreLoader } = this;
    const { loaded } = this.state;

    return loaded ? (
      <Grid className='fullHeight login-body forgot-password-body' textAlign='center' verticalAlign='middle'>
        <Grid.Column>
          <Form>
            <Header as='h2' attached='top' block>
              <Logo />
              <span className='header-text'>Reset Password</span>
            </Header>
            <div className='login-divider' />
            <Segment attached className='element-body-login'>
              <Form.Field>
                <label>Email</label>
                <Input
                  name='email'
                  onChange={this.handleChange}
                  placeholder='name@email.com'
                  type='email' />
              </Form.Field>
              <Form.Field>
                <Button className='reset' onClick={this.handleSendEmail} primary>
                  Send Reset Email
                </Button>
              </Form.Field>
            </Segment>
          </Form>
          {(this.state.success)
            ? (
              <Message positive>
                <Message.Header>Check your email</Message.Header>
                <p>
                  If a profile is associated with that email, you will receive a link to reset your password.
                </p>
              </Message>
            ) : null}
          {(this.state.fail) ? (
            <Message negative>
              <Message.Header>Something went wrong</Message.Header>
              <p>
                Resetting your password has failed.  Please check your email and try again.
              </p>
            </Message>
          ) : null}
        </Grid.Column>
      </Grid>
    ) : <SatCoreLoader />;
  }
}

SatCoreRegister('ForgotPassword', ForgotPassword);
