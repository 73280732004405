import React from 'react';
import { Button, Header, Modal, Popup } from 'semantic-ui-react';
import ReactHtmlParser from 'react-html-parser';
import { SatCoreRegister } from '../../SatCoreRegistry';
import UtilityService from '../../services/UtilityService';

import '../../css/ModalBanner.less';

const ModalBanner = (props) => {
  const { label, title, onClose, titleContainer, infoPopup } = props;

  // We need the label and title to be one unit as they both can vary by length and so need the browser
  // to calculate the whole length to get ellipsis to work correctly.
  const bannerLabel = label ? UtilityService.reactHtmlParserWrapper(label).parsedNoPWrap : '';
  let bannerTitle = title ? UtilityService.reactHtmlParserWrapper(title).parsedNoPWrap : null;

  // show charater entities correctly - for example, $ndash; was literally showing as $ndash; rather than a -
  const transformFn = UtilityService.reactHtmlParserTransform;
  if (bannerTitle) {
    bannerTitle = ReactHtmlParser(bannerTitle, { transform: transformFn });
  }

  const processedTitle = (
    <span>
      <span>{bannerLabel}</span>
      <span>{' '}</span>
      {bannerTitle &&
        <span>{bannerTitle}</span>}
    </span>
  );

  return (
    <Modal.Header className='modal-header'>
      <Header.Content className='modal-header-bar'>
        {bannerTitle ? (
          <span className='modal-title-container' {...titleContainer}>
            <span className='modal-banner-title-container'>
              <Popup
                content={bannerTitle}
                on='hover'
                position='top right'
                trigger={(
                  <span className='modal-banner-title'>
                    {processedTitle}
                  </span>
              )}
                wide />
              <span className='info-popup'>
                {infoPopup &&
                  infoPopup}
              </span>
            </span>
          </span>
        ) : (
          <div className='modal-banner-title' {...titleContainer}>
            {bannerLabel}
          </div>
        )}
        <Button
          aria-label='Close modal'
          basic
          className='btn-modal-banner-close'
          icon='close'
          onClick={onClose}
          size='huge' />
      </Header.Content>
    </Modal.Header>
  );
};

export default ModalBanner;

SatCoreRegister('ModalBanner', ModalBanner);
