import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import ReactHtmlParser from 'react-html-parser';

import {
  Button,
  Image,
  Item,
  Loader,
  Popup,
  Segment
} from 'semantic-ui-react';

import iconClosed from '../img/icon-closed.svg';
import iconCompleted from '../img/icon-completed.svg';
import iconLocked from '../img/icon-locked.svg';
import iconReady from '../img/icon-ready.svg';
import iconStarted from '../img/icon-started.svg';
import info from '../img/group-1583.svg';
import iconExpired from '../img/icon-past-due-alert.svg';
import iconExpiring from '../img/icon-expiring-alert.svg';

import assessmentDefaultCardImage from '../img/default-assessment.svg';
import { customAssessmentDefaultCardImageUri } from '../services/UriService';

import '../css/AssignmentCard.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { ASSIGNMENT_STATUS, ASSIGNMENT_TYPE } from '../managers/AssignmentManager';

import AssignmentService from '../services/AssignmentService';
import CourseService from '../services/CourseService';
import UtilityService from '../services/UtilityService';

import CardTabLabel from './CardTabLabel';

export default
@inject(
  'assignmentManager',
  'courseManager',
  'groupsManager',
  'productManager',
  'reportIdentityManager',
  'userManager')
@observer
class AssignmentCard extends Component {
  constructor(props) {
    super(props);
    this.CautionIcon = SatCoreComponent('CautionIcon');
    this.PopupButton = SatCoreComponent('PopupButton');
    this.StandardsList = SatCoreComponent('StandardsList');
  }

  getDefaultImage(useExpanded = '') {
    const { courseManager } = this.props;
    const isCustomCourse = courseManager.isCustomCourse(courseManager.currentCourseId);

    let className = 'default';
    let cardImg = assessmentDefaultCardImage;

    if (courseManager.useSpecialCustomCourseCardImages && isCustomCourse) {
      className = 'custom-default-special';
      cardImg = customAssessmentDefaultCardImageUri;
    }
    return <Image alt='Lesson default image' className={`${className} ${useExpanded}`} src={cardImg} />;
  }

  formatTime = (str) => {
    str = str.replace(/\s/g, '').replace(/^0+/, '');
    return str.toLowerCase();
  }

  handlePresent = async (params) => {
    const { handlePresent } = this.props;
    handlePresent(params);
  }

  popup = (assignment) => {
    const { assignmentManager, groupsManager, t } = this.props;
    if (assignment.totalActivityInstances > 0) {
      return (
        <>
          <div className='card-label'>
            {assignmentManager.getTranslatedAssignmentTypeFlag(assignment)}
            :
          </div>
          {(assignment.subdomainTypeId && assignment.subdomainTypeId === ASSIGNMENT_TYPE.GROUP) && (
            <Popup
              hideOnScroll={false}
              hoverable={true}
              on='hover'
              position='bottom left'
              trigger={
                <div className='card-value-sm card-value blue clickable'>{assignment.totalActivityInstances}</div>
              }
              wide>
              <>
                {groupsManager.renderGroupAssignmentInfoPopupContent(
                  null, assignment.groupName, assignment.groupStudentNames, false, true
                )}
              </>
            </Popup>
          )}
          {(!assignment.subdomainTypeId || assignment.subdomainTypeId !== ASSIGNMENT_TYPE.GROUP) && (
            <Popup
              hideOnScroll={false}
              hoverable={true}
              on='hover'
              onOpen={() => {
                assignmentManager.assignmentMemberPopupLoading = true;
                assignmentManager.clearAssignmentMembers().then(() => {
                  const forceLoad = true;
                  assignmentManager.fetchAssignmentMembers(assignment.id, forceLoad).then(() => {
                    assignmentManager.assignmentMemberPopupLoading = false;
                  });
                });
              }}
              position='bottom left'
              trigger={
                <div className='card-value-sm card-value blue clickable'>{assignment.totalActivityInstances}</div>
              }
              wide>
              <>
                {(assignmentManager.getAssignmentMembers().length === 0
                            && assignmentManager.assignmentMemberPopupLoading)
                  ? <Loader active inline /> : null}
                {assignmentManager.getAssignmentMembers().map((member, index) => (<div key={index} className='card-value'>{member}</div>)
                )}
              </>
            </Popup>
          )}
        </>
      );
    }
    return (
      <>
        <div className='card-label'>{t('noneLabel')}</div>
        <div className='card-value'>{t('noneValue')}</div>
      </>
    );
  }

  render() {
    const { CautionIcon, PopupButton, StandardsList } = this;

    const {
      assignment,
      assignmentManager,
      contentImageUrl,
      courseElement,
      dialogIncludesAssignmentInfo,
      fetchCourseActivityInformation,
      handleAnswerKey,
      handleEditAssignment,
      handleGradebook,
      hideCourseTitle,
      productManager,
      showExpirationAlerts,
      t,
      userManager
    } = this.props;

    const { getAssignmentExpiredStatus, useExpandedAssignmentCard } = assignmentManager;

    // if there is any data to show in info panel
    const showInfoIconButton = AssignmentService.showAssignmentInfoIconButton(assignment, dialogIncludesAssignmentInfo);

    let showKey = false;
    let assignmentKeyTitle = '';
    if (assignment.attachmentContentItemId !== '' && assignment.attachmentContentItemId !== null) {
      showKey = true;
      assignmentKeyTitle = assignment.attachmentContentItemTitle ? assignment.attachmentContentItemTitle : 'Answer Key';
    }

    const {
      resourceWebTitle, resourceWebSubtitle, nickname
    } = AssignmentService.getAssignmentTitleAndDescription(assignment, false);

    let showSubTitle = false;
    if (resourceWebSubtitle !== '' && resourceWebSubtitle) {
      showSubTitle = true;
    }

    let showCourseTitle = false;
    if (!hideCourseTitle && assignment.courseContentItemName !== null && assignment.courseContentItemName !== '') {
      showCourseTitle = true;
    }
    // If we are showing certain things the card expands so we need to pad to center the image and card content.
    // const useExpanded = (
    //   useExpandedAssignmentCard && (
    //     (showSubTitle && showCourseTitle && showKey) ||
    //     (nickname && nickname.length > 0)
    //   ) ? 'useExpanded' : ''
    // );
    // Decision was made to always use expanded image design so setting it directly but kept above in case they change their minds.
    const useExpanded = useExpandedAssignmentCard ? 'useExpanded' : '';

    let alertIcon = null;
    let alertPopupTextKey = null;
    if (showExpirationAlerts && assignment.status !== ASSIGNMENT_STATUS.COMPLETED) {
      const { isAssignmentExpired, isAssignmentExpiring } = getAssignmentExpiredStatus(assignment);
      if (isAssignmentExpired) {
        alertIcon = iconExpired;
        alertPopupTextKey = 'PopupTextExpired';
      } else if (isAssignmentExpiring) {
        alertIcon = iconExpiring;
        alertPopupTextKey = 'PopupTextExpiring';
      }
    }

    let statusImage = '';
    let statusClass = '';
    if (assignment.status === ASSIGNMENT_STATUS.STARTED) {
      statusImage = iconStarted;
      statusClass = 'icon-started';
    } else if (assignment.status === ASSIGNMENT_STATUS.READY) {
      statusImage = iconReady;
      statusClass = 'icon-ready';
    } else if (assignment.status === ASSIGNMENT_STATUS.LOCKED) {
      statusImage = iconLocked;
      statusClass = 'icon-locked';
    } else if (assignment.status === ASSIGNMENT_STATUS.CLOSED) {
      statusImage = iconClosed;
      statusClass = 'icon-closed';
    } else if (assignment.status === ASSIGNMENT_STATUS.COMPLETED) {
      statusImage = iconCompleted;
      statusClass = 'icon-completed';
    } else {
      statusImage = '';
      statusClass = '';
    }
    const assignmentNameObj = UtilityService.reactHtmlParserWrapper(
      resourceWebTitle
    );
    const transformFn = UtilityService.reactHtmlParserTransform;

    let cardEntityTitle = '';
    if (assignment.assignEntityTypeId === ASSIGNMENT_TYPE.GROUP) {
      cardEntityTitle = assignment.groupName ? assignment.groupName : 'Group Assignment';
    } else {
      cardEntityTitle = assignment.classroomNickname ? assignment.classroomNickname : (assignment.classroomName || '');
    }
    const strippedAssignmentName = nickname || assignmentNameObj.stripped || '';

    // timeframe stuff
    const { reviewOutsideTimeframe, timeframeStartDate, timeframeEndDate, timeframeStartDateStr, timeframeStartTimeStr, timeframeEndDateStr, timeframeEndTimeStr  } = courseElement;
    const timeframeData = CourseService.formatTimeframeCardData(reviewOutsideTimeframe, timeframeStartDate, timeframeEndDate, timeframeStartDateStr, timeframeStartTimeStr, timeframeEndDateStr, timeframeEndTimeStr);

    const { allowTimeframeViewing, formattedTimeframeStartDate, formattedTimeframeEndDate, formattedTimeframeStartTime, formattedTimeframeEndTime, timeframeStartPending, timeframeEndPassed } = timeframeData;

    // eslint-disable-next-line no-nested-ternary, max-len
    const timeFrameMessage = timeframeStartPending ? (
      `Assessment is not available until ${formattedTimeframeStartDate} at ${formattedTimeframeStartTime}`
    ) : (
      timeframeEndPassed ? `Assessment closed on ${formattedTimeframeEndDate} at ${formattedTimeframeEndTime}` : ''
    );
    // end timeframe stuff

    let popupViewMsg = timeFrameMessage, popupEditMsg;

    // TODO remove
    // const { currentReportStandardsFullCourseElement } = reportStandardsClassroomDetailStudentsManager;

    const licenseExpired = !!(assignment.licenseExpired || courseElement?.licenseExpired/* || (
      reportIdentityManager.isReport && currentReportStandardsFullCourseElement?.licenseExpired
    ) */);

    if (licenseExpired) {
      popupViewMsg = (!userManager.isStudent || productManager.allowShowExpiredLicenseWarningForStudent) ?
        t('expiredLicenseButtonViewMsg') : '';
      popupEditMsg = (!userManager.isStudent || productManager.allowShowExpiredLicenseWarningForStudent) ?
        t('expiredLicenseButtonEditMsg') : '';
    }

    return (
      <>
        <CardTabLabel name={cardEntityTitle} />
        <Item key={assignment.id} as={Segment}
          className='assignment-card' data-assignment-name={strippedAssignmentName} data-classroom-name={cardEntityTitle}>
          {contentImageUrl ? <Image className={`content-image ${useExpanded}`} src={contentImageUrl} /> : this.getDefaultImage(useExpanded)}
          <Item.Content>
            <div className='flex-grid main'>
              <div className='card-grid-container'>
                <div className='flex-grid header'>
                  <div className='flex-grid row header assignment-card-header-wrapper'>
                    <div
                      className='flex-header assignment-card-header-title'
                      title={strippedAssignmentName}>
                      {alertIcon && (
                        <Popup
                          className='alert-text-popup'
                          content={(
                            <div className='content-container'>
                              <div className='content-left'><Image alt='alert' className='card-alert-icon' spaced src={alertIcon} /></div>
                              <div className='alert-pop-text'>{t(alertPopupTextKey, 'AssignmentCard: alert Popup Text Not Found')}</div>
                            </div>
                          )}
                          on='click'
                          position='bottom center'
                          trigger={<Image alt='alert' className='card-alert-icon' spaced src={alertIcon} />}
                          wide />
                      )}
                      {nickname || assignmentNameObj.parsed}
                    </div>
                    {!!showInfoIconButton && (
                      <div className='fg-column img'>
                        {/* {showInfoIconButton && ( */}
                        <Image
                          alt=''
                          className='img-info'
                          onClick={() => fetchCourseActivityInformation(
                            assignment, contentImageUrl
                          )}
                          src={info} />
                        {/* )} */}
                      </div>
                    )}
                    {licenseExpired && (
                      !userManager.isStudent || productManager.allowShowExpiredLicenseWarningForStudent
                    ) && (<CautionIcon expiredLicenseCautionIcon />)}
                  </div>
                </div>
                {nickname && (
                  <div className='flex-grid'>
                    <div className='flex-grid row'>
                      <div className='secondary-title'>
                        {assignmentNameObj.parsed}
                      </div>
                    </div>
                  </div>
                )}
                <div className='flex-grid'>
                  <div className='flex-grid row'>
                    <div className='subtitle'>
                      <span className='expander'>
                        {(showSubTitle) &&
                          ReactHtmlParser(resourceWebSubtitle,
                            { transform: transformFn }
                          )}
                      </span>
                      {(showSubTitle && showCourseTitle) &&
                        <span className='spacer'>|</span>}
                      {showCourseTitle && (
                        <span className='expander'>
                          <b>Course: </b>
                          {ReactHtmlParser(
                            assignment.courseContentItemName,
                            { transform: transformFn }
                          )}
                        </span>
                      )}
                    </div>
                    {showKey ? (
                      <div className='subtitle'>
                        {((showCourseTitle || showSubTitle) && showKey) ?
                          <span className='spacer'>|</span> : null}
                        <div className='answer-key card-list-text blue clickable' onClick={() => handleAnswerKey(assignment)}>
                          {assignmentKeyTitle}
                        </div>
                      </div>
                    )
                      : ''}
                  </div>
                </div>
                <StandardsList
                  assignmentId={assignment.id}
                  contentImageUrl={contentImageUrl}
                  standards={assignment.standardsInfo}
                  title={resourceWebTitle} />
                <div className='flex-grid top'>
                  <div className='flex-grid cell one'>
                    <div className='card-label'>Status:</div>
                    <div className='flex-grid status'>
                      <div className='status-image'><Image alt='' className={statusClass} src={statusImage} /></div>
                      <div className='card-value extra-margin'>
                        {t(ASSIGNMENT_STATUS.getFlag(assignment.status))}
                      </div>
                    </div>
                  </div>
                  <div className='flex-grid cell two'>
                    <div className='card-label'>Start Date:</div>
                    <div className='card-value'>{assignment.timezoneStartDate}</div>
                  </div>
                  <div className='flex-grid cell three'>
                    <div className='card-label'>Due Date:</div>
                    <div className='card-value'>{assignment.timezoneEndDate}</div>
                  </div>
                  <div className='flex-grid cell four'>
                    <div className='card-label'>Started:</div>
                    <div className='card-value'>{`${assignment.totalActivityInstancesStarted}/${assignment.totalActivityInstances}`}</div>
                  </div>
                </div>
                <div className={`flex-grid ${showKey ? 'bottom-answer-key' : 'bottom'}`}>
                  <div className='flex-grid cell one'>
                    {this.popup(assignment)}
                  </div>
                  <div className='flex-grid cell two'>
                    <div className='card-label'>Start Time:</div>
                    <div className='card-value'>{this.formatTime(assignment.timezoneStartTime)}</div>
                  </div>
                  <div className='flex-grid cell three'>
                    <div className='card-label'>Due Time:</div>
                    <div className='card-value'>{this.formatTime(assignment.timezoneEndTime)}</div>
                  </div>
                  <div className='flex-grid cell four'>
                    <div className='card-label'>Submitted:</div>
                    <div className='card-value'>{`${assignment.totalActivityInstancesSubmitted}/${assignment.totalActivityInstances}`}</div>
                  </div>
                </div>
              </div>
              <div className='card-button-container card-nav-buttons'>
                {!licenseExpired && allowTimeframeViewing ?
                  <Button basic onClick={() => this.handlePresent({ assignment })} primary>{t('ViewPreview', 'View')}</Button>
                  : (
                    <Popup
                      hideOnScroll={true}
                      hoverable={true}
                      on='hover'
                      position='bottom center'
                      trigger={(
                        <Button basic className='pseudo-disabled' primary>
                          {t('ViewPreview', 'View')}
                        </Button>
                      )}
                      wide={!licenseExpired || undefined}>
                      {popupViewMsg}
                    </Popup>
                  )}
                <PopupButton
                  basic
                  buttonLabel={t('edit')}
                  disabled={licenseExpired}
                  onClick={() => handleEditAssignment(assignment, contentImageUrl)}
                  popupMsg={popupEditMsg}
                  popupWide={false}
                  primary />
                <Button basic onClick={() => handleGradebook(assignment)} primary>
                  {t('gradebookLabel', 'Gradebook')}
                </Button>
              </div>
            </div>
          </Item.Content>
        </Item>
      </>
    );
  }
}

SatCoreRegister('AssignmentCard', AssignmentCard);
