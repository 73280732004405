import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import {
  Button, Card, Container, Loader
} from 'semantic-ui-react';
import Modal from '../components/Modal';
import InputModal from '../components/dialogs/InputModal';
import '../css/ClassCourses.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import {
  PATH_TYPES, VIEW_SELECTION
} from '../managers/NavigationManager';

import ClassroomService from '../services/ClassroomService';
import CourseNavigationService from '../services/CourseNavigationService';
import CourseService from '../services/CourseService';

export default
@inject(
  'assignmentManager', 'classroomManager', 'courseManager',
  'dialogManager', 'navigationManager', 'userManager', 'assessmentManager'
)
@observer
class ClassCourses extends Component {
  constructor(props) {
    super(props);
    this.BreadCrumbsCourseDropdown = SatCoreComponent('BreadCrumbsCourseDropdown');
    this.CourseCard = SatCoreComponent('CourseCard');
    this.CourseListBanner = SatCoreComponent('CourseListBanner');
    this.ModalBanner = SatCoreComponent('ModalBanner');

    this.state = {
      loadingClassCoursesComponent: true
    };
  }

  async componentDidMount() {
    const {
      classroomManager, courseManager, navigationManager, t,
      userManager
    } = this.props;

    const urlParams = new URLSearchParams(window.location.search);

    const currentClassroomId = ClassroomService.getCurrentClassroomId();
    if (!classroomManager.currentClassroomId) {
      classroomManager.setCurrentClassroomId(currentClassroomId);
    }

    courseManager.setTruePublisherCoursesCount(undefined);

    if (urlParams.has('view')) {
      navigationManager.setView(urlParams.get('view'));
    } else {
      navigationManager.setView(VIEW_SELECTION.COURSES);
    }

    const courseCount = courseManager.getClassroomCourseCount(currentClassroomId);
    const breadCrumbKey = courseCount > 1 ? 'InitialBreadcrumb_plural' : 'InitialBreadcrumb';
    if (userManager.isTeacher) {
      await CourseService.fetchClassroomCourses(currentClassroomId, userManager.isTeacher, userManager.isStudent);
      navigationManager.clearAllPaths();
      navigationManager.addPath({
        currentClassroomId,
        currentCourseId: null,
        currentElementId: null,
        name: (userManager.isStudent)
          ? t(breadCrumbKey, 'No initial Breadcrumb for class courses defined')
          : classroomManager.getClassName(currentClassroomId),
        treeNavigationFunction: null, // only valid if type
        type: PATH_TYPES.LINK
      });
    } else {
      // this has to be called again in an else block because the
      // other calls inside the if blocks
      // are happening in a promise
      navigationManager.clearAllPaths();
      navigationManager.addPath({
        currentClassroomId,
        currentCourseId: null,
        currentElementId: null,
        name: (userManager.isStudent)
          ? t(breadCrumbKey, 'No initial Breadcrumb for class courses defined')
          : classroomManager.getClassName(currentClassroomId),
        treeNavigationFunction: null, // only valid if type
        type: PATH_TYPES.LINK,
      });
    }

    setTimeout(() => {
      this.setState({ loadingClassCoursesComponent: false });
    }, 250);
  }

  handleCardClick = async (course) => {
    const courseId = course.id;
    const { classroomManager, courseManager, history } = this.props;
    const isCustomCourse = courseManager.isCustomCourse(courseId);

    courseManager.setCurrentCourseId(courseId);
    courseManager.setCurrentElementId('');
    courseManager.setTreeLoading(true);

    // Save current location
    await CourseNavigationService.saveCurrentCourseLocation(classroomManager.currentClassroomId, courseId);

    let routerUrl = `/class?classroomId=${classroomManager.currentClassroomId}`;
    routerUrl += `&courseId=${courseId}&elementId=ROOT&view=${VIEW_SELECTION.BOOK}`;
    routerUrl += `&isCustomCourse=${isCustomCourse}`;
    history.push(routerUrl);
  }

  handleCourseCardRemove = (course) => {
    const { courseManager } = this.props;
    courseManager.setWarningCourse(course);
    courseManager.setIsDeleteCourseWarningModalOpen(true);
  }

  handleCourseCardRename = (course) => {
    const { courseManager } = this.props;
    courseManager.setRenameCourse(course);
    courseManager.setIsRenameCourseModalOpen(true);
  }

  closeCourseElementModal = () => {
    const { dialogManager } = this.props;
    dialogManager.closeAllDialogs();
  }

  closeRemoveCourseWarning = () => {
    const { courseManager } = this.props;
    courseManager.setWarningCourse(null);
    courseManager.setIsDeleteCourseWarningModalOpen(false);
  }

  closeRenameCourse = () => {
    const { courseManager } = this.props;
    courseManager.setRenameCourse(null);
    courseManager.setIsRenameCourseModalOpen(false);
  }

  confirmedRemoveCourse = async () => {
    const { courseManager } = this.props;
    await this.doDelete();
    courseManager.setWarningCourse(null);
    courseManager.setIsDeleteCourseWarningModalOpen(false);
  }

  confirmedSaveCourse = async () => {
    const { courseManager } = this.props;
    // await this.doDelete();
    // courseManager.setWarningCourse(null);
    // courseManager.setIsDeleteCourseWarningModalOpen(false);
  }

  doDelete = async () => {
    const { classroomManager, courseManager } = this.props;
    const { currentClassroomId } = classroomManager;
    const { warningCourse } = courseManager;
    const result = await courseManager.removeCoursesFromClassroom(
      currentClassroomId, [warningCourse.id]
    );
    if (!result) {
      return;
    }
    CourseNavigationService.removeClassroomCourseLocation(currentClassroomId, warningCourse.id);
    classroomManager.decrementCourseCount(currentClassroomId, 1);

    await this.doSaveRefresh(result);
  }

  doSaveRefresh = async (result) => {
    const { classroomManager, history, navigationManager, userManager } = this.props;
    const { currentClassroomId } = classroomManager;
    if (result) {
      await classroomManager.fetchClassroomData(currentClassroomId);
      await CourseService.fetchClassroomCourses(currentClassroomId, userManager.isTeacher, userManager.isStudent);
      try {
        navigationManager.navigateToClassroom(currentClassroomId, history, true);
      } catch { /* */ }
    } else {
      this.setState({
        serverError: true,
        serverErrorMsg: 'Something went wrong when adding courses, please try again'
      });
    }
  }

  doesNotHaveDuplicateCourseName = async () => {
    return true;
  }

  closeRename = async () => {
    const { courseManager } = this.props;
    if (courseManager.isRenameCourseModalOpen) {
      courseManager.setIsRenameCourseModalOpen(false);
    }
  }

  handleSaveRenameCourseElement = async (save, name, subtitle) => {
    if (save) {
      const { courseManager, assessmentManager, classroomManager } = this.props;
      const { renameCourse } = courseManager;
      const { id } = renameCourse;

      if (id) {
        const result = await assessmentManager.updateContentItemName(id, name, subtitle);
        if (result) {
          courseManager.updateCourseName(classroomManager.currentClassroomId, id, name);
        }
        courseManager.setIsRenameCourseModalOpen(false);
      }
    }
  }

  render() {
    const {
      assignmentManager, classroomManager, courseManager, history, t, userManager
    } = this.props;

    const { loadingClassCoursesComponent } = this.state;

    const courseList = courseManager.getCourseList(classroomManager.currentClassroomId);

    const { isCoTeacher } = userManager;

    const { CourseCard, CourseListBanner, ModalBanner } = this;

    const { renameCourse } = courseManager;

    let removeCourseWarningMsg = `Are you sure you want to remove this ${t('courseLabel', 'course')} from your class?  `;
    removeCourseWarningMsg += 'All curriculum resources, assignments, grades and report data will be permanently removed from your class.';

    let zeroStateMsgKey = '';
    if (courseManager.activeTab === 'custom_courses') {
      zeroStateMsgKey = 'customCourseZeroStateMsg';
    } else {
      zeroStateMsgKey = 'courseZeroStateMsg';
    }

    const loadingClassCourses = (
      loadingClassCoursesComponent || courseManager.coursesLoading || !userManager.loaded
        || !classroomManager.loaded || !classroomManager.currentClassroomId
    );
    return loadingClassCourses ? <Loader active /> : (
      <>
        <Container
          className={(isCoTeacher)
            ? 'co-teacher class-course-container'
            : 'class-course-container'}
          fluid>
          <CourseListBanner
            hideAddCourseButton={false}
            hideAddStudentButton={true}
            hideHeader
            history={history}
            showBreadCrumbs='bottom'
            showCourseSearchButton={assignmentManager.enableCourseSearch}
            title={t('BannerTitle', 'No Class Courses Banner Title')} />
          <Container className='class-course-list-container class-courses' fluid>
            {/* .class-courses-header is marked as 'display: none' by default.
              can be overridden by changing the display to 'flex' for a given satellite */}
            {/* TODO unused */}
            {/* <div className='class-courses-header'>
              <div className='class-courses-header-left'>
                <BreadCrumbsCourseDropdown className='top' history={history} />
              </div>
              <div className='class-courses-header-mid' />
              <div className='class-courses-header-right' />
            </div> */}
            <Card.Group>
              {(/* userManager.loaded && classroomManager.loaded */
              /* && */ !courseManager.coursesLoading && courseList?.length > 0) ?
                courseList.map((course) => (
                  <CourseCard
                    key={`course_${course.id}`}
                    course={course}
                    handleCardClick={this.handleCardClick}
                    handleCourseCardRemove={this.handleCourseCardRemove}
                                  // eslint-disable-next-line no-alert
                    handleCourseCardRename={this.handleCourseCardRename}
                    imageUrl={course.imageUrl} />
                )) :
                !courseManager.coursesLoading && (
                <Container className='class-content'>
                  <div className='no-courses'>
                    {t(zeroStateMsgKey, 'There are no courses assigned to this class.')}
                  </div>
                </Container>
                )}
            </Card.Group>
            {/* <Loader active={courseManager.coursesLoading} /> */}
          </Container>
        </Container>
        <Modal
          className='warning-modal'
          onClose={this.closeRemoveCourseWarning}
          open={courseManager.isDeleteCourseWarningModalOpen}
          size='tiny'>
          <ModalBanner
            label='Warning!'
            onClose={this.closeRemoveCourseWarning} />
          <Modal.Content>
            <p>{removeCourseWarningMsg}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              basic
              className='class-course-no-remove'
              content='No, Cancel'
              onClick={this.closeRemoveCourseWarning}
              primary />
            <Button
              className='class-course-remove'
              content='Yes, continue'
              onClick={this.confirmedRemoveCourse}
              primary />
          </Modal.Actions>
        </Modal>

        {
        (courseManager.isRenameCourseModalOpen) && (
        <InputModal
          bannerLabel='Rename'
          cancelLabel='Cancel'
          closeMe={this.closeRename}
          hideSubtitle={true}
          isOpen={courseManager.isRenameCourseModalOpen}
          isValidName={this.doesNotHaveDuplicateCourseName}
          name={renameCourse.name}
          nameLabel='Name'
          onSave={(save, name, subtitle) => this.handleSaveRenameCourseElement(save, name, subtitle)}
          saveLabel='Save'
          subtitle={renameCourse.description}
          subtitleLabel=''
          {...this.props} />
        )
      }

      </>
    );
  }
}

SatCoreRegister('ClassCourses', ClassCourses);
