import React from 'react';
import { SatCoreRegister } from '../SatCoreRegistry';

function ProgressBar({ progress, total, width, height }) {
  const remaining = total - progress;
  const template = `${progress}fr ${remaining}fr`;

  width = (width) || 191;
  height = (height) || 20;

  return (
    <div style={{
      display: 'inline-grid',
      backgroundColor: '#dadadb',
      borderRadius: 5,
      gridTemplateColumns: template,
      width,
      height
    }}>
      <div style={{
        borderRadius: 5,
        backgroundColor: '#006e9f'
      }} />
      <div style={{
        borderRadius: '0px 5px 5px 0px',
        backgroundColor: '#dadadb'
      }} />
    </div>
  );
}

export default ProgressBar;
SatCoreRegister('ProgressBar', ProgressBar);
