import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import {
  Button,
  Container,
  Dropdown,
  Form,
  Grid,
  Icon,
  Image,
  Message,
  Popup,
  Segment
} from 'semantic-ui-react';

import Moment from 'moment';

import { DateInput } from 'semantic-ui-calendar-react';

import { ASSIGNED_TO_TYPE, ASSIGNER_TYPE, ASSIGNMENT_STATUS, TEACHER_ASSIGNMENT_MENU_STATUS } from '../managers/AssignmentManager';
import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import info from '../img/group-1583.svg';

import UtilityService from '../services/UtilityService';

export default
@inject(
  'assignmentManager', 'classroomManager', 'contentManager',
  'navigationManager', 'tagContentManager', 'userManager',
  'groupsManager'
)
@observer
class AssignmentFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      assignToIds: [],
      assignerType: ASSIGNER_TYPE.ALL,
      assignmentType: ASSIGNED_TO_TYPE.ALL,
      classroomGroups: [],
      classroomId: '',
      contentTypes: [],
      dateSearchType: 'all',
      endDateStr: null,
      selectedGroupIds: [],
      showDateRange: ['date-range', 'assigned-to', 'status', 'assigned-by', 'content-type'],
      startDateStr: null
    };
    this.SCCheckbox = SatCoreComponent('SCCheckbox');
    this.SCRadio = SatCoreComponent('SCRadio');
  }

  componentDidMount = async () => {
    const { assignmentManager, classroomManager, menuStatus, resetSearch, search } = this.props;
    const { state } = this;

    const urlParams = new URLSearchParams(window.location.search);

    const filterDateFromUrlParam = urlParams.get('filterDate');
    const startDateStrFromUrlParam = filterDateFromUrlParam ? assignmentManager.convertAssignmentDateToJavaString(
      new Date(filterDateFromUrlParam)) : null;

    const settings = await assignmentManager.filteredAssignmentSettings || {};

    const classroomId = classroomManager.currentClassroomId || settings.classroomId || '';
    const startDateStr = startDateStrFromUrlParam || settings.filterDateStr || state.startDateStr || null;
    const endDateStr = settings.filterEndDateStr || state.endDateStr || null;
    const status = settings.status ||
      ((menuStatus === TEACHER_ASSIGNMENT_MENU_STATUS.ASSIGNED) ? [ASSIGNMENT_STATUS.LOCKED, ASSIGNMENT_STATUS.READY, ASSIGNMENT_STATUS.QUEUED] :
        ([urlParams.get('status')]) || null);

    let currentClassroomIdEqualsSettingsClassroomId;
    if (classroomManager.currentClassroomId && settings.classroomId) {
      currentClassroomIdEqualsSettingsClassroomId = classroomManager.currentClassroomId === settings.classroomId;
    }
    if (classroomId && classroomId.length > 0) {
      await this.fetchGroupFilterData(classroomId, !!'forceLoad');
      await this.fetchClassRosterData(classroomId);
    }

    const searchText = settings.search || search || null;
    const contentTypes = settings.contentTypes || state.contentTypes || null;
    let assignerType = settings.assignerType || state.assignerType || null;
    const dateSearchType = settings.dateSearchType || state.dateSearchType || null;
    let selectedGroupIds = settings.subdomainIds || state.selectedGroupIds || [];
    const assignToIds = settings.assignToIds || state.selectedGroupIds || [];
    let assignerEntityIds = settings.assignerEntityIds || null;

    let assignmentType = settings.assignmentType || state.assignmentType || null;

    if (!currentClassroomIdEqualsSettingsClassroomId) {
      // classroomId has changed, so we need to reset certain fields for AssignmentFilter
      assignmentType = ASSIGNED_TO_TYPE.ALL;
      selectedGroupIds = [];
    }

    if (assignerType === ASSIGNER_TYPE.ALL) {
      assignerEntityIds = null;
    } else if (!assignerEntityIds || assignerEntityIds.length < 1) {
      assignerType = ASSIGNER_TYPE.ALL;
    }

    this.setState({
      assignerEntityIds,
      assignToIds,
      assignerType,
      assignmentType,
      classroomId,
      contentTypes,
      dateSearchType,
      endDateStr,
      selectedGroupIds,
      startDateStr
    });

    await resetSearch(
      classroomId, startDateStr, status, assignmentType, searchText,
      contentTypes, assignerType, dateSearchType, selectedGroupIds, endDateStr,
      assignerEntityIds, assignToIds, menuStatus
    );
    await this.fetchTags();
  }

  getStartDateStr = () => {
    const today = new Date();
    Moment.locale('en');
    return `${Moment(new Date(today)).format('MM/DD/YYYY')}`;
  }

  handleChangeClassroom = async (event) => {
    const { assignmentManager, classroomManager, menuStatus, resetSearch, search } = this.props;
    const classroomId = (event.target.value && event.target.value.length > 0) ? event.target.value : '';
    classroomManager.setCurrentClassroomId(classroomId || '');

    const settings = assignmentManager.filteredAssignmentSettings || {};

    const {
      endDateStr, startDateStr, contentTypes, dateSearchType
    } = this.state;

    // Get classroom groups and roster for filter
    if (classroomId) {
      this.fetchGroupFilterData(classroomId, !!'forceLoad');
      this.fetchClassRosterData(classroomId);
    }

    // reset assignment type, assignerType and group selection on classroom change as they are all class dependent
    const updatedAssignmentType = ASSIGNED_TO_TYPE.ALL;
    const updatedSelectedGroupIds = [];
    const updatedAssignerType = ASSIGNER_TYPE.ALL;
    const updatedAssignerEntityIds = [];
    const updatedAssignToIds = [];
    this.setState({
      assignToIds: updatedAssignToIds,
      assignerEntityIds: updatedAssignerEntityIds,
      assignerType: updatedAssignerType,
      assignmentType: updatedAssignmentType,
      classroomGroups: [],
      classroomId,
      selectedGroupIds: updatedSelectedGroupIds
    });

    await resetSearch(
      classroomId, startDateStr, settings.status, updatedAssignmentType, search,
      contentTypes, updatedAssignerType, dateSearchType, updatedSelectedGroupIds, endDateStr,
      updatedAssignerEntityIds, updatedAssignToIds, menuStatus
    );
  }

  handleChangeStatus = async (event) => {
    const { value } = event.target;
    const { assignmentManager, menuStatus, resetSearch, search } = this.props;
    const settings = assignmentManager.filteredAssignmentSettings || {};
    const {
      classroomId, startDateStr, assignmentType, contentTypes, assignerType, dateSearchType,
      selectedGroupIds, endDateStr, assignerEntityIds, assignToIds
    } = this.state;

    // eslint-disable-next-line react/destructuring-assignment
    let currentStatusArray = settings.status;
    if (!value) {
      currentStatusArray = [];
    } else if (currentStatusArray.includes(value)) {
      currentStatusArray.splice(currentStatusArray.indexOf(value), 1);
    } else {
      currentStatusArray.push(value);
    }

    if (menuStatus === TEACHER_ASSIGNMENT_MENU_STATUS.ASSIGNED && currentStatusArray.length < 1) {
      currentStatusArray = ['locked', 'ready'];
    }

    await resetSearch(classroomId, startDateStr, currentStatusArray, assignmentType, search, contentTypes,
      assignerType, dateSearchType, selectedGroupIds, endDateStr, assignerEntityIds, assignToIds, menuStatus);
  }

  handleChangeAssignmentType = async (event) => {
    const { assignmentManager, menuStatus } = this.props;
    const settings = assignmentManager.filteredAssignmentSettings || {};
    const assignmentType = event.target.value;
    const {
      classroomId, endDateStr, startDateStr, contentTypes, assignerType, dateSearchType,
      assignerEntityIds
    } = this.state;
    let { assignToIds, selectedGroupIds } = this.state;
    const { resetSearch, search } = this.props;

    if (assignmentType === ASSIGNED_TO_TYPE.ALL) {
      this.setState({ assignToIds: [], assignmentType, selectedGroupIds: [] });
      assignToIds = [];
      selectedGroupIds = [];
    } else if (assignmentType === ASSIGNED_TO_TYPE.CLASSROOM) {
      this.setState({ assignToIds: [], assignmentType, selectedGroupIds: [] });
      assignToIds = [];
      selectedGroupIds = [];
    } else if (assignmentType === ASSIGNED_TO_TYPE.INDIVIDUAL) {
      this.setState({ assignmentType, selectedGroupIds: [] });
      selectedGroupIds = [];
    } else if (assignmentType === ASSIGNED_TO_TYPE.GROUP) {
      this.setState({ assignToIds: [], assignmentType });
      assignToIds = [];
      // Get classroom groups for groups filter
      if (classroomId && classroomId.length > 0) {
        this.fetchGroupFilterData(classroomId);
      }
    } else {
      this.setState({ assignToIds: [], assignmentType, selectedGroupIds: [] });
      assignToIds = [];
      selectedGroupIds = [];
    }

    // in the case of groups and individual students we don't want to reset until further selection is made
    await resetSearch(
      classroomId, startDateStr, settings.status, assignmentType, search, contentTypes, assignerType,
      dateSearchType, selectedGroupIds, endDateStr, assignerEntityIds, assignToIds, menuStatus);
  }

  handleChangeDateSearchType = async (event) => {
    const { assignmentManager, menuStatus } = this.props;
    const settings = assignmentManager.filteredAssignmentSettings || {};
    const dateSearchType = event.target.value;
    // We are requiring you pick a date if range is selected so do nothing unless it's for "all"
    if (dateSearchType === 'all') {
      const {
        classroomId, assignmentType, contentTypes, assignerType,
        selectedGroupIds, assignerEntityIds, assignToIds
      } = this.state;
      const { resetSearch, search } = this.props;
      await resetSearch(classroomId, null, settings.status, assignmentType, search, contentTypes, assignerType,
        dateSearchType, selectedGroupIds, null, assignerEntityIds, assignToIds, menuStatus);
    }
    this.setState({ dateSearchType, endDateStr: null, endError: false, endMessage: '', startDateStr: null });
  }

  handleChangeStartDate = async (event, { name, value }) => {
    const { assignmentManager, menuStatus, t } = this.props;
    const settings = assignmentManager.filteredAssignmentSettings || {};
    const {
      classroomId, assignmentType, assignerType, contentTypes, dateSearchType,
      selectedGroupIds, endDateStr, assignerEntityIds, assignToIds
    } = this.state;
    const startDateStr = value;
    let endError = false;
    let endMessage = '';

    // Check if this is a valid date in case they are typing out the date we don't want to search for partial dates.
    const isADate = Moment(startDateStr, 'MM/DD/YYYY', true).isValid();

    if (isADate || startDateStr.length < 1) {
      const startRealDate = assignmentManager.convertJSStringToJSDate(startDateStr, '00:00:00');
      const endRealDate = assignmentManager.convertJSStringToJSDate(endDateStr, '23:59:59');

      // validate dates
      if (endRealDate && startRealDate) {
        if (endRealDate < startRealDate) {
          endError = true;
          endMessage = t('validFromDateMessage', 'Invalid date.');
        }
      }
      // console.log(`handleChangeStartDate startDate: ${startDateStr} endDate: ${endDateStr}`);// remove
      const { resetSearch, search } = this.props;

      await resetSearch(classroomId, startDateStr, settings.status, assignmentType, search, contentTypes,
        assignerType, dateSearchType, selectedGroupIds, endDateStr, assignerEntityIds, assignToIds, menuStatus);
      this.setState({ endError, endMessage, startDateStr });
    } else {
      endError = true;
      endMessage = t('validDateFormatMessage', 'Invalid date format');
      this.setState({ endError, endMessage, startDateStr });
    }
    return true;
  }

  handleChangeEndDate = async (_event, value) => {
    const { assignmentManager, menuStatus, t } = this.props;
    const settings = assignmentManager.filteredAssignmentSettings || {};
    const {
      classroomId, startDateStr, assignmentType, assignerType, contentTypes, dateSearchType,
      selectedGroupIds, assignerEntityIds, assignToIds
    } = this.state;
    const endDateStr = value;
    let endError = false;
    let endMessage = '';

    // Check if this is a valid date in case they are typing out the date we don't want to search for partial dates.
    const isADate = Moment(endDateStr, 'MM/DD/YYYY', true).isValid();

    if (isADate || endDateStr.length < 1) {
      const startRealDate = assignmentManager.convertJSStringToJSDate(startDateStr, '00:00:00');
      const endRealDate = assignmentManager.convertJSStringToJSDate(endDateStr, '23:59:59');

      // validate dates
      if (endRealDate && startRealDate) {
        if (endRealDate < startRealDate) {
          endError = true;
          endMessage = t('validToDateMessage', 'Invalid date.');
        }
      }

      const { resetSearch, search } = this.props;

      await resetSearch(classroomId, startDateStr, settings.status, assignmentType, search, contentTypes,
        assignerType, dateSearchType, selectedGroupIds, endDateStr, assignerEntityIds, assignToIds, menuStatus);
      this.setState({ endDateStr, endError, endMessage });
    } else {
      endError = true;
      endMessage = t('validDateFormatMessage', 'Invalid date format');
      this.setState({ endDateStr, endError, endMessage });
    }
    return true;
  }

  handleChangeContentType = async (_event, { value }) => {
    const { assignmentManager, menuStatus } = this.props;
    const settings = assignmentManager.filteredAssignmentSettings || {};
    const contentTypes = value;
    const {
      classroomId, startDateStr, assignmentType, assignerType, dateSearchType,
      selectedGroupIds, endDateStr, assignerEntityIds, assignToIds
    } = this.state;
    const { resetSearch, search } = this.props;

    await resetSearch(classroomId, startDateStr, settings.status, assignmentType, search, contentTypes,
      assignerType, dateSearchType, selectedGroupIds, endDateStr, assignerEntityIds, assignToIds, menuStatus);
    this.setState({ contentTypes });
  }

  handleChangeAssignerType = async (event) => {
    const { assignmentManager, menuStatus } = this.props;
    const settings = assignmentManager.filteredAssignmentSettings || {};
    const assignerType = event.target.value;
    let assignerEntityIds = '';
    const {
      classroomId, startDateStr, assignmentType, contentTypes, dateSearchType,
      selectedGroupIds, endDateStr, assignToIds
    } = this.state;

    const classMembers = this.getClassroomsMembers(classroomId);

    if (event.target.value === ASSIGNER_TYPE.LEAD_TEACHER) {
      assignerEntityIds = classMembers.leadTeacherIds;
      if (classMembers.leadTeacherIds && classMembers.leadTeacherIds.length > 0) {
        assignerEntityIds = classMembers.leadTeacherIds;
      } else {
        assignerEntityIds = ['no-lead-teachers'];
      }
    } else if (event.target.value === ASSIGNER_TYPE.CO_TEACHER) {
      if (classMembers.coTeacherIds && classMembers.coTeacherIds.length > 0) {
        assignerEntityIds = classMembers.coTeacherIds;
      } else {
        assignerEntityIds = ['no-co-teachers'];
      }
    }

    const { resetSearch, search } = this.props;

    await resetSearch(classroomId, startDateStr, settings.status, assignmentType, search, contentTypes,
      assignerType, dateSearchType, selectedGroupIds, endDateStr, assignerEntityIds, assignToIds, menuStatus);
    this.setState({ assignerEntityIds, assignerType });
  }

  handleChangeGroupType = async (event) => {
    const { assignmentManager, menuStatus } = this.props;
    const settings = assignmentManager.filteredAssignmentSettings || {};
    const groupType = event.target.value;
    const {
      classroomId, endDateStr, startDateStr, contentTypes, assignerType, dateSearchType,
      assignerEntityIds, assignToIds
    } = this.state;
    const { resetSearch, search } = this.props;

    // This function would only be called if group ALL was selected, so we only worry about resetting group ids.
    if (groupType === 'groupTypeAll') {
      this.setState({ assignmentType: ASSIGNED_TO_TYPE.GROUP, selectedGroupIds: [] });
      await resetSearch(
        classroomId, startDateStr, settings.status, ASSIGNED_TO_TYPE.GROUP, search, contentTypes, assignerType,
        dateSearchType, [], endDateStr, assignerEntityIds, assignToIds, menuStatus);
    }
  }

  handleChangeRosterType = async (event) => {
    const { assignmentManager, menuStatus } = this.props;
    const settings = assignmentManager.filteredAssignmentSettings || {};
    const rosterType = event.target.value;
    const {
      classroomId, endDateStr, startDateStr, contentTypes, assignerType, dateSearchType,
      assignerEntityIds, selectedGroupIds
    } = this.state;
    const { resetSearch, search } = this.props;

    // This function would only be called if group ALL was selected, so we only worry about resetting group ids.
    if (rosterType === 'rosterTypeAll') {
      this.setState({ assignToIds: [], assignmentType: ASSIGNED_TO_TYPE.INDIVIDUAL, rosterType });
      await resetSearch(
        classroomId, startDateStr, settings.status, ASSIGNED_TO_TYPE.INDIVIDUAL, search, contentTypes,
        assignerType, dateSearchType, selectedGroupIds, endDateStr, assignerEntityIds, [], menuStatus);
    }
  }

  handleCheckStudent = async (id) => {
    const { assignmentManager, resetSearch, search, menuStatus } = this.props;
    const settings = assignmentManager.filteredAssignmentSettings || {};
    const {
      assignerType,
      assignerEntityIds,
      assignmentType,
      classroomId,
      contentTypes,
      dateSearchType,
      endDateStr,
      startDateStr,
      selectedGroupIds
    } = this.state;
    let updatedAssignmentType = assignmentType;
    const updatedAssignToIds = [];

    updatedAssignToIds.push(id);

    if (assignmentType !== ASSIGNED_TO_TYPE.INDIVIDUAL) {
      updatedAssignmentType = ASSIGNED_TO_TYPE.INDIVIDUAL;
    }

    this.setState({ assignToIds: updatedAssignToIds, assignmentType: updatedAssignmentType });

    await resetSearch(classroomId, startDateStr, settings.status, updatedAssignmentType, search, contentTypes,
      assignerType, dateSearchType, selectedGroupIds, endDateStr, assignerEntityIds, updatedAssignToIds, menuStatus);
  };

  handleCheckClassroomGroup = async (id) => {
    const { assignmentManager, groupsManager, menuStatus, resetSearch, search } = this.props;
    const settings = assignmentManager.filteredAssignmentSettings || {};
    const {
      assignerType,
      assignerEntityIds,
      assignmentType,
      assignToIds,
      classroomId,
      contentTypes,
      dateSearchType,
      endDateStr,
      selectedGroupIds,
      startDateStr
    } = this.state;
    let updatedSelectedGroupIds = [];
    let updatedAssignmentType = assignmentType;

    // Check if we are using multiselect checkboxes or single select radio buttons
    if (groupsManager.useAssignmentFilterMultiselect) {
      updatedSelectedGroupIds = selectedGroupIds.slice();
      const index = updatedSelectedGroupIds.indexOf(id);

      if (index > -1) {
        updatedSelectedGroupIds.splice(index, 1);
      } else {
        updatedSelectedGroupIds.push(id);
      }
    } else {
      updatedSelectedGroupIds.push(id);
    }

    if (assignmentType !== ASSIGNED_TO_TYPE.GROUP) {
      updatedAssignmentType = ASSIGNED_TO_TYPE.GROUP;
    }

    this.setState({ assignmentType: updatedAssignmentType, selectedGroupIds: updatedSelectedGroupIds });

    await resetSearch(classroomId, startDateStr, settings.status, updatedAssignmentType, search, contentTypes,
      assignerType, dateSearchType, updatedSelectedGroupIds, endDateStr, assignerEntityIds, assignToIds, menuStatus);
  }

  handleExpand = (filterOption) => {
    const { showDateRange } = this.state;
    const index = showDateRange.indexOf(filterOption);
    if (index > -1) {
      showDateRange.splice(index, 1);
    } else {
      showDateRange.push(filterOption);
    }
    this.setState({ showDateRange });
  }

  getClassroomsMembers(classroomId = null) {
    const { classroomManager } = this.props;
    const classroomMembers = {
      coTeacherIds: [],
      leadTeacherIds: []
    };
    if (classroomId) {
      const classroom = classroomManager.getClassroom(classroomId);
      if (classroom) {
        classroomMembers.leadTeacherIds.push(classroom.leadTeacherId);
        if (classroom.coteacherIds.length > 0) {
          classroom.coteacherIds.forEach((coTeacherId) => {
            if (coTeacherId && coTeacherId.length > 0 && !classroomMembers.coTeacherIds.includes(coTeacherId)) {
              classroomMembers.coTeacherIds.push(coTeacherId);
            }
          });
        }
      }
    } else {
      const { classroomsArray } = classroomManager;
      if (classroomsArray) {
        classroomsArray.forEach((classroom) => {
          // only add a lead teacher id, if it's not already there
          if (!classroomMembers.leadTeacherIds.includes(classroom.leadTeacherId)) {
            classroomMembers.leadTeacherIds.push(classroom.leadTeacherId);
          }
          // push classroom co-teachers
          if (classroom.coteacherIds.length > 0) {
            classroom.coteacherIds.forEach((coTeacherId) => {
              if (coTeacherId && coTeacherId.length > 0 && !classroomMembers.coTeacherIds.includes(coTeacherId)) {
                classroomMembers.coTeacherIds.push(coTeacherId);
              }
            });
          }
        });
      }
    }
    return classroomMembers;
  }

  resetFilters = async () => {
    const { assignmentManager, resetSearch, menuStatus } = this.props;
    const classroomId = '';
    const startDateStr = '';
    const endDateStr = null;
    const updatedAssignmentType = ASSIGNED_TO_TYPE.ALL;
    const search = '';
    const contentTypes = [];
    const assignerType = ASSIGNER_TYPE.ALL;
    const dateSearchType = 'all';
    const selectedGroupIds = [];
    const status = assignmentManager.getAssignmentMenuStatus(menuStatus);
    const assignerEntityIds = '';
    const assignToIds = [];

    await resetSearch(
      classroomId, startDateStr, status, updatedAssignmentType, search, contentTypes, assignerType, dateSearchType,
      selectedGroupIds, endDateStr, assignerEntityIds, assignToIds, menuStatus
    );
    this.setState({
      assignerType: ASSIGNER_TYPE.ALL,
      assignmentType: ASSIGNED_TO_TYPE.ALL,
      classroomGroups: [],
      classroomId: '',
      classroomRoster: [],
      contentTypes: [],
      dateSearchType,
      endDateStr: null,
      endError: false,
      endMessage: '',
      selectedGroupIds: [],
      startDateStr: null
    });
  }

  fetchGroupFilterData = async (classroomId, forceLoad = false) => {
    const { groupsManager } = this.props;
    let classroomGroups = groupsManager.groups;
    if (forceLoad || (!classroomGroups || classroomGroups.length < 1)) {
      groupsManager.getFilteredGroupsByClassroom(
        classroomId, null, false, 1, null, null,
        null, 999).then(() => {
        classroomGroups = groupsManager.groups;
        this.setState({ classroomGroups });
      });
    } else {
      this.setState({ classroomGroups });
    }
  }

  fetchClassRosterData = async (classroomId) => {
    const { classroomManager } = this.props;
    let classroomRoster = classroomManager.rosterMap.get(classroomId);
    if (!classroomRoster) {
      await classroomManager.fetchClassroomRoster(classroomId);
      classroomRoster = classroomManager.rosterMap.get(classroomId);
    }
    this.setState({ classroomRoster });
  }

  fetchTags = async () => {
    // eslint-disable-next-line react/destructuring-assignment
    await this.props.fetchTags();
  }

  renderRoster = () => {
    const { t } = this.props;
    const { classroomRoster, assignToIds } = this.state;
    const { SCRadio } = this;

    if (classroomRoster === null || classroomRoster === undefined) {
      return null;
    }

    // check if the last selected student id from settings is still valid (right now there is only one possible selected student)
    // protects against an archived student having been last set in the filter settings and other cases.
    const hasSelectedstudentId = classroomRoster.find((student) => student.userId === assignToIds[0]) !== undefined;
    const allChecked = assignToIds.length < 1 || (assignToIds.length > 0 && !hasSelectedstudentId);

    return (
      <Container className='check-list'>
        <div className='radio-wrapper'>
          <SCRadio
            checked={allChecked}
            label={t('allAssignTypes', 'All')}
            name='typeEntireClass'
            onChange={this.handleChangeRosterType}
            value='rosterTypeAll' />
        </div>
        {classroomRoster.map((student, index) => { // eslint-disable-line no-unused-vars
          const checked = (assignToIds.indexOf(student.userId) > -1);
          return (
            <SCRadio
              key={`${student.userId + student.id}_R`}
              checked={checked}
              label={`${student.firstName} ${student.lastName}`}
              onChange={() => {
                this.handleCheckStudent(student.userId);
              }}
              useHoverLabel={true}
              value={student.userid} />
          );
        })}
      </Container>
    );
  };

  renderClassroomGroups = () => {
    const { groupsManager, t } = this.props;
    const { classroomGroups, selectedGroupIds } = this.state;
    const { SCRadio } = this;

    if (classroomGroups === null || classroomGroups === undefined) {
      return null;
    }

    // check if the last selected group id from settings is still valid (right now there is only one possible selected group)
    // protects against an archived class having been last set in the filter settings and other cases.
    const hasSelectedGroupId = classroomGroups.find((classroomGroup) => classroomGroup.id === selectedGroupIds[0]) !== undefined;
    const allChecked = selectedGroupIds.length < 1 || (selectedGroupIds.length > 0 && !hasSelectedGroupId);

    return (
      <Container className='check-list'>
        <div className='radio-wrapper'>
          <SCRadio
            checked={allChecked}
            label={t('allAssignTypes', 'All')}
            name='typeEntireClass'
            onChange={this.handleChangeGroupType}
            value='groupTypeAll' />
        </div>
        {classroomGroups.map((group) => { // eslint-disable-line no-unused-vars
          const checked = selectedGroupIds.find((classroomGroupId) => classroomGroupId === group.id) !== undefined;
          return (
            <SCRadio
              key={`${group.id}_R`}
              checked={checked}
              hoverLabelContent={() => groupsManager.renderGroupInfoPopupContent(group, false, true)}
              label={group.name}
              name='groupRadio'
              onChange={() => {
                this.handleCheckClassroomGroup(group.id);
              }}
              value={group.id} />
          );
        })}
      </Container>
    );
  };

  render() {
    const {
      assignmentManager, classroomManager, isUpdating, menuStatus, t, tagContentManager
    } = this.props;
    const settings = assignmentManager.filteredAssignmentSettings || {};
    const { status = [] } = settings;
    const {
      assignerType,
      assignmentType,
      classroomId,
      contentTypes,
      dateSearchType,
      endDateStr = null,
      endError,
      endMessage,
      showDateRange,
      startDateStr,
    } = this.state;

    const options = tagContentManager.contentCategoryTagArray.map((contentCategoryTag, index) => ({
      key: contentCategoryTag.id + index,
      text: tagContentManager.getContentCategoryTagName(contentCategoryTag.id),
      value: contentCategoryTag.id
    }));

    const { SCCheckbox } = this;
    const { SCRadio } = this;

    return (
      <div className='filter-wrapper' style={isUpdating ? { cursor: 'wait' } : {}}>
        <Segment
          className='filter'
          style={isUpdating ? { pointerEvents: 'none' } : {}}>
          {/* TODO REMOVE <h3 className='date-header'>Date</h3>
          <span className='filter-info-popup'>
            <Popup
              content={t('assignmentDateDes')}
              on='click'
              trigger={<Image alt='' className='img-info' src={info} />} />
          </span>
          {this.renderDateInput()} TODO REMOVE */}
          <div className='filter-title-wrapper'>
            <Button
              className='reset-link'
              disabled={isUpdating}
              onClick={this.resetFilters}>
              {t('resetFilters')}
            </Button>
          </div>
          {assignmentManager.useDateSearchType && (
          <Grid>
            <Grid.Column className='date-range'>
              <h3>
                <Icon name={showDateRange.includes('date-range') ? 'caret down' : 'caret right'} onClick={() => this.handleExpand('date-range')} />
                {t('dateRangeSectionLabel', 'Due Date Range')}
              </h3>
              {showDateRange.includes('date-range') && (
                <Container>
                  <SCRadio
                    checked={dateSearchType === 'all'}
                    disabled={isUpdating}
                    label={t('allDatesLabel', 'All Time')}
                    name='dateSearchType'
                    onChange={this.handleChangeDateSearchType}
                    value='all' />
                  <SCRadio
                    checked={dateSearchType === 'range'}
                    disabled={isUpdating}
                    label={t('dateRangeLabel', 'Date Range')}
                    name='dateSearchType'
                    onChange={this.handleChangeDateSearchType}
                    value='range' />
                  <Container className='field-wrapper'>
                    {dateSearchType === 'range' && (
                    <>
                      <Container className='date-controls start'>
                        <Form.Field>
                          <DateInput
                            animation='false'
                            className='date-input'
                            clearIcon={<Icon color='red' name='remove' />}
                            clearable
                            dateFormat='MM/DD/YYYY'
                            disabled={dateSearchType !== 'range'}
                            iconPosition='left'
                            label={t('startDateLabel', 'From Selected Date')}
                            name='startDate'
                            onChange={this.handleChangeStartDate}
                            placeholder='Start Date'
                            value={startDateStr || ''} />
                        </Form.Field>
                      </Container>
                      <Container className='date-controls end'>
                        <Form.Field>
                          <DateInput
                            animation='false'
                            className='date-input'
                            clearIcon={<Icon color='red' name='remove' />}
                            clearable
                            dateFormat='MM/DD/YYYY'
                            disabled={dateSearchType !== 'range'}
                            iconPosition='left'
                            label={t('endDateLabel', 'From Selected Date')}
                            name='endDate'
                            onChange={(event, { value }) => this.handleChangeEndDate(event, value)}
                            placeholder='End Date'
                            value={endDateStr || ''} />
                        </Form.Field>
                      </Container>
                      {endError && (
                        <Message
                          content={endMessage}
                          error
                          visible={endError} />
                      )}
                    </>
                    )}
                  </Container>
                </Container>
              )}
            </Grid.Column>
          </Grid>
          )}
          <Grid>
            <Grid.Column className='assigned-to'>
              <h3>
                <Icon name={showDateRange.includes('assigned-to') ? 'caret down' : 'caret right'} onClick={() => this.handleExpand('assigned-to')} />
                {t('classLabel', 'Class')}
              </h3>
              {showDateRange.includes('assigned-to') && (
                <Container>
                  {
                    (classroomManager.classroomsArray.filter((classroom) => !classroom.archived)) ? (
                      <SCRadio
                        key='all_R'
                        checked={classroomId === '' || classroomId === null}
                        disabled={isUpdating}
                        label={t('allClassroomsLabel', 'All Classrooms')}
                        name='classroomRadio'
                        onChange={this.handleChangeClassroom}
                        value='' />
                    ) : null
                  }
                  {
                    classroomManager.classroomsArray.filter((classroom) => !classroom.archived).map((classroom, _index) => {
                      const classNameObj = UtilityService.reactHtmlParserWrapper(
                        classroomManager.getClassName(classroom.id)
                      );
                      return (
                        <div key={`${classroom.id}_R`} className='classroom-controls'>
                          <span
                            key={`${classroom.id}_R`}
                            title={classNameObj.stripped}>
                            <SCRadio
                              key={`${classroom.id}_R`}
                              checked={classroomId === classroom.id}
                              disabled={isUpdating}
                              label={classNameObj.stripped}
                              name='classroomRadio'
                              onChange={this.handleChangeClassroom}
                              value={classroom.id} />
                          </span>
                          {classroomId === classroom.id && (
                            <>
                              <Container className='check-list'>
                                <div className='radio-wrapper'>
                                  <SCRadio
                                    checked={assignmentType === ASSIGNED_TO_TYPE.ALL}
                                    label={t('allAssignTypes', 'All')}
                                    name='typeEntireClass'
                                    onChange={this.handleChangeAssignmentType}
                                    value={ASSIGNED_TO_TYPE.ALL} />
                                </div>
                              </Container>
                              <Container className='check-list'>
                                <div className='radio-wrapper'>
                                  <SCRadio
                                    checked={assignmentType === ASSIGNED_TO_TYPE.CLASSROOM}
                                    label={t('entireClass', 'Entire Class')}
                                    name='typeEntireClass'
                                    onChange={this.handleChangeAssignmentType}
                                    value={ASSIGNED_TO_TYPE.CLASSROOM} />
                                  {/* <Popup
                                    content={t('entireClassIndividualInfoText')}
                                    hideOnScroll
                                    on='click'
                                    trigger={<Image alt='' className='img-info' src={info} />} /> */}
                                </div>
                              </Container>
                              <Container className='check-list'>
                                <div className='radio-wrapper'>
                                  <SCRadio
                                    checked={assignmentType === ASSIGNED_TO_TYPE.INDIVIDUAL}
                                    label={t('entireClassIndividualLabel', 'Entire Class (Individual)')}
                                    name='typeEntireClassIndividual'
                                    onChange={this.handleChangeAssignmentType}
                                    value={ASSIGNED_TO_TYPE.INDIVIDUAL} />
                                  {/* <Popup
                                    content={t('entireClassIndividualInfoText')}
                                    hideOnScroll
                                    on='click'
                                    trigger={<Image alt='' className='img-info' src={info} />} /> */}
                                </div>
                                <div>
                                  {assignmentType === ASSIGNED_TO_TYPE.INDIVIDUAL &&
                                    this.renderRoster()}
                                </div>
                              </Container>
                              <Container className='check-list'>
                                <div className='radio-wrapper'>
                                  <SCRadio
                                    checked={assignmentType === ASSIGNED_TO_TYPE.GROUP}
                                    label={t('groupLabel', 'Group')}
                                    name='typeGroup'
                                    onChange={this.handleChangeAssignmentType}
                                    value={ASSIGNED_TO_TYPE.GROUP} />
                                  {/* <Popup
                                    content={t('groupInfoText')}
                                    hideOnScroll
                                    on='click'
                                    trigger={<Image alt='' className='img-info' src={info} />} /> */}
                                </div>
                                <div>
                                  {assignmentType === ASSIGNED_TO_TYPE.GROUP &&
                                    this.renderClassroomGroups()}
                                </div>
                              </Container>
                            </>
                          )}
                        </div>
                      );
                    })
                  }
                </Container>
              )}
            </Grid.Column>
          </Grid>
          {assignmentManager.useContentTypeSearch && (
            <Grid>
              <Grid.Column className='content-type'>
                <div className='filter-content-type-section'>
                  <h3>
                    <Icon name={showDateRange.includes('content-type') ? 'caret down' : 'caret right'} onClick={() => this.handleExpand('content-type')} />
                    Content Types
                  </h3>
                  {showDateRange.includes('content-type') && (
                    (tagContentManager.contentCategoryTagArray) ? (
                      <Dropdown
                        className='type-select'
                        clearable
                        disabled={isUpdating}
                        fluid
                        multiple
                        onChange={this.handleChangeContentType}
                        options={options}
                        placeholder='Content Types'
                        search
                        selection
                        value={contentTypes} />
                    ) : null
                  )}
                </div>
              </Grid.Column>
            </Grid>
          )}
          {(menuStatus && (menuStatus === 'assigned' || menuStatus === 'all' || menuStatus === 'list')) && (
            <Grid>
              <Grid.Column className='status'>
                <h3>
                  <Icon name={showDateRange.includes('status') ? 'caret down' : 'caret right'}
                    onClick={() => this.handleExpand('status')} />
                  {t('status')}
                </h3>
                {showDateRange.includes('status') && (
                <Container>
                  {/* <SCCheckbox
                        checked={(status && status.length) === 0}
                        disabled={isUpdating}
                        label={ASSIGNMENT_STATUS.getFlag(ASSIGNMENT_STATUS.ALL)}
                        name='status'
                        onChange={this.handleChangeStatus}
                        value='' /> */}
                  <div className='locked-option-wrapper'>
                    <SCCheckbox
                      checked={status.includes(ASSIGNMENT_STATUS.LOCKED)}
                      disabled={isUpdating}
                      label={t(ASSIGNMENT_STATUS.LOCKED, ASSIGNMENT_STATUS.getFlag(ASSIGNMENT_STATUS.LOCKED))}
                      name='status'
                      onChange={this.handleChangeStatus}
                      value={ASSIGNMENT_STATUS.LOCKED} />
                    {assignmentManager.useAssignmentTabRollovers && (
                    <Popup
                      content={t('lockedIconText')}
                      hideOnScroll
                      on='click'
                      trigger={<Image alt='' className='img-info' src={info} />} />
                    )}
                  </div>
                  <SCCheckbox
                    checked={status.includes(ASSIGNMENT_STATUS.READY)}
                    disabled={isUpdating}
                    label={t(ASSIGNMENT_STATUS.READY, ASSIGNMENT_STATUS.getFlag(ASSIGNMENT_STATUS.READY))}
                    name='status'
                    onChange={this.handleChangeStatus}
                    value={ASSIGNMENT_STATUS.READY} />
                  {(menuStatus && (menuStatus === 'all' || menuStatus === 'list')) && (
                    <>
                      <SCCheckbox
                        checked={status.includes(ASSIGNMENT_STATUS.STARTED)}
                        disabled={isUpdating}
                        label={t(ASSIGNMENT_STATUS.STARTED, ASSIGNMENT_STATUS.getFlag(ASSIGNMENT_STATUS.STARTED))}
                        name='status'
                        onChange={this.handleChangeStatus}
                        value={ASSIGNMENT_STATUS.STARTED} />
                      <SCCheckbox
                        checked={status.includes(ASSIGNMENT_STATUS.CLOSED)}
                        disabled={isUpdating}
                        label={t(ASSIGNMENT_STATUS.CLOSED, ASSIGNMENT_STATUS.getFlag(ASSIGNMENT_STATUS.CLOSED))}
                        name='status'
                        onChange={this.handleChangeStatus}
                        value={ASSIGNMENT_STATUS.CLOSED} />
                      <SCCheckbox
                        checked={status.includes(ASSIGNMENT_STATUS.COMPLETED)}
                        disabled={isUpdating}
                        label={t(ASSIGNMENT_STATUS.COMPLETED, ASSIGNMENT_STATUS.getFlag(ASSIGNMENT_STATUS.COMPLETED))}
                        name='status'
                        onChange={this.handleChangeStatus}
                        value={ASSIGNMENT_STATUS.COMPLETED} />
                    </>
                  )}
                </Container>
                )}
              </Grid.Column>
            </Grid>
          )}
          <Grid>
            <Grid.Column className='assigned-by'>
              <h3>
                <Icon name={showDateRange.includes('assigned-by') ? 'caret down' : 'caret right'} onClick={() => this.handleExpand('assigned-by')} />
                {t('assignedBy')}
              </h3>
              {showDateRange.includes('assigned-by') && (
                <Container>
                  <SCRadio
                    checked={assignerType === ASSIGNER_TYPE.ALL}
                    disabled={isUpdating}
                    label={ASSIGNER_TYPE.getFlag(ASSIGNER_TYPE.ALL)}
                    name='assignByGroup'
                    onChange={this.handleChangeAssignerType}
                    value={ASSIGNER_TYPE.ALL} />
                  <SCRadio
                    checked={assignerType === ASSIGNER_TYPE.LEAD_TEACHER}
                    disabled={isUpdating}
                    label={ASSIGNER_TYPE.getFlag(ASSIGNER_TYPE.LEAD_TEACHER)}
                    name='assignByGroup'
                    onChange={this.handleChangeAssignerType}
                    value={ASSIGNER_TYPE.LEAD_TEACHER} />
                  <SCRadio
                    checked={assignerType === ASSIGNER_TYPE.CO_TEACHER}
                    disabled={isUpdating}
                    label={ASSIGNER_TYPE.getFlag(ASSIGNER_TYPE.CO_TEACHER)}
                    name='assignByGroup'
                    onChange={this.handleChangeAssignerType}
                    value={ASSIGNER_TYPE.CO_TEACHER} />
                </Container>
              )}
            </Grid.Column>
          </Grid>
        </Segment>
      </div>
    );
  }
}

SatCoreRegister('AssignmentFilter', AssignmentFilter);
