import { action, computed, makeObservable, observable } from 'mobx';
import { getRegisteredClass } from '../SatCoreRegistry';

import Auth from './AuthManager';
import userManager from './UserManager';

export const PRODUCT_ENDPOINTS = {
  VIEW_LICENSED_PRODUCTS_FOR_CLASSROOM: '/api/viewLicensedProductsForClassroom',
  VIEW_LICENSED_PRODUCTS_FOR_GIVEN_USER_ID: '/api/viewLicensedProductsForUser', // TODO unused and untested
  VIEW_LICENSED_RESOURCE_PRODUCTS_FOR_USER: '/api/viewLicensedResourceProductsForUser',
  VIEW_MY_LICENSED_COURSE_PRODUCTS: '/api/viewLicensedCourseProductsForUser',
  VIEW_MY_LICENSED_PRODUCTS: '/api/viewMyLicensedProducts',
  VIEW_PRODUCT: '/api/viewProduct',
  VIEW_PRODUCT_ATTACHMENT_LIST: '/api/viewProductAttachmentContentItems',
  VIEW_PRODUCT_LICENSE_ENTITIES: '/api/viewProductLicenseEntities',
  VIEW_PRODUCT_RESOURCE_LICENSES: '/api/viewLicensedProductResources',
};

export class ProductManager {
  PRODUCT_FETCH_PAGE_SIZE = 12;

  @observable productResources = [];
  @observable productResourcesPageTotal = null;

  @observable productLicenses = [];

  @observable loaded = false;
  @observable loadingProductLicenses = false;

  @observable productContentItems = new Map();

  @observable totalPages = 0;

  @observable shouldFetchLicensedProducts = false;
  @observable loadingProductDropdown = false;
  @observable selectedUserProductId = 'allProducts';
  @observable userProductsMap = new Map();

  @observable isFromProduct = false;

  // `allowShowExpiredLicenseWarningForStudent` satellite index.js flag, defaults to false.
  // If false, we will not show expired warnings/labels/banners/etc to students, even if the content is expired.
  @observable allowShowExpiredLicenseWarningForStudent = false;

  @observable shouldShowExpiredLicenseWarning = false;

  constructor() {
    makeObservable(this);
  }

  @action clearAll() {
    this.clearUserProductsMap();
    this.clearProductResources();
    this.loaded = false;
    this.productContentItems = new Map();
    this.selectedUserProductId = 'allProducts';
    this.setIsFromProduct(false);
    this.setProductResourcesPageTotal(null);
    this.setShouldShowExpiredLicenseWarning(false);
    this.shouldFetchLicensedProducts = false;
    this.totalPages = 0;
    this.userProductsMap = new Map();
  }

  @action initProductResources(responseData, clearBeforeStoring = true) {
    if (clearBeforeStoring) {
      this.clearProductResources();
      this.setProductResources(responseData);
    } else {
      this.setProductResources(Array.isArray(this.productResources) ?
        [...this.productResources, ...responseData] : responseData);
    }

    this.productResources.forEach((productResource) => {
      if (productResource.id !== null) {
        this.productContentItems.set(productResource.contentItemId, productResource);
      }
    });
  }

  @action getTotalPages = () => this.totalPages;

  @action setProductResources = (productResources) => {
    this.productResources = productResources;
  }

  @action setProductResourcesPageTotal = (pageTotal) => {
    this.productResourcesPageTotal = pageTotal;
  }

  @action setProductLicenses = (productLicenses) => {
    this.productLicenses = productLicenses;
  }

  @action setLoaded(toggle) {
    this.loaded = toggle;
  }

  @action setLoadingProductLicenses = (toggle) => {
    this.loadingProductLicenses = toggle;
  }

  @action clearProductLicenses() {
    this.productLicenses.clear();
  }

  getProductContentItem = (id) => {
    if (id) {
      const productContentItem = this.productContentItems.get(id);
      if (productContentItem !== null && productContentItem !== undefined) {
        return productContentItem;
      }
    }
    return null;
  }

  getProductCourse = (courseId, courseName) => {
    if (courseId) {
      const courseObject = {
        id: courseId,
        name: courseName
      };
      return courseObject;
    }
    return null;
  }

  getProductClassroom = () => {
    const classroomObject = {
      id: this.productClassroomId,
      name: 'Product'
    };
    return classroomObject;
  }

  @action fetchLicensedCourseProducts = async ({
    pageSize = 999,
    skip = 0,
    userId = null // note: will be ignored if `classroomId` is passed
  } = {}) => {
    try {
      const {
        VIEW_MY_LICENSED_COURSE_PRODUCTS
      } = PRODUCT_ENDPOINTS;

      let apiUrlParams = `?pageSize=${pageSize}`;
      apiUrlParams += `&skip=${skip}`;
      apiUrlParams += userId ? `&userId=${userId}` : '';
      const apiUrl = `${Auth.ecms}${VIEW_MY_LICENSED_COURSE_PRODUCTS}${apiUrlParams}`;
      const response = await Auth.fetch(apiUrl, { method: 'GET' });
      const products = response?.status === 'SUCCESS' ? (response.data || []) : [];
      return products;
    } catch (error) {
      console.error(error);
    }
  }

  @action fetchLicensedProducts = async ({
    classroomId = null,
    pageSize = 999,
    skip = 0,
    userId = null // note: will be ignored if `classroomId` is passed
  } = {}) => {
    try {
      const {
        VIEW_LICENSED_PRODUCTS_FOR_CLASSROOM,
        VIEW_LICENSED_PRODUCTS_FOR_GIVEN_USER_ID,
        VIEW_MY_LICENSED_PRODUCTS
      } = PRODUCT_ENDPOINTS;

      let apiUrlParams = `?pageSize=${pageSize}`;
      apiUrlParams += `&skip=${skip}`;
      apiUrlParams += classroomId ? `&classroomId=${classroomId}` : '';
      apiUrlParams += userId ? `&userId=${userId}` : '';

      let apiUrl;
      if (classroomId) {
        apiUrl = `${Auth.ecms}${VIEW_LICENSED_PRODUCTS_FOR_CLASSROOM}${apiUrlParams}`;
      } else if (userId) {
        apiUrl = `${Auth.ecms}${VIEW_LICENSED_PRODUCTS_FOR_GIVEN_USER_ID}${apiUrlParams}`; // TODO unused and untested
      } else {
        apiUrl = `${Auth.ecms}${VIEW_MY_LICENSED_PRODUCTS}${apiUrlParams}`;
      }
      const response = await Auth.fetch(apiUrl, { method: 'GET' });
      const products = response?.status === 'SUCCESS' ? (response.data || []) : [];
      return products;
    } catch (error) {
      console.error(error);
    }
  }

  @action fetchLicensedResourceProducts = async ({
    userId,
    page = 0,
    pageSize = 25,
    sortColumn = 'modificationDate',
    sortDirection = 'desc',
    classroomId = null
  } = {}) => {
    try {
      const {
        VIEW_LICENSED_RESOURCE_PRODUCTS_FOR_USER,
      } = PRODUCT_ENDPOINTS;

      let apiUrlParams = `?pageSize=${pageSize}`;
      const skip = !pageSize ? 0 : (page ? page - 1 : 0) * pageSize;
      apiUrlParams += `&skip=${skip}`;
      if (classroomId) {
        apiUrlParams += classroomId ? `&classroomId=${classroomId}` : '';
      }
      apiUrlParams += userId ? `&userId=${userId}` : '';
      if (sortColumn) {
        apiUrlParams += `&sort[0][field]=${sortColumn}&sort[0][dir]=${sortDirection}`;
      }

      const apiUrl = `${Auth.ecms}${VIEW_LICENSED_RESOURCE_PRODUCTS_FOR_USER}${apiUrlParams}`;

      return await Auth.fetch(apiUrl, { method: 'GET' });
    } catch (error) {
      console.error(error);
    }
  }

  @action fetchProductResourcesByInstitution = async (
    institutionId, page, sortColumn = null, sortDirection = 'ascending', filters, pageSize, clearBeforeStoring = true
  ) => {
    try {
      const { VIEW_PRODUCT_RESOURCE_LICENSES } = PRODUCT_ENDPOINTS;
      const UserService = getRegisteredClass('UserService');
      const hasMultipleInstitutions = UserService.hasMultipleInstitutions();
      this.setLoaded(false);
      // server uses short notation for direction
      const serverDirection = sortDirection === 'ascending' ? 'asc' : 'desc';
      pageSize = pageSize || this.PRODUCT_FETCH_PAGE_SIZE;
      const usePage = (page - 1) * pageSize;
      let url = `${Auth.ecms}${VIEW_PRODUCT_RESOURCE_LICENSES}?includeBlankCard=false&skip=${usePage}&pageSize=${pageSize}`;
      if (sortColumn) {
        url += `&sort[0][field]=${sortColumn}&sort[0][dir]=${serverDirection}`;
      }

      filters = filters || [];
      if (filters && filters.length > 0) {
        filters.forEach((filter, index) => {
          if (filter.field && filter.value) {
            let filterValue = filter.value;
            if (filter.field === 'entityTypeId') {
              // change filterValue string of `entityTypeId` to match db format
              // e.g. filterValue of 'LEARNOSITY ITEM' becomes 'learnosity_item'
              // (since it will be that way in the database)
              filterValue = filter.value.trim().toLowerCase().replace(/\s/g, '_');
            }
            // eslint-disable-next-line max-len
            url += `&filter[filters][${index}][field]=${filter.field}&filter[filters][${index}][operator]=${filter.operator}&filter[filters][${index}][value]=${filterValue}`;
          }
        });
      }
      if (institutionId) {
        url += `&institutionIds=${institutionId}`;
      } else if (hasMultipleInstitutions && (userManager.isSchoolAdmin || this.FROM_TEACHER_PRODUCTS_NAV)) {
        url += `&institutionIds=${userManager.institutionIds.toString()}`;
      }
      // by default, we currently do not show cmap or learnosity resources in the products table (unless specifically searched for)
      const entityTypeIdFilter = filters.find((filter) => filter.field === 'entityTypeId');
      if (!entityTypeIdFilter) {
        const defaultEntityTypeIdFilters = [
          {
            field: 'entityTypeId',
            operator: 'doesnotcontain',
            value: 'curriculum_map'
          },
          {
            field: 'entityTypeId',
            operator: 'doesnotcontain',
            value: 'learnosity'
          }
        ];
        const startIndex = filters ? filters.length : 0;
        for (let i = 0; i < defaultEntityTypeIdFilters.length; i++) {
          const currentIndex = startIndex + i;
          const filter = defaultEntityTypeIdFilters[i];
          // eslint-disable-next-line max-len
          url += `&filter[filters][${currentIndex}][field]=${filter.field}&filter[filters][${currentIndex}][operator]=${filter.operator}&filter[filters][${currentIndex}][value]=${filter.value}`;
        }
      }

      const response = await Auth.fetch(url,
        { method: 'GET' }
      );

      if (response.status === 'SUCCESS') {
        this.setProductResourcesPageTotal(response.pageTotal ? +response.pageTotal : 0);

        this.initProductResources(response.data, clearBeforeStoring);
        this.totalPages = Math.ceil(response.pageTotal / pageSize);
        if (this.productResources.length < response.pageTotal) {
          this.setLoaded(true);
          return true;
        }
        this.setLoaded(true);
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  }

  @action getProductsLicenseEntities = async (institutionId, productId) => {
    try {
      let institutionIds = [];
      const productLicensesMap = new Map();
      if (institutionId) {
        institutionIds = [institutionId];
      } else {
        institutionIds = [...userManager.institutionIds];
      }
      const { VIEW_PRODUCT_LICENSE_ENTITIES } = PRODUCT_ENDPOINTS;
      for (let i = 0; i < institutionIds.length; i++) {
        let url = `${Auth.ecms}${VIEW_PRODUCT_LICENSE_ENTITIES}`;
        url += `?institutionId=${institutionIds[i]}`;
        if (productId) {
          url += `&productId=${productId}`;
        }
        // eslint-disable-next-line no-await-in-loop
        const response = await Auth.fetch(url,
          { method: 'GET' }
        );
        if (response.status === 'SUCCESS' && response.data?.length) {
          for (const product of response.data) {
            productLicensesMap.set(product.productLicenseId, product);
          }
        }
      }
      this.setProductLicenses(Array.from(productLicensesMap.values()));
    } catch (error) {
      console.error(error);
    }
  }

  @action fetchCmapListForProduct = async (productId) => {
    try {
      const {
        VIEW_PRODUCT_ATTACHMENT_LIST
      } = PRODUCT_ENDPOINTS;

      let apiUrlParams = `?pageSize=${999}`;
      apiUrlParams += `&skip=${0}`;
      apiUrlParams += productId ? `&productId=${productId}` : '';
      const apiUrl = `${Auth.ecms}${VIEW_PRODUCT_ATTACHMENT_LIST}${apiUrlParams}`;
      const response = await Auth.fetch(apiUrl, { method: 'GET' });
      const cmaps = response?.status === 'SUCCESS' ? (response.data || []) : [];
      if (cmaps) {
        cmaps.forEach((pcm) => {
          if (pcm.id !== null && pcm.id !== '' && pcm.id !== undefined) {
            pcm.sortBy = pcm.friendlyName || pcm.displayName || pcm.name;
          }
        });
      }
      return cmaps;
    } catch (error) {
      console.error(error);
    }
  }

  @action setShouldFetchLicensedProducts = (toggle) => {
    if (toggle) {
      this.clearUserProductsMap();
      this.shouldFetchLicensedProducts = true;
    } else {
      this.shouldFetchLicensedProducts = false;
    }
  }

  @action setLoadingProductDropdown = (toggle) => {
    this.loadingProductDropdown = toggle;
  }

  @action setSelectedUserProductId = (selectedUserProductId) => {
    this.selectedUserProductId = selectedUserProductId;
  }

  @action clearUserProductsMap = () => {
    this.userProductsMap.clear();
  }

  @action addUserProductsToMap = (products = []) => {
    for (const product of products) {
      this.userProductsMap.set(product.id, product);
    }
  }

  @action clearProductResources = () => {
    this.productResources = [];
  }

  /**
   * This is a setter that essentially puts the application into a 'Product Course Preview Mode'.
   *
   * When set to true, certain features will be disabled or hidden, such as Teacher top navigation buttons.
   *
   * @param {boolean | 'FROM_TEACHER_PRODUCTS_NAV'} isFromProduct
   */
  @action setIsFromProduct = (isFromProduct) => {
    this.isFromProduct = isFromProduct;
  };

  /**
   * Setter for `allowShowExpiredLicenseWarningForStudent` satellite flag, defaults to false.
   *
   * Can be set to true via calling this setter within the index.js file for a given satellite.
   */
  @action setAllowShowExpiredLicenseWarningForStudent = (toggle) => {
    this.allowShowExpiredLicenseWarningForStudent = toggle;
  }

  @action setShouldShowExpiredLicenseWarning = (toggle) => {
    this.shouldShowExpiredLicenseWarning = toggle;
  }

  @computed get FROM_TEACHER_PRODUCTS_NAV() {
    return this.isFromProduct === 'FROM_TEACHER_PRODUCTS_NAV';
  }

  @computed get productClassroomId() {
    if (this.isFromProduct === true) {
      return 'product';
    } else if (this.isFromProduct === 'FROM_TEACHER_PRODUCTS_NAV') {
      return 'FROM_TEACHER_PRODUCTS_NAV';
    } else {
      return undefined;
    }
  }

  @computed get productDropdownOptions() {
    const keys = this.userProductIds;
    const options = keys.map((productId) => {
      const product = this.userProductsMap.get(productId);
      return product.active && {
        text: product.friendlyName || product.name,
        value: product.id
      };
    }).filter((option) => option).sort((a, b) => a.text?.localeCompare(b.text, 'en', { numeric: true }));
    return options;
  }

  @computed get selectedUserProduct() {
    return this.selectedUserProductId && this.selectedUserProductId !== 'allProducts' ? (
      this.userProductsMap?.get(this.selectedUserProductId)
    ) : undefined;
  }

  @computed get userProductIds() {
    return Array.from(this.userProductsMap.keys());
  }

  @computed get products() {
    return Array.from(this.userProductsMap.values());
  }

  @computed get defaultProduct() {
    return this.products.find((product) => product.code === Auth.publisherSatelliteCode);
  }

  @computed get productResourcesHasMore() {
    return typeof this.productResourcesPageTotal !== 'number' || this.productResources?.length < this.productResourcesPageTotal;
  }
}

export default new ProductManager();
