import { action, computed, makeObservable, observable } from 'mobx';

import Auth from './AuthManager';
import {
  DEFAULT_DISTRICT_ADMIN_TOP_NAV_ORDER_ARRAY,
  DEFAULT_SCHOOL_ADMIN_TOP_NAV_ORDER_ARRAY,
  DEFAULT_STUDENT_TOP_NAV_ORDER_ARRAY,
  DEFAULT_TEACHER_TOP_NAV_ORDER_ARRAY
} from '../constants';

export const DYNAMIC_SATELLITE_MANAGER_ENDPOINTS = {
  FETCH_DYNAMIC_SATELLITE_BY_DOMAIN: '/api/viewPublisherSatelliteByDomain',
};

export class DynamicSatelliteManager {
  constructor() {
    makeObservable(this);
  }

  @observable isDynamicSatellite = false;

  @observable dynamicSatelliteLayoutConfig = undefined;

  fetchDynamicSatelliteByDomain = async (locationOrigin = '') => {
    try {
      locationOrigin = locationOrigin || window?.location?.origin || '';
      // TODO useful for local development if you use QA domain urls
      // TODO for `publisher_satellite.satellite_url` values in your local database
      // TODO ---
      // TODO remember to comment back out before committing any changes
      // locationOrigin = 'https://qa-demo.content2classroom.com';
      // locationOrigin = 'https://qa-stemsports.content2classroom.com';
      // locationOrigin = 'https://qa-labaids.content2classroom.com';
      // locationOrigin = 'https://qa-kya.content2classroom.com';
      // locationOrigin = 'https://qa-cb.content2classroom.com';
      // locationOrigin = 'https://qa-youthcomm.content2classroom.com';

      let apiUrl = `${Auth.ecms}${DYNAMIC_SATELLITE_MANAGER_ENDPOINTS.FETCH_DYNAMIC_SATELLITE_BY_DOMAIN}`;
      apiUrl += `?locationOrigin=${locationOrigin}`;

      const response = await Auth.fetch(apiUrl, { method: 'GET' });

      // TODO remove
      // console.info('response', response);

      if (!response?.data?.[0]) {
        throw new TypeError('NO_DYNAMIC_SATELLITE_RESPONSE_DATA');
      } else if (response?.status !== 'SUCCESS') {
        throw new TypeError(response?.statusMessage || response?.status);
      } else {
        return response?.data?.[0];
      }
    } catch (error) {
      console.error(error);
    }
  }

  @action setIsDynamicSatellite = (isDynamicSatellite) => {
    this.isDynamicSatellite = isDynamicSatellite;
  }

  @action setDynamicSatelliteLayoutConfig = (dynamicSatelliteLayoutConfig) => {
    this.dynamicSatelliteLayoutConfig = dynamicSatelliteLayoutConfig;
  }

  @computed get districtAdminTopNavOrderArray() {
    const topNavTabOrderArray = this.dynamicSatelliteLayoutConfig?.districtAdmin?.topNavTabIds;
    if (!topNavTabOrderArray?.includes?.('default') && Array.isArray(topNavTabOrderArray)) {
      return topNavTabOrderArray || [];
    } else {
      return DEFAULT_DISTRICT_ADMIN_TOP_NAV_ORDER_ARRAY;
    }
  }

  @computed get schoolAdminTopNavOrderArray() {
    const topNavTabOrderArray = this.dynamicSatelliteLayoutConfig?.schoolAdmin?.topNavTabIds;
    if (!topNavTabOrderArray?.includes?.('default') && Array.isArray(topNavTabOrderArray)) {
      return topNavTabOrderArray || [];
    } else {
      return DEFAULT_SCHOOL_ADMIN_TOP_NAV_ORDER_ARRAY;
    }
  }

  @computed get teacherTopNavOrderArray() {
    const topNavTabOrderArray = this.dynamicSatelliteLayoutConfig?.teacher?.topNavTabIds;
    if (!topNavTabOrderArray?.includes?.('default') && Array.isArray(topNavTabOrderArray)) {
      return topNavTabOrderArray || [];
    } else {
      return DEFAULT_TEACHER_TOP_NAV_ORDER_ARRAY;
    }
  }

  @computed get studentTopNavOrderArray() {
    const topNavTabOrderArray = this.dynamicSatelliteLayoutConfig?.student?.topNavTabIds;
    if (!topNavTabOrderArray?.includes?.('default') && Array.isArray(topNavTabOrderArray)) {
      return topNavTabOrderArray;
    } else {
      return DEFAULT_STUDENT_TOP_NAV_ORDER_ARRAY;
    }
  }

  @computed get districtAdminMainTopNavId() {
    return this.dynamicSatelliteLayoutConfig?.districtAdmin?.mainTopNavId || 'default';
  }

  @computed get schoolAdminMainTopNavId() {
    return this.dynamicSatelliteLayoutConfig?.schoolAdmin?.mainTopNavId || 'default';
  }

  @computed get teacherMainTopNavId() {
    return this.dynamicSatelliteLayoutConfig?.teacher?.mainTopNavId || 'default';
  }

  @computed get studentMainTopNavId() {
    return this.dynamicSatelliteLayoutConfig?.student?.mainTopNavId || 'default';
  }
}

export default new DynamicSatelliteManager();
