import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import { Sticky } from 'semantic-ui-react';

import '../css/CautionBanner.less';
import imageCaution from '../img/image-caution.png';
import { SatCoreRegister } from '../SatCoreRegistry';

const CautionBanner = observer(({
  bannerKey, cautionBannerMsg, isImpersonationCautionBannerShowing, stickyOffset
} = {}) => {
  const { /* managers */ } = useContext(MobXProviderContext);
  return (
    <Sticky className={classNames('caution-banner-sticky', `${bannerKey}-sticky`, {
      isImpersonationCautionBannerShowing
    })} offset={stickyOffset || 50}>
      <div className={classNames('caution-banner-inner', `${bannerKey}-inner`)}>
        <div className='caution-banner-icon'>
          <img alt='' src={imageCaution} />
        </div>
        <div className='caution-banner-text'>
          {cautionBannerMsg}
        </div>
      </div>
    </Sticky>
  );
});
export default CautionBanner;

SatCoreRegister('CautionBanner', CautionBanner);
