/* eslint-disable max-len */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import {
  Button, Container, Header, Image, Popup
} from 'semantic-ui-react';
import Modal from '../Modal';
import '../../css/LibraryAssessmentBankViewModal.less';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import { PATH_TYPES, VIEW_SELECTION } from '../../managers/NavigationManager';

import CourseService from '../../services/CourseService';

import { DIALOG_NAMES } from '../../managers/DialogManager';

import iconAlert from '../../img/icon-alert-red.svg';

export default
@inject(
  'classroomManager',
  'courseManager',
  'dialogManager',
  'itemBankManager',
  'libraryManager',
  'navigationManager',
  'productManager',
  'tagContentManager',
  'userManager'
)
@observer
class LibraryAssessmentBankViewModal extends Component {
  // eslint-disable-next-line react/static-property-placement
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.BreadCrumbs = SatCoreComponent('BreadCrumbs');
    this.CourseLayout = SatCoreComponent('CourseLayout');
  }

  getInitialState = () => ({
    // activePage: 1,
    // serverError: false,
    // serverErrorMsg: ''
  })

  componentDidMount = async () => {
    const {
      classroomManager, courseManager, navigationManager, productManager
    } = this.props;

    productManager.setIsFromProduct(true);

    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('view')) {
      navigationManager.setView(urlParams.get('view'));
    } else {
      navigationManager.setView(VIEW_SELECTION.BOOK);
    }
    classroomManager.setCurrentClassroomId('product');

    // get the data
    await this.fetchData();

    await CourseService.initCourseDataProduct(true, true);
    if (!navigationManager.pathList || !navigationManager.pathList.length) {
      navigationManager.setBreadcrumb({
        fromView: VIEW_SELECTION.LIBRARY,
        path: {
          currentCourseId: courseManager.currentCourseId,
          name: courseManager.currentCourseName,
          type: PATH_TYPES.ROOT
        }
      });
    }
  }

  fetchData = async (courseResourceElementId = null, forceFetch = false) => {
    const { courseManager, libraryCardData } = this.props;
    let response = null;
    if (libraryCardData?.libraryResource?.entityTypeId === 'course_resource') {
      const courseContentItemId = libraryCardData?.libraryResource?.id;
      if (courseContentItemId) {
        courseManager.setCurrentCourseId(courseContentItemId);
        response = await courseManager.fetchCourseData(courseContentItemId, 'product', courseResourceElementId, forceFetch);
      }
    }
    return response;
  }

  closeThisModal = () => {
    const { closeAssessmentBankModal, productManager } = this.props;
    // this.setState(this.getInitialState());
    closeAssessmentBankModal();
    productManager.setIsFromProduct(false);
  }

  // handlePageChange = async (_event, pageInfo) => {
  //   const { itemBankManager, libraryCardData } = this.props;
  //   const { activePage } = pageInfo;

  //   await itemBankManager.searchContentItemsForItemBank(
  //     libraryCardData.id,
  //     null,
  //     activePage,
  //     25,
  //     'modificationDate',
  //     'desc');

  //   // await this.handleFetchItemBankItems(searchOperator, searchTerms, searchTags, searchStandards, activePage);
  //   this.setState({ activePage });
  // }

  closeCreateAssessmentBankUnitModal = async (refresh = false) => {
    const { dialogManager } = this.props;
    if (refresh) {
      await this.fetchData(null, true);
    }
    dialogManager.closeDialog(DIALOG_NAMES.CREATE_ASSESSMENT_BANK_UNIT_MODAL);
  }

  handleClickCreateNewUnit = async (_event, data) => {
    const { libraryCardData, dialogManager } = this.props;
    const courseContentItemId = libraryCardData?.libraryResource?.id;
    // Launch the create new unit modal
    dialogManager.setOpenDialog(DIALOG_NAMES.CREATE_ASSESSMENT_BANK_UNIT_MODAL, {
      closeCreateAssessmentBankUnitModal: this.closeCreateAssessmentBankUnitModal,
      courseContentItemId,
      isEdit: false
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.CREATE_ASSESSMENT_BANK_UNIT_MODAL));
  }

  closeResourceShoppingModal = async (currentElementId = null, refresh = false) => {
    const { dialogManager } = this.props;
    if (refresh) {
      await this.fetchData(currentElementId, true);
    }
    dialogManager.closeDialog(DIALOG_NAMES.RESOURCE_BANK_SHOP);
  }

  onCloseCreateNewResourceModal = async (data, callback) => {
    const { courseManager, libraryManager } = this.props;
    const { currentCourseId, currentElementId } = courseManager;
    if (data) {
      const assessmentIds = [data.id];
      // if a callback was sent, call it, otherwise, do default refresh.
      if (callback) {
        callback();
      } else {
        await courseManager.clearAll(); // clear course data so add of assessments gets reflected.
        const result = await libraryManager.addAssessmentsToBankUnit(
          currentCourseId,
          currentElementId,
          assessmentIds
        );
        if (!result) {
          alert('An error occurred when trying to save. Please try again later.');
        } else {
          await this.fetchData(currentElementId, true);
        }
      }
    }
  }

  handleOpenResourceShopping = async (_event, pageInfo) => {
    const { courseManager, dialogManager, handleView, libraryCardData, tagContentManager, userManager } = this.props;
    const { libraryResource } = libraryCardData;
    const { currentCourseId, currentCourseElementList, currentElementId } = courseManager;
    await tagContentManager.fetchAvailableCategoriesWithTags(null, 'RESOURCE_BANK'); // passing null uses the default set in tag content manager
    await tagContentManager.fetchStandardFrameworkBookmarks(userManager.userId); // passing null uses the default set in tag content manager
    const isLiveCourse = (libraryResource.entityTypeId === 'course_resource' && libraryResource.contextType === 'published');
    dialogManager.setOpenDialog(DIALOG_NAMES.RESOURCE_BANK_SHOP, {
      assessmentBankData: libraryCardData,
      closeResourceBankShopModal: this.closeResourceShoppingModal,
      currentCourseElementList,
      currentCourseId,
      currentElementId,
      handleView,
      isLiveCourse
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.RESOURCE_BANK_SHOP));
  }

  handleCreateNew = async () => {
    const { dialogManager } = this.props;
    // Launch the create new assessment/(bank) wizard first page.
    dialogManager.setOpenDialog(DIALOG_NAMES.SELECT_NEW_RESOURCE_MODAL, {
      openResourceShopping: this.handleOpenResourceShopping,
      onCloseCreateNewLibraryResourceModal: this.onCloseCreateNewResourceModal,
      selectNewResourceOpen: true,
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.SELECT_NEW_RESOURCE_MODAL));
  }

  handleClickAddNewResource = async (_event, pageInfo) => {
    const { userManager, libraryCardData } = this.props;
    const { libraryResource } = libraryCardData;
    const isLiveCourse = (libraryResource.entityTypeId === 'course_resource' && libraryResource.contextType === 'published');

    // if user has ItemBanking permission show interim create new /add existing modal
    if (userManager.canViewLibraryItemBank && !isLiveCourse) {
      // Launch the create new resource wizard first page.
      this.handleCreateNew();
    } else if (userManager.canViewLibraryResourceBank || isLiveCourse) {
      // else open the new resource shopping directly.
      this.handleOpenResourceShopping();
    }
  }

  handleEditAssessmentBankAssessment = async (courseElement) => {
    const { dialogManager, t } = this.props;
    if (courseElement.type === 'assessment') {
      // Open the c2c-lesson assessement builder.
      dialogManager.setOpenDialog(DIALOG_NAMES.LIBRARY_ASSESSMENT_BUILDER_VIEW, {
        closeLibraryAssessmentBuilderModal: this.closeLibraryAssessmentBuilderModal,
        history,
        contentItemId: courseElement.entityId
      },
      () => dialogManager.closeDialog(DIALOG_NAMES.LIBRARY_ASSESSMENT_BUILDER_VIEW));
    } else {
      // Not sure what to do here.  This would be a copy of a resource bank lesson but we can't edit it yet.
      // So show uneditable message
      dialogManager.setOpenDialog(DIALOG_NAMES.TEXT, {
        closeButtonName: 'Okay',
        message: t('editResourceUnavailableMsg'),
        title: t('editUnavailableTitle'),
      }, () => dialogManager.closeDialog(DIALOG_NAMES.TEXT));
    }
  }

  confirmEditPublishedAssessmentModal = (courseElement) => {
    const { dialogManager, t } = this.props;
    dialogManager.setOpenDialog(DIALOG_NAMES.CONFIRM, {
      title: t('editUnavailableTitle'),
      message: t('editAssessmentUnavailableMsg'),
      cancelButtonClass: 'cancelButton',
      cancelButtonName: t('editUnavailableCancelButton'),
      confirmButtonClass: 'saveButton',
      confirmButtonName: 'Copy',
      confirmHandler: () => this.copyLibraryResourceConfirm(courseElement, () => dialogManager.closeDialog(DIALOG_NAMES.CONFIRM))
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.CONFIRM));
  }

  confirmEditBankResourceModal = async (courseElement) => {
    const { courseManager, dialogManager, libraryManager, t } = this.props;
    const { currentCourseId, currentElementId } = courseManager;
    // call to check for resource edit
    const isEditable = await libraryManager.getResourceEditable(courseElement.contentItemId);
    if (isEditable) {
      // open new edit by copy modal
      dialogManager.setOpenDialog(DIALOG_NAMES.COPY_LIBRARY_RESOURCE_MODAL, {
        contentItemId: courseElement?.contentItemId,
        currentCourseId,
        currentElementId,
        headerLabel: t('copyResourcetitle'),
        onCloseCopyLibraryResourceModal: this.onCloseCopyLibraryResourceModal,
        resourceElementId: courseElement.elementId,
        resourceName: courseElement?.name,
        selectedResourceType: courseElement?.contentItemEntityTypeId,
        targetLocation: 'resourceBank'
      },
      () => dialogManager.closeDialog(DIALOG_NAMES.COPY_LIBRARY_RESOURCE_MODAL));
    } else {
      // show uneditable message
      dialogManager.setOpenDialog(DIALOG_NAMES.TEXT, {
        closeButtonName: 'Okay',
        message: t('editResourceUnavailableMsg'),
        title: t('editUnavailableTitle'),
      }, () => dialogManager.closeDialog(DIALOG_NAMES.TEXT));
    }
  }

  onCloseCopyLibraryResourceModal = async (refresh) => {
    const { courseManager, dialogManager } = this.props;
    const { currentElementId } = courseManager;
    if (refresh) {
      await this.fetchData(currentElementId, true);
    }
    // TODO redirect to edit resource
    dialogManager.closeDialog(DIALOG_NAMES.COPY_LIBRARY_RESOURCE_MODAL);
  }

  onPostCopyCallback = async () => {
    const { setCurrentLibraryMenu, dialogManager, t } = this.props;
    // We need to advise the user the copy is in my drafts and give them the option to go to it.
    dialogManager.setOpenDialog(DIALOG_NAMES.CONFIRM, {
      title: t('afterCopyTitle'),
      message: t('afterCopyMsg'),
      cancelButtonClass: 'cancelButton',
      cancelButtonName: t('afterCopyCancelButton'),
      confirmButtonClass: 'saveButton',
      confirmButtonName: t('afterCopyConfirmButton'),
      confirmHandler: () => {
        setCurrentLibraryMenu('myDrafts');
        dialogManager.closeDialog(DIALOG_NAMES.CONFIRM);
        this.closeThisModal();
      }
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.CONFIRM));
  }

  copyLibraryResourceConfirm = (courseElement, closeCallback = null) => {
    const { dialogManager, t } = this.props;
    if (closeCallback) {
      closeCallback();
    }
    dialogManager.setOpenDialog(DIALOG_NAMES.COPY_LIBRARY_RESOURCE_MODAL, {
      contentItemId: courseElement.contentItemId,
      headerLabel: t('copyTitle'),
      onCloseCopyLibraryResourceModal: this.onCloseCopyLibraryResourceModal,
      onPostCopyCallback: this.onPostCopyCallback,
      resourceName: courseElement.name,
      selectedResourceType: courseElement.contentItemEntityTypeId
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.COPY_LIBRARY_RESOURCE_MODAL));
  }

  closeLibraryAssessmentBuilderModal = async () => {
    const { dialogManager } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.LIBRARY_ASSESSMENT_BUILDER_VIEW);
  }

  getZeroState = () => {
    const { courseManager, t } = this.props;
    const { currentElementId } = courseManager;
    const isAssessmentLevel = currentElementId && currentElementId.length > 0;
    return (
      <>
        {isAssessmentLevel ? (
          <div className='no-units'>
            {t('noAssessmentsMsg', 'No Content.')}
          </div>
        )
          : (
            <div className='no-units'>
              {t('zeroStateMsg', 'No Content.')}
            </div>
          )}
      </>
    );
  };

  renderAssessmentBankModalContent = () => {
    const { courseManager, handleDetails, handleTagsAndStandards, history, t } = this.props;
    const { currentElementId } = courseManager;
    const { CourseLayout } = this;
    const isAssessmentLevel = currentElementId && currentElementId.length > 0;

    // console.log(`currentCourseId: ${courseManager.currentCourseId} list.length: ${courseManager.currentCourseElementList.length}`);// remove
    return (
      <Container>
        <Container className='assessment-bank-content resources-container' fluid>
          <div className='library-assessment-bank-view-header'>
            {!isAssessmentLevel ? (
              <>
                <div>Select <strong>Create New</strong> to add additional {t('unitLabel')}s. Open a {t('unitLabel')} to add Assessments to it. Select {t('editDetailsLabel')} to change the title of the {t('unitLabel')}.</div>
                <div>&nbsp;</div>
                <Button
                  className='ui primary button create-new-unit-button'
                        // disabled={isSelectedCoursesPopupOpen}
                  onClick={() => this.handleClickCreateNewUnit()}>
                  {t('addNewUnit', 'Create New')}
                </Button>
              </>
            )
              : (
                <>
                  <div>Select <strong>Add</strong> to add Assessments to the {t('unitLabel')}.</div>
                  <div>&nbsp;</div>
                  <Button
                    className='ui primary button create-new-unit-button'
                    // disabled={isSelectedCoursesPopupOpen}
                    onClick={() => this.handleClickAddNewResource()}>
                    {t('addNewAssessment', 'Add')}
                  </Button>
                </>
              )}
          </div>
          {(courseManager.currentCourseId !== null
                && courseManager.currentCourseElementList.length > 0) ? (
                  <CourseLayout
                    handleAdminDetails={handleDetails}
                    handleAdminLibraryAssessmentConfirmEdit={this.confirmEditPublishedAssessmentModal}
                    handleAdminLibraryEdit={this.handleEditAssessmentBankAssessment}
                    handleAdminResourceConfirmEdit={this.confirmEditBankResourceModal}
                    handleAdminTagsAndStandards={handleTagsAndStandards}
                    history={history}
                    showAdminControls={true} />
            ) : this.getZeroState()}
        </Container>
      </Container>
    );
  }

  render() {
    const { history, courseManager, libraryAssessmentBankViewModalOpen, libraryCardData, t } = this.props;
    const { libraryResource } = libraryCardData;
    const { BreadCrumbs } = this;
    const { currentElementId } = courseManager;
    const isAssessmentLevel = currentElementId && currentElementId.length > 0;
    const isLiveCourse = (libraryResource.entityTypeId === 'course_resource' && libraryResource.contextType === 'published');

    return (
      <Modal
        className='LibraryAssessmentBankViewModal'
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={this.closeThisModal}
        open={libraryAssessmentBankViewModalOpen}
        size='fullscreen'>
        {/* style={{ overflow: 'auto', top: '-14px' }}> */}
        <Modal.Header className='modal-header'>
          <Header.Content className='modal-header-bar'>
            <Popup content={libraryCardData.title} trigger={(
              <span className='modal-header-label'>
                {libraryCardData.title}
              </span>
            )} wide />
            <div className='modal-header-buttons'>
              <Button
                basic
                className='cancelButton'
                onClick={this.closeThisModal}
                primary>
                {t('Exit')}
              </Button>
            </div>
          </Header.Content>
        </Modal.Header>
        {isLiveCourse && (
          <Container className='live-edit-banner'>
            <Image alt='alert' className='header-alert-icon' spaced src={iconAlert} />
            <div className='header-alert-message'>{t('liveEditingBannerMsg')}</div>
          </Container>
        )}
        {isAssessmentLevel && (
          <Container className='bread-crumb-wrapper' fluid>
            <BreadCrumbs history={history} />
          </Container>
        )}
        <div className='nav-separator' />
        <Modal.Content scrolling>
          {this.renderAssessmentBankModalContent()}
        </Modal.Content>
      </Modal>
    );
  }
}

SatCoreRegister('LibraryAssessmentBankViewModal', LibraryAssessmentBankViewModal);
