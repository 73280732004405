import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Dropdown, Menu } from 'semantic-ui-react';
import { SatCoreRegister } from '../SatCoreRegistry';

export default
@inject('classroomManager', 'userManager')
@observer
class ClassMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  render() {
    const {
      classroomManager,
      handleChangeStudentClassroom,
      handleJoinClass,
      joinClassLabel,
      optionTitleLabel,
      userManager
    } = this.props;
    const { open } = this.state;
    const { currentClassroomId } = classroomManager;
    const classLabel = currentClassroomId ? classroomManager.getClassName(currentClassroomId) : joinClassLabel;
    return (
      <Menu.Item
        as='a'
        className='tnav-tab class-menu'
        onMouseEnter={() => this.setState({ open: true })}
        onMouseLeave={() => this.setState({ open: false })}
        role='tab'
        tabIndex='-1'>
        <Dropdown
          aria-haspopup={true}
          aria-label='Class menu'
          as={(props) => <div {...props} role='menu' />}
          className='class-selector'
          closeOnChange
          icon='caret down'
          open={open}
          options={[
            {
              as: (props) => <div {...props} role='menuitem' tabIndex='-1' />,
              className: 'switch-option',
              key: 'classO_switch',
              onClick: handleJoinClass,
              text: (
                <>
                  <span className='text'>{optionTitleLabel}</span>
                  {userManager.isSsoUser || (
                    <Button className='join-class'
                      onClick={handleJoinClass}
                      primary
                      tabIndex={open ? '0' : '-1'}
                      type='button'>
                      {joinClassLabel}
                    </Button>
                  )}
                </>
              )
            },
            ...classroomManager.classroomsArray.map(({ id }) => ({
              as: (props) => <div {...props} role='menuitem' tabIndex={open ? '0' : '-1'} />,
              key: `classO_${id}`,
              onClick: async (_event, _data) => {
                return handleChangeStudentClassroom(id);
              },
              text: classroomManager.getClassName(id),
              value: id
            }))
          ]}
          selectOnBlur={false}
          simple
          tabIndex='0'
          trigger={<div className='class-dropdown-selection' tabIndex='-1'>{classLabel}</div>}
          value={currentClassroomId} />
      </Menu.Item>
    );
  }
}

SatCoreRegister('ClassMenu', ClassMenu);
