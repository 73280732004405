import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Input, Message } from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import Modal from '../components/Modal';
import '../css/ChangePassword.less';

export default
@inject('userManager')
@observer
class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      oldPassword: '',
      newPassword: '',
      newConfirmPassword: '',
      passwordError: false,
      savePasswordError: false,
      confirmPasswordInputType: 'password',
      newPasswordInputType: 'password',
      tooShortError: false
    };

    this.ShowPasswordButton = SatCoreComponent('ShowPasswordButton');
    this.ModalBanner = SatCoreComponent('ModalBanner');
  }

  closeChangePasswordModal = () => {
    const { close } = this.props;
    this.setState({ newPassword: '', oldPassword: '', newConfirmPassword: '', passwordError: false, tooShortError: false, savePasswordError: false });
    close();
  };

  handleShowPasswordPressed = (name, isPasswordInput) => {
    const inputType = isPasswordInput ? 'password' : 'text';
    this.setState({ [name]: inputType });
  }

  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState((prevState) => {
      const newState = { ...prevState };
      newState[name] = value;
      return newState;
    });
  }

  validate = (e) => {
    const { name } = e.target;

    switch (name) {
      case 'newConfirmPassword':
        this.setState((prevState) => {
          const { newPassword, newConfirmPassword } = prevState;
          return {
            newConfirmPassword,
            passwordError: newPassword !== newConfirmPassword
          };
        });
        break;
      case 'newPassword':
        this.setState((prevState) => {
          const { newPassword } = prevState;
          return {
            newPassword,
            tooShortError: newPassword.length < 5
          };
        });
        break;
      default:
    }
  }

  clearError = () => {
    this.setState({ passwordError: false, savePasswordError: false });
  }

  checkPasswordValues = () => {
    const { oldPassword, newPassword, newConfirmPassword } = this.state;
    return oldPassword !== '' && newPassword !== '' && newConfirmPassword !== '';
  }

  savePasswordChange = async () => {
    const { userManager, modal } = this.props;
    const { oldPassword, newConfirmPassword, passwordError, tooShortError } = this.state;
    if (this.checkPasswordValues() && !passwordError && !tooShortError) {
      const result = await userManager.updatePassword(oldPassword, newConfirmPassword);

      if (result) {
        modal ? this.closeChangePasswordModal() : this.props.history.push('/profile');
      } else {
        this.setState({ savePasswordError: true });
      }
    }
  }

  renderErrors() {
    const { passwordError, savePasswordError, tooShortError } = this.state;
    return (
      <>
        {passwordError && (
        <Message negative>
          <Message.Header>Passwords do not match</Message.Header>
        </Message>
        )}
        {savePasswordError && (
        <Message negative>
          <Message.Header>Password not saved</Message.Header>
          <p>Something went wrong</p>
        </Message>
        )}
        {tooShortError && (
        <Message negative>
          <Message.Header>Password too short</Message.Header>
          <p>The password must be at least eight characters long.</p>
        </Message>
        )}
      </>
    );
  }

  renderContent() {
    const { ShowPasswordButton } = this;
    const { oldPassword, newPassword, newPasswordInputType, newConfirmPassword, confirmPasswordInputType } = this.state;
    return (
      <>
        <div className='change-password-txt'>Enter old password</div>
        <Input
          name='oldPassword'
          onChange={this.handleChange}
          type='password'
          value={oldPassword} />
        <div className='change-password-txt'>Enter new password</div>
        <Input
          icon={(
            <ShowPasswordButton
              isPassword={newPasswordInputType === 'password'}
              name='newPasswordInputType'
              onFlip={this.handleShowPasswordPressed} />
        )}
          name='newPassword'
          onBlur={this.validate}
          onChange={this.handleChange}
          onFocus={this.clearError}
          type={newPasswordInputType}
          value={newPassword} />
        <div className='change-password-txt'>Confirm new password</div>
        <Input
          icon={(
            <ShowPasswordButton
              isPassword={confirmPasswordInputType === 'password'}
              name='confirmPasswordInputType'
              onFlip={this.handleShowPasswordPressed} />
        )}
          name='newConfirmPassword'
          onBlur={this.validate}
          onChange={this.handleChange}
          onFocus={this.clearError}
          type={confirmPasswordInputType}
          value={newConfirmPassword} />
      </>
    );
  }

  renderActions(cancel = true) {
    const { passwordError, savePasswordError, tooShortError } = this.state;
    const error = passwordError || savePasswordError || tooShortError;
    return (
      <>
        {cancel && (
        <Button
          basic
          className='change-password-btn'
          onClick={this.closeChangePasswordModal}
          primary>
          Cancel
        </Button>
        )}
        <Button
          className='change-password-btn'
          disabled={error}
          onClick={this.savePasswordChange}
          primary>
          Save
        </Button>
      </>
    );
  }

  render() {
    const { ModalBanner } = this;
    const { open, modal } = this.props;
    if (!modal) {
      return (
        <div className='change-password-page'>
          <div className='change-password-container'>
            <div className='change-password-heading'>
              Change Password
            </div>
            {this.renderContent()}
            {this.renderActions(false)}
            {this.renderErrors()}
          </div>
        </div>
      );
    }
    return (
      <Modal
        className='change-password-page'
        closeOnEscape={true}
        onClose={this.closeChangePasswordModal}
        open={open}
        size='tiny'>
        <div className='change-password-container'>
          <ModalBanner label='Change Password' onClose={this.closeChangePasswordModal} titleContainer={{ id: 'change_password' }} />
          <Modal.Content className='main-content'>
            {this.renderContent()}
            {this.renderErrors()}
          </Modal.Content>
          <Modal.Actions>
            {this.renderActions()}
          </Modal.Actions>
        </div>
      </Modal>
    );
  }
}

ChangePassword.defaultProps = {
  modal: true
};

SatCoreRegister('ChangePassword', ChangePassword);
